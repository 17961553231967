import Comment from '@models/Comment';
import dayjs from 'dayjs';
import * as React from 'react';

export interface ICommentItemProps {
  comment: Comment
}

export default class CommentItem extends React.Component<ICommentItemProps> {
  public render() {
    const {comment} = this.props;
    return (
      <div className='py-3 border-top'>
        <div className='row-between'>
          <div className='fw-bold'>{comment.createdBy.getFullName()}</div>
          <div className='text-s text-secondary'>{dayjs(comment.createdAt).format("D MMMM YYYY HH:mm")}</div>
        </div>
        <div>
          {comment.content}
        </div>
      </div>
    );
  }
}
