import React, { Component } from 'react';
import EntityManager from '@services/EntityManager';
import AlertModel from '@models/Alert';
import dayjs from 'dayjs';
import DOMService from '@services/DOMService';
import { SidebarRoute } from './layout/SidebarRouter';

interface Alert {
    id: string;
    startAt: string;
    message: string;
}

interface AlertSummary {
    lessThan7Days: number;
    lessThan24Hours: number;
    passed: number;
}

interface State {
    alerts: AlertModel[];
    alertSummary?: AlertSummary;
}

class AlertWidget extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            alerts: [],
        };
    }

    componentDidMount() {
        this.loadAlerts();
    }

    calculateAlertSummary() {
        const now = dayjs();
        const summary: AlertSummary = { lessThan7Days: 0, lessThan24Hours: 0, passed: 0 };

        this.state.alerts.forEach(alert => {
            const alertDate = dayjs(alert.startAt);
            if (alertDate.isBefore(now)) {
                summary.passed += 1;
            } else if (alertDate.isBefore(now.add(24, 'hour'))) {
                summary.lessThan24Hours += 1;
            } else if (alertDate.isBefore(now.add(7, 'days'))) {
                summary.lessThan7Days += 1;
            }
        });

        this.setState({ alertSummary: summary });
    }

    async loadAlerts() {
        try {
            let response = await EntityManager.all<AlertModel>(AlertModel);
            if (response.models) {
                const sortedAlerts = response.models.sort((a, b) => dayjs(a.startAt).diff(dayjs(b.startAt)));
                this.setState({ alerts: sortedAlerts }, this.calculateAlertSummary);
            }
        } catch (error) {
            console.error("Failed to load alerts", error);
        }
    }

    removeAlert = (id: string) => {
        this.setState(prevState => ({
            alerts: prevState.alerts.filter(alert => alert.id !== id)
        }), this.calculateAlertSummary);
    }

    handleGoToClick = (alert) => {
        DOMService.openSidebar(SidebarRoute.AlertForm, {id: alert.id, onSubmit:(alert) => {
                DOMService.closeSidebar();
                this.loadAlerts();
            }})
    }

    render() {
        const { alerts } = this.state;
        const now = dayjs();
        const activeAlerts = alerts.filter(alert => alert.active);

        if (activeAlerts.length === 0) {
            return null;
        }

        return (
            <div className="position-fixed top-0 end-0 p-3 bg-dark bottom-0 bg-opacity-75" style={{ zIndex: 5 }}>
                {this.state.alerts.filter(alert => alert.active).map((alert) => {
                    const alertDate = dayjs(alert.startAt);
                    const isPassed = alertDate.isBefore(now);
                    const isLessThan24Hours = alertDate.isBefore(now.add(24, 'hour'));
                    const isLessThan7Days = alertDate.isBefore(now.add(7, 'days'))

                    return (
                        <div key={alert.id} className="alert alert-light alert-dismissible fade show w-75 mx-auto mb-2" role="alert">
                            <strong>Alerte :</strong> {alert.message}
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.removeAlert(alert.id)}></button>
                            <div><strong>Date :</strong> {dayjs(alert.startAt).format('DD/MM/YYYY HH:mm')}</div>
                            {isPassed && <div className="alert alert-dark mt-2">Cette alerte est dépassée!</div>}
                            {isLessThan24Hours && !isPassed && <div className="alert alert-danger mt-2">Moins de 24 heures restantes!</div>}
                            {isLessThan7Days && !isPassed && !isLessThan24Hours && <div style={{backgroundColor:"#edc599"}} className={"alert mt-2"}>À réaliser dans la semaine.</div>}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default AlertWidget;
