import AsyncButton from '@components/button/AsyncButton';
import AvatarLetter from '@components/common/AvatarLetter';
import { DummyForm } from '@components/form/DummyForm';
import SmartInputText from '@components/input/SmartInputText';
import SidebarLayout from '@components/layout/SidebarLayout';
import RecordManagerV2 from '@components/logic/RecordManagerV2';
import User from '@models/User';
import Yard from '@models/Yard';
import EntityManager from '@services/EntityManager';
import * as React from 'react';

export interface IYardSharePageProps {
  yardId
}

export default class YardSharePage extends React.Component<IYardSharePageProps, any> {

  public manager: RecordManagerV2<User>;

  constructor(props) {
    super(props);
    this.state = this.initialState()
  }

  initialState() {
    return {
      userId: null,
      firstname: "",
      email: "",
      sharing: null
    }
  }

  submit = async (params) => {
    let response = await EntityManager.post<Yard>(Yard, {path: this.props.yardId + "/share", params});
    if (response.ok) {
      await this.manager.loadModels();
      this.setState(this.initialState());
    }
  }

  selectUser = (user: User) => {
    this.setState({
      sharing: user.id
    })
    this.submit({
      userId: user.id,
      firstname: user.firstname,
      email: user.email,
    });
  }

  public render() {
    const {userId, firstname, email, sharing} = this.state;
    return (
      <SidebarLayout
        title="Partager le chantier"
      >
        <DummyForm submit={() => this.submit({userId, firstname, email})} formProps={{}}>
          {(loading, validity) =>
            <div className="mb-5">
              <SmartInputText label={"Email"} containerClassName='mb-3' onChange={(_) => this.setState({email: _})} value={this.state.email} required />
              <SmartInputText label={"Prénom"} containerClassName='mb-3' onChange={(_) => this.setState({firstname: _})} value={this.state.firstname} required />
              <AsyncButton loading={loading} className="btn btn-primary ">{"Partager"}</AsyncButton>
            </div>
          }
        </DummyForm>
        <RecordManagerV2<User> options={{modelClass: User, params: {scope: "onlyCustomer", _with: ["yardShares"]}, loadOnReady: true}} >
          {(manager, props) => {
            this.manager = manager;
            return <div className="">
              <div className='mb-4'>
                Derniers partages
              </div>
              <div>
                {props.models?.map((model) => (
                  <div className='row-flex mb-3'>
                    <AvatarLetter name={model.firstname} />
                    <div className='ms-3'>
                      <div className='text-capitalize fw-bold'>
                        {model.getFullName()}
                      </div>
                      <div>
                        {model.email}
                      </div>
                    </div>
                    { model.isShared(this.props.yardId) ?
                      <div className='ms-auto text-primary'>
                        Déjà partagé
                      </div>
                      : <AsyncButton loading={sharing === model.id} onClick={() => this.selectUser(model)} className="ms-auto btn btn-primary">
                      Partager
                    </AsyncButton>}
                  </div>
                ))}
              </div>
            </div>
          }}
        </RecordManagerV2>
      </SidebarLayout>
    );
  }
}
