import * as React from 'react';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import SmartInput2, { ISmartInputProps } from './SmartInput2';


function SmartColorInput(props: ISmartInputProps) {

  let id = uniqueId();
  const {
    containerClassName,
    manager,
    className,
    autoFocus
  } = props;
  return (
    <div className={containerClassName}>
      <div>
        { manager.renderLabel()}
      </div>
      <label id={id} className={classNames({"color-indice position-relative mb-0 selected": true, [containerClassName]: !!containerClassName})}  >
        <div className={classNames({"color-indice-input": true, "border": props.value === "#ffffff", [props.className]: className})} style={{...props.style, backgroundColor: props.value}}></div>
        <input
          value={props.value}
          name={props.name}
          onBlur={props.onBlur}
          disabled={props.disabled}
          autoFocus={autoFocus}
          className="visually-hidden position-absolute"
          onChange={ manager.handleChange }
          required={props.required}
          type="color"
          id={id}
        />
      </label>
      {props.children}
    </div>
  )
}

export default SmartInput2<ISmartInputProps, any>(SmartColorInput)