
import KeyyoCall from '@core/KeyyoCall';
import { EventEmitter } from 'events';
import React from "react";
import AlertDialog, { IAlertDialogProps } from "../components/layout/AlertDialog";
import AppSidebar from "../components/layout/AppSidebar";
import BaseModal from "../components/layout/BaseModal";
import BaseToaster from "../components/layout/BaseToaster";
import DialogBase from "../components/layout/DialogBase";
import SidebarRouter, { SidebarRoute } from "../components/layout/SidebarRouter";

class DOMService {

  modalRef: BaseModal;
  sidebarRef: AppSidebar
  toasterRef: BaseToaster;
  dialogRef: DialogBase
  callDialogRef: any
  sidebarLayoutRef: any;
  notifierRef;
  emitter = new EventEmitter();

  modal(modal: JSX.Element) {
    this.modalRef.setModal(modal);
    return this;
  }

  alert(props: IAlertDialogProps) {
    props.onCancel = () => {
      this.closeModal();
    }
    this.modalRef.setModal(<AlertDialog {...props} />)
  }

  pop(modal: JSX.Element, props: any = {}) {
    return this;
  }

  show() {
    this.modalRef.setState({show: true})
  }

  closeModal() {
    this.modalRef.setState({show: false, modal: null})
  }

  openSidebar(route: SidebarRoute, props = null, params = {}) {
    SidebarRouter.addStack({route, props});
    this.sidebarRef.show(params);
  }

  closeSidebar() {
    this.sidebarRef.close();
    this.emitter.emit("closeAdminSidebar")
  }

  openCallDialog(keyooCall: KeyyoCall) {
    this.callDialogRef.setDialog(keyooCall);
  }

  closeCallDialog() {
    this.callDialogRef.close()
  }

  onCloseAdminSidebar(callback) {
    this.emitter.once("closeAdminSidebar", callback);
  }

  setToaster(text) {
    if (SidebarRouter.stacks.length > 0) {
      this.sidebarLayoutRef.setState({error: text});
    } else {
      this.toasterRef.setToast(text);
    }
    return this;
  }

  dialog(model, promise: Promise<any>) {
    this.dialogRef.show(model, promise);
    return this;
  }

  // openAdminSidebar(route: AdminSidebarRoute, props = null, callback?) {
  //   this.adminSidebarRef.show(route, props);
  //   if (callback) this.onCloseAdminSidebar(callback);
  // }

  // closeToast() {
  //   toaster.setState({show: false})
  // }

  // toast(toast: JSX.Element) {
  //   toaster.setToast(toast);
  //   return this;
  // }

}

export default new DOMService();