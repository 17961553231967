import { convertHexToRGBA } from 'src/helper/ColorHelper';
import Model from 'src/models/Model';
import ArrayModelAttribute from './core/ArrayModelAttribute';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import Prestation from './Prestation';

export default class PrestationType extends Model {

  public label: string
  public key: string
  public icon: string
  public color: string
  public prestations: Prestation[]

  static modelUrl = "prestation_types";
  public modelName = "prestation_type";

  public attributes = new AttributesCollection({
    label: new Attribute("label"),
    key: new Attribute("key"),
    icon: new Attribute("icon"),
    color: new Attribute("color"),
    prestations: new ArrayModelAttribute("prestations", Prestation),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  solidIcon() {
    return `fas ${this.icon}`;
  }

  regularIcon() {
    return `far ${this.icon}`;
  }

  getStyle() {
    return {color: this.color, backgroundColor: convertHexToRGBA(this.color || "black", 10)}
  }

}