
import GetX from '@services/GetX';
import React from 'react';
import Model from '../../models/Model';
import DOMService from '../../services/DOMService';
import EntityManager from '../../services/EntityManager';

export interface IRecordManagerV2Props<T extends Model>{
  searchOnUrl?: boolean
  options: IRecordManagerV2Options
  defaultState?: IRecordManagerV2State<T>
  getRef?: string
  children: (manager: RecordManagerV2<T>, props: IRecordManagerV2State<T>) => JSX.Element
}


export interface IRecordManagerV2State<M extends Model> {
  models?: M[]
  input?: React.RefObject<HTMLInputElement>
  totalCount?: number
  page?: number
  lastPage?: number
  total?: number
  filter?: {[attr: string]: any}
  search?: string
  perPage?: number
  searching?: boolean
  selected?: M[]
}

export interface IRecordManagerV2Options {
  paginate?: boolean
  loadOnReady?: boolean
  parentModel?: Model
  defaultState?: IRecordManagerV2State<Model>
  params?: any
  modelClass: any
}

export default class RecordManagerV2<M extends Model> extends React.Component<IRecordManagerV2Props<M>, IRecordManagerV2State<M>> {

  public lastSearch: string;
  public options
  public inputRef = React.createRef<HTMLInputElement>()

  constructor(props: any) {
    super(props)

    this.state = {
      models: null,
      selected: [],
      input: React.createRef<HTMLInputElement>(),
      totalCount: null,
      page: 1,
      perPage: props.defaultState?.perPage || 30,
      lastPage: null,
      total: null,
      filter: props.defaultState?.filter || {}
    } as IRecordManagerV2State<M>
  }

  componentDidMount() {
    if (this.props.options.loadOnReady) this.loadModels();
    if (this.props.getRef) GetX.put(this.props.getRef, this);
  }

  componentWillUnmount(): void {
    if (this.props.getRef) GetX.destroy(this.props.getRef);
  }

  public loadModels = async () => {
    let params = this.buildParams()
    
    if (this.props.options.paginate) {
      this.setState({models: null})
      let response = await EntityManager.allWithPaginate<M>(this.props.options.modelClass, {params, parentModel: this.props.options.parentModel});
      
      this.setState({models: response.models, page: response.currentPage, lastPage: response.lastPage, total: response.total, searching: false});
    } else {
      let response = await EntityManager.all<M>(this.props.options.modelClass, {params, parentModel: this.props.options.parentModel});
      this.setState({models: response.models, totalCount: response.count, searching: false});
    }
  }

  public buildParams = () => {
    let params = {...this.state.filter, page: this.state.page, perPage: this.state.perPage, ...this.props.options.params};
    if (this.state.search) params["search"] = this.state.search;

    Object.keys(params).forEach(key => {
      if (!params[key]) delete params[key];
      if (params[key] instanceof Date) params[key] = params[key].toJSON();
    })
    return params;
  }

  public exportCsv = async (exportOption = "actual") => {
    let response;
    if (exportOption === "actual") response = await EntityManager.getDirect(this.props.options.modelClass, {path: "csv", params: this.buildParams()})
    if (exportOption === "all") response = await EntityManager.getDirect(this.props.options.modelClass, {path: "csv"})
    if (exportOption === "selected") response = await EntityManager.getDirect(this.props.options.modelClass, {path: "csv", params: {_in: this.state.selected.map(s => s.id)}})

    var blob=new Blob([response.response.data]);
    var link=document.createElement('a');
    link.href=window.URL.createObjectURL(blob);
    link.download= this.props.options.modelClass.modelUrl + ".csv";
    link.click();
  }

  public onPageChange = (page: number) => {
    this.setState({page}, () => this.loadModels());
    window.scroll({top: 0})
  }

  public onPerPageChange = (perPage: number) => {
    this.setState({perPage}, () => this.loadModels());
  }

  public updateMany = async (models: Model[], updateFields) => {
    models.forEach(m => m.update(updateFields));
    await EntityManager.updateMany(this.props.options.modelClass, {params: models, only: Object.keys(updateFields)});
    this.setState({selected: []})
    this.loadModels();
  }

  public deleteMany = async (models: Model[]) => {
    await EntityManager.deleteMany(this.props.options.modelClass, {params: models});
    this.setState({selected: []})
    this.loadModels();
  }

  public delete = async (model: Model, afterDelete?) => {
    DOMService.alert({
      title: "Suppression",
      message: "Etes vous sûr de vouloir supprimer cet élément ?",
      onConfirm: async () => {
        await EntityManager.delete(model);
        DOMService.closeModal();
        if (afterDelete) afterDelete();
        if (this.props.options.loadOnReady) this.loadModels();
      }
    });
  }

  handleSearch = (search) => {
    this.setState({search})
  }

  onSelectedChange = (selected: M[]) => {
    this.setState({selected})
  }

  onSearch = async () => {
    if (!this.state.search && !this.lastSearch) return;
    this.lastSearch = this.state.search;
    this.setState({ searching: true, page: 1, selected: [] }, () => this.loadModels());
  }

  onFilter = async (name: string, value) => {
    this.setState({filter: {[name]: value}, page: 1, selected: []}, () => this.loadModels());
  }

  mergeFilter = async (object) => {
    this.setState({filter: {...this.state.filter, ...object}, page: 1, selected: []}, () => this.loadModels());
  }

  removeFilters = (filterNames: string[]) => {
    let filter = {...this.state.filter};
    filterNames.forEach((name) => delete filter[name]);
    this.setState({filter, page: 1}, () => this.loadModels());
  }

  clearFilter = () => {
    this.setState({filter: {}, page: 1}, () => this.loadModels());
  }

  clearSearch = () => {
    this.setState({ search: "" , searching: false}, () => {
      // this.putSearchInUrl();
      this.lastSearch = "";
      this.loadModels();
      this.inputRef.current.focus();
    });
  }

  // putSearchInUrl() {
  //   if (!this.props.searchOnUrl) return;
  //   const search = "?" + queryString.stringify({ search: this.state.search }, {skipNull: true, skipEmptyString: true});
  //   this.props.history.push({
  //     search
  //   })
  // }

  public render() {
    return this.props.children(this, this.state)
  }
}


