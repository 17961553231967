import { sessionBloc } from "@bloc/SessionBloc";
import Yard from "@models/Yard";
import dayjs from "dayjs";
import YardGrid from "./YardGrid";

export default class CustomerYardGrid extends YardGrid {


  gridConfig = {
    headers: ["Secteur", "Début", "Fin", "Etat"],
    rowClassName: (model: Yard) => model.deletedAt ? "bg-light-warning" : null,
    body: [
      (yard: Yard) => <div className="text-capitalize">{yard.city}</div>,
      (yard: Yard) => dayjs(yard.startAt).format("D MMMM YYYY"),
      (yard: Yard) => dayjs(yard.endAt).format("D MMMM YYYY"),
      (yard: Yard) => this.renderStatus(yard),
    ]
  }

  firstBar() {
    return null
  }

  onEdit = null;

  public rowActions = (model: Yard) => <></>

  onRowClick = (model: Yard) => {
    sessionBloc.push("/clientarea/yards/" + model.id)
  };

}