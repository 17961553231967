import AsyncButton from '@components/button/AsyncButton';
import { DummyForm } from '@components/form/DummyForm';
import SmartInputText from '@components/input/SmartInputText';
import NoAppLayout from '@components/layout/NoAppLayout';
import classNames from 'classnames';
import * as React from 'react';
import { Redirect } from 'react-router';
import ApiService from '@services/ApiService';

export interface IForgotPasswordPageProps {
}

export default class ForgotPasswordPage extends React.PureComponent<IForgotPasswordPageProps, any> {

  constructor(props: IForgotPasswordPageProps) {
    super(props);

    this.state = {
      email: "",
      errors: null,
      redirect: false
    }
  }

  submit = async () => {
    const {email} = this.state;
    await ApiService.post("users/reset_password", {email});
    this.setState({redirect: true})
  }

  handleChange = (value, e) => {
    this.setState({[e.target.name]: value, errors: null})
  }

  public render() {
    const {email, errors, redirect} = this.state;
    if (redirect) return <Redirect to="/password_reset"/>
    return (
      <NoAppLayout>
        <h3 className="fw-bold mb-5 text-center">
        Mot de passe oublié ?
        </h3>
        <DummyForm submit={this.submit} formProps={{noValidate: true}}>
          {(loading, validity) =>
            <>
              <SmartInputText type="email" containerClassName="mb-4" className={classNames({"is-invalid": !!errors})} label="Adresse Email" value={email} name="email" required onChange={this.handleChange} autoFocus>
                {errors && <div className="text-danger mt-1">{errors.credential}</div>}
              </SmartInputText>
              <div className="text-center">
                <AsyncButton  loading={loading}  type="submit" className="btn btn-primary m-auto w-100 text-dark-primary">
                  Réinitialiser mon mot de passe
                </AsyncButton>
              </div>
            </>
          }
        </DummyForm>
      </NoAppLayout>
    );
  }
}
