import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';

export interface IProgressCanvaProps {
  value: number
  size: number
}

export function ProgressCanva (props: IProgressCanvaProps) {
  const {value, size} = props
  let remaining = 100 - value;
  if (remaining < 0) remaining = 0;
  
  return (
    <div className="position-relative mt-auto">
      <div style={{width: size+"px", margin: "auto"}}>
        <Doughnut data={{
          datasets: [{
            label: "test",
            data: [value, remaining],
            backgroundColor: ["#4d9cc1", "#e1e3ed"],
            borderColor: ["transparent", "transparent"]
          }],
          
        }} options={{
          cutout: size / 2 - (size / 9),
          plugins: {
            tooltip: {
              enabled: false
            },
            legend: {
              display: false
            },
          }
        }} />
      </div>
      <div style={{fontSize: (size / 70) + "rem"}} className="doughnut-text fw-bold">{value.toFixed(0)} %</div>
    </div>
  );
}
