import { AxiosResponse } from 'axios';
import Model from '../../models/Model';
import EntityManager from '../EntityManager';
import HttpResponse from './HttpResponse';

export default class ModelResponse<T extends Model> extends HttpResponse {

  public model: T;

  constructor(response: AxiosResponse, options?) {
    super(response, options);
    if (response.data.model) this.model = EntityManager.toModel(options.modelClass, response.data.model);
  }


}