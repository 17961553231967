import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import DOMService from '../../services/DOMService';

export default class BaseModal extends React.Component<any, any> {

  constructor(props) {
    super(props)
    DOMService.modalRef = this;
    this.state = {
      modal: null,
      show: false,
      props: {}
    }
  }

  public onHide = () => {
    this.setState({show: false});
  }

  public setModal = (modal: any) => this.setState({modal, show: true, props: modal.type?.modalProps ?? {}})

  public render() {
    const { show, modal, props } = this.state;
    // if (!show || !modal) return <></>;
    return (
      <Modal  show={show} {...props} onHide={this.onHide}>
        { modal }
      </Modal>
    );
  }
}
