import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import ModelAttribute from './core/ModelAttribute';
import Model from "./Model";
import Report from './Report';
import YardPrice from './YardPrice';

export default class ReportComposition extends Model {

  public quantity: number
  public yardPrice: YardPrice
  public reportId: number
  public yardPriceId: number
  public callingCard: number
  public report: Report

  static modelUrl = "report_compositions";
  public modelName = "report_composition";

  public attributes = new AttributesCollection({
    quantity: new Attribute("quantity"),
    callingCard: new Attribute("callingCard"),
    yardPrice: new ModelAttribute("yardPrice", YardPrice),
    report: new ModelAttribute("report", Report),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

}