import classNames from 'classnames';
import * as React from 'react';
import SmartInput2, { ISmartInputProps } from './SmartInput2';

export interface ISmartCheckboxProps extends ISmartInputProps {
  checked?: boolean;
  primary?: boolean;
  color?: string;
}

export interface ISmartCheckboxState {
}

function SmartCheckbox(props: ISmartCheckboxProps) {

  const {
    containerClassName,
    manager,
    id,
    label,
    className,
  } = props;
  return (
    <div className={ classNames({ [containerClassName]: !!containerClassName, "smart-checkbox": true, "smart-switch-primary": props.primary })}>
      <input
        value={props.value}
        name={props.name}
        disabled={props.disabled}
        id={props.id}
        required={props.required}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        style={props.style}
        className={"inp-cbx " + className}
        type="checkbox"
        onChange={ (e) => manager.updateState(e.target.checked, e) }
        checked={props.checked}
      />
      <label className={"cbx mb-0"} htmlFor={id}>
        <span>
          { props.checked ? <i className="fas fa-check"></i>: <i></i>}
        </span>
        <span>{label}</span>
      </label>
      {props.children}
    </div>
  );
}

export default SmartInput2<ISmartCheckboxProps, any>(SmartCheckbox)