import React from "react";
import SmartInput2, { ISmartInputProps } from "./SmartInput2";

export interface ISmartInputTextareaProps extends ISmartInputProps {
  rows?: number
  maxLength?: number
  minLenght?: number
  onFocus?
}

function SmartInputTextarea(props: ISmartInputTextareaProps) {

  const {
    maxLength,
    containerClassName,
    manager,
    className,
    rows,
    errorMessage
  } = props;
  return (
    <div className={containerClassName}>
    { manager.renderLabel()}
        <textarea
          value={props.value}
          disabled={props.disabled}
          name={props.name}
          id={props.id}
          required={props.required}
          placeholder={props.placeholder}
          style={props.style}
          ref={props.innerRef}
          maxLength={maxLength}
          onFocus={props.onFocus}
          className={className + " form-control"}
          onKeyDown={ manager.onKeyDown }
          onChange={ manager.handleChange }
          rows={rows ?? 3}
        ></textarea>
        {errorMessage && <div className="text-danger text-s mt-1">{errorMessage}</div>}
        {props.children}
    </div>
  );
}

export default SmartInput2<ISmartInputTextareaProps, any>(SmartInputTextarea)