import AttributesCollection from "./core/AttributeCollection";
import Attribute from "./core/Attribute";
import Model from './Model';

export default class LegalStatus extends Model {
    public name: string;

    static modelUrl = "legal_statuses";
    public modelName = "legalStatus";

    public attributes = new AttributesCollection({
        name: new Attribute("name"),
    });

    constructor(json) {
        super(json);
        this.fillAttributes(json);
    }
}
