import SidebarLayout from '@components/layout/SidebarLayout';
import SidebarRouter, { SidebarRoute } from '@components/layout/SidebarRouter';
import showModel, { IInjectedShowModelProps } from '@components/logic/ShowModel';
import Contact from '@models/Business';
import * as React from 'react';

export interface IMapPlaceDetailProps extends IInjectedShowModelProps<Contact> {
}

class MapPlaceDetail extends React.Component<IMapPlaceDetailProps> {
  public render() {
    const {model} = this.props; 
    return (
      <SidebarLayout
        title="Detail"
      >
        <div className="fadeInDown">
          <div className='mb-3'>
            <div className='text-s text-secondary'>Contact</div>
            <div>{model.getFullName()}</div>
            <div>{model.email}</div>
            <div>{model.phone}</div>
            <div>{model.portable}</div>
          </div>
          <div className='mb-3'>
            <div className='text-s text-secondary'>Adresse</div>
            <div className="address">{ [model.address?.number, model.address?.address].filter((p) => p).join(" ") }</div>
            {model.address?.addressComp && <div className="address">{ model.address?.addressComp }</div>}
            <div className="city">{ model.address?.cpAndCity() }</div>
          </div>
          <div className='mb-3'>
            <button onClick={() => SidebarRouter.push(SidebarRoute.ContactShow, {contact: model}) } className="btn btn-primary">Fiche contact</button>
          </div>
          <hr />
          <div className="mb-3 fw-bold">Interventions</div>
        </div>
      </SidebarLayout>
    );
  }
}

export default showModel(MapPlaceDetail, {modelClass: Contact}) as any