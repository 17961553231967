import * as React from 'react';
import SmartInput2, { ISmartInputProps } from './SmartInput2';

export interface ISmartInputNumberProps extends ISmartInputProps {
  step?: React.Key
  min?: React.Key
  max?: React.Key
  noBootstrapClass?: boolean
}
export interface ISmartInputNumberState {
}

function SmartInputNumber(props: ISmartInputNumberProps) {

  const {
    containerClassName,
    manager,
    className,
    autoFocus,
    maxLength,
    minLenght,
    min,
    max,
    errorMessage
  } = props;
  return (
    <div className={containerClassName}>
      { manager.renderLabel()}
      {
        manager.wrapWithIcon(
          <input
            value={props.value}
            name={props.name}
            type={"number"}
            disabled={props.disabled}
            id={props.id}
            required={props.required}
            placeholder={props.placeholder}
            onBlur={props.onBlur}
            style={props.style}
            className={className + " form-control"}
            onKeyDown={ manager.onKeyDown }
            onChange={ (e) => manager.updateState(e.target.value ? parseFloat(e.target.value) : null, e) }
            min={min}
            max={max}
            maxLength={maxLength}
            minLength={minLenght}
            autoFocus={autoFocus}
          />
        )
      }
      {errorMessage && <div className="text-danger text-s mt-1">{errorMessage}</div>}
      {props.children}
    </div>
  );
}

export default SmartInput2<ISmartInputNumberProps, any>(SmartInputNumber)