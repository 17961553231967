import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import Alert from "@models/Alert";
import InterventionSlot from '@models/InterventionSlot';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import ApiService from "@services/ApiService";
import listPlugin from "@fullcalendar/list";
import DOMService from "@services/DOMService";
import {SidebarRoute} from "@components/layout/SidebarRouter";
import Model from "@models/Model";

interface IContactSlotCalendarProps {
    contactId: number;
    parentModel: Model
    contentHeight?
    params?
    onSelect?: (start: Date, end: Date) => void
    add?: InterventionSlot,
    onSlotClick?
    eventContent?
    eventsInBackground?
    canEdit?
    list?
    with?
    hideAmountByDay?
    withCount?
}


interface IContactSlotCalendarState {
    events: [];
}

export default class ContactSlotCalendar extends Component<IContactSlotCalendarProps, IContactSlotCalendarState> {
    constructor(props: IContactSlotCalendarProps) {
        super(props);
        this.state = {
            events: [],
        };
    }

    componentDidMount() {
        this.fetchData(this.props.contactId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contactId !== this.props.contactId) {
            this.fetchData(this.props.contactId);
        }
    }

    fetchData = async (contactId) => {
        try {
            const response = await ApiService.get(`contacts/${contactId}/interventions-alerts`);
            const events = this.mapDataToEvents(response.data);
            // @ts-ignore
            this.setState({ events });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    onSlotClick = (event) => {
        if (!("intervention" in event)) {
            DOMService.openSidebar(SidebarRoute.AlertForm, {id: event.id, onSubmit: () => {
                    DOMService.closeSidebar();
                }});
        } else {
            DOMService.openSidebar(SidebarRoute.InterventionSlotForm, {id: event.id, onSubmit: () => {
                    DOMService.closeSidebar();
                }});
        }
        //DOMService.openSidebar(SidebarRoute.InterventionSlotForm, {id: slot.id, noCalendar: true, onSubmit: (_) => this.reload()})
    }

    mapDataToEvents(data) {
        const { interventions, alerts } = data;

        const events = [
            ...interventions.map(slot => ({
                title: `Rendez-vous: ${slot.comment || "No Description"} Agent: ${slot.user.firstname && slot.user.lastname ? slot.user.firstname + " " + slot.user.lastname : "No Name"}`,                start: new Date(slot.startAt),
                end: new Date(slot.endAt),
                color: "#f56b00",
                slot: slot
            })),
            ...alerts.map(alert => ({
                title: "Alerte: " + (alert.message || "No Message"),
                start: alert.startAt,
                end: dayjs(alert.startAt).add(1, 'hour').toDate(),
                color: "#ff0000",
                slot: alert
            }))
        ];
        return events;
    }

    render() {
        return (
            <FullCalendar
                plugins={[listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView='listWeek'
                headerToolbar={{
                    left: "prev,next",
                    center: "title",
                    right: "today"
                }}
                buttonText={{
                    today: "Aujourd'hui",
                    timeGridDay: "Par jour",
                    timeGridWeek: "Par semaine",
                    listWeek: "Liste"
                }}
                events={this.state.events}
                locale={"fr"}
                hiddenDays={[0, 6]}
                slotDuration={'00:30:00'}
                slotMinTime="00:00:00"
                eventClassNames={"pointer"}
                slotMaxTime="24:00:00"
                eventClick={(e) => this.onSlotClick(e.event._def.extendedProps.slot)}
            />
        );
    }
}
