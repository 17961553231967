import Interlocutor from '@models/Interlocutor';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import Model from "./Model";
import RequiredValidator from './core/form/validators/RequiredValidator';
import Sector from "./Sector";
import ArrayModelAttribute from "./core/ArrayModelAttribute";

export default class Customer extends Model {

  public name: string
  public email: string
  public phone: string
  public comment: string
  public address: string
  public city: string
  public cp: string
  public contactImportClass: string
  public interventionImportClass: string
  public sectors: Sector[]
  public interlocutors: Interlocutor[]

  static modelUrl = "customers";
  public modelName = "customer";

  public attributes = new AttributesCollection({
    name: new Attribute("name", {validator: new RequiredValidator()}),
    email: new Attribute("email"),
    phone: new Attribute("phone"),
    address: new Attribute("address"),
    city: new Attribute("city"),
    cp: new Attribute("cp"),
    comment: new Attribute("comment"),
    sectors: new ArrayModelAttribute("sectors", Sector),
    interlocutors: new ArrayModelAttribute("interlocutors", Interlocutor),
    contactImportClass: new Attribute("contactImportClass"),
    interventionImportClass: new Attribute("interventionImportClass"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  cpAndCity() {
    return [this.cp, this.city].join(" ")
  }

}