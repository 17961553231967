import ModelAttribute from "./ModelAttribute";
import Option from '../Option';
import Model from "../Model";
import OptionService from "../../services/OptionService";


export class OptionAttribute extends ModelAttribute {

  public optionType: string

  constructor(name: string, options?) {
    super(name, Option, options);
    this.optionType = options?.optionType || this.resolveOptionType();
  }

  public fill(parentModel: any, json) {
    super.fill(parentModel, json);
    if (!this.value) this.value = OptionService.find(this.idValue);
  }

  setDefault() {
    let option = OptionService.getDefault(this.optionType);
    if (option) {
      this.value = option;
      this.idValue = option.id;
    }
  }

  resolveOptionType() {
    return this.name.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }

  public toApiData(parentModel: Model, stringify = false) {
    return parentModel[this.key];
  }

  checkForSubmit() {
    if (!this.validator) return true;

    let valid = this.validator.check(this.getValue());
    if (!valid) {
      this.errors = this.validator.getError();
      this.change();
    }
    return valid;
  }

  hasFileAttribute() {
    return false;
  }
}