import React, {Component} from 'react';
import {
    format,
    isWeekend,
} from 'date-fns';
import {fr} from 'date-fns/locale';
import EntityManager from "@services/EntityManager";
import UserState from "@models/UserState";
import User from "@models/User";
import ApiService from "@services/ApiService";
import _ from "lodash";
import Yard from "@models/Yard";
import Model from "@models/Model";
import Select from "react-select/base";
import MonthPicker from "@components/common/MonthPicker";
import List from "@models/List";
import {sessionBloc} from "@bloc/SessionBloc";

const idSDTC = 12;

interface State {
    currentDate: Date;
    mode: number;
    states: any;
    employees: any;
    calendar: any[];
    modification: Object,
    yards: any[],
    selectedYard: any,
    yardInputValue: any,
    yardInputMenuIsOpen: boolean,
    searchUser: string,
    selectedYardFilter: any,
    yardFilterInputValue: any,
    yardFilterInputMenuIsOpen: boolean,

    userList: any
    selectedList: any
    listInputMenuIsOpen: boolean,
    listInputValue: string
}

interface Props {
    calendar: any[];

    refreshCalendar(date): any;

    workerYards: any,
    date: Date,
    currentDate: Date;
    mode: number;
    states: any;
    employees: any;
    yards: any[],
    selectedYard: any,
    yardInputValue: any,
    yardInputMenuIsOpen: boolean,
    searchUser: string,
    selectedYardFilter: any,
    yardFilterInputValue: any,
    yardFilterInputMenuIsOpen: boolean,

    userList: any
    selectedList: any
    listInputMenuIsOpen: boolean,
    listInputValue: string
    updateState: (updatedState: Partial<State>) => void;
}

interface OptionInterface {
    color: string,
    id: number,
    haveSector: number,
    stateName: string,
}

const tableContainer = {
    width: '100%',
    overflow: 'auto',
    maxHeight: '70vh',
};

const tableStyle = {
    minWidth: '600px',
};

const cellStyle = {
    width: '150px',
    padding: '8px',
    border: '.5px solid #ccc',
};

const weekendCellStyle = {
    ...cellStyle,
    backgroundColor: '#595959',
};

const holidayCellStyle = {
    ...cellStyle,
    backgroundColor: '#808080',
};

const headerCellStyle = {
    ...cellStyle,
    top: '0',
    backgroundColor: 'grey',
    zIndex: '2',
};

const selectStyles = (theme) => {
    if (theme === "theme--dark") {
        return {
            control: (provided, state) => ({
                ...provided,
                backgroundColor: "#1e1d2b",
                zIndex: "3",
                color: "white",
            }),
            menuPortal: (provided, state) => ({
                ...provided,
                backgroundColor: "#1e1d2b",
                zIndex: "3",
                color: "white",
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? "#007bff" : state.isFocused ? "#2c2b3c" : "#1e1d2b",
                color: state.isSelected ? "white" : "white",
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: "white",
            }),
        };
    } else {
        return {
            control: (provided, state) => ({
                ...provided,
                zIndex: "3",
            }),
            menuPortal: (provided, state) => ({
                ...provided,
                zIndex: "3",
            }),
        };
    }
};


function estJourFerie(date) {
    const joursFeries = [
        // Jours fériés fixes
        [1, 1],   // Jour de l'An
        [5, 1],   // Fête du Travail
        [5, 8],   // Victoire 1945
        [7, 14],  // Fête Nationale
        [8, 15],  // Assomption
        [11, 1],  // Toussaint
        [11, 11], // Armistice 1918
        [12, 25], // Noël

        // Jours fériés variables
        // Calculés en fonction du jour de Pâques
        calculerLundiDePaque(date.getFullYear()),
        calculerAscension(date.getFullYear()),
        calculerPentecote(date.getFullYear())
    ];

    const jour = date.getDate();
    const mois = date.getMonth() + 1; // Les mois commencent à 0

    for (const jourFerie of joursFeries) {
        const [moisFerie, jourFerieFixe] = jourFerie;
        if (mois === moisFerie && jour === jourFerieFixe) {
            return true;
        }
    }

    return false;
}

// Fonction pour calculer le jour de Pâques (méthode de Gauss)
function calculerPaques(annee) {
    const a = annee % 19;
    const b = Math.floor(annee / 100);
    const c = annee % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const n = Math.floor((h + l - 7 * m + 114) / 31);
    const p = (h + l - 7 * m + 114) % 31;

    const jour = p + 1;
    const mois = n;

    return [mois, jour];
}

// Fonction pour calculer le Lundi de Pâques
function calculerLundiDePaque(annee) {
    const [mois, jour] = calculerPaques(annee);
    const date = new Date(annee, mois - 1, jour);
    date.setDate(date.getDate() + 1); // Lundi de Pâques
    return [date.getMonth() + 1, date.getDate()];
}

// Fonction pour calculer l'Ascension
function calculerAscension(annee) {
    const [mois, jour] = calculerPaques(annee);
    const date = new Date(annee, mois - 1, jour);
    date.setDate(date.getDate() + 39); // Ascension (40 jours après Pâques)
    return [date.getMonth() + 1, date.getDate()];
}

// Fonction pour calculer la Pentecôte
function calculerPentecote(annee) {
    const [mois, jour] = calculerPaques(annee);
    const date = new Date(annee, mois - 1, jour);
    date.setDate(date.getDate() + 50); // Pentecôte (50 jours après Pâques)
    return [date.getMonth() + 1, date.getDate()];
}


class WorkerAgenda extends Component<Props, State> {
    menuPortalTarget = document.body;

    constructor(props) {
        super(props);
        this.state = {
            currentDate: props.date,
            mode: 1,
            states: null,
            employees: null,
            calendar: _.cloneDeep(props.calendar), //Pour empecher la modification du props
            modification: {},
            yards: [],
            selectedYard: {"name": "Sélectionner un chantier", "id": -1},
            yardInputValue: "",
            yardInputMenuIsOpen: false,
            searchUser: "",
            selectedYardFilter: {"name": "Tous", id: "Tous"},
            yardFilterInputValue: "",
            yardFilterInputMenuIsOpen: false,

            userList: [],
            selectedList: {"name": "Tous", id: "Tous"},
            listInputMenuIsOpen: false,
            listInputValue: ""
        };

        this.resetCalendar = this.resetCalendar.bind(this)
        this.updateCalendar = this.updateCalendar.bind(this)
        this.handleChangeYard = this.handleChangeYard.bind(this)
        this.handleChangeInputYard = this.handleChangeInputYard.bind(this)
        this.handleChangeWorkerSearch = this.handleChangeWorkerSearch.bind(this)
        this.handleMonthChange = this.handleMonthChange.bind(this)
        this.handleChangeFilterYard = this.handleChangeFilterYard.bind(this)
        this.handleChangeInputFilterYard = this.handleChangeInputFilterYard.bind(this)
        this.handleChangeFilterList = this.handleChangeFilterList.bind(this)
        this.handleChangeInputFilterList = this.handleChangeInputFilterList.bind(this)
    }


    componentDidMount() {
    }

    loadData() {
        //let result = await ApiService.get("agenda_states");
        //this.loadCalendar();
    }

    handleMonthChange(date: Date) {
        this.setState(prevState => ({currentDate: date}), () => {
            this.props.refreshCalendar(this.state.currentDate);
        });
    }

    handleCellClick = (dayIndex, employee, userIndex) => {
        if (this.props.mode !== idSDTC) {
            if (this.props.selectedYard.id > -1 || (this.props.states[this.props.mode].haveYard === 0)) {
                let calendar = this.state.calendar;
                let update = this.state.modification
                if (userIndex > -1) {
                    calendar[dayIndex].user_ids.splice(userIndex, 1)
                    calendar[dayIndex].state_ids.splice(userIndex, 1)
                    calendar[dayIndex].yard_ids.splice(userIndex, 1)
                }
                calendar[dayIndex].user_ids.push(employee.id)
                calendar[dayIndex].state_ids.push(this.props.mode)
                calendar[dayIndex].yard_ids.push(this.props.states[this.props.mode].haveYard === 1 ? this.props.selectedYard.id : "")

                if (!(employee.id in update)) {
                    update[employee.id] = {};
                }
                update[employee.id][calendar[dayIndex].day] = [this.props.mode, "382", this.props.states[this.props.mode].haveYard === 1 ? this.props.selectedYard.id : ""];
                this.setState({calendar: calendar})
            }
        } else {
            this.resetCalendar()
            this.setSDTC(employee.id, dayIndex + 1, this.props.date.getMonth() + 1, this.props.date.getFullYear(), this.props.mode);
        }
    }

    async setSDTC(user_id, day, month, year, state_id) {
        await ApiService.get("setSDTC", {user_id, day, month, year, state_id});
        this.props.refreshCalendar(this.props.currentDate)
    }

    handleNameClick = (employee) => {
        if (this.props.mode !== idSDTC && (this.props.selectedYard.id > -1 || (this.props.states[this.props.mode].haveYard === 0))) {
            if (employee) {
                let calendar = this.state.calendar;
                let update = this.state.modification
                calendar.forEach((day, dayIndex) => {
                    if (!isWeekend(new Date(day.day)) && !estJourFerie(new Date(day.day))) {

                        let userIndex = day.user_ids.findIndex((element) => element == employee)
                        if (userIndex > -1) {
                            day.user_ids.splice(userIndex, 1)
                            day.state_ids.splice(userIndex, 1)
                            day.yard_ids.splice(userIndex, 1)
                        }
                        day.user_ids.push(employee)
                        day.state_ids.push(this.props.mode)
                        day.yard_ids.push(this.props.states[this.props.mode].haveYard === 1 ? this.props.selectedYard.id : "")

                        if (!(employee in update)) {
                            update[employee] = {};
                        }
                        update[employee][calendar[dayIndex].day] = [this.props.mode, "382", this.props.states[this.props.mode].haveYard === 1 ? this.props.selectedYard.id : ""];
                    }
                })

                const firstDayOfNextMonth = new Date(this.state.currentDate.getFullYear(), this.state.currentDate.getMonth() + 1, 1);

                // Obtenez le dernier jour du mois suivant
                const lastDayOfNextMonth = new Date(firstDayOfNextMonth.getFullYear(), firstDayOfNextMonth.getMonth() + 1, 0);

                for (let day = new Date(firstDayOfNextMonth); day <= lastDayOfNextMonth; day.setDate(day.getDate() + 1)) {
                    if (!isWeekend(day) && !estJourFerie(day)) {
                        if (!(employee in update)) {
                            update[employee] = {};
                        }
                        //Problème décalage de 1 jour

                        const dateString = day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate()
                        update[employee][dateString] = [this.props.mode, "382", this.props.states[this.props.mode].haveYard === 1 ? this.props.selectedYard.id : ""];
                    }
                }

                this.setState({calendar: calendar, modification: update});
            }
        }
    }

    handleModeChange = (index) => {
        this.props.updateState({mode: index});
    }

    renderOptionButtons() {
        if (this.props.states !== null) {
            return (
                <div style={{textAlign: 'center'}}>
                    {Object.entries(this.props.states).map(([key, option]: [string, OptionInterface]) => (
                        <button
                            className={"btn btn-outline-dark me-3 mb-3"}
                            style={{backgroundColor: option.color}}
                            key={option.id}
                            onClick={() => this.handleModeChange(option.id)}
                        >
                            {option.stateName}
                        </button>
                    ))}
                    <div>
                        Secteur:
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            name="Chantier"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id as string}
                            options={[{"name": "Sélectionner un chantier", "id": -1}, ...this.props.yards]}
                            onChange={this.handleChangeYard}
                            inputValue={this.props.yardInputValue}
                            onInputChange={this.handleChangeInputYard}
                            onMenuClose={() => {
                                this.props.updateState({yardInputMenuIsOpen: false})
                            }}
                            onMenuOpen={() => {
                                this.props.updateState({yardInputMenuIsOpen: true})
                            }}
                            menuIsOpen={this.props.yardInputMenuIsOpen}
                            value={this.props.selectedYard}
                            //Case le style du dropbox pour pouvoir le modifier après
                            menuPortalTarget={this.menuPortalTarget}
                            styles={selectStyles(document.body.className)}
                        />
                    </div>
                </div>
            );
        }
    }

    formatDate(day) {
        let date = new Date(day)
        let formattedDate = `${format(date, 'EEEE', {locale: fr})} ${format(date, 'dd')}`
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
    }

    resetCalendar() {
        const deepCopyCalendar = _.cloneDeep(this.props.calendar);
        this.setState({calendar: deepCopyCalendar, modification: {}});
    }

    async updateCalendar() {
        //Envoie des données
        await ApiService.get("update_agenda", {modification: JSON.stringify(this.state.modification)});
        //this.loadData();
        this.setState({modification: {}})
        this.props.refreshCalendar(this.state.currentDate);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.calendar !== this.props.calendar) {
            this.setState({calendar: [...this.props.calendar]});
        }
    }

    handleChangeYard(yard) {
        this.props.updateState({selectedYard: yard});
    };

    handleChangeInputYard(search) {
        this.props.updateState({yardInputValue: search})
    }

    handleChangeFilterYard(yard) {
        this.props.updateState({selectedYardFilter: yard});
    };

    handleChangeInputFilterYard(search) {
        this.props.updateState({yardFilterInputValue: search})
    }

    handleChangeWorkerSearch(search) {
        this.props.updateState({searchUser: search.target.value})
    }

    handleChangeFilterList(list) {
        this.props.updateState({selectedList: list})
    }

    handleChangeInputFilterList(search) {
        this.props.updateState({listInputValue: search})
    }

    workerToShow(worker, yardFilterId) {
        let firstname = worker.firstname ? worker.firstname : ""
        let lastname = worker.lastname ? worker.lastname : ""
        let isShow = ((firstname.toLowerCase() + " " + lastname.toLowerCase()).search(this.props.searchUser.toLowerCase()) > -1)
        //Vérification qu'un filtrage est sélectionné
        let listId = []
        if (isShow && yardFilterId !== "Tous" && this.props.selectedList.name) {
            //Vérification le chantier a eu des travailleurs
            isShow = !!this.props.workerYards[yardFilterId]
            if (isShow) {
                isShow = this.props.workerYards[yardFilterId].includes(worker.id.toString())
            }
        }

        if (isShow) {
            let listId = this.props.selectedList.users;
            if (listId) {
                isShow = listId.includes(worker.id)
            }
        }

        return isShow
    }

    render() {
        // @ts-ignore
        return (
            <>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem', justifyContent: 'center', paddingTop: "10px"}}>
                    <div style={{marginRight: '1rem'}}>
                        <input name="Recherche travailleurs" value={this.props.searchUser}
                               onChange={this.handleChangeWorkerSearch} placeholder={"Recherche d'utilisateur"}/>
                    </div>
                    <div> Chantier:
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            name="Filtrage chantier"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id as string}
                            options={[{"name": "Tous", id: "Tous"}, ...this.props.yards]}
                            onChange={this.handleChangeFilterYard}
                            inputValue={this.props.yardFilterInputValue}
                            onInputChange={this.handleChangeInputFilterYard}
                            onMenuClose={() => {
                                this.props.updateState({yardFilterInputMenuIsOpen: false})
                            }}
                            onMenuOpen={() => {
                                this.props.updateState({yardFilterInputMenuIsOpen: true})
                            }}
                            menuIsOpen={this.props.yardFilterInputMenuIsOpen}
                            value={this.props.selectedYardFilter}
                            //Case le style du dropbox pour pouvoir le modifier après
                            menuPortalTarget={this.menuPortalTarget}
                            styles={selectStyles(document.body.className)}
                        />
                    </div>

                    <div style={{paddingLeft: "10px"}}>
                        Responsable:
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            name="Filtrage chantier"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id as string}
                            options={[{"name": "Tous", id: "Tous"}, ...this.props.userList]}
                            onChange={this.handleChangeFilterList}
                            inputValue={this.props.listInputValue}
                            onInputChange={this.handleChangeInputFilterList}
                            onMenuClose={() => {
                                this.props.updateState({listInputMenuIsOpen: false})
                            }}
                            onMenuOpen={() => {
                                this.props.updateState({listInputMenuIsOpen: true})
                            }}
                            menuIsOpen={this.props.listInputMenuIsOpen}
                            value={this.props.selectedList}
                            //Case le style du dropbox pour pouvoir le modifier après
                            menuPortalTarget={this.menuPortalTarget}
                            styles={selectStyles(document.body.className)}
                        />
                    </div>
                </div>
                {this.renderOptionButtons()}
                <div>
                    <MonthPicker initialMonth={this.props.currentDate} onMonthChange={this.handleMonthChange}/>
                </div>
                {Object.keys(this.state.modification).length > 0 &&
                    <div>
                        <button className={"btn btn-primary me-5 mb-3"} onClick={this.resetCalendar}>Annuler</button>
                        <button className={"btn btn-primary me-5 mb-3"} onClick={this.updateCalendar}>Valider</button>
                    </div>
                }

                <div style={tableContainer}>
                    <table style={tableStyle}>
                        <thead>
                        <tr>
                            <th style={headerCellStyle}>Day / Employee</th>
                            {this.props.employees && this.props.employees.map((employee) => (
                                this.workerToShow(employee, this.props.selectedYardFilter.id) ?
                                    <th className={"position-sticky"} key={employee.id} style={headerCellStyle}
                                        onClick={() => this.handleNameClick(employee.id)}>
                                        {employee.firstname ? employee.firstname.charAt(0).toUpperCase() + employee.firstname.slice(1) : ""} {employee.lastname ? employee.lastname.charAt(0).toUpperCase() + employee.lastname.slice(1) : ""}
                                    </th> : <></>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.calendar && this.props.yards.length !== 0 && this.state.calendar.map((day, indexDay) => {
                            let isWeek = isWeekend(new Date(day.day))
                            let isHoliday = estJourFerie(new Date(day.day))
                            return (
                                <tr key={day.day}>
                                    <td style={{
                                        ...cellStyle,
                                        position: 'sticky',
                                        fontSize: isWeek || isHoliday? "10px": "",
                                        left: '0',
                                        color: isWeek || isHoliday ? '#fff' : 'black',
                                        backgroundColor: isWeek ? '#595959' : isHoliday ? '#808080' : '#E6E6E6',
                                        zIndex: '1',
                                    }}>
                                        {this.formatDate(day.day)}
                                    </td>
                                    {this.props.employees && this.props.employees.map((employee) => {
                                        if (!this.workerToShow(employee, this.props.selectedYardFilter.id)) {
                                            return <></>
                                        }
                                        let userIndex = day.user_ids.findIndex((element) => element == employee.id)
                                        let yard = undefined;

                                        if (userIndex > -1) {
                                            yard = day.yard_ids[userIndex] !== "" ? this.props.yards.find((yard) => {
                                                return yard.id == day.yard_ids[userIndex]
                                            }) : "";
                                        }

                                        return <td key={employee.id + "_" + day.day}
                                                   style={isWeek ? weekendCellStyle : isHoliday ? holidayCellStyle : {
                                                       ...cellStyle,
                                                       backgroundColor: userIndex > -1 ? this.props.states[day.state_ids[userIndex]].color : ""
                                                   }}
                                                   onClick={isWeek || isHoliday ? null : () => this.handleCellClick(indexDay, employee, userIndex)}>
                                            {!isWeek && !isHoliday && userIndex > -1 && <>
                                                {(yard ? yard.name + " " : "") + this.props.states[day.state_ids[userIndex]].stateName}

                                            </>}
                                        </td>
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </>
        )
            ;
    }
}

export default WorkerAgenda;
