import * as React from 'react';
import classNames from 'classnames';

export interface ITextIconProps {
  leftIcon?: string;
  rightIcon?: string;
  spacing?: number;
  iconStyle?: React.CSSProperties;
  iconClassName?: string
  className?: string
  center?: boolean
  onClick?: (e) => void
}

export default function TextIcon(props: ITextIconProps & React.HTMLProps<HTMLDivElement>) {
  const { leftIcon, rightIcon, children, spacing, className, onClick, iconStyle, iconClassName, center } = props;
  return (
    <div onClick={onClick} className={classNames({["row-" + (center ? "center" : "flex")]: true,  [className]: !!className})}>
      { leftIcon && <div className={classNames({"width-4": true, [`me-${spacing}`]: !!spacing})}>
        <i style={{...iconStyle}} className={classNames({[leftIcon]: true, [iconClassName]: !!iconClassName})} />
      </div> }
      <span>{children}</span>
      { rightIcon && <div className="width-4 text-end">
        <i style={iconStyle} className={classNames({[rightIcon]: true, [`ms-${spacing}`]: !!spacing, [iconClassName]: !!iconClassName})} />
      </div>}
    </div>
  );
}
