import * as React from 'react';

export interface IAlertDialogProps {
  title: string
  message: string
  buttonText?: string
  onConfirm: () => void
  onCancel?: () => void
}

export interface IAlertDialogState {
}

export default function AlertDialog(props: IAlertDialogProps) {

  const { title, message, onConfirm, onCancel, buttonText } = props;
  return (
    <div className="alert-dialog">
      <div className="alert-dialog-header">
        <div>
          <i className="fas fa-exclamation-circle fa-2x text-primary"></i>
        </div>
        <div>{title}</div>
      </div>
      <div className="alert-dialog-body">
        {message}
      </div>
      <div className="alert-dialog-footer">
        <button className="btn btn-light me-3" onClick={() => onCancel()}>Annuler</button>
        <button className="btn btn-primary" onClick={() => onConfirm()}>{buttonText || "Confirmer"}</button>
      </div>
    </div>
  );
}
