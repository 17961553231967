import AdminModelsList from '@components/common/AdminModelsList';
import Customiser from '@components/common/Customiser';
import MainLayout from '@components/layout/MainLayout';
import Prestation from '@models/Prestation';
import * as React from 'react';
import PrestationGrid from '../components/grid/PrestationGrid';
import { sessionBloc } from '../bloc/SessionBloc';
import { NavLink } from 'react-router-dom';
import DOMService from '@services/DOMService';
import { SidebarRoute } from '@components/layout/SidebarRouter';

export interface ISettingPageProps {
}

export default class SettingPage extends React.PureComponent<ISettingPageProps> {
  public render() {
    return (
      <MainLayout title="Paramètres">
        <div className="app-content">
          <div className="d-flex justify-content-between">
            <div className="mb-4">
              <Customiser/>
              <button onClick={() => DOMService.openSidebar(SidebarRoute.PasswordUpdatePage)} className="btn btn-primary btn-sm mt-4">Changer de mot de passe</button>
            </div>
            <div className="mb-4">
              <NavLink className="btn btn-light" to="/logout">Se déconnecter</NavLink>
            </div>
          </div>
          { sessionBloc.isAdmin() && <div>
            <div className="text-l mb-3">Prestations</div>
            <AdminModelsList
              gridClass={PrestationGrid}
              options={{ loadOnReady: true, paginate: true, modelClass: Prestation}}
            />
          </div>}
        </div>
      </MainLayout>
    );
  }
}
