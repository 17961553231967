import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import Model from './Model';

export default class UserState extends Model {
    public id: number
    public stateName: string
    public color: string
    public haveSector: boolean

    static modelUrl = "user_state";
    public modelName = "user_state";

    public attributes = new AttributesCollection({
        id: new Attribute("id"),
        stateName: new Attribute("stateName"),
        color: new Attribute("color"),
        haveSector: new Attribute("haveSector"),
    });

    constructor(json) {
        super(json)
        this.fillAttributes(json);
    }

}