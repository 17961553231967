import AsyncButton from '@components/button/AsyncButton';
import AdvancedContactSelect from '@components/crm/AdvancedContactSelect';
import SmartDatePicker from '@components/input/SmartDatePicker';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import SidebarLayout from '@components/layout/SidebarLayout';
import formModel, { IInjectedFormModelProps } from '@components/logic/FormModel';
import Alert from '@models/Alert';
import Contact from '@models/Business';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import dayjs from 'dayjs';
import * as React from 'react';
import MultiUserSelect from './MultiUserSelect';
import SmartSelect from '@components/input/SmartSelect';

export interface IAlertYardModalProps {
}

class AlertForm extends React.Component<IAlertYardModalProps & IInjectedFormModelProps<Alert>> {

  // constructor(props) {
  //   super(props);
    
  // }

  async inactivate() {
    this.props.model.active = false;
    await EntityManager.update(this.props.model, {only: ["active"]});
    this.props.onSubmit(this.props.model);
  }
  
  onContactSelected(contact: Contact) {
    this.props.model.contact = contact;
    this.setState({});
  }

  changeTime(attr, type, e) {
    const {model} = this.props;
    const date = model[attr] ? new Date(model[attr]) : new Date();
    if (type === "hour") {
      date.setHours(e.id);
    } else {
      date.setMinutes(e.id);
    }
    model[attr] = date;
    this.setState({});
  }


  delete = async () => {
    DOMService.setToaster(<div className="p-3">
      <div>Etes vous sur  de vouloir supprimer cette alerte ?</div>
      <div className="row-center mt-3">
        <div className="btn btn-light mx-2">Annuler</div>
        <div onClick={() => {
      EntityManager.delete(this.props.model).then(() => {
        DOMService.closeModal();
        this.props.onSubmit(this.props.model);
      });
    }} className="btn btn-danger mx-2">Supprimer</div>
      </div>
    </div>);
    
  }

  public render() {
    const {model, submitting, submit} = this.props;
    return (
      <SidebarLayout
        title={model.id ? "Modification d'une alerte" : "Création d'une alerte"}
        bottomArea={<AsyncButton className="btn w-100 btn-lg" style={{backgroundColor:"#d46e00", color:"white"}} loading={submitting} onClick={submit} >{model.id ? "Modifier l'alerte" : "Créer l'alerte"}</AsyncButton>}
        action={<div>
          {model.id && <div onClick={this.delete} className="my-n2 small-btn-icon">
            <i className="fas fa-trash"></i>
          </div>}
        </div>}
      >
        <div className="fadeInDown">
          {model.id && model.active && (
              <button onClick={() => this.inactivate()} className='btn btn-danger mb-4'>Désactiver l'alerte</button>
          )}
          {/* <SmartDatePicker model={model} name={"startAt"} label="M'alerter quand ?" containerClassName="mb-3"/> */}
          {model.startAt ? <div className="fw-bold mb-3">
                {dayjs(model.startAt).format("DD/MM/YYYY HH:mm")}
              </div> :
              <SmartDatePicker model={model} label="Quand ?" name="startAt" containerClassName='mb-3'/>
          }
          <div className="row-flex mb-3">
            <SmartSelect
                label="Heure début"
                onChange={(e) => this.changeTime("startAt", "hour", e)}
                containerClassName='me-2'
                options={[...Array(24).keys()].map(e => ({label: `${e}`.padStart(2, '0'), id: e}))}
                value={{
                  label: model.startAt ? `${model.startAt.getHours()}`.padStart(2, '0') : undefined,
                  id: model.startAt ? model.startAt.getHours() : undefined
                }}
            />
            <SmartSelect
                label="Minute début"
                onChange={(e) => this.changeTime("startAt", "minute", e)}
                containerClassName='me-6'
                options={[0, 15, 30, 45].map(e => ({label: `${e}`.padStart(2, '0'), id: e}))}
                value={{
                  label: model.startAt ? `${model.startAt.getMinutes()}`.padStart(2, '0') : undefined,
                  id: model.startAt ? model.startAt.getMinutes() : undefined
                }}
            />
          </div>
          <SmartInputTextarea model={model} name="message" label="Message" containerClassName="mb-3" rows={6}/>
          <AdvancedContactSelect contact={model.contact} onSelect={(contact) => this.onContactSelected(contact)}
                                 className="mb-3"/>
          <MultiUserSelect model={model} placeholder="Qui alerter ?"/>
          {/* <UserSelect model={model} name="to" placeholder="Choisir un utilisateur" label="Qui alerter ?" containerClassName="mb-3" /> */}
        </div>
      </SidebarLayout>
    );
  }
}

export default formModel<IInjectedFormModelProps<Alert>>(AlertForm, {modelClass: Alert})