import SidebarLayout from '@components/layout/SidebarLayout';
import Contact from '@models/Business';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import OptionService from '@services/OptionService';
import * as React from 'react';

export interface IStatusDialogProps {
  contact: Contact
  onChange?
}

export default class StatusDialog extends React.Component<IStatusDialogProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      selected: null
    }
  }

  status = [
   "Préparation", "Clôturé", "En cours"
  ]

  submit = async () => {
    const {contact, onChange} = this.props
    const {selected} = this.state
    contact.status = selected?.label;
    await EntityManager.update(contact, {only: ["status"]});
    DOMService.closeSidebar();
  }

  public render() {
    const {selected} = this.state
    return (
      <SidebarLayout
        title="Appliquer un statut"
        bottomArea={<button className="btn btn-primary w-100 btn-lg" onClick={this.submit} >{"Changer le statut"}</button>}
      >
        <div>
          {OptionService.get("interventions_status").filter(a => this.status.includes(a.label)).map((status) => (
            <div onClick={() => this.setState({selected: status})} style={selected===status ? {backgroundColor: status.color, color: "white"} : {backgroundColor: "#f1f2f3", color: status.color}} className='mb-3 py-3 px-4 rounded-2 pointer fw-bold'>
              {status.label}
            </div>
          ))}
        </div>
      </SidebarLayout>
    );
  }
}
