import { consumeSessionBloc, sessionBloc } from '@bloc/SessionBloc';
import SmartSwitch from '@components/input/SmartSwitch';
import * as React from 'react';
import { ISessionBlocConsumer } from '../../bloc/SessionBloc';

export interface ICustomiserProps {
}

class Customiser extends React.Component<ICustomiserProps & ISessionBlocConsumer> {
  public render() {
    return (
      <div>
        <SmartSwitch id="theming" label="Dark thème" value={this.props.theme === "theme--dark"} onChange={() => sessionBloc.switchTheme()} />
      </div>
    );
  }
}

export default consumeSessionBloc(Customiser)