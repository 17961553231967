import classNames from "classnames";
import React from "react";
import CustomSelect from "./CustomSelect";
import SmartInput2, { ISmartInputProps } from "./SmartInput2";


export interface ISmartSelectProps extends ISmartInputProps {
  options: any[]
  onChange?: (o) => void
  placeholder?
  value?,
  customRender?
  noSearch?
  onClear?: () => void
  renderOption?: (o) => JSX.Element
  filterRules?: (o, i) => boolean
  equalityRule?: (o, i) => boolean
  classic?: boolean
  clearable?: boolean
  allOption?: {label: string, value: any}
  onOpen?
}

function SmartSelect(props: ISmartSelectProps) {

  const customRender = (child) => {
    if (!props.customRender) return child;
    return props.customRender(child);
  }

  const {
    containerClassName,
    manager,
    clearable,
    onClear,
    errorMessage
  } = props;
  return (
    <div className={classNames({"w-100": true, [containerClassName]: !!containerClassName})}>
      { manager.renderLabel()}
      {
        customRender(
          manager.wrapWithIcon(
            <CustomSelect
              value={ props.value }
              name={ props.name }
              disabled={ props.disabled }
              id={ props.id }
              placeholder={ props.placeholder || "Choisir" }
              onBlur={ props.onBlur }
              onOpen={props.onOpen}
              style={ props.style }
              noSearch={props.noSearch}
              onChange={ (option) => manager.updateState(props.classic ? option.value : option) }
              filterRules={ props.filterRules }
              renderOption={ props.renderOption }
              equalityRule={ props.equalityRule }
              options={ props.options }
              onClear={clearable ? () => manager.updateState(null) : onClear}
              allOption={props.allOption}
            />
            , {rightIcon: onClear && props.value ? null : <i className="fas fa-caret-down text-secondary"></i>}
          )
        )
      }
      {errorMessage && <div className="text-danger text-s mt-1">{errorMessage}</div>}
      {props.children}
    </div>
  )
}

export default SmartInput2<ISmartSelectProps, any>(SmartSelect)