import * as React from 'react';

export interface IPage403Props {
}

export default class Page403 extends React.Component<IPage403Props> {
  public render() {
    return (
      <h3>
        Non autorisé
      </h3>
    );
  }
}
