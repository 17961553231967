import Attribute from './core/Attribute';
import AttributesCollection from "./core/AttributeCollection";
import DateAttribute from './core/DateAttribute';
import Model from "./Model";

export default class Contract extends Model {

  public ref: string;
  public type: string;
  public status: string;
  public mep: Date

  static modelUrl = "counters";
  public modelName = "counter";

  public attributes = new AttributesCollection({
    ref: new Attribute("ref"),
    type: new Attribute("type"),
    status: new Attribute("status"),
    mep: new DateAttribute("mep"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

}