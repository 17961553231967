import Model from './Model';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';

export default class OptionType extends Model {

  public name: string;
  public label: string;

  static modelUrl = "option_types";
  public modelName = "option_type";

  public attributes = new AttributesCollection({
    name: new Attribute("name"),
    label: new Attribute("label"),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  static canHasIcon(name: string) {
    return [].includes(name)
  }

  static canHasColor(name: string) {
    return [].includes(name)
  }
}