import Axios, { AxiosResponse } from "axios";
import queryString from 'query-string';
import env from "../config/env";
import SessionService from "./SessionService";

interface IHeaders {
  headers: { Authorization: string }
}

class ApiService {

  public get(path: string, params = {}, options = {}): Promise<AxiosResponse> {
    const config = this.buildConfig(options);
    const url = queryString.stringifyUrl({ url: this.getBaseUrl() + path, query: params }, { arrayFormat: 'index' });
    return Axios.get(url, { ...config, ...options });
  }


  public post(path: string, data: any, option = {}): Promise<AxiosResponse> {
    return Axios.post(this.getBaseUrl() + path, data, this.buildConfig(option))
        .catch(error => {
          console.error('API post error:', error);
          throw error;  // Re-throw the error for further handling or to keep the promise chain.
        });
  }

  public put(path: string, data: any, option = {}): Promise<AxiosResponse> {
    return Axios.put(this.getBaseUrl() + path, data, this.buildConfig(option));
  }

  public delete(path: string, params = {}): Promise<AxiosResponse> {
    return Axios.delete(queryString.stringifyUrl({url: this.getBaseUrl() + path, query: params}, {arrayFormat: 'index'}), this.buildConfig({}));
  }

  public buildConfig(options?: any) {
    if (options.withHeader === false) return {};
    let headers: IHeaders = this.buildHeader(options?.multipart);
    return Object.assign(headers, options);
  }

  private buildHeader(multipart = false): IHeaders {
    let token: string = SessionService.getToken();
    let header = { headers: { Authorization: `Bearer ${token}` } };
    if (multipart) header.headers['content-type'] = 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL';
    return header;
  }

  public getBaseUrl(): string {
    return env.protocol + env.apiUrl + "api/";
  }

}

export default new ApiService()
