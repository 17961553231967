import ArrayModelAttribute from "./ArrayModelAttribute";
import Model from "../Model";

export default class ManyToManyAttribute extends ArrayModelAttribute {

  public toApiData(parentModel: Model, stringify = false) {
    let value;
    if (!parentModel[this.name]) return null;
    value = parentModel[this.name].map((a) => a.id);
    if (stringify) value = JSON.stringify(value);
    return value;
  }


}