import Exchange from '@models/Exchange';
import EntityManager from '@services/EntityManager';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import ExchangeItem from './ExchangeItem';

export interface ICrmActivitiesProps {
}

export default class CrmActivities extends React.Component<ICrmActivitiesProps, {exchanges: Exchange[]}> {

  constructor(props) {
    super(props);
    this.state = {
      exchanges: null
    }
  }

  componentDidMount() {
    this.loadExchanges();
  }

  async loadExchanges() {
    let response = await EntityManager.getCollection<Exchange>(Exchange, {path: "mine"});
    this.setState({exchanges: response.models});
  }

  public render() {
    const {exchanges} = this.state
    return (
      <div>
        {exchanges?.map((exchange) => <ExchangeItem key={exchange.id} exchange={exchange} subtitle={
          exchange.contact ? <NavLink className={"fw-bold text-s"} to={exchange.contact.getFrontUrl()}>
            {exchange.contact.getFullName()}
          </NavLink> : ""
        } />)}
      </div>
    );
  }
}
