import * as React from 'react';
import logo from "src/assets/images/favicon.png"

interface INoAppLayoutProps {
}

const NoAppLayout: React.FunctionComponent<INoAppLayoutProps> = (props) => {
  return <div className="d-flex min-vh-100">
    <div className="m-auto ">
      <div className="row-flex mb-4">
        <div className="band1"></div>
        <div className="band2"></div>
        <div className="rounded me-1 row-flex sidebar-link">
          <img src={logo} alt="" width="30px" />
        </div>
        <div className="fw-bold godin-logo mb-0"></div>
      </div>
      <div className="p-8 rounded-2 shadow login-card mb-11" style={{maxWidth: "600px", width: "100%"}}>
        {props.children}
      </div>
    </div>
  </div>
};

export default NoAppLayout;
