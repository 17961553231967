import { SidebarRoute } from '@components/layout/SidebarRouter';
import Contact from '@models/Business';
import DOMService from '@services/DOMService';
import * as React from 'react';

export interface IAdvancedContactSelectProps {
  onSelect?
  className?
  contact?: Contact
}

export default class AdvancedContactSelect extends React.Component<IAdvancedContactSelectProps> {
  public render() {
    const {onSelect, className, contact} = this.props
    return (
      <div className={className}>
        <div className='mb-3 text-s text-secondary'>
          Concerne le contact
        </div>
        {contact && <div className='mb-3'>
            <div>
                <div className='d-flex'>
                    <p className='me-2'>Société:</p>
                    {contact.firstname}
                </div>
                <div className='d-flex'>
                    <p className='me-2'>Directeur:</p>
                    {contact.lastname}
                </div>
            </div>
            <div className='text-secondary'>
            </div>
        </div>}
          <button onClick={() => DOMService.openSidebar(SidebarRoute.ContactsGridSidebar, {onSelect}) } className="btn btn-warning text-white">{contact ? "Modifier le contact" : "Choisir un contact"}</button>
      </div>
    );
  }
}
