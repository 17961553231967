import { OptionAttribute } from '@models/core/OptionAttribute';
import Slot from '@models/Slot';
import { convertHexToRGBA } from 'src/helper/ColorHelper';
import Yard from 'src/models/Yard';
import { sessionBloc } from '../bloc/SessionBloc';
import ArrayModelAttribute from './core/ArrayModelAttribute';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import ModelAttribute from './core/ModelAttribute';
import Model from './Model';
import Option from './Option';
import Report from './Report';
import YardShare from './YardShare';
import ArrayAttribute from './core/ArrayAttribute';


export default class User extends Model {

  public firstname: string
  public lastname: string
  public email: string
  public phone: string
  public type: string
  public password: string;
  public currentYard: Yard;
  public isAdmin: boolean;
  public isManager: boolean;
  public isCustomer: boolean;
  public slotByDay: any[];
  public active: boolean;
  public role: Option;
  public senderEmail: string
  public slots: Slot[]
  public reports: Report[]
  public absentReports: Report[]
  public yardShares: YardShare[]
  
  static modelUrl = "users";
  public modelName = "user";

  public attributes = new AttributesCollection({
    firstname: new Attribute("firstname"),
    lastname: new Attribute("lastname"),
    slotByDay: new ArrayAttribute("slotByDay"),
    email: new Attribute("email"),
    phone: new Attribute("phone"),
    active: new Attribute("active"),
    senderEmail: new Attribute("senderEmail"),
    isAdmin: new Attribute("isAdmin", {submittable: false}),
    isCustomer: new Attribute("isCustomer", {submittable: false}),
    isManager: new Attribute("isManager", {submittable: false}),
    role: new OptionAttribute("role", {optionType: "user_role"}),
    password: new Attribute("password"),
    currentYard: new ModelAttribute("currentYard", Yard, {submittable: false}),
    donePrestations: new Attribute("donePrestations", {submittable: false}),
    slots: new ArrayModelAttribute("slots", Slot, {submittable: false}),
    reports: new ArrayModelAttribute("reports", Report, {submittable: false}),
    absentReports: new ArrayModelAttribute("absentReports", Report, {submittable: false}),
    yardShares: new ArrayModelAttribute("yardShares", YardShare, {submittable: false}),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  public getFullName(): string {
    let str = [this.firstname, this.lastname].filter(s => s);
    return str.join(" ");
  }

  public isMe = (): boolean => sessionBloc.state.user.id === this.id;

  public getStyle() {
    return {color: this.role.color, backgroundColor: convertHexToRGBA(this.role.color || "black", 10)}
  }

  public isShared(yardId: number) {
    return this.yardShares.find(ys => ys.yardId === yardId);
  }

  getProfitability({start, end}) {
    let total = 0;
    let isProfitable = 0;
    let reportCount = 0;
    let days = [];
    this.reports.forEach(report => {
      if (report.isBetween({start, end})) {
        total += report.profitability.amount;
        reportCount++;
        isProfitable += report.profitability.amount / report.profitability.ratio;
        if (!days.includes(report.date.getDay())) days.push(report.date.getDay())
      }
    });
    

    let divider = Math.min(reportCount, days.length);
    
    return {moy: divider ? (total / divider).toFixed(0) : "0", isProfitable: (isProfitable / divider) >= 1};
  }

}

// getProfitability({start, end}) {
//   let isProfitable = 0;
//   let days = {
//     1: {amount: 0, profitable: 0},
//     2: {amount: 0, profitable: 0},
//     3: {amount: 0, profitable: 0},
//     4: {amount: 0, profitable: 0},
//     5: {amount: 0, profitable: 0}
//   }
//   this.reports.forEach(report => {
//     if (report.isBetween({start, end})) {
//       days[report.date.getDay()].amount += report.profitability.amount;
//       days[report.date.getDay()].profitable += report.profitability.amount / report.profitability.ratio;
//     }
//   });
  

//   return {moy: divider ? (total / divider).toFixed(0) : "0", isProfitable: (isProfitable / divider) >= 1};
// }