import User from 'src/models/User';
import AttributesCollection from "./core/AttributeCollection";
import ModelAttribute from './core/ModelAttribute';
import Model from "./Model";
import Yard from "./Yard";

export default class YardShare extends Model {

  public user: User;
  public yard: Yard;
  public yardId: number;

  static modelUrl = "reports";
  public modelName = "report";

  public attributes = new AttributesCollection({
    user: new ModelAttribute("user", User),
    yard: new ModelAttribute("yard", Yard),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }
}