import { sessionBloc } from '@bloc/SessionBloc';
import TextIcon from '@components/common/TextIcon';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import YardCompositionSelect from '@components/modelSelect/YardCompositionSelect';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg, EventResizeDoneArg } from "@fullcalendar/interaction";
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import User from '@models/User';
import Yard from '@models/Yard';
import YardComposition from '@models/YardComposition';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import classNames from 'classnames';
import dayjs from 'dayjs';
import * as React from 'react';
import { toEuro } from '../../helper/PriceHelper';
import Report from '../../models/Report';

export interface IPrestationCalendarProps {
  yard: Yard
  data: any
  editable?: boolean
  onChange
}

export interface IPrestationCalendarState {
  [arg: string]: any
  amount: number
  slots: any[]
  selectedSlot: any
  filter: any
  selectedComposition: YardComposition
}

export default class PrestationCalendar extends React.Component<IPrestationCalendarProps, IPrestationCalendarState> {

  public calendar = React.createRef<FullCalendar>()

  constructor(props: IPrestationCalendarProps) {
    super(props);

    this.state = {
      amount: 1,
      slots: [],
      selectedSlot: null,
      filter: null,
      selectedComposition: null
    }
  }

  componentDidMount() {
    this.setState({})
    document.addEventListener("keyup", this.onKeyDown, false)
  }

  componentWillUnmount(): void {
    document.removeEventListener("keyup", this.onKeyDown, false)
  }

  onKeyDown = (e) => {
    if (e.key === "ArrowLeft") {
      this.calendar.current.getApi().prev();
      this.setState({})
    }
    if (e.key === "ArrowRight") {
      this.calendar.current.getApi().next();
      this.setState({})
    }
  }

  deleteSlot = async (slot) => {
    await EntityManager.delete(slot)
    this.setState({ slots: this.state.slots.filter(s => s.id !== slot.id) })
  }

  eventDetail = (e) => {
    let report: Report = e.event._def.extendedProps.report;
    if (!report) return null;
    if (report.absent) return <div className="p-1">
      Absent
    </div>
    return <div className="p-1">
      {report.reportCompositions.map(rc => (
        <div key={rc.getElementId()} className="mb-1 text-s fw-bold">{rc.quantity} {rc.yardPrice.prestation.label}</div>
      ))}
      { sessionBloc.isAdminOrResponsibleNational() && <div className="mt-2">
        <div className="text-xs">{toEuro(report.profitability.amount)}</div>
      </div>}
    </div>;
  };

  resourceLabelContent = (e) => {
    let user: User = e.resource?.extendedProps?.user;
    let profitableData = user?.getProfitability({start: e.view.activeStart, end: e.view.activeEnd});
    return <div>
      <div>
        {e.fieldValue}
      </div>
      {(user && profitableData.moy !== "0" && sessionBloc.isAdminOrResponsibleNational()) && <div className={classNames({[profitableData.isProfitable ? "text-success" : "text-danger"]: true})}>
        {`€${profitableData.moy}/jour`}
      </div>}
    </div>
  }

  dateClick = async (e: DateClickArg) => {
    const {date, resource} = e
    DOMService.openSidebar(SidebarRoute.ReportForm, {
      newModel: new Report({
        date,
        user: resource.extendedProps.user,
        yardComposition: this.state.selectedComposition || this.props.yard.yardCompositions[0]
      }),
      yard: this.props.yard,
      onSubmit: this.reportsChange
    })
  }

  eventDateChange = async (e: EventResizeDoneArg) => {
    let report: Report = e.event.extendedProps.report;
    let userId: any = e.event._def.resourceIds[0];
    report.date = e.event.start;
    report.userId = userId
    await EntityManager.update(report);
  }

  eventClick = async (e) => {
    DOMService.openSidebar(SidebarRoute.ReportForm, {
      id: e.event._def.extendedProps.report.id,
      yard: this.props.yard,
      onSubmit: this.reportsChange
    })
  }

  reportsChange = () => {
    DOMService.closeSidebar()
    this.props.onChange()
  }

  defaultDate = (): Date => {
    const {yard} = this.props
    if (dayjs(yard.startAt).isAfter(dayjs())) return yard.startAt;
    if (dayjs(yard.endAt).isBefore(dayjs()))  return yard.endAt;
    return null;
  }

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  handleSiteChange = async (e) => {
    this.handleChange(e);
  }

  selectSlotType(selectedSlot) {
    this.setState({selectedSlot})
  }

  isSelected(selectedSlot) {
    return this.state.selectedSlot === selectedSlot;
  }

  onFilter = (selectedComposition) => {
    this.setState({selectedComposition});
  }

  getPeriodLabel() {
    return this.calendar.current?.getApi().view.title
  }

  prev = () => {
    this.calendar.current?.getApi().prev();
    this.setState({})
  }

  next = () => {
    this.calendar.current?.getApi().next();
    this.setState({})
  }

  plugins() {
    let plugins= [resourceTimelinePlugin, dayGridPlugin];
    if (this.props.editable) plugins.push(interactionPlugin);
    return plugins;
  }

  height() {
    return sessionBloc.isCustomer() ? "calc(100vh - 300px)" : "calc(100vh - 230px)";
  }

  public render() {
    const {yard, data, editable} = this.props
    
    const {selectedComposition} = this.state
    return (
      <div style={{minWidth: "650px"}}>
        <div className="fc">
          <div className="row-between mb-4">
            <div className="row-flex ">
              <div className="fc-button-group">
                <button onClick={this.prev} type="button" title="Mois précédent" aria-pressed="false" className="fc-prev-button fc-button fc-button-primary">
                  <span className="fc-icon fc-icon-chevron-left">
                  </span>
                </button>
                <button onClick={this.next} type="button" title="Mois suivan t" aria-pressed="false" className="fc-next-button fc-button fc-button-primary">
                  <span className="fc-icon fc-icon-chevron-right">
                  </span>
                </button>
              </div>
              <h4 className="ms-3 mb-0">
                {this.getPeriodLabel()}
              </h4>
            </div>
            <div className="w-100 ms-3" style={{maxWidth: "300px"}}>
              <YardCompositionSelect onChange={this.onFilter} value={selectedComposition} options={yard.yardCompositions} allOption={{label: <div className="row-flex">
                <TextIcon className="me-3 capitalize" leftIcon="fas fa-circle">
                  <div>Tout</div>
                </TextIcon>
              </div>, value: null}} />
            </div>
          </div>
        </div>
        <FullCalendar
          ref={this.calendar}
          plugins={this.plugins()}
          initialView='resourceTimelineWeek'
          schedulerLicenseKey="0344037874-fcs-1637598111"
          filterResourcesWithEvents
          headerToolbar={false}
          buttonText={{
            today:    'Aujourd\'hui',
            month:    'Mois',
            week:     'Semaine',
            day:      'Jour',
          }}
          events={ selectedComposition ? data.events.filter(ev => !ev.report || ev.report.yardCompositionId === selectedComposition.id) : data.events }
          resources={data.resources}
          initialDate={this.defaultDate()}
          eventContent={this.eventDetail}
          defaultAllDay
          editable
          timeZone={"fr"}
          eventClick={editable ? this.eventClick : null}
          displayEventTime={false}
          eventAllow={(dropInfo, _) => {
            return dropInfo.start >= yard.startAt && dropInfo.end <= yard.endAt;
          }}
          resourceAreaWidth="200px"
          resourceAreaHeaderContent="Ouvriers"
          resourceLabelContent={this.resourceLabelContent}
          eventChange={this.eventDateChange}
          dateClick={this.dateClick}
          weekends={false}
          height={this.height()}
          slotDuration={{ days: 1 }}
          locale={"fr"}
        />
      </div>
    );
  }
}
