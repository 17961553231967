import groupBy from "lodash/groupBy";
import OptionService from '@services/OptionService';
import { convertHexToRGBA } from 'src/helper/ColorHelper';

class ChartService {

  public yearLabel = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

  agentBackgroundColor = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',

  ];

  public yearBackgroundColor = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
  ];
  public yearBorderColor = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
  ];

  buildData(json: any[]): any {
    let labels = []
    let data = []
    json.forEach(d => {
      labels.push(d.city)
      data.push(d.amount)
    });
    
    return {
      datasets: [{
        data: data,
        backgroundColor: this.yearBackgroundColor,
        borderColor: "transparent",
      }],
      labels: labels
    }
  }

  buildByPrestation(json: any[]): any {
    let data = {
      readPrestation: {
        datasets: [{
          data: [],
          backgroundColor: [],
          borderColor: "transparent"
        }],
        labels: []
      },
      otherPrestation: {
        datasets: [{
          data: [],
          backgroundColor: [],
          borderColor: "transparent"
        }],
        labels: []
      }
    }
    let opacity = 50;
    
    json.forEach(d => {
      let pt = OptionService.findPrestation(d.prestation);
      
      if (pt.prestationType.key === "prestation_type_reading") {
        data.readPrestation.labels.push(pt.label)
        data.readPrestation.datasets[0].data.push(d.amount)
        data.readPrestation.datasets[0].backgroundColor.push(convertHexToRGBA(pt.color, opacity))
      } else {
        data.otherPrestation.labels.push(pt.label)
        data.otherPrestation.datasets[0].data.push(d.amount)
        data.otherPrestation.datasets[0].backgroundColor.push(convertHexToRGBA(pt.color, opacity))
      }
    });
    
    return data;
  }

  buildCaEvolutionData(json: any[]) {
    let data = []
    let month: any = groupBy(json, "month")
    for (let index = 1; index <= 12; index++) {
      if (month[index]) data.push(month[index][0].amount)
      else             data.push(0)
    }
    
    return {
      labels: this.yearLabel,
      datasets: [{
        data,
        label: "Chiffre d'affaire",
        fill: true,
        borderColor: "#4d9cc1",
        backgroundColor: (context) => {
          var gradientFill = context.chart.ctx.createLinearGradient(0, 0, 0, 450);
          gradientFill.addColorStop(0, "#4d9cc1b3");
          gradientFill.addColorStop(1, "#4d9cc100");
          if (!context.chart.chartArea) {
            return;
          }
          return gradientFill
        },
        // borderColor: this.yearBorderColor,
        borderWidth: 1,
        tension: 0.5
      }]
    }
  }

  buildEvolutionData(json: any[]): any {
    let datasets = []
    let prestations: any = groupBy(json, "prestationTypeId");
    datasets = Object.keys(prestations).map((prestationTypeId: string, index: number) => {
      
      let subdata = []
      let month: any = groupBy(prestations[prestationTypeId], "month");
      for (let index = 1; index <= 12; index++) {
        if (month[index]) subdata.push(parseInt(month[index][0].count))
        else             subdata.push(0)
      }
      
      let prestationModel = OptionService.findPrestationType(parseInt(prestationTypeId))
      return {
        label: prestationModel.label,
        data: subdata,
        backgroundColor: convertHexToRGBA(prestationModel.color, 20),
        borderColor: prestationModel.color,
        tension: 0.5,
        borderWidth: 1
      };
    })
    
    return {
      labels: this.yearLabel,
      datasets
    }
  }
}

export default new ChartService();