import User from "@models/User";
import React from "react";
import ModelSelect, { IModelSelectProps } from "./ModelSelect";

export default class UserSelect extends React.Component<IModelSelectProps> {

  formatOptionLabel = (model: User) => {
    return <div className="row-flex">
      <div className="me-3 capitalize">
        <div>{model.getFullName()}</div>
      </div>
      <div>
        <div className="badge" style={model.getStyle()}>{model.role.label}</div>
      </div>
    </div>
  }

  render() {
    return (
      <ModelSelect<User> {...this.props} formatOptionLabel={this.formatOptionLabel} attributeRef="firstname" modelClass={User} />
    );
  }

}
