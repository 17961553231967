import React from 'react';

export default function SendedEmails({ emails, onSelectEmail }) {
  return (
    <div className='email-container'>
      {emails.map(email => (
        <div key={email.id} className='email' onClick={() => onSelectEmail(email.id)}>
          <strong>{email.subject} </strong>
          <em>
            ({
              new Date(email.receivedDateTime).toLocaleString('fr-FR', {
                year: 'numeric', 
                month: 'numeric', 
                day: 'numeric', 
                hour: '2-digit', 
                minute: '2-digit'
              })
            })
          </em>
          <div>À : {email.toRecipients.map(r => r.emailAddress.address).join(", ")}</div>
        </div>
      ))}
    </div>
  );
}