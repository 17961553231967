import * as React from 'react';
import { sessionBloc } from '@bloc/SessionBloc';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import Contact from '@models/Business';
import DOMService from '@services/DOMService';
import ImageBg from "@components/common/ImageBg";
import nafSectors from "@models/nafSectors.json";
import { format } from 'date-fns';
import address from "address";
import Building from "@models/Building";
import ApiService from "@services/ApiService";
import UserSelect from "@components/modelSelect/UserSelect";
import ContactDetails from '../ContactDetails';

export interface IBusinessInfoProps<Contact> {
    business: Contact;
    oneCol?: boolean;
}

function getSectorDescription(fullCodeNAF) {
    if(fullCodeNAF){
    const codeNAF = fullCodeNAF.substring(0, 2);
    return nafSectors[codeNAF] + " | " + fullCodeNAF || "Secteur non spécifié";
   }
   else{
   return "Secteur non spécifié";
}
}

export default class BusinessInfo extends React.Component<IBusinessInfoProps<Contact>, {
    expandedAddressIds: number[]
}> {
    constructor(props) {
        super(props);
        this.state = {
            expandedAddressIds: []
        };
        this.openBuildingInfos = this.openBuildingInfos.bind(this);
        this.openAddressForm = this.openAddressForm.bind(this);
        this.addNewAddress = this.addNewAddress.bind(this);
    }

    toggleAddress = (index) => {
        const { expandedAddressIds } = this.state;
        const position = expandedAddressIds.indexOf(index);
        if (position > -1) {
            this.setState({ expandedAddressIds: expandedAddressIds.filter(id => id !== index) });
        } else {
            this.setState({ expandedAddressIds: [...expandedAddressIds, index] });
        }
    }

    openAddressForm = (address) => {
        DOMService.openSidebar(SidebarRoute.AddressForm, {
            id: address.id,
        });
    }

    removeAddress = (addressId, index) => {
        ApiService.delete(`addresses/${addressId}`,)
    }

    addNewAddress = () => {
        DOMService.openSidebar(SidebarRoute.AddressForm, {
            isAddingNew: true,
            contactId: this.props.business.id
        });
    }

    openBuildingInfos = (building) => {
        DOMService.openSidebar(SidebarRoute.BuildingInfos, {
            parentModel: building,
        });
    }

    handleReferentChange = async (value) => {
        const { business } = this.props;
        const newReferentId = value.id;
        this.forceUpdate();

        try {
            const response = await ApiService.post(`contacts/${business.id}/assign-referent/${newReferentId}`, {
                referent_id: newReferentId
            });
        } catch (error) {
            console.error("Error updating referent:", error);
        }
        window.location.reload();
    }

    renderInfo(title, value, className = "mb-3") {
        let formattedValue = value;
        if (title === "Date de création" && value) {
            const dateValue = value instanceof Date ? value : new Date(value);
            formattedValue = format(dateValue, "dd/MM/yyyy");
        }

        return (
            <div className={className}>
                <div className='text-s text-secondary'>{title}</div>
                <div className="row-flex">
                    <div>{formattedValue}</div>
                </div>
            </div>
        );
    }

    renderAddress(address) {
        return (
            <div className='d-flex' style={{ cursor: 'pointer' }}>
                {address && 
                    <div>
                        <div className='d-flex'>
                            <div className="address me-2">
                                {[address?.number, address?.address].filter((p) => p).join(" ")}
                            </div>
                            {address?.addressComp && <div className="address me-2">{address?.addressComp}</div>}
                            <br />
                            <div className="me-2">{address.cp}</div>
                            <div>{address.city}</div>
                        </div>
                    </div>}
                
            </div>
        );
    }

    renderAddresses(addresses) {
        return (
            <>
                {addresses.map((address, index) => (
                    <div key={index} className='mb-3 w-100'>
                        <div className='text-s text-secondary cursor-pointer' onClick={() => this.toggleAddress(index)}>
                            {address.isPrincipal ? "Adresse Principale :" : `Adresse ${index + 1}`} - Cliquer pour afficher
                        </div>
                        {this.state.expandedAddressIds.includes(index) && (
                            <div>
                                {this.renderAddress(address)}
                                <div className='d-flex justify-content-between align-items-center'>
                                    <button className="btn btn-sm p-0" style={{ color: "#d46e00" }}
                                        onClick={() => this.openAddressForm(address)}>Modifier l'adresse
                                    </button>
                                    <button className="btn btn-sm text-danger p-0"
                                        onClick={() => this.removeAddress(address.id, index)}>Supprimer l'adresse
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
                <button className="btn btn-sm p-0" onClick={this.addNewAddress}>+ Ajouter une nouvelle adresse</button>
            </>
        );
    }

    public render() {
        const { business, oneCol } = this.props;
        console.log(business)
        return (
            <>
                <div className='mb-3 bg-light p-3 rounded'>
                    <div className='fw-bold mb-3'>Informations</div>
                    <div className='row'>
                        <div className="col-md-6">
                            <div className='row'>
                                <div className="col-6">{this.renderInfo("SIRET", business.siret)}</div>
                                <div className="col-6">{this.renderInfo("Directeur de l'entreprise", business.lastname)}</div>
                            </div>
                            {this.renderAddress(business.addresses[0])}
                            {this.renderInfo("Numéro TVA", business.vatNumber)}
                            {business.referent ?
                                this.renderInfo("Référent", business.referent ? `${business.referent.firstname} ${business.referent.lastname}` :
                                    <div></div>)
                                :
                                <UserSelect
                                    name="referent_id"
                                    label="Attribuer un référent"
                                    containerClassName="mb-3"
                                    onChange={this.handleReferentChange}
                                    value={business.referent}
                                />
                            }
                             {this.renderInfo("Numéro de télephone", business.phone)}
                        </div>
                        <div className="col-md-6">
                            {this.renderInfo("Secteur d'activité :", getSectorDescription(business.codeNaf))}
                            {this.renderInfo("effectif +/-", business.effectif || "non spécifié")}
                            {business.bornAt && this.renderInfo("Date de création", business.bornAt.toString())}
                            {this.renderInfo("Statut RCS", business.rcs)}
                            {this.renderInfo("Secteur", business.activitySector)}
                            {this.renderInfo("Mail", business.email)}
                            
                           
                        </div>
                    </div>
                </div>
                <ContactDetails contactId={business.id} />
                <div className='mb-3 bg-light p-3 rounded'>
                    <div className='fw-bold mb-3'>Multi-adresses</div>
                    <div className={"mb-3"}>
                        {this.renderAddresses(business.addresses || [])}
                    </div>
                </div>
            </>
        );
    }
}