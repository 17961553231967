import { sessionBloc } from '@bloc/SessionBloc';
import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import SmartOptionSelect from '@components/input/SmartOptionSelect';
import SmartSwitch from '@components/input/SmartSwitch';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import * as React from 'react';
import User from 'src/models/User';
import SidebarLayout from '../layout/SidebarLayout';
import EntityManager from '@services/EntityManager';
import List from '@models/List';
import UserListSelect from '@components/modelSelect/UserListSelect';

export interface IUserFormProps extends IInjectedFormModelProps<User> {
}

export interface IUserFormState {
  userLists: Array<any>,
  listSelected: any,
  loading: boolean
}

class UserForm extends React.Component<IUserFormProps & IFormModelProps<User>, IUserFormState> {
  constructor(props: IUserFormProps & IFormModelProps<User>) {
    super(props);

    this.state = {
      userLists: [],
      listSelected: null,
      loading: false,
    }
    this.addToList = this.addToList.bind(this)
    this.removeOfList = this.removeOfList.bind(this)
  }

  componentDidMount() {
    if(this.props.id){
      this.loadList();
    }
    
}

  async loadList(){
    let data = (
        await EntityManager.get(User,{
            path: 'get_lists',
            params: {userId: this.props.id}
        })
    ).data.lists;
    this.setState({userLists: data})
}

  submit = async () => {
    await this.props.submit()
  }

  async removeOfList(listId){
    let userId = this.props.model.id
    let formData = new FormData();
    formData.append("list_id", listId)
    formData.append("user_id", userId)

    await EntityManager.post(List, {path: "removeOfList", params: formData});
    await this.loadList()
}

  async addToList(){
    console.log(this.state)
    let listId = this.state.listSelected.id
    let userId = this.props.model.id
    let formData = new FormData();
    formData.append("list_id", listId)
    formData.append("user_id", userId)
    //Requête pour rajouter a la liste
    await EntityManager.post(List, {path: "addToList", params: formData});
    await this.loadList()
  }

  selectList(list: List){
    this.setState({listSelected: list})
}

  public render() {
    console.log(this.state)
    const {model, submitting, id} = this.props;
    return (
      <SidebarLayout
        title={id ? "Modification d'un utilisateur" : "Création d'un utilisateur"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={this.submit} >{id ? "Modifier l'utilisateur" : "Créer l'utilisateur"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="firstname" label="Prénom" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="lastname" label="Nom" containerClassName="mb-3" />
            </div>
          </div>
          <SmartInputText model={model} name="phone" label="Téléphone" containerClassName="mb-3" />
          {sessionBloc.isAdmin() && <SmartOptionSelect model={model} name="role" label="Role" containerClassName="mb-3" />}
          {(sessionBloc.isAdmin() || sessionBloc.isNationalResponsible() || sessionBloc.isOrdonnanceur()) && <SmartInputText model={model} name="email" label="Adresse Email" containerClassName="mb-3" />}
          {(model.id && sessionBloc.isAdmin()) && <SmartSwitch id="accountactive" model={model} name="active" label="Compte activé" containerClassName="mb-3" />}
          {id && <>
            <div className="card-title mb-3">Liste d'utilisateur</div>
                    <div className=" justify-content-between">
                        <div>
                            <UserListSelect value={this.state.listSelected} onChange={(e) => this.selectList(e)} placeholder={"Sélectionner une liste d'utilisateur"} noSearch={true}/>
                            <br></br>
                            <AsyncButton className="btn btn-primary" loading={this.state.loading} onClick={this.addToList} disabled={!this.state.listSelected} name="user" >
                                Ajouter a la liste
                            </AsyncButton>
                        </div>
                        <br/>
                        <div>
                            {this.state.userLists.map((list) =>
                                <div>
                                    {list.name} <AsyncButton className="btn btn-primary" onClick={() => this.removeOfList(list.id)}   loading={false}>
                                    Supprimer
                                </AsyncButton>
                                </div>
                            )}
                        </div>
                    </div>
          </>}
                    
                
        </div>
        
      </SidebarLayout>
    );
  }
}

export default formModel<IUserFormProps>(UserForm, {modelClass: User})