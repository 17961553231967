import { sessionBloc } from '@bloc/SessionBloc';
import KeyyoService from '@services/KeyyoService';
import classNames from 'classnames';
import * as React from 'react';
import CrmRouter from 'src/CrmRouter';
import Page403 from 'src/pages/Page403';
import Navbar from './Navbar';

export interface ICRMLayoutProps {
}

export default class CRMLayout extends React.Component<ICRMLayoutProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      minimize: false
    };
    // Binding is necessary to make `this` work in the callback
    this.onMinimize = this.onMinimize.bind(this);
  }

  componentDidMount(): void {
    //KeyyoService.start()
  }

  onMinimize = () => {
    this.setState({ minimize: !this.state.minimize });
  }

  public render() {
    if (!sessionBloc.isAdmin() && !sessionBloc.isSecretary() && !sessionBloc.isOrdonnanceur()) return <Page403/>
    return (
      <div className={classNames({"base-app": true, "minimize": this.state.minimize})}>
        <div>
          {this.state.minimize && (
              <button className="show-navbar-button btn btn-link" onClick={this.onMinimize}>
                Afficher Menu
              </button>
          )}
        <Navbar router={CrmRouter()} onMinimize={this.onMinimize} minimize={this.state.minimize} />
          {this.props.children}
        </div>
      </div>
    )
  }
}
