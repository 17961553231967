import { ProgressCanva } from '@components/common/ProgressCanva';
import YardComposition from '@models/YardComposition';
import * as React from 'react';
import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames';
import { toEuro } from '../../helper/PriceHelper';
import { sessionBloc } from '@bloc/SessionBloc';

export interface IYardCompositionProgressProps {
  compo: YardComposition
  selected: string
}

export function YardCompositionProgress (props: IYardCompositionProgressProps) {

  const [open, setOpen] = useState(false);

  const {compo, selected} = props
  
  return (
    <>
      <div className="d-flex mb-2">
        <ProgressCanva value={compo.progress()} size={50} />
        <div className="ms-3 w-100">
          <div className="row-flex pointer" onClick={() => setOpen(!open)}>
            <span className="fw-bold">{compo.prestationType.label}</span>
            <span className="text-s ms-2">{compo.getSecondaryStat()}</span>
            <div className="ms-auto p-2 my-n2">
              <i className={classNames({"fas fa-chevron-down text-secondary": true, "fa-rotate-180": open})}></i>
            </div>
          </div>
          <div className="row-flex w-100">
            <div className="" style={{minWidth: "80px"}}>
              <span className="text-primary fw-bold">{compo.details.total}</span>
              <span> / </span>
              <span>{compo.quantity}</span>
            </div>
            <div className="text-xs text-secondary ms-2">
              Restant {compo.remaining()}
            </div>
          </div>
        </div>
      </div>
      <Collapse in={open}>
        <div className="px-3">
          {!sessionBloc.isCustomer() && <div className="pt-3 row-between">
            <div className="text-s">Ratio période du chantier</div>
            <div className="">{`${compo.prestationsRate}/jour`}</div>
          </div>}
          {compo.prestationsRateFromNow && <div className="row-between">
            <div className="text-s">Ratio à partir d'aujourd'hui</div>
            <div className="">{`${compo.prestationsRateFromNow}/jour`}</div>
          </div>}
          <div className="mb-5 mt-3">
            {Object.values(compo.details.detail).map(prestaData => (
              <div key={prestaData.label} className="mb-1">
                <div className="text-secondary text-s">
                  {prestaData.label}
                </div>
                <div>
                  {(selected !== "all") ? <div className="row-between">
                    <div className="">{prestaData.byMonth[selected] ? prestaData.byMonth[selected].total : 0} réalisés</div>
                    {sessionBloc.isAdminOrNationalResponsible && <div>{prestaData.byMonth[selected] ? toEuro(prestaData.byMonth[selected].amount) : toEuro(0)}</div>}
                  </div> : <div className="row-between">
                    <div className="">{prestaData.total} réalisés</div>
                    {sessionBloc.isAdminOrNationalResponsible && <div>{toEuro(prestaData.amount)}</div>}
                  </div>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Collapse>
    </>
  );
}
