import Address from 'src/models/Address';
import Contact from '@models/Business';
import Attribute from './core/Attribute';
import AttributesCollection from "./core/AttributeCollection";
import ModelAttribute from './core/ModelAttribute';
import Model from "./Model";
import DateAttribute from './core/DateAttribute';

export default class Intervention extends Model {
  public instruction: string
  public comment: string
  public returnComment: string
  public resultReturn: string
  public ref: string
  public motifCode
  public status: string
  public contact: Contact;
  public address: Address;
  public realisedAt: Date;

  static modelUrl = "interventions";
  public modelName = "intervention";

  public attributes = new AttributesCollection({
    instruction: new Attribute("instruction"),
    comment: new Attribute("comment"),
    returnComment: new Attribute("returnComment"),
    resultReturn: new Attribute("resultReturn"),
    ref: new Attribute("ref"),
    motifCode: new Attribute("motifCode"),
    realisedAt: new DateAttribute("realisedAt"),
    status: new Attribute("status"),
    contact: new ModelAttribute("contact", Contact),
    address: new ModelAttribute("address", Address),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

}