import { sessionBloc } from '@bloc/SessionBloc';
import dayjs from 'dayjs';
import EntityManager from '@services/EntityManager';
import DOMService from '@services/DOMService';
import ApiService from './ApiService';
import Call from '@models/Call';
import KeyyoCall from '@core/KeyyoCall';

declare var window: any

interface SessionResult {
  number: string
  now: number
  session_id: string
}

export interface CallKeyyo {
  callref?: string
  caller?: string
  callee?: string
  state?: string
  setup_date?: number
  connect_date?: number
  release_date?: number
  callpark_slot?: string
  onSetup?
  onConnect?
  onRelease?
  onMissed?
  get_duration?
}

class KeyyoService {

  public api;
  private token;
  public ownNumber
  public landlineNumber = "33582819628"
  public currentCall

  start() {
    if (this.api) return;
    this.api = new window.Keyyo.CTI();
    this.ownNumber = sessionBloc.user.phone;
    // this.ownNumber = localStorage.getItem("KeyyoNumber")
    this.listenCall();
  }

  stop() {
    this.api = null
  }

  async getCSI() {
    this.token = localStorage.getItem("CSI")
    let expire = localStorage.getItem("CSIexpire")
    if (!this.token || dayjs(expire).isBefore(dayjs())) {
      let res = await ApiService.post("keyyo/token", {});
      this.token = res.data["token"];
      localStorage.setItem("CSI", this.token);
      localStorage.setItem("CSIexpire", dayjs().add(59, "minute").toJSON());
    }
  }

  async listenCall() {
    await this.getCSI()
    this.api.onNewCall = (call: CallKeyyo) => {
      
      call.onSetup = () => this.onSetup(call);
      call.onConnect = () => this.onConnect(call);
      call.onRelease = () => this.onRelease(call);
      call.onMissed = () => this.onMissed(call);
      this.currentCall = call;
    };

    
    // this.api.restore_session(this.token, (err, res: SessionResult) =>{
    //   console.log(err, res);
    //   if (res?.number) this.ownNumber = res.number;
    // })
    
    this.api.create_session(this.token, (err, res: SessionResult) =>{
      if (err) {
        console.log(err);
        return;
      }
    
      this.api.subscribe(this.ownNumber, function(err, res) {
        if (err) {
          console.log(err);
          return;
        }
      
        console.log("connected");
      });
    })
  }

  hangUpCall() {
    this.currentCall?.hang_up((err, res) => {
      if (err) {
        console.log(err);
        return;
      }
    
      console.log(res)
    });
  }

  answerCall() {
    this.currentCall?.answer((err, res) => {
      if (err) {
        console.log(err);
        return;
      }
    
      console.log(res)
    });
  }

  startCall(number) {
    this.api.dial(number, (err, res) => {
      if (err) {
        console.log(err);
        return;
      }
    
    });
  }

  onSetup(call: CallKeyyo) {
    let keyyoCall = new KeyyoCall(call);
    DOMService.openCallDialog(keyyoCall)
  }

  onConnect(call: CallKeyyo) {
    DOMService.callDialogRef.updateState(call.callref , "connected")

  }

  onRelease(call: CallKeyyo) {
    console.log("onRelease");
    // let duration = call.get_duration()
    DOMService.callDialogRef.release(call.callref)
  }

  onMissed(call: CallKeyyo) {
    console.log("onMissed");
    let keyyoCall = new KeyyoCall(call);
    DOMService.closeCallDialog();
    if (keyyoCall.isEmitted) {
    } else {
      EntityManager.create(new Call({phone: call.caller}))
    }
  }

}

export default new KeyyoService();