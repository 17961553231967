import User from 'src/models/User';
import Contact from '@models/Business';
import Model from 'src/models/Model';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import DateAttribute from './core/DateAttribute';
import ModelAttribute from './core/ModelAttribute';
import ManyToManyAttribute from './core/ManyToManyAttribute';

export default class Alert extends Model {

  public active: boolean
  public message: string
  public createdById: number
  public toId: number
  public startAt: Date
  public contact: Contact
  public users: User[]

  static modelUrl = "alerts";
  public modelName = "alert";

  public attributes = new AttributesCollection({
    startAt: new DateAttribute("startAt"),
    message: new Attribute("message"),
    active: new Attribute("active"),
    contact: new ModelAttribute("contact", Contact),
    users: new ManyToManyAttribute("users", User, {key: "user_ids"}),
    createdBy: new ModelAttribute("createdBy", User),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

}