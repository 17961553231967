import React, { Component } from 'react';
import Contact from "@models/Business";
import Document from "@models/Document";
import ApiService from "@services/ApiService";

interface ContactFileProps {
    oneCol?: boolean;
    contact: Contact;
}

interface ContactFileState {
    openedFiles: { [key: string]: boolean };
    documents: Document[];
}

export default class ContactFile extends Component<ContactFileProps, ContactFileState> {
    constructor(props: ContactFileProps) {
        super(props);
        this.state = {
            openedFiles: {},
            documents: props.contact.documents || [],
        };
    }

    toggleFile = (id: string) => {
        this.setState((prevState) => ({
            openedFiles: {
                ...prevState.openedFiles,
                [id]: !prevState.openedFiles[id],
            },
        }));
    }



    handleDownload = async (fileName) => {
        ApiService.get(`sendFileByEmail/${fileName}`)
            .then(response => {
                alert('Le fichier a été envoyé par email.');
            })
            .catch(error => {
                console.error('Error sending file by email:', error);
                alert('Erreur lors de l\'envoi du fichier par email.');
            });
    };


    handleDelete = async (documentId: string) => {
        const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce document ?");
        if (isConfirmed) {
            try {
                await ApiService.delete(`documents/${documentId}`);
                const updatedDocuments = this.state.documents.filter(doc => doc.id !== documentId);
                this.setState({ documents: updatedDocuments });
                alert("Document supprimé avec succès");
            } catch (error) {
                console.error('Erreur lors de la suppression du document:', error);
            }
        }
    }




    renderFile = (document: Document) => {
        const isOpen = !!this.state.openedFiles[document.id];
        const itemStyle = isOpen ? { backgroundColor: '#f4f7fa', color: 'black', padding: '10px', margin: '5px 0' } : { padding: '10px', margin: '5px 0', cursor: 'pointer' };

        return (
            <div key={document.id} style={itemStyle} className={"border-bottom"}
                 onClick={() => this.toggleFile(document.id)}>
                <div className={"d-flex justify-content-between"}>
                    <div>{document.title}</div>
                    {isOpen ? (
                        <i className="fas fa-folder-open text-black"></i>
                    ) : (
                        <i className="fas fa-folder text-black"></i>
                    )}
                </div>

                {isOpen && (
                    <>
                        <div><strong>Commentaire:</strong> {document.comment}</div>
                        <div><strong>Type de document:</strong> {document.documentType?.getDocumentType()}</div>
                        {document.file && document.file.filePath && (
                            <>
                                <button onClick={() => this.handleDownload(document.file.fileName)}
                                        className={'w-75 btn-secondary'}>
                                    Recevoir le fichier par mail : {document.file.fileName}
                                </button>
                            </>
                        )}
                        <button onClick={() => this.handleDelete(document.id)}
                                className={'float-end btn-danger rounded'}>
                            Supprimer
                        </button>
                    </>
                )}
            </div>
        );
    }

    public render() {
        const { documents } = this.state;

        return (
            <>
                <div>
                    {documents.map(this.renderFile)}
                </div>
            </>
        );
    }
}