import React, { Component } from 'react';
import { Client } from '@microsoft/microsoft-graph-client';
import MainLayout from '@components/layout/MainLayout';
import LightTabs from '@components/common/LightTabs';

// Importez vos nouveaux composants
import ReceivedEmails from '../components/crm/ReceivedEmails';
import SendedEmails from '../components/crm/SendedEmails';
import ArchivedEmails from '../components/crm/ArchivedEmails';
import DeletedEmails from '../components/crm/DeletedEmails';

export default class CRMOutlookPage extends React.Component<{}, {
  receivedEmails: any[],
  sentEmails: any[],
  deletedEmails: any[],
  archivedEmails: any[],
  archivedEmailIds: string[],
  deletedEmailIds: string[],
  selectedEmailId: string | null,
  searchQuery: string,
  userEmail: string,
  calendarEvents: any[]
}> {

  constructor(props) {
      super(props);
      this.state = {
          receivedEmails: [],
          sentEmails: [],
          deletedEmails: [],
          archivedEmails: [],
          archivedEmailIds: [], // Assurez-vous que cette propriété est initialisée
          deletedEmailIds: [],  // Assurez-vous que cette propriété est initialisée
          selectedEmailId: null,
          searchQuery: '',
          userEmail: '',
          calendarEvents: [],
      };
  }

  initGraphClient = (token) => {
    return Client.init({
      authProvider: (done) => {
        done(null, token);
      },
    });
  }

  loadEmailsFromOutlook = async () => {
    try {
      const token = 'EwCIA8l6BAAUs5+HQn0N+h2FxWzLS31ZgQVuHsYAAYjBjKXDZtumAcgrdZtoztADkEdxIR0ad3RMZZHNyvvTQCRYLqH1IsLCfG1lIZzB+Eus9+4qQDK2GVYFfSgY30T5Di6uF/wuOorV8eHFbYjnyF7h/Sr7c3O3wufLA+KLFYp2vKoKmsu/4eAGpH08vG+dX0utMWYbia2OsWe1WJpjipR7JOeKqXeq+N92gHHXhEiU6hZO87+8T7QdmXwMhbHaOuF8ZHuwybQKzHI7Gce7iukcRUokDDXpdb2qxi98lsJBiRaji/fcKw2jHtLxlh8T+SyetOfGza5XItr7qgIXIziwUXPvRz5qOJgiH66UJJhQ2OoZcoO77oqjui3Z8JgDZgAACAbo9CwQpx+qWAKto9YrZgVuAwzkA2wKv409E88wYabQkJmgjVsIv8WtuMiUd+lOeJ7cUtBQT+DIi1NiPYruRQhB/VstMqztcV8Ul5v35S5qW1TDkfs5+Y06GIfJB0AOEnBiAU3GfyXv7J77v4H6xEEyaWVE9UBRpvTEfkWUnExDX+1GIuaE0hzzCMExMNFCS6xpyvPL02prFUAbdly/8bQ5c+JSeZjNxYqIAWqa5B38182nJPwZQtwypp4c4P5LIBORy//N+pcb/Lxh5RrEfvtb5QKuuKVNHEvgrGUX7gx7XnIRE8j8JB+GVp/2g26N/nsjgJf9fCBEU/InIjlvInp4Dqd+Be0FZXh5kJ7SW1iOz9kwJy6lX1LwRkm12TOTpkScK+dih7VcVJ26g1XfVY8G/wEgxfTmFBHl/cv/m6stDtp9A4hzLPzPcMK+i4G05oNPeNhuKafb431nN9H1KZ2RAshrSjeSMALkrLiE3zsCr/d4nBDAa68fYusYXpJWcsLfc0q4BzbLUcZQb3rA16SIUwALj7g2tmjgEd4drDXqIfLF/NO977WeIIfJ9p8u+wAIzNu9TL9JjAP+VBY8bglo4Q6sdEzzWbOWH3W+gTfHJC1JukO4Oupotr7ti3/5yQj2fCrEDMfGEKhhaBQpCHU5U1Y9L12b3San8Lh3/gYfFt9yn/YROqVUc18qcnhvzevG9iKgEXNZJeD6nc19Q7YKZ0vk+OL4tLR4ctweuxeqtgQSCnAP45oXIwpmjSlpZZNwQPgrPoOKLmgCpTkvmTpUkSfQSG7zq4VEfFRmLj9saGiwAg==';
      const client = this.initGraphClient(token);

      let messages = await client.api('/me/messages').expand('attachments').get();
      messages = await Promise.all(messages.value.map(async (email) => {
        if (email.hasAttachments) {
          const attachments = await client.api(`/me/messages/${email.id}/attachments`).get();
          email.attachmentsContent = attachments.value;
        }
        return email;
      }));

      const userEmail = 'guyllaume.morreau1@outlook.com';
      const receivedEmails = messages.filter(email => email.sender.emailAddress.address !== userEmail && !this.state.archivedEmailIds.includes(email.id) && !this.state.deletedEmailIds.includes(email.id));
      const sentEmails = messages.filter(email => email.sender.emailAddress.address === userEmail && !this.state.archivedEmailIds.includes(email.id) && !this.state.deletedEmailIds.includes(email.id));

      this.setState({ receivedEmails, sentEmails, userEmail });
    } catch (error) {
      console.error('Erreur lors du chargement des emails:', error);
    }
  }

  loadDeletedEmailsFromOutlook = async () => {
    try {
      const token = 'EwCIA8l6BAAUs5+HQn0N+h2FxWzLS31ZgQVuHsYAAYjBjKXDZtumAcgrdZtoztADkEdxIR0ad3RMZZHNyvvTQCRYLqH1IsLCfG1lIZzB+Eus9+4qQDK2GVYFfSgY30T5Di6uF/wuOorV8eHFbYjnyF7h/Sr7c3O3wufLA+KLFYp2vKoKmsu/4eAGpH08vG+dX0utMWYbia2OsWe1WJpjipR7JOeKqXeq+N92gHHXhEiU6hZO87+8T7QdmXwMhbHaOuF8ZHuwybQKzHI7Gce7iukcRUokDDXpdb2qxi98lsJBiRaji/fcKw2jHtLxlh8T+SyetOfGza5XItr7qgIXIziwUXPvRz5qOJgiH66UJJhQ2OoZcoO77oqjui3Z8JgDZgAACAbo9CwQpx+qWAKto9YrZgVuAwzkA2wKv409E88wYabQkJmgjVsIv8WtuMiUd+lOeJ7cUtBQT+DIi1NiPYruRQhB/VstMqztcV8Ul5v35S5qW1TDkfs5+Y06GIfJB0AOEnBiAU3GfyXv7J77v4H6xEEyaWVE9UBRpvTEfkWUnExDX+1GIuaE0hzzCMExMNFCS6xpyvPL02prFUAbdly/8bQ5c+JSeZjNxYqIAWqa5B38182nJPwZQtwypp4c4P5LIBORy//N+pcb/Lxh5RrEfvtb5QKuuKVNHEvgrGUX7gx7XnIRE8j8JB+GVp/2g26N/nsjgJf9fCBEU/InIjlvInp4Dqd+Be0FZXh5kJ7SW1iOz9kwJy6lX1LwRkm12TOTpkScK+dih7VcVJ26g1XfVY8G/wEgxfTmFBHl/cv/m6stDtp9A4hzLPzPcMK+i4G05oNPeNhuKafb431nN9H1KZ2RAshrSjeSMALkrLiE3zsCr/d4nBDAa68fYusYXpJWcsLfc0q4BzbLUcZQb3rA16SIUwALj7g2tmjgEd4drDXqIfLF/NO977WeIIfJ9p8u+wAIzNu9TL9JjAP+VBY8bglo4Q6sdEzzWbOWH3W+gTfHJC1JukO4Oupotr7ti3/5yQj2fCrEDMfGEKhhaBQpCHU5U1Y9L12b3San8Lh3/gYfFt9yn/YROqVUc18qcnhvzevG9iKgEXNZJeD6nc19Q7YKZ0vk+OL4tLR4ctweuxeqtgQSCnAP45oXIwpmjSlpZZNwQPgrPoOKLmgCpTkvmTpUkSfQSG7zq4VEfFRmLj9saGiwAg==';
      const client = this.initGraphClient(token);

      let deletedMessages = await client.api('/me/mailFolders/deleteditems/messages').get();
      const deletedEmailIds = deletedMessages.value.map(email => email.id);
      this.setState({ deletedEmails: deletedMessages.value, deletedEmailIds });
    }
    catch (error) {
      console.error('Erreur lors du chargement des emails de la corbeille:', error);
      }
      }
      
      loadArchivedEmailsFromOutlook = async () => {
      try {
      const token = 'EwCIA8l6BAAUs5+HQn0N+h2FxWzLS31ZgQVuHsYAAYjBjKXDZtumAcgrdZtoztADkEdxIR0ad3RMZZHNyvvTQCRYLqH1IsLCfG1lIZzB+Eus9+4qQDK2GVYFfSgY30T5Di6uF/wuOorV8eHFbYjnyF7h/Sr7c3O3wufLA+KLFYp2vKoKmsu/4eAGpH08vG+dX0utMWYbia2OsWe1WJpjipR7JOeKqXeq+N92gHHXhEiU6hZO87+8T7QdmXwMhbHaOuF8ZHuwybQKzHI7Gce7iukcRUokDDXpdb2qxi98lsJBiRaji/fcKw2jHtLxlh8T+SyetOfGza5XItr7qgIXIziwUXPvRz5qOJgiH66UJJhQ2OoZcoO77oqjui3Z8JgDZgAACAbo9CwQpx+qWAKto9YrZgVuAwzkA2wKv409E88wYabQkJmgjVsIv8WtuMiUd+lOeJ7cUtBQT+DIi1NiPYruRQhB/VstMqztcV8Ul5v35S5qW1TDkfs5+Y06GIfJB0AOEnBiAU3GfyXv7J77v4H6xEEyaWVE9UBRpvTEfkWUnExDX+1GIuaE0hzzCMExMNFCS6xpyvPL02prFUAbdly/8bQ5c+JSeZjNxYqIAWqa5B38182nJPwZQtwypp4c4P5LIBORy//N+pcb/Lxh5RrEfvtb5QKuuKVNHEvgrGUX7gx7XnIRE8j8JB+GVp/2g26N/nsjgJf9fCBEU/InIjlvInp4Dqd+Be0FZXh5kJ7SW1iOz9kwJy6lX1LwRkm12TOTpkScK+dih7VcVJ26g1XfVY8G/wEgxfTmFBHl/cv/m6stDtp9A4hzLPzPcMK+i4G05oNPeNhuKafb431nN9H1KZ2RAshrSjeSMALkrLiE3zsCr/d4nBDAa68fYusYXpJWcsLfc0q4BzbLUcZQb3rA16SIUwALj7g2tmjgEd4drDXqIfLF/NO977WeIIfJ9p8u+wAIzNu9TL9JjAP+VBY8bglo4Q6sdEzzWbOWH3W+gTfHJC1JukO4Oupotr7ti3/5yQj2fCrEDMfGEKhhaBQpCHU5U1Y9L12b3San8Lh3/gYfFt9yn/YROqVUc18qcnhvzevG9iKgEXNZJeD6nc19Q7YKZ0vk+OL4tLR4ctweuxeqtgQSCnAP45oXIwpmjSlpZZNwQPgrPoOKLmgCpTkvmTpUkSfQSG7zq4VEfFRmLj9saGiwAg==';
      const client = this.initGraphClient(token);
      let archivedMessages = await client.api('/me/mailFolders/archive/messages').get();
      const archivedEmailIds = archivedMessages.value.map(email => email.id);
      this.setState({ archivedEmails: archivedMessages.value, archivedEmailIds });
    } catch (error) {
      console.error('Erreur lors du chargement des emails archivés:', error);
    }
    
    }

    loadCalendarFromOutlook = async () => {
      try {
        const token = '[EwCIA8l6BAAUs5+HQn0N+h2FxWzLS31ZgQVuHsYAAYjBjKXDZtumAcgrdZtoztADkEdxIR0ad3RMZZHNyvvTQCRYLqH1IsLCfG1lIZzB+Eus9+4qQDK2GVYFfSgY30T5Di6uF/wuOorV8eHFbYjnyF7h/Sr7c3O3wufLA+KLFYp2vKoKmsu/4eAGpH08vG+dX0utMWYbia2OsWe1WJpjipR7JOeKqXeq+N92gHHXhEiU6hZO87+8T7QdmXwMhbHaOuF8ZHuwybQKzHI7Gce7iukcRUokDDXpdb2qxi98lsJBiRaji/fcKw2jHtLxlh8T+SyetOfGza5XItr7qgIXIziwUXPvRz5qOJgiH66UJJhQ2OoZcoO77oqjui3Z8JgDZgAACAbo9CwQpx+qWAKto9YrZgVuAwzkA2wKv409E88wYabQkJmgjVsIv8WtuMiUd+lOeJ7cUtBQT+DIi1NiPYruRQhB/VstMqztcV8Ul5v35S5qW1TDkfs5+Y06GIfJB0AOEnBiAU3GfyXv7J77v4H6xEEyaWVE9UBRpvTEfkWUnExDX+1GIuaE0hzzCMExMNFCS6xpyvPL02prFUAbdly/8bQ5c+JSeZjNxYqIAWqa5B38182nJPwZQtwypp4c4P5LIBORy//N+pcb/Lxh5RrEfvtb5QKuuKVNHEvgrGUX7gx7XnIRE8j8JB+GVp/2g26N/nsjgJf9fCBEU/InIjlvInp4Dqd+Be0FZXh5kJ7SW1iOz9kwJy6lX1LwRkm12TOTpkScK+dih7VcVJ26g1XfVY8G/wEgxfTmFBHl/cv/m6stDtp9A4hzLPzPcMK+i4G05oNPeNhuKafb431nN9H1KZ2RAshrSjeSMALkrLiE3zsCr/d4nBDAa68fYusYXpJWcsLfc0q4BzbLUcZQb3rA16SIUwALj7g2tmjgEd4drDXqIfLF/NO977WeIIfJ9p8u+wAIzNu9TL9JjAP+VBY8bglo4Q6sdEzzWbOWH3W+gTfHJC1JukO4Oupotr7ti3/5yQj2fCrEDMfGEKhhaBQpCHU5U1Y9L12b3San8Lh3/gYfFt9yn/YROqVUc18qcnhvzevG9iKgEXNZJeD6nc19Q7YKZ0vk+OL4tLR4ctweuxeqtgQSCnAP45oXIwpmjSlpZZNwQPgrPoOKLmgCpTkvmTpUkSfQSG7zq4VEfFRmLj9saGiwAg==]';
        const client = this.initGraphClient(token);
    
        // Définir la période pour le planning (par exemple, 7 jours à partir d'aujourd'hui)
        const startDate = new Date();
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 7);
    
        let calendarView = await client.api(`/me/calendarView?startDateTime=${startDate.toISOString()}&endDateTime=${endDate.toISOString()}`)
        .select('subject,start,end,location,attendees,body')  // Ajout de body
        .orderby('start/dateTime')
        .get();
  
      this.setState({ calendarEvents: calendarView.value });
    } catch (error) {
      console.error('Erreur lors du chargement du calendrier:', error);
    }
  }
    
    componentDidMount() {
    this.loadEmailsFromOutlook();
    this.loadDeletedEmailsFromOutlook();
    this.loadArchivedEmailsFromOutlook();
    this.loadCalendarFromOutlook();
    }

    renderCalendarEvent = (event) => {
      const start = new Date(event.start.dateTime).toLocaleString('fr-FR', {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: '2-digit', minute: '2-digit'
      });
      const end = new Date(event.end.dateTime).toLocaleString('fr-FR', {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: '2-digit', minute: '2-digit'
      });
      
      return (
        <div key={event.id} className='calendar-event'>
          <strong>{event.subject}</strong>
          <div>
            <em>Début : {start} </em>
            <em>Fin : {end}</em>
          </div>
          {event.location && <div>Lieu : {event.location.displayName}</div>}
          {event.attendees && (
            <div>
              Participants :
              <ul>
                {event.attendees.map((attendee, index) => (
                  <li key={index}>{attendee.emailAddress.name}</li>
                ))}
              </ul>
            </div>
          )}
          {event.body && (
            <div>
              <strong>Description :</strong>
              <p>{event.body.contentType === 'text' ? event.body.content : <div dangerouslySetInnerHTML={{ __html: event.body.content }} />}</p>
            </div>
          )}
        </div>
      );
    }
    
    renderEmail = (email, isReceived) => {
    const isSelected = this.state.selectedEmailId === email.id;
    const emailDate = new Date(email.receivedDateTime).toLocaleString('fr-FR', {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: '2-digit', minute: '2-digit'
    });
    const renderHTML = (rawHTML) => <div dangerouslySetInnerHTML={{ __html: rawHTML }} />;
    return (
      <div key={email.id} className={isSelected ? 'email selected-email' : 'email'} onClick={() => this.selectEmail(email.id)}>
        <span>
          <strong>Sujet : {email.subject} </strong>
          <em>({emailDate})</em>
        </span>
        <span>
          {isReceived ? ` De : ${email.sender.emailAddress.address}` : ` À : ${email.toRecipients.map(recipient => recipient.emailAddress.address).join(", ")}`}
        </span>
        {isSelected && (
          <div>
            <p><strong>Contenu :</strong> {renderHTML(email.body.content)}</p>
            {email.attachmentsContent && email.attachmentsContent.map((attachment, index) => (
              <div key={index}>
                {attachment['@odata.mediaContentType'].includes('image') ? 
                  <img style={{ maxWidth: '500px', maxHeight: '500px' }} src={attachment.contentLocation || `data:image/png;base64,${attachment.contentBytes}`} alt={attachment.name} /> : 
                  <a href={`data:${attachment.contentType};base64,${attachment.contentBytes}`} download={attachment.name}>{attachment.name}</a>
                }
              </div>
            ))}
          </div>
        )}
      </div>
    );
    
    }
    
    selectEmail = (emailId) => {
      this.setState(prevState => ({
        selectedEmailId: prevState.selectedEmailId === emailId ? null : emailId
      }));
    }
    
    filterEmails = (email) => {
    const { searchQuery } = this.state;
    if (!searchQuery) return true;
    return email.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
    email.sender.emailAddress.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
    email.toRecipients.some(recipient => recipient.emailAddress.address.toLowerCase().includes(searchQuery.toLowerCase()));
    }
    
    handleSearchChange = (e) => {
    this.setState({ searchQuery: e.target.value });
    }
    
    render() {
      const { receivedEmails, sentEmails, deletedEmails, archivedEmails, searchQuery, userEmail, calendarEvents } = this.state;
      const filteredReceivedEmails = receivedEmails.filter(this.filterEmails);
      const filteredSentEmails = sentEmails.filter(this.filterEmails);
      const filteredDeletedEmails = deletedEmails.filter(this.filterEmails);
      const filteredArchivedEmails = archivedEmails.filter(this.filterEmails);
  
      return (
        <MainLayout title="Microsoft Graph">
          <div className='user-email'>{userEmail}</div>
          <input className='form-control' type="text" placeholder="Rechercher..." value={searchQuery} onChange={this.handleSearchChange} />
  
          {/* Intégration des composants pour chaque type d'email */}
          
          <LightTabs
          tabTitles={["Emails Reçus", "Emails Envoyés", "Emails Supprimés", "Emails Archivés"]}
          tabContents={[
            () => <ReceivedEmails emails={receivedEmails} onSelectEmail={this.selectEmail} />,
            () => <SendedEmails emails={sentEmails} onSelectEmail={this.selectEmail} />,
            () => <DeletedEmails emails={deletedEmails} />,
            () => <ArchivedEmails emails={archivedEmails} />
          ]}
        />

    <div className='calendar-container mt-3'>
    <h2>Planning Outlook</h2>
    {calendarEvents.map(event => this.renderCalendarEvent(event))}
  </div>

    {filteredReceivedEmails.length === 0 && filteredSentEmails.length === 0 && filteredDeletedEmails.length === 0 && filteredArchivedEmails.length === 0 && <p>Aucun email correspondant à la recherche.</p>}
  </MainLayout>
);

}
}