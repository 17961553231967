import { SidebarRoute } from "@components/layout/SidebarRouter";
import React from "react";
import Prestation from "src/models/Prestation";
import ModelGrid from './ModelGrid';
import { toEuro } from '../../helper/PriceHelper';

export default class PrestationGrid extends ModelGrid<Prestation> {

  public formRoute = SidebarRoute.PrestationForm
  static gridName = "prestation"
  
  gridConfig = {
    headers: ["Prestation", "Prix de base", "Seuil de rentabilité", "Couleur", "Catégorie"],
    body: [
      (prestation: Prestation) => <b>{prestation.label}</b>,
      (prestation: Prestation) => <div>{toEuro(prestation.price)}</div>,
      (prestation: Prestation) => <div>{prestation.thresholdText()}</div>,
      (prestation: Prestation) => <div className="height-4 width-4 rounded" style={{background: prestation.color}}></div>,
      (prestation: Prestation) => <div className="px-3 py-2 rounded ">
        <i className={prestation.prestationType.solidIcon() + " me-3"}></i>
        {prestation.prestationType.label}
      </div>,
    ]
  }

  onRowClick = null
  multipleActions = [
  ];

  render() {
    return <>
      {this.firstBar()}
      {this.tableArea()}
    </>
  }
}