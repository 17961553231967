import Sector from '@models/Sector';
import * as React from 'react';
import ModelSelect, { IModelSelectProps } from './ModelSelect';

export default class CustomerSelect extends React.Component<IModelSelectProps> {

  formatOptionLabel = (model: Sector) => {
    return <div className="row-flex">
      <div className="me-3 capitalize">
        <div>{model.name}</div>
      </div>
      <div>
        <div className="badge bg-primary">{model.customer.name}</div>
      </div>
    </div>
  }

  render() {
    return (
      <ModelSelect<Sector> {...this.props} formatOptionLabel={this.formatOptionLabel} modelClass={Sector} />
    );
  }

}
