import Attribute from "./Attribute";
import Model from "../Model";

export default class FileAttribute extends Attribute {

  public toApiData(parentModel: Model, stringify = false) {
    if (!parentModel[this.key]) return null;
    return parentModel[this.key];
  }

  hasFileAttribute() {
    return true;
  }

}