import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions
} from 'chart.js';
import apiService from "@services/ApiService";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                color: '#537798',
            },
        },
        tooltip: {
            enabled: true,
        },
    },
    scales: {
        x: {
            ticks: {
                color: '#537798',
            },
        },
        y: {
            ticks: {
                color: '#537798',
            },
        }
    },
};

const labels = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];

interface StatEchangeState {
    data: {
        labels: string[];
        datasets: {
            label: string;
            data: number[];
            backgroundColor: string;
        }[];
    };
}

export default class StatEchange extends React.Component<{}, StatEchangeState> {
    state: StatEchangeState = {
        data: {
            labels,
            datasets: [
                {
                    label: 'Echange email',
                    data: [],
                    backgroundColor: '#e5a866',
                },
                {
                    label: 'RDV',
                    data: [],
                    backgroundColor: '#d87c19',
                },
            ],
        }
    };

    componentDidMount() {
        this.fetchData();
    }

    const // @ts-ignore
    monthIndexOffset = 1;

    fetchData = async () => {
        try {
            const emailExchangesResponse = await apiService.get('user/exchanges');
            const emailExchangesData = emailExchangesResponse.data;
            console.log('Email Exchanges Data:', emailExchangesData);

            const appointmentsResponse = await apiService.get('user/appointments');
            const appointmentsData = appointmentsResponse.data;
            console.log('Appointments Data:', appointmentsData);

            const emailExchangesCounts = Array.from({ length: 12 }, () => 0);
            const appointmentsCounts = Array.from({ length: 12 }, () => 0);

            emailExchangesData.forEach(exchange => {
                const monthIndex = exchange.month - this.monthIndexOffset;
                emailExchangesCounts[monthIndex] = exchange.count;
            });

            appointmentsData.forEach(appointment => {
                const monthIndex = appointment.month - this.monthIndexOffset;
                appointmentsCounts[monthIndex] = appointment.count;
            });

            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    datasets: [
                        {
                            label: 'Echange email',
                            data: emailExchangesCounts,
                            backgroundColor: '#e5a866',
                        },
                        {
                            label: 'RDV',
                            data: appointmentsCounts,
                            backgroundColor: '#d87c19',
                        },
                    ]
                }
            }));
            console.log('Updated State:', this.state);
        } catch (error) {
            console.error('Failed to fetch data: ', error);
            alert('Failed to load data, please try again later.');
        }
    }

    render() {
        return (
            <div>
                <h3 className={'text-center fs-4'}>Volume de traitement de contact</h3>
                <Bar options={options} data={this.state.data} />
            </div>
        );
    }
}
