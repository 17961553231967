import OptionService from '@services/OptionService';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { convertHexToRGBA } from 'src/helper/ColorHelper';

export interface ISectorStatTabProps {
  data
  quartiers
}

export default class SectorStatTab extends React.Component<ISectorStatTabProps> {

  renderTableGlobal = () => {
    let all = this.props.data.global.total.reduce((i, t) => i+t, 0)
    return <table className='table'>
    <thead>
      <tr>
        <th>Statut</th>
        <th>Total</th>
          <th>Pourcentage</th>
      </tr>
    </thead>
    <tbody>
      {this.props.data.global.total.map((total, i) => (
        <tr>
          <td style={{backgroundColor: this.props.data.global.colors[i] + "26"}}>
            <b>{this.props.data.global.labels[i]}</b>
          </td>
          <td style={{backgroundColor: this.props.data.global.colors[i] + "26"}}>{total } / {all}</td>
          <td style={{backgroundColor: this.props.data.global.colors[i] + "26"}}>{(total / all*100).toFixed(2)} %</td>
        </tr>
      ))}
    </tbody>
  </table>
  }


  renderTable = (keyData, call1: (r) => any = (row) => row.real_status_id) => {
    return <table className='table'>
      <thead>
        <tr>
          <th>Statut</th>
          <th>Total</th>
          <th>Pourcentage</th>
          {this.props.quartiers.map(q => (
            <th>{q}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        { this.props.data[keyData].map((row, i) => {
          let status = OptionService.find(row.real_status_id)
          let style = {backgroundColor: status.color + "26"};
          let quartierValues = this.props.quartiers.map((q) => {
            return this.props.data[keyData + "_quartiers"][q]?.find(u => call1(u)===call1(row))?.total || 0
          })
          
          return <tr>
            <td style={style}>
              <b>{status.label}</b>
            </td>
            <td style={style}>{row.total }</td>
            {quartierValues.map(val => (
              <td style={style}>
                {val}
              </td>
            ))}
            <td></td>
          </tr>
        })}
      </tbody>
    </table>
  }


  detailToDoughnut(data) {
    data = data.map(d => {
      d.real_status = OptionService.find(d.real_status_id)
      return d;
    })
    return {
      datasets: [{
        data: data.map(s => s.total),
        backgroundColor: data.map(s => convertHexToRGBA(s.real_status.color, 50)),
        borderColor: data.map(s => convertHexToRGBA(s.real_status.color, 50))
      }],
      labels: data.map(s => s.real_status.label)
    }
  }

  render() {
    let formatter = (value, ctx) => {
      let sum = 0;
      let dataArr = ctx.chart.data.datasets[0].data;
      dataArr.forEach((data: number) => {
        sum += data;
      });
      if ((value*100 / sum) < 4) return null;
      return (value*100 / sum).toFixed(2)+"%";
    }

    const {data, quartiers} = this.props;

    return (
      <div>
        <div className="my-5 container-fluid">
          <div className="row">
            <div className='col-md-6'>
              <Doughnut data={{
                datasets: [{
                  data: data.global.total,
                  backgroundColor: data.global.colors.map(s => s + "70"),
                  borderColor: data.global.colors.map(s => s + "70")
                }],
                labels: data.global.labels
              }}
                        // @ts-ignore
                plugins={[ChartDataLabels]} style={{maxHeight: "450px"}} options={{
                  plugins: {
                    legend: {
                      display: false
                    },
                    datalabels: {
                      formatter
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          return context.parsed.toString();
                        }
                      }
                    }
                  }
                }}
              />
            </div>
            <div className='col-md-6'>
            {this.renderTableGlobal()}
            <div className="my-3">
              {data.global.description.map((d, i) => {
                return <div className='row-flex mb-1'>
                  <div style={{minWidth: "30px"}}>
                    <div className="d-inline-block size-3 me-2 rounded-pill" style={{backgroundColor: data.global.colors[i]}}></div>
                  </div>
                  <div>{d}</div>
                </div>
              })}
            </div>
            {Object.keys(data.global.legend).map(key => (
              <div>
                <div className="">
                  <span className='fw-bold me-2'>{key} :</span>
                  <span>
                    {data.global.legend[key]}
                  </span>
                </div>
              </div>
            ))}
            </div>
          </div>
        </div>
      </div>
  )}
}
