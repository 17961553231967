import Attribute, { AttributeOptions } from "./Attribute";
import Model from "../Model";

export interface BaseModelAttributeOptions extends AttributeOptions {
  submitObject?: boolean
}

export default class BaseModelAttribute extends Attribute {

  public className;
  public submitObject: boolean;

  constructor(name: string, className: any, options?: BaseModelAttributeOptions) {
    super(name, options);
    this.className = className;
    this.submitObject = options?.submitObject;
  }

  public createAttribute(parentModel: Model, json) {
    return json[this.name] && new this.className(json[this.name])
  }

  checkForSubmit() {
    if (!this.submitObject && this.checkSelfForSubmit()) return true;
    if (this.submitObject && (!this.value || this.value?.checkForSubmit())) return true;
    this.change();
    return false
  }

  hasFileAttribute() {
    return this.value?.attributes?.hasFileAttribute();
  }

  serialize() {
    return this.value?.serialize();
  }

}