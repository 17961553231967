import React from "react";
import AsyncButton from '@components/button/AsyncButton';
import UserSlotCalendar from '@components/calendar/UserSlotCalendar';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import SmartOptionSelect from '@components/input/SmartOptionSelect';
import formModel, {IFormModelProps, IInjectedFormModelProps} from '@components/logic/FormModel';
import UserSelect from '@components/modelSelect/UserSelect';
import Contact from '@models/Business';
import InterventionSlot from '@models/InterventionSlot';
import EntityManager from '@services/EntityManager';
import GetX from '@services/GetX';
import OptionService from '@services/OptionService';
import Exchange from '@models/Exchange';
import SidebarLayout from '../layout/SidebarLayout';
import {sessionBloc} from "@bloc/SessionBloc";
import SmartInputText from "@components/input/SmartInputText";
import ApiService from "@services/ApiService";
import DocumentTypeService from "@services/DocumentTypesService";
import Document from "@models/Document";
import Address from "@models/Address";

export interface IExchangeFormProps extends IInjectedFormModelProps<Exchange> {
    status: string;
}

export interface IExchangeFormState {
    status: string;
    noAgentError: boolean;
    noRdvError: boolean;
    withRdv: boolean;
    file: File | null;
    selectedDocumentTypeName: string;
    selectedDocumentTypeId: number;
    DocumentModel: Document,
    loaded: boolean
}

class ExchangeForm extends React.Component<IExchangeFormProps & IFormModelProps<Exchange>, IExchangeFormState> {
    constructor(props: IExchangeFormProps & IFormModelProps<Exchange>) {
        super(props);
        this.state = {
            status: props.status,
            noAgentError: false,
            noRdvError: false,
            withRdv: false,
            file: null,
            selectedDocumentTypeName: '',
            selectedDocumentTypeId: 0,
            DocumentModel: new Document({}),
            loaded: false
        };
    

        if (props.status) {
            props.model.interventionSlot = new InterventionSlot({
                contact: props.parentModel,
                createdBy: sessionBloc.user
            });
        }
    }

    async componentDidMount() {
        await DocumentTypeService.load(); // Charge les types de documents
        this.setState({loaded: true})

      }

    async submit() {
        const {submit, parentModel} = this.props;
        const {file, withRdv, selectedDocumentTypeId, DocumentModel} = this.state;

        if (withRdv && !this.props.model.interventionSlot.user) {
            this.setState({noAgentError: true});
            return;
        }
        if (withRdv && !this.props.model.interventionSlot.startAt) {
            this.setState({noRdvError: true, noAgentError: false});
            return;
        }

        try {

            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                const fileUploadResponse = await ApiService.post('uploads/files', formData);

                if (fileUploadResponse.data && fileUploadResponse.data.file) {
                    const fileId = fileUploadResponse.data.file.id;

                    const documentData = {
                        title: DocumentModel.title,
                        comment: DocumentModel.comment,
                        file_id: fileId,
                        contact_id: parentModel.id,
                        document_type_id: selectedDocumentTypeId,
                    };
                    const documentResponse = await ApiService.post('documents/create', documentData);
                    if (documentResponse.data && documentResponse.data.document) {
                    }
                }
            }

            await submit();
            let contact = parentModel as Contact;
            if (this.state.status) {
                contact.status = this.state.status;
                await EntityManager.update(contact, {only: ["status"]});
                GetX.get("contactShow")?.load();
            }
        } catch (error) {
            console.error('Error during the exchange submission:', error);
        }
    }

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0];
        if (file) {
            this.setState({file});
        }
    };

    handleDocumentTypeChange = (selectedOption) => {
        this.setState({
            selectedDocumentTypeName: selectedOption.label,
            selectedDocumentTypeId: selectedOption.value,
        });
    };

    public render() {
        const {model, submitting, id, parentModel} = this.props;
        const {status, noAgentError, withRdv, noRdvError, selectedDocumentTypeName, DocumentModel, loaded} = this.state;
        let contact = parentModel as Contact;

        return (
            <SidebarLayout
                title={id ? "Modifier un échange" : "Créer un échange"}
                bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting}
                                         onClick={() => this.submit()}>{id ? "Modifier" : "Créer"}</AsyncButton>}
            >
            {
                loaded && 
                <div className="fadeInDown">
                    {contact && <div>
                        <div className='mb-3'>
                            <div className='text-s text-secondary'>Contact</div>
                            <div>{contact.getFullName()}</div>
                            <div>{contact.email}</div>
                            <div>{contact.phone}</div>
                            <div>{contact.portable}</div>
                        </div>
                    </div>}
                    <SmartInputText model={model} name="title" label="Titre" containerClassName="mb-3 "/>
                    <SmartInputTextarea model={model} name="comment" label="Commentaire" containerClassName="mb-3 "/>
                    <SmartOptionSelect model={model} name="type" label="Type" containerClassName="mb-3"/>
                    {/* <SmartDateRangePicker leftIcon={<i className="fas fa-calendar-alt"></i>} model={model} names={["startAt", "endAt"]}  containerClassName="mb-3 "/> */}
                    <hr/>
                    {!id &&
                        <div className='mb-3'>
                            <h3 className='text-muted mb-3'><u>Document</u></h3>
                            <SmartInputText model={DocumentModel} name="title" label="Titre du document"
                                            containerClassName="mb-3 "/>
                                            <SmartOptionSelect
                                model={DocumentModel} name="documentType" label="Type de document"
                                // @ts-ignore
                                options={DocumentTypeService.getAll().map(dt => ({
                                    value: dt.value,
                                    label: dt.label,
                                }))} onChange={this.handleDocumentTypeChange} value={selectedDocumentTypeName}
                                containerClassName="mb-3"/>
                            <SmartInputText model={DocumentModel} name="comment" label="Commentaire"
                                            containerClassName="mb-3 "/>
                                            {console.log(DocumentTypeService)}
                            
                            <input type="file" className="form-control" id="file-upload"
                                   onChange={this.handleFileChange}/>
                        </div>
                    }

                </div>
            }
                
            </SidebarLayout>
        );
    }
}

export default formModel<IExchangeFormProps>(ExchangeForm, {modelClass: Exchange})