import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import SmartOptionSelect from '@components/input/SmartOptionSelect';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import * as React from 'react';
import Building from '@models/Building';
import SidebarLayout from '../layout/SidebarLayout';
import ApiService from "@services/ApiService";

export interface IBuildingInfosFormProps extends IInjectedFormModelProps<Building> {
    parentModel: Building
}

export interface IBuildingInfosFormState {
    isEditing: boolean;
}

class BuildingInfosForm extends React.Component<IBuildingInfosFormProps & IFormModelProps<Building>, IBuildingInfosFormState> {
    constructor(props: IBuildingInfosFormProps & IFormModelProps<Building>) {
        super(props);
        this.state = {
            isEditing: false,
        };
    }

    toggleEdit = () => {
        this.setState({ isEditing: !this.state.isEditing });
    }

    submit = async () => {
        const { parentModel } = this.props;
        const updatedData = {
            yearBuilt: parentModel.yearBuilt,
            totalArea: parentModel.totalArea,
            numFloors: parentModel.numFloors,
        };

        try {
            await ApiService.post(`building/${parentModel.id}`, updatedData);
        } catch (error) {
            console.error("Error updating building information:", error);
        }
        this.setState({ isEditing: false });
    }


    public render() {
        const { parentModel, submitting } = this.props;
        const { isEditing } = this.state;

        return (
            <SidebarLayout
                title={"Informations sur le bâtiment"}
                bottomArea={<>
                    {isEditing && (
                        <AsyncButton className="btn btn-primary w-100 btn-lg"
                                     loading={submitting}
                                     onClick={() => this.submit()}>
                            Enregistrer les informations
                        </AsyncButton>
                    )}
                    <button className="btn btn-info w-100 btn-lg"
                            onClick={this.toggleEdit}>
                        {isEditing ? 'Annuler' : 'Modifier'}
                    </button>
                </>}>

                <div className="fadeInDown">
                    {isEditing ? (
                        <>
                            <SmartInputText model={parentModel} name="yearBuilt" label="Année de construction" containerClassName="mb-3" />
                            <SmartInputText model={parentModel} name="totalArea" label="Superficie totale (m²)" containerClassName="mb-3" />
                            <SmartInputText model={parentModel} name="numFloors" label="Nombre d'étages" containerClassName="mb-3" />
                        </>
                    ) : (
                        <>
                            <div className="mb-3"><strong>Année de construction:</strong> {parentModel.yearBuilt}</div>
                            <div className="mb-3"><strong>Superficie totale:</strong> {parentModel.totalArea} m²</div>
                            <div className="mb-3"><strong>Nombre d'étages:</strong> {parentModel.numFloors}</div>
                        </>
                    )}
                </div>
            </SidebarLayout>
        );
    }
}

export default formModel<IBuildingInfosFormProps>(BuildingInfosForm, {modelClass: Building});

