import Model from './Model';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";

export default class List extends Model {

    public name: string;

    static modelUrl = "lists";
    public modelName = "lists";

    public attributes = new AttributesCollection({
        name: new Attribute("name"),
    });

    constructor(json) {
        super(json)
        this.fillAttributes(json);
    }

}