import { AxiosResponse } from 'axios';
import User from '../../models/User';
import ModelResponse from './ModelResponse';

export default class LoginResponse extends ModelResponse<User> {

  public token: string;

  constructor(response: AxiosResponse, options?) {
    super(response, {modelClass: User});
    this.token = response.data.token;
  }

}