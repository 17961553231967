import AdminModelsList from '@components/common/AdminModelsList';
import * as React from 'react';
import UserGrid from '../components/grid/UserGrid';
import User from 'src/models/User';
import MainLayout from '@components/layout/MainLayout';

export interface IUsersPageProps {
}

export default class UsersPage extends React.Component<IUsersPageProps> {
  public render() {
    return (
      <MainLayout title="Utilisateurs">
        <div className="app-content">
          <AdminModelsList
            gridClass={UserGrid}
            options={{ loadOnReady: true, paginate: true, modelClass: User}}
          />
        </div>
      </MainLayout>
    );
  }
}
