import SidebarLayout from '@components/layout/SidebarLayout';
import showModel, { IInjectedShowModelProps } from '@components/logic/ShowModel';
import Contact from '@models/Business';
import * as React from 'react';
import BusinessInfo from './BusinessInfo';

export interface IContactShowSidebarProps extends IInjectedShowModelProps<Contact> {
}

class ContactShowSidebar extends React.Component<IContactShowSidebarProps> {
  public render() {
    const { model } = this.props;
    return (
      <SidebarLayout
        title="Detail de l'abonné"
      >
        <div className="fadeInDown">
          <div className="mb-3 text-l">{model.firstname}</div>
            <BusinessInfo oneCol={true} business={model} />
          {/* <div className="row-end mb-3">
            <ContactActions contact={contact} />
          </div>
          <BusinessInfo contact={contact} /> */}
        </div>
      </SidebarLayout>
    );
  }
}

export default showModel(ContactShowSidebar, {modelClass: Contact}) as any