import CrmCalendar from '@components/calendar/CrmCalendar';
import CrmActivities from '@components/crm/CrmActivities';
import MontaubanIDKs from '@components/charts/MontaubanIDKs'
import AlertWidget from "@components/AlertWidget";
import NotificationBell from '@components/NotificationBell';
import villeImage from '../assets/images/ville_mtb_horiz_Blanc.png';
import Statistique from "@models/Statistique";
import * as React from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import StatFinance from "@components/charts/StatFinance";
import StatEchange from "@components/charts/StatEchange";
import DOMService from "@services/DOMService";
import {SidebarRoute} from "@components/layout/SidebarRouter";
import statistiqueForm from "@components/form/StatistiqueForm";
import StatistiqueForm from "@components/form/StatistiqueForm";
import OptionService from "@services/OptionService";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


export interface ICRMPageProps {
}

export interface ICRMPageState {
}

export default class CRMPage extends React.Component<ICRMPageProps, ICRMPageState> {
    constructor(props: ICRMPageProps) {
        super(props);
        this.state = {}
        OptionService.loadQuartier(29);
    }



    public render() {

        return (
            <div className="home">
                <div className="px-0 pt-4 px-md-5">
                    <div style={{marginRight: "10px"}}>
                        <img style={{
                            display: "flex",
                            alignItems: "center",
                            maxHeight: "180px",
                            height: "60px",
                            marginTop: "1.5rem",
                        }}
                             src={villeImage} alt="Ville"/>
                        <div className={'position-relative'}>
                            <NotificationBell/>
                        </div>

                        <h1 className={'text-center fs-1 mb-3'}>Développement Économique du Grand Montauban</h1></div>
                    <div className="col-12 fadeInDown app-content mb-3 w-100">
                        <CrmCalendar/>
                    </div>
                    <div className="row justify-content-around">
                        <AlertWidget/>
                        <div className="w-50 fadeInDown app-content" style={{
                            width: "47%"
                        }}>
                            <MontaubanIDKs/>
                        </div>
                        <div className="fadeInDown app-content" style={{
                            width: "47%"
                        }}>
                            <StatFinance/>
                        </div>

                    </div>

                    <div className="row justify-content-around mt-3 mb-4">

                        <div className="col-12 d-flex flex-column app-content">
                        <StatEchange/>
                            <div className="flex-grow-1">
                                <div className="app-content fadeInDown">
                                    <h5 className="mb-5 fw-bold">
                                        Historique des échanges
                                    </h5>
                                    <div style={{maxHeight: "calc(100vh - 260px)", overflow: "auto"}}>
                                        <CrmActivities/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
