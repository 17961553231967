import { sessionBloc } from '@bloc/SessionBloc';
import InterlocutorItem from '@components/common/InterlocutorItem';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import showModel, { IInjectedShowModelProps } from '@components/logic/ShowModel';
import Interlocutor from '@models/Interlocutor';
import Sector from '@models/Sector';
import DOMService from '@services/DOMService';
import * as React from 'react';

export interface SectorPageProps extends IInjectedShowModelProps<Sector> {
  id
}

export interface SectorPageState {
}

class SectorPage extends React.Component<SectorPageProps, SectorPageState> {


  constructor(props: SectorPageProps) {
    super(props);

    this.state = {
    }
  }

  public goToInterlocutorForm(){
    DOMService.openSidebar(SidebarRoute.InterlocutorForm, {newModel: new Interlocutor({
      "contactableId": this.props.id,
      "contactableType": "App\\Models\\Sector",
    }), onSubmit: () => this.load()} )
  }

  load() {
    DOMService.closeSidebar();
    this.props.load();
  }

  onEdit(i) {
    DOMService.openSidebar(SidebarRoute.InterlocutorForm, {id: i.id, onSubmit: () => this.load()})
  }

  public render() {
    const { model } = this.props;
    return (
        <div className="home">
          <div className="px-0 pt-3 px-md-5">
            <div className="app-content">
              <div className="row-flex mb-3">
                <i onClick={() => sessionBloc.pop()} className="fas fa-chevron-left pe-4 pointer"></i>
                <h3 className='mb-0'>
                  <span className='me-2'>Interlocuteurs de {model.customer.name}</span>
                  <span>{model.name}</span>
                </h3>
                <div className="ms-auto">
                  <button onClick={() => this.goToInterlocutorForm()} className="btn btn-primary">
                    Ajouter un interlocuteur
                  </button>
                </div>
              </div>
              <div>
                {/* <div className="card-title">Interlocuteurs</div> */}
                <div className="row">
                  {[...model.interlocutors, ...model.customer.interlocutors].map((i) => (
                    <div key={i.id} className='col-md-3'>
                      <InterlocutorItem interlocutor={i} onGoEdit={() => this.onEdit(i)} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}
export default showModel(SectorPage, {modelClass: Sector}) as any
