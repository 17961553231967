import TextIcon from '@components/common/TextIcon';
import SmartInputText from '@components/input/SmartInputText';
import Customer from '@models/Customer';
import Sector from '@models/Sector';
import * as React from 'react';

export interface ISectorCustomerFormProps {
  customer: Customer
}

export default class SectorCustomerForm extends React.Component<ISectorCustomerFormProps> {

  public newSector = new Sector({});

  async delete(sector: Sector) {
    this.props.customer.sectors = this.props.customer.sectors.filter((s) => s !== sector);
    this.setState({})
  }

  async createSector() {
    this.props.customer.sectors.push(this.newSector);
    this.newSector = new Sector({});
    this.setState({})
  }

  public render() {
    const {customer} = this.props
    return (
      <div className="">
        <div className="row-flex mb-3">
          <SmartInputText placeholder='Nom du secteur' model={this.newSector} containerClassName="flex-grow-1" name="name" />
          <button onClick={() => this.createSector()} className="btn btn-primary">Créer</button>
        </div>
        {customer.sectors.map((sector: Sector) => (
          <div key={sector.getElementId()} className="yard-user-deletable mb-2 me-2">
            <div className="row-flex">
              <div className="mx-3">
                <TextIcon leftIcon="fas fa-chart-tree-map">{sector.name}</TextIcon>
              </div>
              { !sector.id && <div onClick={() => this.delete(sector)} className="px-3 clear-btn ms-auto pointer me-n2"><i className="text-secondary far fa-times"></i></div>}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
