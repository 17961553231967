import ArrayModelAttribute from "./core/ArrayModelAttribute";
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import ModelAttribute from './core/ModelAttribute';
import Model from "./Model";
import PrestationType from './PrestationType';
import Report from "./Report";

interface doneData {
  total: number
  detail: {
    [key: string]: {
      byMonth: {[key: string]: {total: number, amount: number, date: string}},
      label: string,
      total: number,
      amount: number
    }
  }
}

export default class YardComposition extends Model {

  public quantity: number
  public yardId: number
  public prestationTypeId: number
  public details: doneData
  public subPrestationRate: number
  public prestationType: PrestationType
  public reports: Report[]
  public prestationsRate: number;
  public prestationsRateFromNow: number;

  static modelUrl = "yard_compositions";
  public modelName = "yard_composition";

  public attributes = new AttributesCollection({
    quantity: new Attribute("quantity"),
    yardId: new Attribute("yardId"),
    subPrestationRate: new Attribute("subPrestationRate", {submittable: false}),
    done: new Attribute("done", {submittable: false}),
    details: new Attribute("details", {submittable: false}),
    prestationType: new ModelAttribute("prestationType", PrestationType),
    prestationsRate: new Attribute("prestationsRate", {submittable: false}),
    reports: new ArrayModelAttribute("reports", Report, {submittable: false}),
    prestationsRateFromNow: new Attribute("prestationsRateFromNow", {submittable: false}),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  static secondaryStat: {[key: string]: (c: YardComposition) => string} = {
    "prestation_type_reading": (compo) => {
      let value = compo.details.detail["prestationCard"] ? (compo.details.detail["prestationCard"].total / compo.details.total * 100).toFixed(0) : 0;
      return "("+value + "% de cartes)"
    },
    "prestation_type_renew": (compo) => null,
    "prestation_type_module": (compo) => null,
    "prestation_type_geo": (compo) => null,
  }

  getSecondaryStat() {
    return YardComposition.secondaryStat[this.prestationType.key](this);
  }

  progress() {
    return  this.details.total / this.quantity * 100;
  }

  progressText() {
    return this.progress().toFixed(0) + " %";
  }

  subPrestationRateText() {
    if (!this.subPrestationRate) return null;
    return `${this.subPrestationRate} %`;
  }

  isComplete() {
    return this.details.total >= this.quantity;
  }

  remaining() {
    let remaining = this.quantity - this.details.total;
    if (remaining < 0) remaining = 0;
    return remaining;
  }

}