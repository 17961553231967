import InterventionSlot from '@models/InterventionSlot';
import Sector from '@models/Sector';
import EntityManager from '@services/EntityManager';
import Call from '@models/Call';
import Intervention from '@models/Intervention';
import DOMService from "@services/DOMService";
import KeyyoService from "@services/KeyyoService";
import Address from "./Address";
import Comment from "./Comment";
import Contract from "./Contract";
import Document from './Document';
import ArrayModelAttribute from "./core/ArrayModelAttribute";
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import ModelAttribute from "./core/ModelAttribute";
import Customer from './Customer';
import Exchange from "./Exchange";
import Model from './Model';
import Option from './Option';
import {OptionAttribute} from './core/OptionAttribute';
import DateAttribute from "@models/core/DateAttribute";
import LegalStatus from "./LegalStatus";
import User from "@models/User";

export default class Contact extends Model {
    public phone: string
    public portable: string
    public payerName: string
    public reference: string
    public realStatus: Option
    public status: string
    public civ: string
    public firstname: string
    public lastname: string
    public email: string
    public customerId: number
    public addressId: number
    public exchanges: Exchange[]
    public comments: Comment[]
    public interventions: Intervention[]
    public rdvInAddress: InterventionSlot[]
    public rdvInQuartier: InterventionSlot[]
    public contract: Contract
    public address: Address
    public customer: Customer
    public sector: Sector
    public quartier
    sectorId
    public import: any
    public documents: Document[]
    public siret: number
    public codeNaf: string
    public effectif: number
    public parent_organization: number
    public bornAt: Date
    public legalStatus: LegalStatus
    public addresses: Address[]
    public referent: User
    public rcs: string
    public vatNumber: string
    public activitySector: string


    static modelUrl = "contacts";
    public modelName = "contact";

    public attributes = new AttributesCollection({
        phone: new Attribute("phone"),
        portable: new Attribute("portable"),
        payerName: new Attribute("payerName"),
        reference: new Attribute("reference"),
        civ: new Attribute("civ"),
        quartier: new Attribute("quartier"),
        firstname: new Attribute("firstname"),
        realStatus: new OptionAttribute("realStatus", {optionType: "interventions_status"}),
        status: new Attribute("status"),
        import: new Attribute("import"),
        lastname: new Attribute("lastname"),
        email: new Attribute("email"),
        address: new ModelAttribute("address", Address, {submittable: false}),
        customer: new ModelAttribute("customer", Customer),
        sector: new ModelAttribute("sector", Sector),
        contract: new ModelAttribute("contract", Contract, {submitObject: true}),
        exchanges: new ArrayModelAttribute("exchanges", Exchange),
        comments: new ArrayModelAttribute("comments", Comment),
        interventions: new ArrayModelAttribute("interventions", Intervention, {submittable: false}),
        rdvInAddress: new ArrayModelAttribute("rdvInAddress", InterventionSlot, {submittable: false}),
        rdvInQuartier: new ArrayModelAttribute("rdvInQuartier", InterventionSlot, {submittable: false}),
        documents: new ArrayModelAttribute("documents", Document),
        siret: new Attribute("siret"),
        codeNaf: new Attribute("codeNaf"),
        effectif: new Attribute("effectif"),
        parent_organization: new Attribute("parent_organization"),
        bornAt: new DateAttribute("bornAt"),
        legalStatus: new ModelAttribute("legalStatus", LegalStatus),
        addresses: new ArrayModelAttribute("addresses", Address),
        referent: new ModelAttribute("referent", User),
        rcs: new Attribute('rcs'),
        vatNumber: new Attribute('vatNumber'),
        activitySector: new Attribute('activitySector')
    });

    constructor(json) {
        super(json)
        for (let address in json.addresses) {
            json.addresses[address].isPrincipal = json.addresses[address].pivot.isPrincipal;
        }
        this.fillAttributes(json);
    }

    public getFullName(): string {
        let str = [this.civ, this.firstname, this.lastname].filter(s => s);
        return str.join(" ");
    }


    public getLink() {
        return `/crm/contacts/${this.id}`;
    }

    public callOnFixe() {
        if (!this.phone) DOMService.setToaster("Aucun numéro enregistré pour ce contact")
        else KeyyoService.startCall(this.phone)
    }

    public callOnPortable() {
        if (!this.portable) DOMService.setToaster("Aucun numéro enregistré pour ce contact")
        else KeyyoService.startCall(this.portable)
    }


    public startCall() {
        let number = this.portable || this.phone
        if (!number) DOMService.setToaster("Aucun numéro enregistré pour ce contact");
        else KeyyoService.startCall(number);
        EntityManager.postDirect(Call, {path: "clear", params: {number}});
    }

    public getFrontUrl(): string {
        let classs: any = this.constructor;
        return `/crm/${classs.modelUrl}/${this.id}`;
    }
}