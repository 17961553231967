import OptionService from "../../services/OptionService";
import ModelAttribute from "./ModelAttribute";


export class PrestationAttribute extends ModelAttribute {


  public fill(parentModel: any, json) {
    super.fill(parentModel, json);
    if (!this.value) this.value = OptionService.findPrestation(this.idValue);
  }

}