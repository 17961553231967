import { AxiosResponse } from 'axios';
import Model from '../../models/Model';
import EntityManager from '../EntityManager';
import HttpResponse from './HttpResponse';

export default class PaginateResponse<T extends Model> extends HttpResponse {

  public models: T[]
  public currentPage: number;
  public from: number;
  public lastPage: number;
  public total: number;

  constructor(response: AxiosResponse, options?) {
    super(response, options);
    if (response.data.data) {
      this.models = response.data.data.map(m => EntityManager.toModel(options.modelClass, m))
      this.currentPage = response.data.currentPage
      this.from = response.data.from
      this.lastPage = response.data.lastPage
      this.total = response.data.total
    } 
  }

}