import classNames from 'classnames';
import * as React from 'react';
import Button from '../components/common/Button';
import SmartInputText from '../components/input/SmartInputText';
import LoginResponse from '@services/apiResponse/LoginResponse';
import authService from '../services/AuthService';
import { Redirect } from 'react-router';
import AsyncButton from '@components/button/AsyncButton';
import { DummyForm } from '@components/form/DummyForm';
import NoAppLayout from '../components/layout/NoAppLayout';
import { NavLink } from 'react-router-dom';

export interface ILoginPageProps {
}

export interface ILoginPageState {
  [arg: string]: any
  email: string
  password: string
  isChecking: boolean
  redirect: string
  errors: {[name: string]: string}
}

export default class LoginPage extends React.Component<ILoginPageProps, ILoginPageState> {

  public button = React.createRef<Button>()

  constructor(props: ILoginPageProps) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isChecking: false,
      redirect: null,
      errors: null
    }
  }

  onKeyPressed = (e) => {
    if (e.key === "Enter") this.submit()
  }

  handleChange = (value, e) => {
    this.setState({[e.target.name]: value, errors: null})
  }

  onKeyPress = (e) => {
    if (e.key === "Enter") this.submit()
  }

  onLogin = async (response: LoginResponse) => {
    if (response.ok) {
      this.setState({redirect: "/"})
    } else {
      this.setState({errors: response.errors, isChecking: false});
    }
  }

  submit = async () => {
    let response: LoginResponse = await authService.login({email: this.state.email, password: this.state.password})
    this.onLogin(response)
  }

  public render() {
    const {email, password, errors, redirect} = this.state;
    if (redirect) return <Redirect to={redirect} />
    return (
      <NoAppLayout>
        <h3 className="fw-bold mb-5 text-center">
        Connectez-vous à votre compte
        </h3>
        <DummyForm submit={this.submit} formProps={{noValidate: true}}>
          {(loading, validity) =>
            <>
              <SmartInputText type="email" containerClassName="mb-4" className={classNames({"is-invalid": !!errors})} label="Adresse Email" value={email} name="email" required onChange={this.handleChange} autoFocus/>
              <SmartInputText id="password" containerClassName="mb-4" type="password" className={classNames({"is-invalid": !!errors})} label="Mot de passe" name="password" value={password} required onChange={this.handleChange}>
              {errors && <div className="text-danger mt-1">{errors.credential}</div>}
              </SmartInputText>
              <div className="mb-5">
                <NavLink to="/forgot_password" className="text-end link">
                  <div>Mot de passe oublié ?</div>
                </NavLink>
              </div>
              <div className="text-center">
                <AsyncButton  loading={loading}  type="submit" className="btn btn-primary m-auto w-100 text-dark-primary">
                  SE CONNECTER
                </AsyncButton>
              </div>
            </>
          }
        </DummyForm>
      </NoAppLayout>
    );
  }
}
