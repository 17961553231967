import { sessionBloc } from '@bloc/SessionBloc';
import ClientAreaLayout from '@components/layout/ClientAreaLayout';
import CRMLayout from '@components/layout/CRMLayout';
import ManagementLayout from '@components/layout/ManagementLayout';
import Redirector from '@components/layout/Redirector';
import WorkerLayout from '@components/layout/WorkerLayout';
import Prestation from '@models/Prestation';
import OptionService from '@services/OptionService';
import { ArcElement, CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineElement, LogarithmicScale, PointElement, RadialLinearScale, Tooltip } from 'chart.js';
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import { Component } from 'react';
import {Redirect, Switch} from 'react-router';
import AdminRouter from './AdminRouter';
import './assets/sass/app.scss';
import { consumeSessionBloc } from './bloc/SessionBloc';
import PrivateRoute from './components/logic/PrivateRoute';
import CrmRouter from './CrmRouter';
import ClientAreaPage from './pages/ClientAreaPage';
import ContactShowPage from './pages/ContactShowPage';
import CustomerShowPage from './pages/CustomerShowPage';
import InterventionSlotsPage from './pages/InterventionSlotsPage';
import InterventionStatePage from './pages/InterventionStatePage';
import MapPage from './pages/MapPage';
import ResumeSector from './pages/ResumeSector';
import SectorInterlocutorPage from './pages/SectorInterlocutorPage';
import SectorPage from './pages/SectorPage';
import SectorStat from './pages/SectorStat';
import UserShowPage from './pages/UserShowPage';
import YardShowPage from './pages/YardShowPage';
import AuthService from './services/AuthService';
import EntityManager from './services/EntityManager';
import CRMOutlookPage from './pages/CRMOutlookPage';
ChartJS.register(
  ArcElement,
  LineElement,
  CategoryScale,
  LogarithmicScale,
  RadialLinearScale,
  Filler,
  LinearScale,
  PointElement,
  Legend,
  Tooltip
)
dayjs.locale('fr')


class App extends Component<any, any> {

  constructor(props) {
    super(props);

    this.state = {
      init: false,
    }
  }

  async componentDidMount() {
    if (AuthService.hasToken()) {
      OptionService.load();
      OptionService.setPrestation((await EntityManager.all<Prestation>(Prestation)).models);
    }
    this.setState({init: true});
  }

  render() {
    const {theme} = this.props
    if (!this.state.init) return <></>

    document.body.className = theme
    return (
      <div >
          <PrivateRoute exact path="/" component={() => <Redirect to="/crm" />} />
          <PrivateRoute exact path="/management/*" component={() => <Redirect to="/crm" />} />
          <PrivateRoute path="/crm" component={(p) =>
        <Switch>
          <PrivateRoute exact path="/crm/state" component={() => <InterventionStatePage />}/>
          <PrivateRoute path="/crm/sectors/:id/stats" component={(p) => <SectorStat id={p.match.params.id} />}/>
          <PrivateRoute exact path="/crm/sectors/:id/failed" component={(p) => <CRMLayout><ResumeSector id={p.match.params.id} /></CRMLayout>}/>
          <PrivateRoute exact path="/crm/sectors/:id/interlocutors" component={(p) => <CRMLayout><SectorInterlocutorPage id={p.match.params.id} /></CRMLayout>}/>
          <PrivateRoute exact path="/crm/sectors/:id/map" component={(p) => <CRMLayout><MapPage sectorId={p.match.params.id} /></CRMLayout>}/>
          <PrivateRoute exact path="/crm/sectors/:id" component={(p) => <CRMLayout><SectorPage id={p.match.params.id} /></CRMLayout>}/>
          <PrivateRoute exact path="/crm/sectors/:customer_id/contact/:id" component={(p) => <CRMLayout><ContactShowPage id={p.match.params.id} /></CRMLayout>}/>
          <PrivateRoute exact path="/crm/contacts/:id" component={(p) => <CRMLayout><ContactShowPage id={p.match.params.id} /></CRMLayout>}/>
          <PrivateRoute exact path="/crm/dossiers-types/:id" component={(p) => <CRMLayout>
          <PrivateRoute path="/crm/CRMOutlookPage" component={() => <CRMLayout><CRMOutlookPage/></CRMLayout>} exact />
            <CustomerShowPage id={p.match.params.id} />
          </CRMLayout>}/>
          {CrmRouter().map(route => (
            <PrivateRoute key={route.label} exact={route.exact} path={route.url} component={() => {
              return <CRMLayout><route.page/></CRMLayout>
            }} />
          ))}
        </Switch>
        }/>
        <PrivateRoute path="/management" component={(p) => 
          <ManagementLayout>
            <Switch>
              <PrivateRoute exact path="/management/yards/:id" component={(p) => <YardShowPage id={p.match.params.id} />}/>
              <PrivateRoute exact path="/management/users/:id" component={(p) => <UserShowPage id={p.match.params.id} />}/>
              <PrivateRoute exact path="/management/customers/:id" component={(p) => <CustomerShowPage id={p.match.params.id} />}/>
              {AdminRouter(sessionBloc.user).map(route => (
                <PrivateRoute key={route.label} exact={route.exact} path={route.url} component={() => {
                  return <route.page/>
                }} />
              ))}
            </Switch>
          </ManagementLayout>
        }/>
        <PrivateRoute path="/clientarea" component={(p) => 
          <ClientAreaLayout>
            <Switch>
              <PrivateRoute exact path="/clientarea/" component={(p) => <ClientAreaPage/>}/>
              <PrivateRoute exact path="/clientarea/yards/:id" component={(p) => <YardShowPage id={p.match.params.id} editable={false} />}/>
            </Switch>
          </ClientAreaLayout>
        }/>
        <PrivateRoute path="/worker" component={(p) => 
          <WorkerLayout>
            <Switch>
              <PrivateRoute exact path="/worker" component={(p) => <InterventionSlotsPage />}/>
            </Switch>
          </WorkerLayout>
        }/>
      </div>
    );
  }
}

export default consumeSessionBloc(App);
