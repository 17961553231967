import * as React from 'react';
import Building from '@models/Building';
import SidebarLayout from '../layout/SidebarLayout';
import SmartInputText from '@components/input/SmartInputText';
import AsyncButton from '@components/button/AsyncButton';
import ApiService from '@services/ApiService';
import Address from "@models/Address";

interface IAddBuildingFormProps {
    address: Address;
    onSuccess?: () => void;
    onCancel?: () => void;
}

interface IAddBuildingFormState {
    building: Building;
    isSubmitting: boolean;
}
//TODO passer address_id et contact_id
class AddBuildingForm extends React.Component<IAddBuildingFormProps, IAddBuildingFormState> {
    constructor(props: IAddBuildingFormProps) {
        super(props);
        this.state = {
            building: new Building({...props}),
            isSubmitting: false,
        };
    }

    submit = async () => {
        const { address } = this.props;
        this.setState({isSubmitting: true});

        const {building} = this.state;

        try {
            await ApiService.post(`building`, building);
        } catch (error) {
            console.error("Erreur lors de l'ajout du bâtiment :", error);
        } finally {
            this.setState({isSubmitting: false});
        }
    };

    render() {
        const {building, isSubmitting} = this.state;
        return (
            <SidebarLayout title="Ajouter un bâtiment"
                           bottomArea={<>
                               <AsyncButton className="btn btn-primary w-100 btn-lg" loading={isSubmitting}
                                            onClick={this.submit}>
                                   Ajouter
                               </AsyncButton>
                           </>}>
                <div>
                    <SmartInputText model={building} name="yearBuilt" label="Année de construction"
                                    containerClassName="mb-3"/>
                    <SmartInputText model={building} name="totalArea" label="Superficie totale (m²)"
                                    containerClassName="mb-3"/>
                    <SmartInputText model={building} name="numFloors" label="Nombre d'étages"
                                    containerClassName="mb-3"/>

                </div>
            </SidebarLayout>
        );
    }
}

export default AddBuildingForm;
