import UserSlotCalendar from '@components/calendar/UserSlotCalendar';
import LightTabs from '@components/common/LightTabs';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import showModel, { IInjectedShowModelProps } from '@components/logic/ShowModel';
import Contact from '@models/Business';
import InterventionSlot from '@models/InterventionSlot';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import * as React from 'react';
import ContactActions from './ContactActions';
import ContactComments from './ContactComments';
import BusinessInfo from './BusinessInfo';
import ContactRdvNext from './ContactRdvNext';
import ExchangesList from './ExchangesList';
import InterventionsList from './InterventionsList';
import ContactFile from "./ContactFile";
import GetX from '@services/GetX';
import { Dropdown } from 'react-bootstrap';
import Intervention from '@models/Intervention';
import Alert from "@models/Alert";
import { sessionBloc } from "@bloc/SessionBloc";
import ContactSlotCalendar from "@components/calendar/ContactSlotCalendar";
import { useHistory } from 'react-router-dom';
import ApiService from '@services/ApiService';

export interface IContactShowProps extends IInjectedShowModelProps<Contact> {
  id: number;
  oneCol?: boolean;
  parentModel: Contact;
  manager: any;
  contact: Contact;
  onSlotCreated?: () => void;
}

const ContactShow: React.FC<IContactShowProps> = (props) => {
  const { model, oneCol, manager } = props;
  const [status, setStatus] = React.useState(model.status);
  const contactCalendar = React.useRef<ContactSlotCalendar>(null);
  const history = useHistory();

  const creationOptions = [
    {
      label: "Ajouter un fichier",
      action: () => DOMService.openSidebar(SidebarRoute.FileForm, {
        parentModel: props.parentModel,
        contactId: model.id,
        onSubmit: () => {
          manager.loadModels();
          GetX.get("contactShow")?.load();
          DOMService.closeSidebar();
        }
      }, "static")
    },
    {
      label: "Envoyer un email",
      icon: "fa-envelope",
      disabled: false,
      action: () => DOMService.openSidebar(SidebarRoute.SendPage, { type: "email", contact: model, customer: model.customer })
    },
    {
      label: "Créer un rendez-vous",
      icon: "fa-calendar-alt",
      disabled: false,
      action: () => DOMService.openSidebar(SidebarRoute.InterventionSlotForm, { newModel: new InterventionSlot({ contactId: model.id, startAt: new Date(), endAt: new Date() }), onSubmit: () => {
        DOMService.closeSidebar()
      } }, { style: { maxWidth: "1000px" } })
    },
    {
      label: "Modifier le statut",
      action: () => DOMService.openSidebar(SidebarRoute.StatusDialog, {
        parentModel: props.parentModel,
        contact: model,
        onSubmit: () => {
          manager.loadModels();
          GetX.get("contactShow")?.load();
          DOMService.closeSidebar();
        }
      }, "static")
    },
    {
      label: "Supprimer le contact",
      action: () => deleteContact()
    }
  ];

  const newExchange = (manager) => {
    DOMService.openSidebar(SidebarRoute.ExchangeForm, { parentModel: props.parentModel, onSubmit: () => {
      manager.loadModels();
      GetX.get("contactShow")?.load();
      DOMService.closeSidebar();
    } }, "static")
  }

  const edit = () => {
    DOMService.openSidebar(SidebarRoute.ContactForm, { id: props.id, onSubmit: () => {
      reload()
    } })
  }

  const reload = () => {
    DOMService.closeSidebar();
    props.load();
  }

  const eventDetail = ({ event }) => {
    let slot: InterventionSlot = event._def.extendedProps.slot;
    return <div className="p-1">
      <div className="fw-bold">{slot?.user?.getFullName()}</div>
    </div>;
  };

  const setStatusToNull = async () => {
    model.status = null;
    await EntityManager.update(model, { only: ["status"] });
    props.load();
  }

  const deleteContact = async () => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer ce contact?");
    if (confirmDelete) {
      try {
        await ApiService.delete(`api/contact/${model.id}`);
        history.push('/crm/sectors');
      } catch (error) {
        console.error("Error deleting contact:", error);
      }
    }
  }

  const statusStyles = {
    "Préparation": { color: "rgb(162, 192, 19)", textColorInactive: "rgb(162, 192, 19)" },
    "En cours": { color: "rgba(212, 110, 0, 0.6)", textColorInactive: "rgba(212, 110, 0, 0.6)" },
    "Clôturé": { color: "rgba(34, 80, 123, 0.6)", textColorInactive: "rgba(34, 80, 123, 0.6)" }
  };

  const statusColor = statusStyles[status] ? statusStyles[status].color : "grey";

  return (
    <div>
      <div className="row">
        <div className="col-md-6 col-12 mb-3 row-flex">
          <h3 className='mb-0'>
            {model.firstname}
          </h3>
          <div className="badge ms-3" style={{ backgroundColor: statusColor }}>{model.status}</div>
        </div>
        <div className="col-md-6 col-12 mb-3">
          <div className="row-end">
            {/* Menu déroulant avec options */}
            <Dropdown>
              <Dropdown.Toggle as={React.forwardRef<HTMLButtonElement, { onClick: () => void }>(
                ({ onClick }, ref) => (
                  <button ref={ref} onClick={onClick} className="btn btn-dark btn-sm rounded-2 me-3  px-4 fs-4">
                    +
                  </button>
                )
              )} />
              <Dropdown.Menu align="end">
                {creationOptions.map(opt => (
                  <Dropdown.Item key={opt.label} onClick={opt.action}>
                    {opt.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <button onClick={edit} className="btn btn-primary ms-3">Modifier l'entreprise</button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className={(oneCol ? "col-xl-5" : "col-md-6") + " col-12 mb-3"}>
          <BusinessInfo oneCol={oneCol} business={model} />
        </div>
        <div className={(oneCol ? "col-xl-7" : "col-md-6") + " col-12 mb-3 border-start border-5 border-light"}>
          <LightTabs
            tabTitles={["Echanges", "Rendez-vous / Alertes", "Fichiers", "Commentaires" + (model.comments.length ? "(" + model.comments.length + ")" : "")]}
            tabContents={[
              () => <ExchangesList parentModel={model} />,
              () => <ContactSlotCalendar contactId={model.id} hideAmountByDay ref={contactCalendar} canEdit parentModel={model} onSlotClick={(slot) => { }} eventContent={eventDetail} params={{ _with: ["user"] }} />,
              () => <ContactFile contact={model} />,
              () => <ContactComments contact={model} />
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default showModel(ContactShow, { modelClass: Contact, getRef: "contactShow" }) as any;
