import classNames from 'classnames';
import * as React from 'react';


export default class CustomMarker extends React.Component<{contactId, onClick?, selected?, lat?, lng?, color?}> {

  shouldComponentUpdate(nextProps: Readonly<{ contactId: any; onClick?: any; selected?: any; lat?: any; lng?: any; color?: any; }>, nextState: Readonly<{}>, nextContext: any): boolean {
    return false;
  }


  public render() {
    const { onClick, contactId, selected, color } = this.props;
    // let tooltip = "";
    // tooltip += contact.getFullName() + "\n" + contact.counter.connectionAddress.fullAddress()
    
    return (
      <div onClick={onClick} key={"m" + contactId} className={classNames({"custom-marker": true, "selected": selected})}
        // aria-label={tooltip}
        // data-balloon-break data-balloon-length="large"
        // data-balloon-pos={"down"}
      >
        <i style={{color, fontSize: "1.5rem"}} className="fas fa-map-marker-alt"></i>
        {/* { selected && <div className="tooltip-info"></div> } */}
        {/* <div className="marker-shadow"></div> */}
      </div>
    );
  }
}
