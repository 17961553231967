import KeyyoService from '@services/KeyyoService';
import { AxiosResponse } from "axios";
import { sessionBloc } from '../bloc/SessionBloc';
import LoginResponse from '@services/apiResponse/LoginResponse';
import ApiService from "./ApiService";
import SessionService from "./SessionService";
import UserManager from "./UserManager";

export interface Credential {
  email?: string,
  password?: string
  googleId?: string
  facebookId?: string
}

export class AuthService {

  public usersManager: UserManager = new UserManager();

  public async login(credential: Credential): Promise<LoginResponse> {
    let loginResponse = await this.usersManager.login(credential);
    if (loginResponse.ok) {
      sessionBloc.setSessionData(loginResponse.model);
      this.storeToken(loginResponse.token);
    }
    return loginResponse;
  }

  loginWithGoogle(response) {
    return this.login({googleId: response.profileObj.googleId});
  }

  loginWithFacebook(response) {
    return this.login({facebookId: response.id});
  }

  public hasToken() {
    return SessionService.getToken() !== null;
  }

  public isAuthenticated(): boolean {
    return !!sessionBloc.state.user;
  }

  public isAuthorized(role: any): boolean {
    return this.isAuthenticated() && (role === "all" || sessionBloc.state.user.type === role)
  }

  public storeToken(token: string) {
    SessionService.setCookie(SessionService.cookieName, token, 30);
  }

  public async validateEmail(email: string): Promise<boolean> {
    let response: AxiosResponse = await ApiService.post("validate", {email});
    return response.data.exist;
  }

  public async signin(credential: any) {
    let response = await this.usersManager.signin(credential);
    this.storeToken(response.response.data.token);
    return response;
  }

  public async me() {
    let response = await authService.usersManager.me();
    await sessionBloc.setSessionData(response.model);
    if (!response.ok) {
      this.deleteToken();
    }
    return true;
  }

  public deleteToken(): void {
    SessionService.eraseCookie(SessionService.cookieName)
    sessionBloc.setState({user: null});
  }

  public logout() {
    this.deleteToken();
    KeyyoService.stop()
  }

}

const authService = new AuthService();
export default authService;
