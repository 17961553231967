import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartOptions,
} from 'chart.js';
import ApiService from "@services/ApiService";
import DOMService from "@services/DOMService";
import {SidebarRoute} from "@components/layout/SidebarRouter";
import StatistiqueForm from "@components/form/StatistiqueForm";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

interface DataByCategory {
    [category: string]: number[];
}

interface GraphData {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string;
    }[];
}

interface StatKPIVilleState {
    selectedYear: number;
    selectedDataType: string;
    dataByCategory: DataByCategory;
    graphData?: GraphData;
}

export default class StatKPIVille extends React.Component<{}, StatKPIVilleState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            selectedYear: new Date().getFullYear(),
            selectedDataType: 'Industrie',
            dataByCategory: {
                Industrie: new Array(12).fill(0),
                Innovation: new Array(12).fill(0),
                Agricole: new Array(12).fill(0),
                AideCommerce: new Array(12).fill(0),
                AideCommerceCentreVille: new Array(12).fill(0),
            },
            graphData: {
                labels: [],
                datasets: [],
            },
        };
    }


    componentDidMount() {
        this.fetchData(this.state.selectedYear);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedYear !== this.state.selectedYear || prevState.selectedDataType !== this.state.selectedDataType) {
            this.fetchData(this.state.selectedYear);
        }
    }

    openStatForm = (statistique) => {
        DOMService.openSidebar(SidebarRoute.StatistiqueForm, {
            parentModel: statistique,
            fetchData: this.fetchData,
        });

    }

    fetchData = async (year) => {
        try {
            const dataType = this.state.selectedDataType;
            const response = await ApiService.get(`statistiques/${dataType}/${year}`);
            this.processData(response, dataType);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    };

    processData(dataResponse, dataType) {
        const labels = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', "Août", "Septembre", "Octobre", "Novembre", "Décembre"]; // Exemple de labels
        const data = dataResponse.data ?? [];
        let newData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        data.forEach(d => {newData[d.month] = d.value})

        this.setState(prevState => ({
            ...prevState,
            graphData: {
                labels: labels,
                datasets: [
                    {
                        label: dataType,
                        data: newData.map(d => d),
                        backgroundColor: '#71860d',
                    },
                ],
            },
        }));
    }


    handleYearChange = (offset: number) => {
        this.setState((prevState) => ({
            selectedYear: prevState.selectedYear + offset,
        }));
    };

    handleDataTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newDataType = event.target.value;
        this.setState({ selectedDataType: newDataType }, () => {
            this.fetchData(this.state.selectedYear);
        });
    };

    render() {
        const { selectedYear, selectedDataType, dataByCategory } = this.state;

        const options: ChartOptions<'line'> = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                    labels: {
                        color: '#537798',
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: '#537798',
                    },
                },
                y: {
                    ticks: {
                        color: '#537798',
                    },
                }
            },
        };

        return (
            <div>
                <h3 className={'text-center fs-4'}>Indicateurs clés de performances</h3>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '20px'
                }}>
                    <button className={"bg-transparent border-0"}
                            onClick={() => this.handleYearChange(-1)}>
                        <span className="fc-icon fa-2x fc-icon-chevron-left text-primary"></span>
                    </button>
                    <span>{selectedYear}</span>
                    <button className={"bg-transparent border-0"}
                            onClick={() => this.handleYearChange(1)}>
                        <span className="fc-icon fa-2x fc-icon-chevron-right text-primary"></span>
                    </button>
                </div>
                <Line options={options}
                      data={this.state.graphData ? this.state.graphData : {labels: [], datasets: []}}/>
                <select value={selectedDataType} onChange={this.handleDataTypeChange}
                        style={{height: '20px', width: 'auto', fontSize: '1rem', marginBottom: '20px'}}>
                    <option value="Industrie">Industrie</option>
                    <option value="Innovation">Innovation</option>
                    <option value="Agricole">Agricole</option>
                    <option value="AideCommerce">Aide au commerce</option>
                    <option value="AideCommerceCentreVille">Aide au commerce centre ville</option>
                </select>
                <button className="btn text-primary" onClick={() => this.openStatForm(StatistiqueForm)}>+
                    Ajouter des données
                </button>
                <div>
                    {/* Possibilité d'ajouter des données */}
                </div>
            </div>
        );
    }
}