import Contact from '@models/Business';
import Intervention from '@models/Intervention';
import User from '@models/User';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import DateAttribute from './core/DateAttribute';
import ModelAttribute from './core/ModelAttribute';
import Model from './Model';

export default class InterventionSlot extends Model {

  public startAt: Date
  public endAt: Date
  public remaining: number
  public user: User
  public intervention: Intervention
  public interventionId: number
  public userId: number
  public duration: number
  public contact: Contact
  public contactId: number
  public status: string
  public slotInDay: number
  public createdBy: User
  public comment: string

  static modelUrl = "intervention_slots";
  public modelName = "intervention_slot";

  public attributes = new AttributesCollection({
    startAt: new DateAttribute("startAt"),
    endAt: new DateAttribute("endAt"),
    duration: new Attribute("duration"),
    slotInDay: new Attribute("slotInDay"),
    status: new Attribute("status"),
    remaining: new Attribute("remaining", {submittable: false}),
    user: new ModelAttribute("user", User),
    intervention: new ModelAttribute("intervention", Intervention),
    contact: new ModelAttribute("contact", Contact),
    createdBy: new ModelAttribute("createdBy", User),
    comment: new Attribute("comment")
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  getTitle() {
    if (this.contact) return this.contact.getFullName();
    return "intervention"
  }

  getColor() {
    if (this.status == "done") return "#4dc169";
    else if (this.status == "canceled") return "#c17a4d";
    else if (this.status == "absent") return "#7775f3";
    else if (this.status == "unsuccessful") return "#db2222";
    return "#4d9cc1";
  }

  getHumanStatus() {
    if (this.status == "done") return "OUVERT";
    else if (this.status == "canceled") return "Annulé";
    else if (this.status == "absent") return "Absent";
    else if (this.status == "unsuccessful") return "Sans succès";
    return "A faire";
  }

  matchWithAddress(text) {
    let match = false;
    text.split(" ").forEach(word => {
      const regex = new RegExp(`${word.toLowerCase()}`, "g");
    });
    return match;
  }

}