import YardCard from '@components/card/YardCard';
import SmallLoading from '@components/common/SmallLoading';
import Yard from '@models/Yard';
import ApiService from '@services/ApiService';
import ChartService from '@services/ChartService';
import * as React from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import EntityManager from '../services/EntityManager';
import MainLayout from '@components/layout/MainLayout';
import SmartDateRangePicker from '@components/input/SmartDateRangePicker';
import { currentMonthRange, currentWeekRange } from 'src/helper/DateHelper';
import { ScopeButton } from '@components/common/Scoper';
import { toEuro } from '../helper/PriceHelper';
import TextIcon from '@components/common/TextIcon';
import dayjs from 'dayjs';


export interface IHomePageState {
  currentYards: Yard[]
  evolution
  ca
  byPrestation
  loaded
  from: Date
  to: Date
  periodLabel
  yearOption
}

export default class HomePage extends React.Component<any, IHomePageState> {

  periodSelect = [
    {label: "Depuis le début", value: [null, null]},
    {label: "Ce mois-ci", value: currentMonthRange()},
    {label: "Cette semaine", value: currentWeekRange()},
  ];

  yearsOptions = [
    {label: "Cet année", value: dayjs().year()},
  ];
  dateRangePicker = React.createRef<any>();

  constructor(props) {
    super(props)

    const defaultPeriod = this.periodSelect[0];

    this.state = {
      currentYards: null,
      evolution: null,
      ca: null,
      byPrestation: null,
      loaded: false,
      from: defaultPeriod.value[0],
      to: defaultPeriod.value[1],
      periodLabel: defaultPeriod.label,
      yearOption: this.yearsOptions[0].value
    }
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const {from, to, yearOption} = this.state;
    let response = await ApiService.get("dashboard", {from: from?.toJSON(), to: to?.toJSON(), evoYear: yearOption});
    let {currentYards, evolution, ca, byPrestation} = response.data;
    this.setState({
      currentYards: currentYards,
      evolution: ChartService.buildCaEvolutionData(evolution),
      ca,
      byPrestation: ChartService.buildByPrestation(byPrestation),
      loaded: true
    });
  }

  handleChangeRange = (range) => {
    const periodLabel = this.periodSelect.find(s => s.value[0] === range[0] && s.value[1] === range[1])?.label;
    this.setState({from: range[0], to: range[1], periodLabel}, () => {
      if (range[0] && range[1]) this.loadData()
    });
  }

  setPeriod = (opt) => {
    this.setState({from: opt.value[0], to: opt.value[1], periodLabel: opt.label}, () => this.loadData());
  }

  renderActions = (ref) => {
    return <div className="row-flex">
      {this.periodSelect.map(option => (
        <div className="ms-2">
          <ScopeButton onClick={() => {
            this.setPeriod(option);
            ref.close();
          }} active={option.label === this.state.periodLabel} >{option.label}</ScopeButton>
        </div>
      ))}
    </div>
  }

  public render() {
    const {currentYards, evolution, ca, loaded, byPrestation, from, to, periodLabel} = this.state;
    if (!loaded) return <SmallLoading/>
    let cartePercent;
    if (byPrestation.readPrestation.datasets[0].data.length === 2) {
      let data1 = parseFloat(byPrestation.readPrestation.datasets[0].data[0]);
      let data2 = parseFloat(byPrestation.readPrestation.datasets[0].data[1]);
      cartePercent = (data1 / (data2 + data1) * 100).toFixed(2) + " %"
    }
    let simpleState = [
      {
        icon: "fas fa-euro",
        label: "Chiffre d'affaire",
        value: toEuro(ca.amount)
      },
      // {
      //   icon: "fas fa-euro",
      //   label: "Rentabilité moyenne",
      //   value: toEuro(ca.amount)
      // },
      {
        icon: "fas fa-address-card",
        label: "Pourcentage de carte",
        value: cartePercent
      }
    ]
    
    return (
      <div className="home-page">
        <MainLayout title={<h3 className="mb-0">{"Bonjour John"}</h3>} right={
          <div>
            <SmartDateRangePicker from={from} to={to} onChange={this.handleChangeRange} rightIcon={<i className="fas fa-calendar-alt "></i>} className="bg-front " periodLabel={periodLabel} actions={this.renderActions} />
          </div>
        }>
          <div className="row">
            {simpleState.map(stat => <div className="col-lg col-12">
              <div className="app-content mb-3 fadeInDown">
                <div className="d-flex">
                  <div className="decorated-icon">
                    <i className={stat.icon}></i>
                  </div>
                  <div  className="ms-3">
                    <div className="text-secondary text-s">
                      {stat.label}
                    </div>
                    <h4 className="fw-bold">{stat.value}</h4>
                  </div>
                </div>
              </div>
            </div>)}
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 mb-3 fadeInDown animate-delay-1">
              <div className="app-content">
                <div className="card-title">Autres prestations</div>
                <Doughnut data={byPrestation.otherPrestation} style={{maxHeight: "450px"}} options={{
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          return toEuro(context.parsed);
                        }
                      }
                    }
                  }
                }} />
              </div>
            </div>
            <div className="col-lg-6 col-12 mb-3 fadeInDown animate-delay-2">
              <div className="app-content">
                <div className="card-title">Relève de compteur</div>
                <Doughnut data={byPrestation.readPrestation} style={{maxHeight: "450px"}} options={{
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          let data: any = context.dataset.data;
                          let total = data.reduce((a, b: string) => a + parseFloat(b), 0);
                          let percent = (context.parsed / total * 100).toFixed(2);
                          return `${percent} %`
                        }
                      }
                    }
                  }
                }}/>
              </div>
            </div>
          </div>
          <div className="app-content fadeInDown animate-delay-3 mb-4">
            <div className="card-title">Evolution du chiffre d'affaire {dayjs().format("YYYY")}</div>
            <Line
              style={{maxHeight: "450px"}}
              options={{
                maintainAspectRatio: false
              }}
              data={evolution}
              />
          </div>
        </MainLayout>
        <div className="bg-front px-5 secondary-home-sidebar d-none d-lg-block">
          <div className="head-up row-flex fw-bold">
            <TextIcon leftIcon="far fa-clock">
              <h5 className="mb-0">Chantier en cours</h5>
            </TextIcon>
          </div>
          {currentYards.map((yard, index) => <YardCard yard={yard} className={"fadeInLeft animate-delay-"+index} />)}
        </div>
      </div>
    );
  }
}
