import React, { Component } from 'react';

export default class OutsideAlerter extends Component<any> {

    public wrapperRef: React.RefObject<HTMLDivElement>;

    constructor(props) {
      super(props);
      this.wrapperRef = React.createRef<HTMLDivElement>();
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        this.props.close();
      }
    }

    render() {
      return <div style={this.props.style} className={this.props.className} ref={this.wrapperRef}>{this.props.children}</div>;
    }
}
