import React from "react";
import SmallLoading from "../common/SmallLoading";
import OutsideAlerter from "../logic/OutsideAlerter";
import SmartInputText from "./SmartInputText";
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import includes from "lodash/includes";


export interface ICustomSelectProps {
  options: any[]
  labelName?: string
  valueName?: string
  placeholder?
  onOpen?
  value?
  name?,
  disabled?
  id?,
  onBlur?
  noSearch?
  allOption?: {label: any, value: any}
  style?,
  onClear?: () => void
  renderOption?: (o) => JSX.Element
  filterRules?: (o, i) => boolean
  equalityRule?: (o, i) => boolean
  onChange: (o) => void
}

export default class CustomSelect extends React.PureComponent<ICustomSelectProps, any> {

  public contentRef = React.createRef<HTMLDivElement>();

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      options: props.options,
      loading: false,
      input: "",
    }

  }

  public handleChange = (input) => {
    this.setState({input, options: this.filterOptions(input)})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) this.setState({options: this.props.options})
  }

  filterOptions(input) {
    return input === "" ? this.props.options : this.props.options.filter(o => this.props.filterRules ? this.props.filterRules(o, input) : this.filterRules(o, input))
  }

  filterRules = (o, input: string) => {
    return includes(o.label.toLowerCase(), input.toLowerCase());
  }

  defaultRenderOption = (o) => o.label

  defaultEqualityRule = (o, s) => s?.id === o.id

  open = () => {
    this.setState({isOpen: true});
    this.props.onOpen?.call();
  }

  onSelect(option) {
    this.setState({isOpen: false})
    this.props.onChange(option);
  }

  onClear = (e) => {
    e.stopPropagation();
    this.props.onClear();
  }

  renderButton() {
    const { renderOption, value, placeholder, onClear, allOption } = this.props;
    const { loading } = this.state;
    
    return <div onClick={this.open} className="select-button">
      {value ? (
        <div className="row-flex w-100">
          {renderOption ? renderOption(value) : this.defaultRenderOption(value)}
        </div>
      ) : (
        <div className="row-flex my-auto">
          {allOption?.label || placeholder}
        </div>
      )}
      {(value !== null && value !== "" && onClear) && <div onClick={this.onClear} className="align-self-center px-3 clear-btn"><i className="text-secondary far fa-times"></i></div>}
      {loading && <div  className="align-self-center me-3"><SmallLoading className="dark"/></div>}
    </div>
  }

  render() {
    const { isOpen, input, options } = this.state;
    const { style, renderOption, value, equalityRule, allOption, noSearch } = this.props;

    return (
      <div ref={this.contentRef} style={style} className="select-design">
        { !isOpen && this.renderButton()}
        { isOpen && <OutsideAlerter close={() => this.setState({isOpen: false})}>
          {!noSearch ? <div className="select-button open">
            <SmartInputText containerClassName="w-100" leftIcon={<i className="fas fa-search"/>} onChange={this.handleChange} value={input} autoFocus placeholder="Rechercher..." autoComplete="off"/>
          </div> : this.renderButton()}
          <div className="select-options">
            {allOption && <div key={uniqueId()} className={classNames({"selected": allOption.value, "option": true})} onClick={() => this.onSelect(allOption.value)}>{allOption.label}</div>}
            {options.length === 0 && <div className="text-secondary py-3 text-center">Cette liste est vide</div>}
            { options?.map((v) => <div key={uniqueId()} className={classNames({"selected": equalityRule ? equalityRule(v, value) : this.defaultEqualityRule(v, value), "option": true})} onClick={() => this.onSelect(v)}>{renderOption ? renderOption(v) : this.defaultRenderOption(v)}</div>)}
          </div>
        </OutsideAlerter>}
      </div>
    );
  }
}

