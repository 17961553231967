import * as React from 'react';
import SidebarRouter from './SidebarRouter';
import DOMService from '@services/DOMService';

function simplifySqlError(errorMessage) {
  switch (true) {
      case /no such table/.test(errorMessage):
          return "Erreur : La table spécifiée n'existe pas. Assurez-vous que le nom de la table est correct et que la table a été créée.";
      case /syntax error/.test(errorMessage):
          return "Erreur : Il y a une erreur de syntaxe dans votre requête SQL. Vérifiez la syntaxe SQL et assurez-vous que toutes les instructions sont correctement formulées.";
      case /UNIQUE constraint failed/.test(errorMessage):
          return "Erreur : l'élément existe déjà";
      case /Duplicate entry/.test(errorMessage):
        return "Erreur : l'élément existe déjà.";
      case /NOT NULL constraint failed/.test(errorMessage):
        return "Erreur : Un champ obligatoire est manquant. Assurez-vous que tous les champs obligatoires sont fournis dans votre requête.";
      default:
          return "Erreur SQL : " + errorMessage;
  }
}

export default class SidebarLayout extends React.Component<{children, title, bottomArea?, onLeadingClick?, action?}, any> {

  constructor(props) {
    super(props)
    this.state = {
      error: null
    }
    DOMService.sidebarLayoutRef = this
  }

  render() {
    const {children, title, bottomArea, action} = this.props
    const {error} = this.state
    return (
      <>
        <div className="d-flex flex-column position-relative">
          <div className="min-vh-100 px-4 pt-3" style={{paddingBottom: "110px"}}>
            <div className="row-flex">
              {SidebarRouter.popable() && <i onClick={() => SidebarRouter.pop()} className="fas fa-chevron-left pe-3 pointer"></i>}
              <div className="fw-bold text-xl">{title}</div>
              <div className="ms-auto">{action}</div>
              <div onClick={() => DOMService.closeSidebar()} className="pointer py-3 px-4 my-n3 me-n4 row-center text-secondary" >
                <i className="far fa-times text-xl"></i>
              </div>
            </div>
            <div className="sidebar-divider"></div>
            {children}
          </div>
          {error && <div onClick={() => this.setState({error: null})} className="error-sidebar-container">
            <div className="error-sidebar">{simplifySqlError(error)}</div>
          </div>}
        </div>
        {bottomArea && <div style={DOMService.sidebarRef.state.style} className={`bg-white bottom-0 mt-auto position-fixed w-100`}>
          {bottomArea}
        </div>}
      </>
    );
  }
}
