import * as React from 'react';
import ApiService from "@services/ApiService";
import SmartCheckbox from './input/SmartCheckbox';

export interface IContactDetailsProps {
    contactId: number;
}

interface IContactDetail {
    id: number;
    name: string;
    email: string;
    phone: string;
    portable: string;
    receiveEmails: boolean;
    vip: boolean;
    fonction: string;
}

interface IContactDetailsState {
    contactDetails: IContactDetail[];
    selectedContactId: number;
    newContactDetail: IContactDetail;
    isEditing: boolean;
    showForm: boolean;
}

export default class ContactDetails extends React.Component<IContactDetailsProps, IContactDetailsState> {
    constructor(props: IContactDetailsProps) {
        super(props);
        this.state = {
            contactDetails: [],
            selectedContactId: -1,
            newContactDetail: {
                id: 0, name: '', email: '', phone: '', portable: '', receiveEmails: false, vip: false,
                fonction: ''
            },
            isEditing: false,
            showForm: true
        };
        this.handleContactChange = this.handleContactChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEditContactDetail = this.handleEditContactDetail.bind(this);
        this.handleDeleteContactDetail = this.handleDeleteContactDetail.bind(this);
        this.toggleForm = this.toggleForm.bind(this);
    }

    componentDidMount() {
        this.fetchContactDetails();
    }

    async fetchContactDetails() {
        const { contactId } = this.props;
        try {
            const response = await ApiService.get(`api/contact-details/${contactId}`);
            this.setState({ contactDetails: response.data });
        } catch (error) {
            console.error("Error fetching contact details:", error);
        }
    }

    handleContactChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const selectedContactId = parseInt(event.target.value);
        const isEditing = selectedContactId !== -1;
        const newContactDetail = isEditing ? this.getSelectedContact(selectedContactId) : { id: 0, name: '', email: '', phone: '', portable: '', receiveEmails: false, vip: false, fonction: '' };
        console.log(newContactDetail)
        this.setState({ selectedContactId, isEditing, newContactDetail, showForm: !isEditing });
    }

    handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            newContactDetail: {
                ...prevState.newContactDetail,
                [name]: value
            }
        }));
    }

    handleCheckboxChange(name: string, checked: boolean) {
        this.setState((prevState) => ({
            newContactDetail: {
                ...prevState.newContactDetail,
                [name]: checked
            }
        }));
    }

    async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const { contactId } = this.props;
        const { newContactDetail, isEditing, selectedContactId } = this.state;
        let id = null;
        try {
            if (isEditing) {
                id = (await ApiService.put(`api/contact-detail/${selectedContactId}`, newContactDetail)).data.id;
            } else {
                id = (await ApiService.post(`api/contact-details`, { ...newContactDetail, contact_id: contactId })).data.id;
            }
            // Fetch updated contact details
            await this.fetchContactDetails();
            const updatedContactDetails = this.state.contactDetails;
            const updatedContactDetail = updatedContactDetails.find(contact => contact.id === id) || { id: 0, name: '', email: '', phone: '', portable: '', receiveEmails: false, vip: false, fonction: '' };
    
            this.setState({
                newContactDetail: updatedContactDetail,
                isEditing: false,
                selectedContactId: id,
                showForm: false
            });
        } catch (error) {
            console.error("Error saving contact detail:", error);
        }
    }
    

    handleEditContactDetail(contact: IContactDetail) {
        this.setState({ newContactDetail: contact, isEditing: true, showForm: true, selectedContactId: contact.id });
    }

    async handleDeleteContactDetail(contactId: number) {
        const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer ce contact?");
        if (confirmDelete) {
            try {
                await ApiService.delete(`api/contact-detail/${contactId}`);
                this.setState({ selectedContactId: -1, isEditing: false, newContactDetail: { id: 0, name: '', email: '', phone: '', portable: '', receiveEmails: false, vip: false, fonction: '' }, showForm: true });
                this.fetchContactDetails();
            } catch (error) {
                console.error("Error deleting contact detail:", error);
            }
        }
    }

    toggleForm() {
        this.setState((prevState) => ({
            showForm: !prevState.showForm,
            isEditing: false,
            newContactDetail: { id: 0, name: '', email: '', phone: '', portable: '', receiveEmails: false, vip: false, fonction: ''}
        }));
    }

    getSelectedContact(selectedContactId: number) {
        const { contactDetails } = this.state;
        return contactDetails.find(contact => contact.id === selectedContactId) || { id: 0, name: '', email: '', phone: '', portable: '', receiveEmails: false, vip: false, fonction: '' };
    }

    render() {
        const { contactDetails, selectedContactId, newContactDetail, isEditing, showForm } = this.state;

        return (
            <>
                <div className='mb-3 bg-light p-3 rounded'>
                    <div className='fw-bold mb-3'>Contact</div>
                    <select onChange={this.handleContactChange} className="form-select mb-3" value={selectedContactId}>
                        <option value="-1">Sélectionner un contact</option>
                        {contactDetails.map((contact) => (
                            <option key={contact.id} value={contact.id}>{contact.name}</option>
                        ))}
                    </select>
                    {selectedContactId !== -1 && !showForm && (
                        <>
                            <div className={"mb-3"}>
                                <div className='text-s text-secondary'>Fonction</div>
                                <div className="row-flex">
                                    <div>{newContactDetail.fonction}</div>
                                </div>
                            </div>
                            <div className={"mb-3"}>
                                <div className='text-s text-secondary'>Email</div>
                                <div className="row-flex">
                                    <div>{newContactDetail.email}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className={"mb-3"}>
                                        <div className='text-s text-secondary'>Téléphone Fixe</div>
                                        <div className={"mb-3"}>
                                            <div className="row-flex">
                                                <div>{newContactDetail.phone}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className={"mb-3"}>
                                        <div className='text-s text-secondary'>Portable</div>
                                        <div className={"mb-3"}>
                                            <div className="row-flex">
                                                <div>{newContactDetail.portable}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-warning" onClick={() => this.setState({ showForm: true })}>
                                Modifier
                            </button>
                        </>
                    )}
                    {showForm && (
                        <div className='mb-3 bg-light p-3 rounded'>
                            <div className='fw-bold mb-3'>{isEditing ? 'Modifier Contact' : 'Ajouter Contact'}</div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Nom Prénom</label>
                                    <input type="text" className="form-control border" id="name" name="name" value={newContactDetail.name} onChange={this.handleInputChange} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="fonction" className="form-label">Fonction</label>
                                    <input type="text" className="form-control border" id="fonction" name="fonction" value={newContactDetail.fonction} onChange={this.handleInputChange} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="email" className="form-control border" id="email" name="email" value={newContactDetail.email} onChange={this.handleInputChange} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="phone" className="form-label">Téléphone Fixe</label>
                                    <input type="text" className="form-control border" id="phone" name="phone" value={newContactDetail.phone} onChange={this.handleInputChange} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="portable" className="form-label">Portable</label>
                                    <input type="text" className="form-control border" id="portable" name="portable" value={newContactDetail.portable} onChange={this.handleInputChange} />
                                </div>
                                <div className="mb-3 d-flex">
                                    <SmartCheckbox 
                                        id={"checkMail" + newContactDetail.id} 
                                        checked={newContactDetail.receiveEmails} 
                                        onChange={(checked) => this.handleCheckboxChange('receiveEmails', checked)} 
                                    />
                                    <label>
                                        Recevoir des e-mails
                                    </label>
                                    <SmartCheckbox 
                                        id={"checkVip" + newContactDetail.id} 
                                        checked={newContactDetail.vip} 
                                        onChange={(checked) => this.handleCheckboxChange('vip', checked)} 
                                    />
                                    <label>
                                        VIP
                                    </label>
                                </div>
                                {!newContactDetail.receiveEmails && (
                                    <div className="alert alert-warning" role="alert">
                                        Ce contact ne recevra pas les emails envoyés à l'entreprise.
                                    </div>
                                )}
                                <button type="submit" className="btn btn-primary">{isEditing ? 'Modifier' : 'Ajouter'}</button>
                                {isEditing && (
                                    <button type="button" className="btn btn-danger ms-2" onClick={() => this.handleDeleteContactDetail(selectedContactId)}>
                                        Supprimer
                                    </button>
                                )}
                                <button type="button" className="btn btn-secondary ms-2" onClick={() => this.setState({ showForm: false })}>
                                    Annuler
                                </button>
                            </form>
                        </div>
                    )}
                </div>
            </>
        );
    }
}
