import classNames from 'classnames';
import * as React from 'react';
import SmartInput2, { ISmartInputProps } from './SmartInput2';


export interface ISmartSwitchProps extends ISmartInputProps {
  checked?: boolean;
  primary?: boolean;
  color?: string;
}

function SmartSwitch(props: ISmartSwitchProps) {

  const handleChange = (e) => props.manager.updateState(e.target.checked);

  const { color, primary, containerClassName } = props;
  return (
    <>
      <div className={ classNames({ [containerClassName]: !!containerClassName, "smart-switch": true, "smart-switch-primary": primary })}>
        <input
          checked={props.checked || props.value}
          type="checkbox"
          onChange={handleChange}
          name={props.name}
          id={props.id}
        />
        <label style={{background: props.checked ? color : null}} htmlFor={props.id} className="check-trail">
          <span className="check-handler"></span>
        </label>
        {props.manager.renderLabel("switch-label mb-0")}
      </div>
      {props.children}
    </>
  );
}

export default SmartInput2<ISmartSwitchProps, any>(SmartSwitch)