import { AxiosResponse } from 'axios';
import { env } from 'process';

export default class HttpResponse {

  public ok = false;
  public errorMessage: string
  public errors
  public response: AxiosResponse;

  constructor(response: AxiosResponse, options?) {
    this.response = response;
    if (response.status < 300) {
      this.initOkResponse(response, options)
    } else {
      this.initErrorResponse(response, options)
    }
  }

  initOkResponse(response: AxiosResponse, options) {
    this.ok = true;
  }

  initErrorResponse(response: AxiosResponse, options) {
    this.errorMessage = response.data.message;
    this.errors = response.data.fieldErrors;
    if (env.NODE_ENV === "development") console.error(response.data)
  }

  get data() { return this.response.data};
  get exception() { return this.data.exception};

}