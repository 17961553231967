import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import '@fullcalendar/daygrid/main.css';
import interactionPlugin from "@fullcalendar/interaction";
import * as React from 'react';
import Alert from '@models/Alert';
import DOMService from '@services/DOMService';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import { sessionBloc } from '@bloc/SessionBloc';
import EntityManager from '@services/EntityManager';
import dayjs from 'dayjs';
import listPlugin from "@fullcalendar/list";
import frLocale from '@fullcalendar/core/locales/fr';


export interface ICrmCalendarProps {
}

export default class CrmCalendar extends React.Component<ICrmCalendarProps, {alerts: Alert[]}> {

  constructor(props) {
    super(props);
    this.state = {
      alerts: null
    }
  }

  componentDidMount() {
    this.loadAlerts();
  }

  async loadAlerts() {
    let response = await EntityManager.all<Alert>(Alert);
    this.setState({alerts: response.models});
  }

  select =(e) => {
    let alert: Alert = new Alert({
      startAt: e.date || e.start,
      to: sessionBloc.user
    });

    DOMService.openSidebar(SidebarRoute.AlertForm, {newModel: alert, onSubmit: (newAlert) => {
      DOMService.closeSidebar();
      this.loadAlerts();
    }})
  }

  getEvents = () => {
    if (!this.state.alerts) return [];
    return this.state.alerts.map((alert: Alert) => ({
      title: alert.contact?.getFullName() ?? alert.message ?? "alerte",
      start: alert.startAt,
      alert: alert,
      end: dayjs(alert.startAt).add(30, "minute").toDate(),
      color: alert.toId ===sessionBloc.user.id ? "#4d9cc1" : "#4dc1b1",
      backgroundColor: alert.toId ===sessionBloc.user.id ? "#4d9cc1" : "#4dc1b1",
    }))
  }

  onPickAlert(alert) {
    DOMService.openSidebar(SidebarRoute.AlertForm, {id: alert.id, onSubmit:(alert) => {
      DOMService.closeSidebar();
      this.loadAlerts();
    }})
  }

  eventContent = ({event}) => {
    let alert: Alert = event._def.extendedProps.alert
    let tooltip = ""
    if (alert.contact) tooltip += `${alert.contact.getFullName()} \n`
    if (alert.message) tooltip += `${alert.message}\n`;
    tooltip += "Alerte pour " + alert.users.map(u => u.getFullName()).join(", ")
    return <div onClick={(e) => this.onPickAlert(alert)} className="alert-message-container" aria-label={tooltip} data-balloon-break data-balloon-length="large" data-balloon-pos={event.start.getHours() > 14 ? "up" : "down"}>
      <div className='d-flex'>
        <div className="fw-bold">{dayjs(event.start).format('HH:mm')}</div>
        <div className="fw-bold ms-2">{alert.message}</div>
      </div>
    </div>;
  };

  public render() {
    // const {user} = this.props;
    return (
        <div className="calendar-container d-flex justify-content-around">
          <div className='col-7'>
            <h3 className={'text-center fs-4'}>Rétroplanning</h3>
            <FullCalendar
                plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                initialView='dayGridMonth'
                locale={frLocale}
              dayCellContent={({date, isToday, view}) => {
                const todayClass = isToday ? 'my-today-class' : '';
                return (
                    <div className={`day-cell-content ${todayClass}`}>
                      {dayjs(date).format('D')}
                    </div>
                );
              }
              }
              headerToolbar={{
                left: "prev,next",
                center: "title",
                right: "today"
              }}
              slotMinTime="08:00:00"
              slotMaxTime="18:00:00"
              allDaySlot={true}
              firstDay={1}
              schedulerLicenseKey="0344037874-fcs-1637598111"
              contentHeight={"700px"}
              eventClassNames="pointer"
              eventContent={this.eventContent}
              hiddenDays={[]}
              dateClick={this.select}
              select={this.select}
              selectable={true}
              events={this.getEvents()}
            />
          </div>
          <div className='col-4'>
            <h3 className={'text-center fs-4'}>Informations Journalières</h3>
            <FullCalendar
                plugins={[listPlugin]}
                initialView='listDay'
                locale={frLocale}
                headerToolbar={{
                  left: 'prev,next',
                  center: 'title',
                  right: 'today'
                }}
                contentHeight={"700px"}
                selectable={true}
                eventContent={this.eventContent}
                events={this.getEvents()}
            />
          </div>
        </div>
    );
  }
}
