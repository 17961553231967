import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import Interlocutor from '@models/Interlocutor';
import * as React from 'react';
import SidebarLayout from '../layout/SidebarLayout';
import PhonesMultipleForm from './PhonesMultipleForm';

export interface IInterlocutorFormProps extends IInjectedFormModelProps<Interlocutor> {
}

export interface IInterlocutorFormState {
}

class InterlocutorForm extends React.Component<IInterlocutorFormProps & IFormModelProps<Interlocutor>, IInterlocutorFormState> {
  constructor(props: IInterlocutorFormProps & IFormModelProps<Interlocutor>) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const {model, submit, submitting, id} = this.props;
    
    return (
      <SidebarLayout
        title={id ? "Modification d'un interlocuteur" : "Création d'un interlocuteur"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={submit} >{id ? "Modifier l'interlocuteur" : "Créer l'interlocuteur"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <SmartInputText model={model} name="fullname" label="Prénom et Nom" containerClassName="mb-3" />
          <SmartInputText model={model} name="email" label="Email"  containerClassName="mb-3" />
          <SmartInputText model={model} name="job" label="Poste" containerClassName="mb-3" />
          <PhonesMultipleForm phones={model.phones} onChange={(e) => model.phones = e} />
        </div>
      </SidebarLayout>
    );
  }
  
  
}

export default formModel<IInterlocutorFormProps>(InterlocutorForm, {modelClass: Interlocutor, refreshOnFieldsChange: ["phones"]})