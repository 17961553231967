import AsyncButton from '@components/button/AsyncButton';
import SmartDateRangePicker from '@components/input/SmartDateRangePicker';
import SmartInputText from '@components/input/SmartInputText';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import CustomerSelect from '@components/modelSelect/CustomerSelect';
import Yard from '@models/Yard';
import YardComposition from '@models/YardComposition';
import OptionService from '@services/OptionService';
import * as React from 'react';
import DOMService from 'src/services/DOMService';
import YardPrice from '../../models/YardPrice';
import SidebarLayout from '../layout/SidebarLayout';
import MultiUserSelect from './MultiUserSelect';
import YardSubForm from './YardSubForm';

export interface IYardFormProps extends IInjectedFormModelProps<Yard> {
}

export interface IYardFormState {
}

class YardForm extends React.Component<IYardFormProps & IFormModelProps<Yard>, IYardFormState> {
  constructor(props: IYardFormProps & IFormModelProps<Yard>) {
    super(props);

    OptionService.prestations.forEach(presta => {
      if (!props.model.yardPrices.find(item => item.prestationId === presta.id)) props.model.yardPrices.push(new YardPrice({prestation: presta}));
    })
    OptionService.prestationTypes().forEach(prestaType => {
      if (!props.model.yardCompositions.find(item => item.prestationTypeId === prestaType.id)) props.model.yardCompositions.push(new YardComposition({prestationType: prestaType}));
    })
  }

  submit = () => {
    const {submit} = this.props;
    if (this.checkValidity()) submit();
  }

  errorToaster = (message, yc?: YardComposition) => {
    return DOMService.setToaster(<div className="col-center">
      <div>
        <i className="fas fa-exclamation-circle fa-2x text-primary"></i>
      </div>
      <div>{message}</div>
      {yc && <div className="fw-bold text-center">
        {yc.prestationType.label}
      </div>}
    </div>)
  }

  checkValidity = () => {
    const {model} = this.props;
    let valid = true;
    if (!model.startAt || !model.endAt) {
      this.errorToaster("Aucune période définis pour ce chantier");
      return false;
    }
    model.yardCompositions.forEach((yc) => {
      let definedPrice = 0;
      model.yardPrices.filter((yp) => yp.prestation.prestationTypeId === yc.prestationTypeId).forEach((yp) => {
        if (yp.unitPrice) definedPrice++;
      });
      if (definedPrice === 0 && yc.quantity) {
        this.errorToaster("Aucun prix définis sur un type de prestation", yc);
        valid = false;
      }
      if (definedPrice > 0 && !yc.quantity) {
        if (model.id) {
          this.errorToaster("Si vous souhaitez supprimer ce type de prestation, veuillez supprimer les prix associés", yc);
        } else {
          this.errorToaster("Aucune quantité définis sur un ou plusieurs prix de prestation", yc);
        }
        valid = false;
      }
    })
    return valid;
  }

  public render() {
    const {model, submitting, id} = this.props;
    
    return (
      <SidebarLayout
        title={id ? "Modification d'un chantier" : "Création d'un chantier"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={this.submit} >{id ? "Modifier le chantier" : "Créer le chantier"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <div className="row ">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="name" label="Nom" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="batch" label="Numéro de lot" containerClassName="mb-3" />
            </div>
          </div>
          <div className="">
            <SmartInputText model={model} name="city" label="Secteur" containerClassName="mb-3 " />
            <CustomerSelect label="Secteur" model={model} name="sector" placeholder="Choisir un sector/client" containerClassName="mb-3" />
          </div>
          <SmartInputTextarea model={model} name="description" label="Description" containerClassName="mb-3 " />
          <SmartDateRangePicker leftIcon={<i className="fas fa-calendar-alt"></i>} model={model} names={["startAt", "endAt"]}  containerClassName="mb-3 "/>
          <div className="mb-3 ">
            <MultiUserSelect model={model} params={{scope: "onlyResponsibles"}} placeholder="Ajouter un responsable" />
            <YardSubForm yard={model} />
          </div>
        </div>
      </SidebarLayout>
    );
  }
}

export default formModel<IYardFormProps>(YardForm, {modelClass: Yard})
