import { sessionBloc } from '@bloc/SessionBloc';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import Comment from '@models/Comment';
import Contact from '@models/Business';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import * as React from 'react';
import CommentItem from './CommentItem';

export interface IContactCommentsProps {
  contact: Contact
}

export default class ContactComments extends React.Component<IContactCommentsProps, {comment: Comment}> {

  constructor(props) {
    super(props);
    this.state = {
      comment: this.emptyComment()
    }
    
  }

  async addComment() {
    if (!this.state.comment.content) return DOMService.setToaster("Le commentaire est vide")
    const { contact } = this.props;
    let response = await EntityManager.create<Comment>(this.state.comment, {parentModel: contact});
    if (response.ok) {
      contact.comments.unshift(response.model);
      this.setState({comment: this.emptyComment()})
    } else {
      DOMService.setToaster("Une erreur est survenue")
    }
  }

  emptyComment() {
    return new Comment({createdBy: sessionBloc.user})
  }

  public render() {
    const { contact } = this.props;
    const { comment } = this.state;
    return (
      <div>
        <div className=" mb-3">
          <SmartInputTextarea model={comment} name="content" label="Avancée" containerClassName="mb-3" />
          <div className='row-end'>
            <button onClick={() => this.addComment()} className="btn btn-primary">Ajouter</button>
          </div>
        </div>
        <div>
          {contact.comments.map((comment) => <CommentItem key={comment.id} comment={comment} />)}
        </div>
      </div>
    );
  }
}
