import Model from "./Model";
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";

export default class Building extends Model {
    public yearBuilt: number;
    public totalArea: number;
    public numFloors: number;
    public contactId: number;

    static modelUrl = "buildings";
    public modelName = "building";

    public attributes = new AttributesCollection({
        yearBuilt: new Attribute("yearBuilt"),
        totalArea: new Attribute("totalArea"),
        numFloors: new Attribute("numFloors"),
        contactId: new Attribute("contactId"),
    });

    constructor(json?: any) {
        super(json);
        this.fillAttributes(json);
    }
}
