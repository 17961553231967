import { SidebarRoute } from '@components/layout/SidebarRouter';
import RecordManagerV2 from '@components/logic/RecordManagerV2';
import Contact from '@models/Business';
import Exchange from '@models/Exchange';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import GetX from '@services/GetX';
import OptionService from '@services/OptionService';
import * as React from 'react';
import ExchangeItem from './ExchangeItem';

export interface IExchangesListProps {
    parentModel: Contact
}

export default function ExchangesList (props: IExchangesListProps) {
    const newExchange = (manager) => {
        DOMService.openSidebar(SidebarRoute.ExchangeForm, {parentModel: props.parentModel, onSubmit: () => {
                manager.loadModels();
                GetX.get("contactShow")?.load();
                DOMService.closeSidebar();
            }}, "static")
    }

    const newExchangeWithRdv = (manager) => {
        DOMService.openSidebar(SidebarRoute.ExchangeForm, {status: "RDV", parentModel: props.parentModel, onSubmit: () => {
                manager.loadModels();
                GetX.get("contactShow")?.load();
                DOMService.closeSidebar();
            }}, "static")
    }

    const newInvoiceExchange = async (manager) => {
        let exchange = new Exchange({type: OptionService.getOption("exchange_type", "exchange_type_messaging"), contact: props.parentModel});
        await EntityManager.create(exchange);
        manager.loadModels();
        GetX.get("contactShow")?.load();
    }

    const deleteExchange = async (manager, exchange) => {
        await manager.delete(exchange, () => GetX.get("contactShow")?.load());
        ;
    }

    return (
        <RecordManagerV2<Exchange> getRef="contactExchanges" options={{modelClass: Exchange, loadOnReady: true, paginate: true, parentModel: props.parentModel}}>
            {(manager, props) => <>
                <div className="row-end mb-3">
                    <button onClick={() => newExchange(manager)} className="btn btn-primary btn-sm rounded-2">Ajouter un échange</button>
                </div>
                <div style={{minHeight: "100px"}}>
                    {props.models?.map((exchange) =>
                        <ExchangeItem key={exchange.id} onDelete={() => deleteExchange(manager, exchange)} exchange={exchange} onChange={() => manager.loadModels()} subtitle={exchange.createdBy ? <div className='text-secondary text-s'>{exchange.createdBy.getFullName()}</div> : null} />
                    )}
                </div>
            </>
            }
        </RecordManagerV2>
    );
}