import SectorStatHeader from '@components/charts/SectorStatHeader';
import SectorStatTab from '@components/charts/SectorStatTab';
import LightTabs from '@components/common/LightTabs';
import Sector from '@models/Sector';
import EntityManager from '@services/EntityManager';
import * as React from 'react';

export interface ISectorStatAdminProps {
  id
}

export default class SectorStatAdmin extends React.Component<ISectorStatAdminProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      quartier: null,
      loading: false
    }
  }

  async load() {
    const {quartier} = this.state;
    let params: any = {};
    if (quartier) params.quartier = quartier;
    this.setState({loading: true})
    let res: any = await EntityManager.getDirect(Sector, {path: this.props.id +  "/admin_stats", params});
    
    this.setState({data: res.data, loading: false});

  }

  filterQuartier(quartier) {
    this.setState({quartier}, () => this.load())
  }

  async componentDidMount() {
    this.load();
  }

  public render() {
    const {data, quartier, loading} = this.state;
    const {id} = this.props;
    return (
      <div>
      <SectorStatHeader loading={loading} sectorId={id} title="AVANCEMENT ADMINISTRATIF" filterQuartier={(e) => this.filterQuartier(e)} quartier={quartier} />
      {data && <LightTabs
          headerStyle={{width: "500px", margin: "auto"}}
          tabTitles={Object.keys(data.data)}
          tabContents={Object.keys(data.data).map(k => () => <SectorStatTab quartiers={data.quartiers} data={data.data[k]} />)}
        />}
        
      </div>
    );
  }
}
