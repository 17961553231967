import YardCalendar from '@components/calendar/YardCalendar';
import { DetailedLoading } from '@components/common/DetailedLoading';
import MainLayout from '@components/layout/MainLayout';
import Yard from '@models/Yard';
import EntityManager from '@services/EntityManager';
import * as React from 'react';

export interface IPlanningPageProps {
}

export default class PlanningPage extends React.Component<IPlanningPageProps, any> {

  constructor(props: any) {
    super(props)

    this.state = {
      models: null,
    }
  }

  componentDidMount() {
    this.load()
  }

  load = async () => {
    let response = await EntityManager.getCollection<Yard>(Yard, {path: "planning"});
    this.setState({models: response.models});
  }

  public render() {
    return (
      <MainLayout title="Planning">
        <div className="app-content">
          {!this.state.models ? <DetailedLoading/> : <YardCalendar load={this.load} yards={this.state.models}  />}
        </div>
      </MainLayout>
    );
  }
}
