import AsyncButton from '@components/button/AsyncButton';
import SmallLoading from '@components/common/SmallLoading';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import SmartOptionSelect from '@components/input/SmartOptionSelect';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import KeyyoCall from '@core/KeyyoCall';
import Contact from '@models/Business';
import Exchange from '@models/Exchange';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import GetX from '@services/GetX';
import KeyyoService from '@services/KeyyoService';
import OptionService from '@services/OptionService';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

export interface ICallDialogBodyProps {
  call: KeyyoCall
}

export interface ICallDialogBodyState {
  contact: Contact
  loaded: boolean
  collapsed: boolean
  exchange: Exchange
  submitting: boolean
}

export default class CallDialogBody extends React.Component<ICallDialogBodyProps, ICallDialogBodyState> {

  constructor(props) {
    super(props);
    this.state = {
      contact: null,
      loaded: false,
      collapsed: false,
      exchange: null,
      submitting: false
    }
  }

  async findNumberAndRefresh() {
    const {call} = this.props
    let number = call.getOtherNumber();
    let response = await EntityManager.get<Contact>(Contact, {path: "number", params: {number}});
    this.setState({contact: response.model.id ? response.model : null, loaded: true});
  }

  async componentDidMount() {
    this.findNumberAndRefresh();
  }

  async componentDidUpdate(prevProps: Readonly<ICallDialogBodyProps>, prevState: Readonly<ICallDialogBodyState>, snapshot?: any) {
    if (prevProps.call.call.callref !== this.props.call.call.callref) this.findNumberAndRefresh();
  }

  newContact() {
    const {call} = this.props
    let number = call.getOtherNumber();
    let param = {}
    if (number.startsWith("336") || number.startsWith("337")) param["portable"] = number
    else param["phone"] = number
    let newModel = new Contact(param);
    DOMService.openSidebar(SidebarRoute.ContactForm, {newModel});
  }

  async submitExchange() {
    this.setState({submitting: true})
    await EntityManager.create(this.state.exchange);
    this.setState({submitting: false, exchange: null});
    GetX.get("contactExchanges")?.loadModels();
  }

  newExchange() {
    let type = this.props.call.isEmitted ? OptionService.getOption("exchange_type", "exchange_type_call") : OptionService.getOption("exchange_type", "exchange_type_received")
    let exchange = new Exchange({callref: this.props.call.getRef(), type, contact: this.state.contact });
    this.setState({exchange})
  }

  public render() {
    const {contact, loaded, collapsed, exchange, submitting} = this.state
    const {call} = this.props
    let number = call.getOtherNumber();
    
    if (collapsed) return <div onClick={() => this.setState({collapsed: false})} className='call-dialog-body row-center pointer' style={{width: "3.5rem", height: "3.5rem"}}>
      <i className='fas fa-phone text-primary' style={{fontSize: "1.5rem"}}></i>
    </div>
    return (
      <div className='call-dialog-body px-3 pt-2 pb-3 mt-3' style={{width: exchange ? "30rem" : null}}>
        <div className="row-flex mb-2">
          <i className='fas fa-phone text-primary' style={{fontSize: "1.5rem"}}></i>
          <div className='ms-3 text-primary fw-bold'>{number}</div>
          <div className="ms-auto row-end">
            <div className='pointer' onClick={() => DOMService.callDialogRef.release(call.id)}>
              <i className='far fa-xmark text-secondary'></i>
            </div>
            {/* <div className='pointer ms-3' onClick={() => DOMService.closeCallDialog()}>
              <i className='fas fa-times text-secondary'></i>
            </div> */}
          </div>
        </div>
        <div className='mb-2 row-between'>
          <div className="fw-bold">
            {call.state === "setup" && "Appel en cours..."}
            {call.state === "connected" && "Appel commencé"}
            {call.state === "released" && "Appel terminé"}
          </div>
          <div className="row-end">
            { call.state === "setup" &&
              <div onClick={() => KeyyoService.answerCall() } className='text-success pointer'>
                <i className='fas fa-phone fa-2x'></i>
              </div>
            }
            { (call.state === "connected" || call.state === "setup") &&
              <div onClick={() => KeyyoService.hangUpCall() } className='text-danger pointer ms-3'>
                <i className='fas fa-phone-xmark fa-2x'></i>
              </div>
            }
          </div>
        </div>
        {!loaded ? <SmallLoading className='primary'/> : (
          !contact ? <div>
            <div>Numéro non connu</div>
            <button onClick={() => this.newContact()} className="btn btn-primary w-100 text-white mt-3">Ajouter un contact</button>
          </div> : 
          <div className=''>
            <div className="mb-3">
              {contact.getFullName()}
            </div>
            {contact.getLink() !== window.location.pathname ? <div>
              <NavLink to={contact.getLink()} className="btn btn-primary w-100 text-white">Voir en détail</NavLink>
            </div> : <div className='text-success'>
              Vous êtes actuellement sur la fiche de l'abonné
            </div>}
          </div>
        )}
        {contact && <div className='mt-3'>
          {exchange ? <div>
            <div>
              <SmartOptionSelect model={exchange} name="type" label="Type" containerClassName="mb-3" />
              <SmartInputTextarea model={exchange} name="comment" label="Commentaire" containerClassName="mb-3 " rows={6} />
              <AsyncButton loading={submitting} onClick={() => this.submitExchange()} className="btn btn-primary w-100">Créer l'échange</AsyncButton>
            </div>
          </div> : <button onClick={() => this.newExchange()} className="btn btn-info w-100">Créer un échange</button>}
          {/* <button onClick={() => DOMService.openSidebar(SidebarRoute.ExchangeForm, {parentModel: contact, onSubmit: () => {
            DOMService.closeSidebar();
          }})} className="btn btn-info w-100">Créer un échange</button> */}
        </div>}
      </div>
    );
  }
}
