import { AxiosResponse } from "axios";
import ApiService from "./ApiService";
import { Credential } from "./AuthService";
import LoginResponse from "@services/apiResponse/LoginResponse";
import EntityManager from "./EntityManager";
import ModelResponse from "@services/apiResponse/ModelResponse";
import User from "src/models/User";

export default class UserManager {

  public async login(credential: Credential): Promise<LoginResponse> {
    let response: AxiosResponse;
    try {
      response = await ApiService.post(`users/login`, credential);
    } catch (error) {
      response = error.response
    }
    return new LoginResponse(response);
  }

  public async signin(params) {
    return await EntityManager.post<User>(User, {params});
  }

  public async passwordUpdate(params) {
    let response: AxiosResponse = await ApiService.post(`users/password_update`, params);
    return response;
  }

  public async forgotPassword(email: string) {
    let response: AxiosResponse = await ApiService.post(`users/forgot_password`, {email});
    return response;
  }

  public async changePassword(params: any) {
    let response: AxiosResponse = await ApiService.post(`users/password_change`, params);
    return response;
  }

  public async me(): Promise<ModelResponse<User>> {
    return await EntityManager.get<User>(User, {path: "me"});
  }

  public async addCard(data) {
    return await EntityManager.post<User>(User, {path: "cards", params: data})
  }

  public async removeCard(card) {
    return await ApiService.delete("users/cards/" + card.id)
  }


  public async allCards() {
    return await ApiService.get("users/cards");
  }

}
