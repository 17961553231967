import { sessionBloc } from "@bloc/SessionBloc";
import SmallLoading from "@components/common/SmallLoading";
import CustomMarker from "@components/crm/CustomMarker";
import SmartInputText from "@components/input/SmartInputText";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import Contact from "@models/Business";
import DOMService from "@services/DOMService";
import EntityManager from "@services/EntityManager";
import OptionService from "@services/OptionService";
import GoogleMapReact from "google-map-react";
import { debounce } from "lodash";
import React from "react";
import { apiKey } from "src/config/keys";

export interface IMapPageProps {
  sectorId?
}

const mapStyles = [
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
        {
            "color": "#ff6262"
        },
        {
            "visibility": "off"
        }
    ]
},
  {
    featureType: "transit",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }]
  },
];

export default class MapPage extends React.Component<IMapPageProps, any> {

  mapEl
  legends = [
    {label: "OUVERT", color: "#2d98c1", status: ["OUVERT"]},
    {label: "EXCLUS", color: "#33d16c", status: ["MANQ", "EXCLUS", "2 APPEL", "INJ", "REFUS", "NE PAS RAPPELER", "IMP VALIDE"]},
    {label: "PLANIFIEE", color: "#d1c533", status: ["RDV", "CHARGEE"]},
    {label: "RESTANTE", color: "#d13333", status: ["A RAPPELER", "1 APPEL", "A CHARGER", "IMP"]},
  ]

  disabled = []

  getStatusDisabled() {
    return this.legends.filter(l => this.disabled.includes(l.label)).map(e => e.status).flat();
  }

  statusToColor = {
    "OUVERT": "#2d98c1",
    "MANQ": "#33d16c",
    "EXCLUS": "#33d16c",
    "2 APPEL": "#33d16c",
    "INJ": "#33d16c",
    "REFUS": "#33d16c",
    "IMP": "#33d16c",
    "NE PAS RAPPELER": "#33d16c",
    "IMP VALIDE": "#33d16c",
    "RDV": "#d1c533",
    "CHARGEE": "#d1c533",
    "A RAPPELER": "#d13333",
    "1 APPEL": "#d13333",
    "A CHARGER": "#d13333"
  }

  constructor(props: any) {
    super(props);
    this.state = {
      models: [],
      center: this.getCenter(),
      loading: false,
      address: null
    }
  }

  onDragEnd = debounce(() => {
    if (this.state.address) return;
    this.loadContacts()
  }, 600);

  async loadContacts() {
    this.setState({loading: true})
    let bounds = this.mapEl.getBounds();
    let southWest = bounds.getSouthWest();
    let northEast = bounds.getNorthEast();
    
    let params: any = {
      latmin: southWest.lat(),
      latmax: northEast.lat(),
      lngmin: southWest.lng(),
      lngmax: northEast.lng(),
    }

    if (this.state.address) params.address = this.state.address;
    if (this.props.sectorId) params.sectorId = this.props.sectorId;
    
    let response = await EntityManager.getDirect(Contact, {path: "map", params});
    this.setState({models: response.data.models, loading: false});
  }

  getCenter() {
    return {
      lat: 44.0176,
      lng: 1.355
    }
  }

  centerOn(shop) {
    this.setState({center: {
      lat: shop.latitude,
      lng: shop.longitude
    }, selectedShop: shop})
  }

  onLoad(mapEl) {
    this.mapEl = mapEl.map;
    this.loadContacts();
    
    // eventBloc.getEvents();
    // navigator.geolocation.getCurrentPosition((pos) => {
    //   locationService.setLocation(pos);
    //   this.setState({center: {lat: pos.coords.latitude, lng: pos.coords.longitude}})
    // });
  }

  onAddressChange(address) {
    this.setState({address});
    this.onAddressFilter();
  }

  onAddressFilter = debounce(() => {
    this.loadContacts()
  }, 600);

  resetAddress() {
    this.setState({address: null}, () => this.loadContacts());
  }

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  scrollToAnchor(id){
    var aTag = window.document.getElementById(id)
    window.scrollTo({ top: aTag.offsetTop });
  }

  render() {
    const { center, loading, address } = this.state;

    let models = this.state.models.filter((m) => !this.getStatusDisabled().includes(OptionService.find(m.realStatusId)?.label) );

    return (
      <div className="home">
        <div className="position-relative">
          <div className="map" style={{height: "100vh"}}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: apiKey, language: 'fr' }}
            defaultCenter={this.state.center}
            defaultZoom={11}
            options={{ styles: mapStyles, fullscreenControl: false, zoomControl: false }}
            onGoogleApiLoaded={(_) => this.onLoad(_)}
          >
              { models.map((model) => (
                <CustomMarker
                  key={model.id}
                  contactId={model.id}
                  onClick={() => DOMService.openSidebar(SidebarRoute.ContactShow, {id: model.id})}
                  // contact={contact}
                  color={this.statusToColor[OptionService.find(model.realStatusId).label]}
                  // selected={selectedShop?.id === shop.id}
                  lat={model.latitude}
                  lng={model.longitude}
                />
                
              ))}
            </GoogleMapReact>
          </div>
          <div className='d-flex flex-row'>
            <div style={{top: "1rem", left: "98%"}} className="position-absolute translate-middle-x z-3">
              {this.props.sectorId && <div onClick={() => sessionBloc.pop() } >
                <i className="fas fa-xmark-circle fa-2x me-3"></i>
              </div>}
            </div>
          </div>
        </div>
        </div>

    );
  }
}
