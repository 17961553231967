import { sessionBloc } from '@bloc/SessionBloc';
import logo from "../../assets/images/favicon.png"
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import TextIcon from '@components/common/TextIcon';
import Page403 from 'src/pages/Page403';

export interface IClientAreaLayoutProps {
}

export default class ClientAreaLayout extends React.Component<IClientAreaLayoutProps> {
  public render() {
    if (!sessionBloc.isCustomer()) return <Page403/>
    return (
      <div>
        <nav className='client-navbar'>
          <div className='px-0 px-md-5 w-100'>
            <div className="row-between">
              <div className="row-center head-up">
                <div className="rounded row-flex sidebar-link">
                  <img src={logo} alt="" width="30px" />
                </div>
                <div className="fw-bold ms-1 godin-logo mb-0"></div>
              </div>
              <div className="row-end">
                <NavLink to={"/"} className="link me-5 fw-bold">
                  Mes chantiers
                </NavLink>
                <Dropdown>
                  <Dropdown.Toggle as={React.forwardRef(({ children, onClick }: any, ref: React.LegacyRef<HTMLDivElement>) => (
                    <div onClick={onClick} ref={ref} className='row-end pointer'>
                      <div>
                        {sessionBloc.user.firstname}
                      </div>
                      <div className='bg-primary height-6 width-6 ms-3 row-center rounded-pill'>
                        <i className='fas fa-user text-white'></i>
                      </div>
                    </div>
                  ))}/>
                  <Dropdown.Menu align="end" className="fade" style={{marginTop: "1rem"}} >
                    <Dropdown.Item  >
                    <TextIcon leftIcon="fas fa-sign-out"><NavLink className="" to="/logout">Se déconnecter</NavLink></TextIcon>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </nav>
        {this.props.children}
      </div>
    );
  }
}
