import Sector from '@models/Sector';
import dayjs from 'dayjs';
import { sessionBloc } from '@bloc/SessionBloc';
import Customer from '@models/Customer';
import Contact from '@models/Business';
import Attribute from './core/Attribute';
import AttributesCollection from "./core/AttributeCollection";
import Model from "./Model";

export default class CustomEmail extends Model {
  public title: string;
  public object: string;
  public content: string;
  public default: boolean;
  public type: string;

  public contacts: Contact[];
  public customer: Customer;
  public sector: Sector;
  public dateRdv: Date;

  static modelUrl = "custom_emails";
  public modelName = "custom_email";

  public attributes = new AttributesCollection({
    title: new Attribute("title"),
    content: new Attribute("content"),
    type: new Attribute("type"),
    object: new Attribute("object"),
    default: new Attribute("default"),
  });

  constructor(json) {
    super(json);
    this.fillAttributes(json);
  }

  withContacts(contacts: Contact[]) {
    this.contacts = contacts;
    return this;
  }

  withSector(sector: Sector) {
    this.sector = sector;
    return this;
  }

  withCustomer(customer: Customer) {
    this.customer = customer;
    return this;
  }

  withDateRdv(dateRdv: Date) {
    this.dateRdv = dateRdv;
    return this;
  }

  getRealObjectWith() {
    return this.convert(this.object);
  }

  getRealContentWith() {
    return this.convert(this.content);
  }

  needRdvDate() {
    return this.content?.includes("{slot}");
  }

  needContact() {
    const contactKeywords = ["firstname", "reference", "lastname", "fullname", "civ", "email", "portable", "full_address", "city", "diameter", "accessibility", "my_fullname", "my_phone"];
    return contactKeywords.some((word) => this.content?.includes("{" + word + "}"));
  }

  convert(text: string) {
    if (!text) return "";

    let convertedText = text;
    if (this.contacts && this.contacts.length > 0) {
      const contact = this.contacts[0];
      if (contact) {
        convertedText = convertedText
          .replaceAll("{firstname}", contact.firstname || "")
          .replaceAll("{reference}", contact.reference || "")
          .replaceAll("{lastname}", contact.lastname || "")
          .replaceAll("{fullname}", contact.getFullName() || "")
          .replaceAll("{civ}", contact.civ || "")
          .replaceAll("{email}", contact.email || "")
          .replaceAll("{portable}", contact.portable || "");
      }
    }

    convertedText = convertedText
      .replaceAll("{my_fullname}", sessionBloc.user?.firstname)
      .replaceAll("{my_phone}", sessionBloc.user?.phone || "")
      .replaceAll("{customer}", this.customer?.name || "")
      .replaceAll("{slot}", this.dateRdv ? dayjs(this.dateRdv).format("D MMMM YYYY à HH:mm") : "");

    return convertedText;
  }
}
