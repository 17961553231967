import showModel, { IShowModelProps } from '@components/logic/ShowModel';
import Yard from '@models/Yard';
import * as React from 'react';
import SidebarLayout from '../layout/SidebarLayout';
import YardPresent from './YardPresent';
import SmallLoading from '@components/common/SmallLoading';

export interface IYardShowSidebarProps {
}

class YardShowSidebar extends React.Component<IShowModelProps<Yard>> {
  public render() {
    const {model} = this.props
    if (!model) return <SmallLoading/>
    return (
      <SidebarLayout title={model.name}>
        <div className="fadeInDown">
          <YardPresent yard={model} />
        </div>
      </SidebarLayout>
    );
  }
}

export default showModel<any>(YardShowSidebar, {modelClass: Yard})