import * as React from 'react';

export interface IMediaFileButtonProps {
  onFileChange?: (arg: File) => void;
  onPreviewReady?: (result: string | ArrayBuffer, file: File) => void
  onFilesChange?: (arg: File[]) => void;
  className?: string;
  multiple?: boolean
  id?: any
  children: any
}

export default function MediaFileButton({onFileChange, onPreviewReady = null, className, id, children, multiple, onFilesChange}: IMediaFileButtonProps) {

  let input = React.useRef<HTMLInputElement>()

  const handleChange = (e) => {
    if (e.target.files.length === 0) return;
    if (multiple) {
      onFilesChange(e.target.files);
    } else {
      const maxAllowedSize = 10 * 1024 * 1024;
      if(e.target.files[0].size > maxAllowedSize){
        window.alert("La taille du fichier est trop importante");
        return;
      }
      let file: File = e.target.files[0];
      onFileChange(file);
      if (!!onPreviewReady) loadPreview(file);
    }
    
  }

  const loadPreview = (file: File) => {
    let reader: FileReader = new FileReader();
    reader.onloadend = () => {
      onPreviewReady(reader.result, file);
    }
    reader.readAsDataURL(file)
  }

  return (
    <div onClick={() => input.current.click()} className={className || ""}>
      <input multiple={multiple} ref={input} onChange={handleChange} type="file" name="file" id={id || "post-file"} className="media-file"/>
      {/* <div className="m-0" style={{width: "40px", height: "40px"}}> */}
        {children}
      {/* </div> */}
    </div>
  );
}
