import { sessionBloc } from "@bloc/SessionBloc";
import { DetailedLoading } from '@components/common/DetailedLoading';
import MainLayout from '@components/layout/MainLayout';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import YardPresent from '@components/yard/YardPresent';
import Yard from '@models/Yard';
import EntityManager from '@services/EntityManager';
import dayjs from 'dayjs';
import * as React from 'react';
import DOMService from 'src/services/DOMService';
import PrestationCalendar from '../components/calendar/PrestationCalendar';
import showModel, { IInjectedShowModelProps } from '../components/logic/ShowModel';

export interface IYardShowPageProps extends IInjectedShowModelProps<Yard> {
  id,
  editable?
}

class YardShowPage extends React.Component<IYardShowPageProps> {

  static defaultProps = {
    editable: true
  }

  onEdit = () => {
    DOMService.openSidebar(SidebarRoute.YardForm, {id: this.props.id, onSubmit: this.onSubmit})
  }

  onSubmit = (_) => {
    DOMService.closeSidebar();
    this.props.load();
  }

  getData = () => {
    let data = {events: [], resources: []}

    data.events.push({
      display: "inverse-background",
      start: this.props.model.startAt,
      end: this.props.model.endAt,
      backgroundColor: "#8E959D",
    });
    data.events.push({
      resourceId: "new",
      display: "none",
      start: this.props.model.startAt,
      end: this.props.model.endAt,
    });
    this.props.model.reportsByUser.forEach(user => {
      data.resources.push({
        id: user.id,
        user,
        title: user.getFullName(),
      });

      let reports = user.reports;
      reports.forEach(report => {
        data.events.push({
          resourceId: report.userId,
          id: report.id.toString(),
          title: report.getDescription(),
          report,
          allDay: true,
          start: report.date,
          end: dayjs(report.date).add(1, "day").toDate(),
          backgroundColor: report.yardComposition.prestationType.color,
          borderColor: report.yardComposition.prestationType.color,
        })
      })

      let absents = user.absentReports;
      absents.forEach(report => {
        data.events.push({
          resourceId: report.userId,
          id: report.id.toString(),
          title: report.getDescription(),
          report,
          allDay: true,
          start: report.date,
          end: dayjs(report.date).add(1, "day").toDate(),
          backgroundColor: "#d8374d",
          borderColor: "#d8374d",
        })
      })
    })
    data.resources.push({
      id: "new",
      title: "Rapport d'un nouvel ouvrier",
    });

    return data;
  }

  openCloseModal = () => {
    DOMService.alert({
      title: "Cloturer le chantier",
      message: "Etes vous sûr de vouloir cloturer ce chantier ?",
      onConfirm: this.close
    });
  }

  close = async () => {
    this.props.model.closed = true;
    await EntityManager.update(this.props.model, {only: ["closed"]});
    DOMService.closeModal();
    this.setState({})
  }

  onShare = async () => {
    DOMService.openSidebar(SidebarRoute.YardShare, {yardId: this.props.model.id})
  }

  public render() {
    const { model, editable } = this.props;

    if (!model) return <DetailedLoading/>
    let data = this.getData();
    return (
      <MainLayout
        title={<div className="row-flex">
          <h4 className="mb-0">{model.name}</h4>
          <div className={"ms-3 badge " + (model.getStateClass())}>{model.getStateText()}</div>
        </div>}
        right={ editable && <div className="row-end">
          <button onClick={this.onShare} className="btn btn-danger me-3">Partager</button>
          { (!model.closed && sessionBloc.isManager()) && <button onClick={this.openCloseModal} className="btn btn-info me-3">Cloturer</button>}
          <button onClick={this.onEdit} className="btn btn-primary">Modifier</button>
        </div>}
      >
        <div className="app-content fadeIn pe-3">
          <div className="row">
            <div className={"mb-3"}><button className='btn btn-dark btn-sm rounded-2 me-3' onClick={() => sessionBloc.push("/management/yards")}>Retour</button></div>
            <div className="col-lg-8 mb-3 overflow-auto">
              <PrestationCalendar data={data} yard={model} onChange={() => this.props.load()} editable={editable} />
            </div>
            <div className="col-lg-4 yard-show-present" style={{height: `calc(100vh - 150px${!editable ? " - 70px" : ""})`}}>
              <YardPresent yard={model}  />
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default showModel<any>(YardShowPage, {modelClass: Yard})