import AsyncButton from '@components/button/AsyncButton';
import MediaFileButton from '@components/common/MediaFileButton';
import SmartInputText from '@components/input/SmartInputText';
import SidebarLayout from '@components/layout/SidebarLayout';
import Contact from '@models/Business';
import Sector from '@models/Sector';
import EntityManager from '@services/EntityManager';
import * as React from 'react';

export interface IImportDialogProps {
  onImportSuccess
  parentModel: Sector
}

export default class ImportDialog extends React.Component<IImportDialogProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      quartier: "",
      error: null,
      contact: null,
      interventionFiles: null,
      loading: null
    }
  }

  setFile(file) {
    this.setState({contact: file})
  }

  setFiles(interventionFiles) {
    this.setState({interventionFiles: Array.from(interventionFiles)})
  }

  async importInterventions() {
    if(!(this.state.loading==="intervention")){
      let formData = new FormData();
      this.state.interventionFiles.forEach((file, i) => {

        formData.append("files" + i, file);
      });
      formData.append("name", this.state.importName);
      formData.append("customerId", this.props.parentModel.customer?.id);
      formData.append("sectorId", this.props.parentModel?.id);
      this.setState({loading: "intervention"})
      let result = await EntityManager.post(Contact, {path: "csv/intervention", params: formData, multipart: true});
      this.setState({loading: null})
      if (!result.ok) {
        this.setState({error: result.errorMessage})
      } else {
        this.props.onImportSuccess();
      }
    }
  }
  
  async importContact() {
    let formData = new FormData();
    formData.append("file", this.state.contact);
    formData.append("quartier", this.state.quartier);
    formData.append("sectorId", this.props.parentModel?.id);
    this.setState({loading: "contact"})
    let result = await EntityManager.post(Contact, {path: "csv/contact", params: formData, multipart: true});
    this.setState({loading: null})
    if (!result.ok) {
      this.setState({error: result.errorMessage})
    } else {
      this.props.onImportSuccess();
    }
  }

  public render() {
    const {quartier, error, contact, interventionFiles, loading} = this.state
    
    return (
      <SidebarLayout
        title="Importer"
      >
        <div className="fadeInDown">
          <div className='mb-7'>
            <div className='mb-2'>Importer des contacts</div>
            {/* <CustomerSelect label="Secteur" onChange={(sector) => this.setState({sector})} value={sector} params={{customerId: this.props.parentModel.id}} name="sector" placeholder="Choisir un secteur" containerClassName="mb-3" /> */}
            <SmartInputText containerClassName='mb-3' placeholder="Zone géographique" onChange={(quartier) => this.setState({quartier})} value={quartier} />
            <div className="row-flex">
              <MediaFileButton  onFileChange={(_) => this.setFile(_)} >
                <button className="btn btn-outline-success">Choisir un fichier</button>
              </MediaFileButton>
              <AsyncButton loading={loading==="contact"} onClick={() => this.importContact()} className="btn btn-success ms-3" disabled={!contact}>Envoyer</AsyncButton>
            </div>
            {contact && <div className='bg-light p-3 mt-3'>
              {contact.name}
            </div>
            }
          </div>

          {error && <div className='bg-danger p-3 text-white'>
            {error}
          </div>}
        </div>
      </SidebarLayout>
    );
  }
}
