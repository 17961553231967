import { sessionBloc } from "@bloc/SessionBloc";
import SmartInputText from "@components/input/SmartInputText";
import SmartSelect from "@components/input/SmartSelect";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import Contact from "@models/Business";
import DOMService from "@services/DOMService";
import { debounce } from "lodash";
import ModelsTable from "../common/ModelsTable";
import PaginationComponent from "../common/PaginationComponent";
import PerPageSelect from "../common/PerPageSelect";
import ModelGrid from './ModelGrid';
import dayjs from "dayjs";
import nafSectors from "@models/nafSectors.json";
import OptionService from "@services/OptionService";
import ApiService from '@services/ApiService'; // Ajout de cette ligne

function getSectorDescription(fullCodeNAF) {
    if (fullCodeNAF){
        const codeNAF = fullCodeNAF.substring(0, 2);
        return nafSectors[codeNAF] || "Secteur non spécifié";
    }
    else {
        return "Secteur non spécifié"
    }
    }
    

export default class ContactGrid extends ModelGrid<Contact> {

    public formRoute = SidebarRoute.ContactForm;

    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            selectedContacts: [],
        };
        this.toggleFilters = this.toggleFilters.bind(this);
        this.handleSelectedChange = this.handleSelectedChange.bind(this);
        this.sendEmailToSelectedContacts = this.sendEmailToSelectedContacts.bind(this); // Ajout de cette ligne
    }

    toggleFilters() {
        this.setState(prevState => ({
            showFilters: !prevState.showFilters
        }));
    }

    componentDidMount() {
        const { sectorId } = this.props;
    }

    componentWillUnmount() {
        localStorage.removeItem("contactGridFilters");
    }

    public newOptions = () => ({
        onSubmit: this.submit,
        parentModel: this.props.manager.props.options.parentModel
    });

    static gridName = "contact";

    title() {
        return "Contacts";
    }

    onSpecificSearch() {
        this.props.manager.onFilter("address", this.props.manager.state.byAddress);
    }

    mergeSavedFilter(data) {
        let f = localStorage.getItem("contactGridFilters");
        if (!f) {
            localStorage.setItem("contactGridFilters", JSON.stringify(data));
        } else {
            let state = JSON.parse(f);
            localStorage.setItem("contactGridFilters", JSON.stringify({ ...state, ...data }));
        }
    }

    clearAddress() {
        this.props.manager.setState({ byAddress: null }, () => this.mergeSavedFilter(this.props.manager.state));
        this.props.manager.onFilter("address", null);
    }

    filter(value, key) {
        const { manager } = this.props;
        let filter = manager.state.filter;
        filter[key] = value;
        manager.setState({ filter, page: 1 });
        this.mergeSavedFilter(filter);
        this.reload(manager.loadModels);
    }

    toggleStatus(statusLabel) {
        const { manager, filter } = this.props;
        let currentStatus = filter.status || [];

        let newState;
        if (currentStatus.includes(statusLabel)) {
            newState = currentStatus.filter(label => label !== statusLabel);
        } else {
            newState = [...currentStatus, statusLabel];
        }

        this.mergeSavedFilter({ status: newState });
        manager.mergeFilter({ status: newState });
    }

    statusStyles = {
        "Pr\u00E9paration": { color: "rgb(162, 192, 19)", textColorInactive: "rgb(162, 192, 19)" },
        "En cours": { color: "rgba(212, 110, 0, 0.6)", textColorInactive: "rgba(212, 110, 0, 0.6)" },
        "Cl\u00F4tur\u00E9": { color: "rgba(34, 80, 123, 0.6)", textColorInactive: "rgba(34, 80, 123, 0.6)" },
        "DIA": { color: "rgba(34, 80, 123, 0.6)", textColorInactive: "rgba(34, 80, 123, 0.6)" }
    };

    reload = debounce((c) => {
        c();
    }, 1000);

    status = [
        "Préparation", "Clôturé", "En cours", "DIA"
    ];

    handleSelectedChange(selectedContacts) {
        this.setState({ selectedContacts });
    }

    async sendEmailToSelectedContacts() {
        const { selectedContacts } = this.state;
        const contactDetailsPromises = selectedContacts.map(contact =>
            ApiService.get(`contacts/${contact.id}/details/receiving-emails`)
        );

        try {
            const contactDetailsResponses = await Promise.all(contactDetailsPromises);
            const allContactDetails = contactDetailsResponses.flatMap(response => response.data);

            const contactEmails = allContactDetails.map(contactDetail => contactDetail.email);

            DOMService.openSidebar(SidebarRoute.SendPage, {
                type: "email",
                to: contactEmails.join(', ')
            });
        } catch (error) {
            console.error("Error fetching contact details: ", error);
        }
    }

    firstBar() {
        const { manager, filter } = this.props;
        let quartiersOption = [
            { label: "Tous", value: null },
        ];
        if (OptionService) quartiersOption = quartiersOption.concat(OptionService.quartiers[this.props.manager.props.options.parentModel ? this.props.manager.props.options.parentModel.id : 29]?.map((q) => ({ label: q, value: q })));

        return <div className="">
            <div className="d-flex justify-content-start gap-2 mb-3 mt-5">
                {this.status.map((statusLabel) => (
                    <div key={statusLabel}>
                        <div onClick={() => this.toggleStatus(statusLabel)}
                             className="btn btn-sm text-nowrap rounded-2" style={{
                            color: filter.status?.includes(statusLabel) ? "#ffffff" : this.statusStyles[statusLabel].textColorInactive,
                            backgroundColor: filter.status?.includes(statusLabel) ? this.statusStyles[statusLabel].color : "transparent",
                            borderColor: this.statusStyles[statusLabel].textColorInactive,
                            borderWidth: "2px",
                            borderStyle: "solid"
                        }}>{statusLabel}</div>
                    </div>
                ))}
                <div onClick={() => {
                    manager.mergeFilter({ status: [] });
                    this.mergeSavedFilter({ status: [] });
                }} className="btn btn-dark btn-sm text-nowrap rounded-2">Tout
                </div>
            </div>
            {
                this.state.showFilters && (
                    <div className={''}>
                        <div className="row">
                            <div className="col-md col-12 mb-3">
                                <SmartInputText value={filter.search} innerRef={manager.inputRef}
                                                onChange={(e) => this.filter(e, "search")}
                                                placeholder={"Nom de l'entreprise ou Dirigeant"} />
                            </div>
                            <div className="col-md col-12 mb-3">
                                <SmartInputText className="" value={filter.yearCreated}
                                                onChange={(e) => this.filter(e, "yearCreated")}
                                                placeholder={"Années de création"} />
                            </div>
                            <div className="col-md col-12 mb-3">
                                <SmartInputText className="" value={filter.monthCreated}
                                                onChange={(e) => this.filter(e, "monthCreated")}
                                                placeholder={"Mois de création"} />
                            </div>
                            <div className="col-md col-12 mb-3">
                                <SmartInputText value={filter.siret_2} onChange={(e) => this.filter(e, "siret_2")}
                                                placeholder={"SIRET"} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md col-12 mb-3">
                                <SmartInputText value={filter.contact_name}
                                                onChange={(e) => this.filter(e, "contact_name")}
                                                placeholder={"Nom Prénom d'un contact de l'entreprise"} />
                            </div>
                            <div className="col-md col-12 mb-3">
                                <SmartInputText value={filter.codeNaf}
                                                onChange={(e) => this.filter(e, "codeNaf")}
                                                placeholder={"Domaine d'Activité (code NAF)"} />
                            </div>
                            <div className="col-md col-12 mb-3 text-muted">
                                <SmartSelect options={quartiersOption}
                                             value={filter.quartier ? {
                                                 label: filter.quartier,
                                                 value: filter.quartier
                                             } : null}
                                             onChange={(e) => {
                                                console.log(e)
                                                manager.mergeFilter({ quartier: e.value });
                                                this.mergeSavedFilter({ quartier: e.value });
                                             }} placeholder={"Par zone géographique"} />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    }

    gridConfig: any = {
        headers: ["Nom de l'Entreprise", "Président", "Date de Création", "Domaine d'Activité", "Secteur", "SIRET", "Statut"],
        body: [
            (contact: Contact) => <div className="row-flex">
                <b>{contact.firstname}</b>
            </div>,

            (contact: Contact) => <div>{contact.lastname}</div>,

            (contact: Contact) => <div>{dayjs(contact.bornAt).format('DD/MM/YYYY')}</div>,

            (contact: Contact) => <div>{getSectorDescription(contact.codeNaf)}</div>,

            (contact: Contact) => <div>{contact.activitySector}</div>,

            (contact: Contact) => <div>{contact.siret}</div>,

            (contact: Contact) => <div>{contact.status}</div>,
        ]
    }

    tableArea() {
        const { models, lastPage, page, perPage, selected, manager, total, filter } = this.props;
        return <div className="">
            <div className="row-flex">
                <PaginationComponent lastPage={lastPage} currentPage={page} onPageChange={manager.onPageChange} />
                <div className="ms-4">
                    <PerPageSelect value={perPage} onChange={this.onPerPageChange} />
                </div>
                <div className="ms-4">
                <div onClick={() => {
                    manager.mergeFilter({ "byName": filter.byName == "asc" ? "desc" : "asc", "bySiret": null })
                }}
                             className="btn btn-primary me-2">Trier par ordre alphabétique</div>
                <div onClick={() => {
                    manager.mergeFilter({ "bySiret": filter.bySiret == "asc" ? "desc" : "asc", "byName": null})
                }}
                             className="btn btn-primary me-2">Trier par SIRET</div>
                    <button
                        onClick={this.toggleFilters}
                        className={`btn btn-secondary ms-3 ${this.state.showFilters ? 'text-blue' : ''}`}
                        title="Cliquez pour afficher ou masquer les filtres"
                    >
                        <i className={`fa fa-filter ${this.state.showFilters ? 'text-muted' : ''}`}
                           aria-hidden="true"></i>
                    </button>
                </div>
                <div className="ms-auto">
                    {total || 0} résultat(s)
                </div>
            </div>
            <ModelsTable
                config={this.gridConfig}
                models={models}
                onRowClick={this.onRowClick}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
                selected={this.state.selectedContacts}
                onSelectedChange={this.handleSelectedChange}
                allowMultipleSelection={true} // Activer la sélection multiple ici
                multipleActions={(selected) => (
                    <button
                        className="btn btn-primary"
                        onClick={this.sendEmailToSelectedContacts} // Utiliser la méthode pour envoyer l'email
                    >
                        Envoyer un email aux contacts sélectionnés
                    </button>
                )}
                rowActions={this.rowActions}
            />
            {models && <div className="d-flex justify-content-between fadeIn">
                <div className="row-flex">
                    <PaginationComponent lastPage={lastPage} currentPage={page} onPageChange={manager.onPageChange} />
                    <div className="ms-4">
                        <PerPageSelect value={perPage} onChange={this.onPerPageChange} />
                    </div>
                </div>
                {this.specificFooter()}
            </div>}
        </div>
    }

    onRowClick = (model: Contact) => {
        sessionBloc.push("/crm/contacts/" + model.id, {
            ids: this.models().map(m => m.id),
            from: "/crm/sectors/" + model.sectorId
        });
    }
}