import Contact from '@models/Business';
import Model from 'src/models/Model';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import ModelAttribute from './core/ModelAttribute';

export default class Call extends Model {

  public phone: string
  public contact: Contact
  public contactId

  static modelUrl = "calls";
  public modelName = "call";

  public attributes = new AttributesCollection({
    phone: new Attribute("phone"),
    contact: new ModelAttribute("contact", Contact),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  formattedNumber() {
    let str = this.phone;
    if (this.phone.startsWith("33")) str = "0" + this.phone.substring(2);
    return str.replace(/\B(?=(\d{2})+(?!\d))/g, ".");
  }

}