import EntityManager from './EntityManager';
import DocumentType from '../models/DocumentType';

class DocumentTypeService {
    private documentTypes: DocumentType[] = [];

    async load() {
        try {
            const response = await EntityManager.all(DocumentType);
            if (response && Array.isArray(response.data)) {
                this.documentTypes = response.data.map(item => new DocumentType(item));
            } else {
                console.error("Expected response.data to be an array, but got:", response.data);
                this.documentTypes = [];
            }
        } catch (error) {
            console.error("Could not load document types:", error);
            throw error;
        }
    }

    getAll() {
        return this.documentTypes.map(dt => ({
            value: dt.id,
            label: dt.label,
        }));
    }

    findById(id: number) {
        return this.documentTypes.find(dt => dt.id === id);
    }

}

export default new DocumentTypeService();
