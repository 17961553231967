import Validator from './Validator';
export default class RequiredValidator extends Validator {

  message

  constructor(message = "Ce champ est requis.") {
    super();
    this.message = message;
  }

  check(value) {
    return !!value;
  }

}