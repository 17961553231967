import { sessionBloc } from '@bloc/SessionBloc';
import InterlocutorItem from '@components/common/InterlocutorItem';
import SearchInput from '@components/input/SearchInput';
import SmartInputText from '@components/input/SmartInputText';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import showModel, { IInjectedShowModelProps } from '@components/logic/ShowModel';
import Customer from '@models/Customer';
import Interlocutor from '@models/Interlocutor';
import Sector from '@models/Sector';
import DOMService from '@services/DOMService';
import { groupBy } from 'lodash';
import * as React from 'react';

export interface ICustomerShowPageProps extends IInjectedShowModelProps<Customer> {
  id
}

export interface ICustomerShowPageState {
  stat: any
  sectorInterlocutors: Interlocutor[]
  sectors: any[]
  search: string
}

class CustomerShowPage extends React.Component<ICustomerShowPageProps, ICustomerShowPageState> {
  constructor(props: ICustomerShowPageProps) {
    super(props);

    this.state = {
      stat: null,
      sectorInterlocutors: this.props.model.interlocutors,
      sectors: this.props.model.sectors,
      search: ""
    }
  }


  goToInterlocutorForm() {
    DOMService.openSidebar(SidebarRoute.InterlocutorForm, {
      newModel: new Interlocutor({
        "contactableId": this.props.id,
        "contactableType": "App\\Models\\Customer",
      }),
      onSubmit: () => this.load()
    })
  }

  public goToSectorInterlocutorForm(sectorId) {
    DOMService.openSidebar(SidebarRoute.InterlocutorForm, {newModel: new Interlocutor({
      "contactableId": sectorId,
      "contactableType": "App\\Models\\Sector",
    }), onSubmit: () => this.load()} )
  }

  onSearch = () => {
    this.setState({
    })
  }

  onSearchChange = (search) => {
    let sectorInterlocutors = this.props.model.interlocutors.filter(e => e.fullname?.match(new RegExp(search,"gi")));
    let sectors = this.props.model.sectors.map(s => ({
      interlocutors: s.interlocutors.filter(e => e.fullname?.match(new RegExp(search,"gi"))),
      id: s.id,
      name: s.name
    }))
    this.setState({
      search, sectorInterlocutors, sectors
    })
  }

  async load() {
    DOMService.closeSidebar();
    await this.props.load();
  }

  afficherDocx(fichier) {
    if (fichier.chemin.startsWith('http')) {
      window.open(fichier.chemin, '_blank');
    } else {
      const lien = document.createElement('a');
      lien.href = fichier.chemin;
      lien.download = fichier.nom;
      document.body.appendChild(lien);
      lien.click();
      document.body.removeChild(lien);
    }
  }

  onEdit(i) {
    DOMService.openSidebar(SidebarRoute.InterlocutorForm, {id: i.id, onSubmit: () => this.load()})
  }

  public render() {
    const { model } = this.props;
    const fichiersDocx = [
      { id: 1, nom: 'Convention.docx', chemin: 'https://drive.usercontent.google.com/download?id=1aCtL2UcAm2hi0joxC7eXJUEmDini9skG&export=download&authuser=0&confirm=t&uuid=85644da1-3c19-4a76-846f-1161699d20cf&at=APZUnTXbAf7VS3F2E-EqtJo1qgla:1711536374677' },
      { id: 2, nom: 'Let notif.docx', chemin: 'https://drive.usercontent.google.com/download?id=16TeQXsxstJE9DIxjrDXeQjw8hB_seqw-&export=download&authuser=0&confirm=t&uuid=190dc035-d372-4a6c-8e90-c604dcf1411d&at=APZUnTX7vb9cmPe694kSVuPaeKhF:1711536465630' },
      { id: 3, nom: 'Mémoire.docx', chemin: 'https://drive.usercontent.google.com/download?id=1Ys83ReFmbXtfHNCFbA0bAjcb0_z3jOuA&export=download&authuser=0&confirm=t&uuid=68266be1-db04-4cc7-a73d-6225835e2133&at=APZUnTV8CIbdytRptHlyCwR-ot22:1711536440331' },
      { id: 4, nom: 'Note.docx', chemin: 'https://drive.usercontent.google.com/download?id=1CQEF3BChU6DN_8Qjyr_UJJrdogfHpC-P&export=download&authuser=0&confirm=t&uuid=598f0157-4e88-4c75-b43d-de60d29381de&at=APZUnTWtlctdy1IsYGTpa7RZLRZh:1711536488407' },
      { id: 5, nom: 'Rapport.docx', chemin: 'https://drive.usercontent.google.com/download?id=1X-E7UccxSXY7JV6GSsRl5dq2nix_8aPI&export=download&authuser=0&confirm=t&uuid=87997de7-a71c-4227-936a-f3218acf6a86&at=APZUnTV0FLQdCrWw2DCY0GZbVI6e:1711536511413' }
      // Ajoutez d'autres fichiers ici
    ];

    const fichiers = fichiersDocx;

    return (
        <div className="home">
          <div className="px-0 pt-3 px-md-5">
            <div className="app-content">
              <div className="row-flex mb-3">
                <i onClick={() => sessionBloc.pop()} className="fas fa-chevron-left pe-4 pointer"></i>
                <h2 className='mb-0'>Fichiers pour <b>VITALIA</b></h2>
              </div>
              <div>
                <div className="row">
                  {fichiers.map((fichier) => (
                      <div key={fichier.id} className='col-md-3 mb-3'>
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title">{fichier.nom}</h5>
                            <button onClick={() => this.afficherDocx(fichier)} className="btn btn-primary">
                              Télécharger
                            </button>
                          </div>
                        </div>
                      </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}
export default showModel(CustomerShowPage, {modelClass: Customer}) as any
