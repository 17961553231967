import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import CustomerSelect from '@components/modelSelect/CustomerSelect';
import Contact from '@models/Business';
import DOMService from '@services/DOMService';
import * as React from 'react';
import SidebarLayout from '../layout/SidebarLayout';
import { formatISO } from 'date-fns';
import Address from "@models/Address";
import apiService from "@services/ApiService";
import SmartDatePicker from "@components/input/SmartDatePicker";
import UserSelect from "@components/modelSelect/UserSelect";

export interface IContactFormProps extends IInjectedFormModelProps<Contact> {
}

export interface IContactFormState {
    addressModel: Address;
    isSubmitting: boolean;
}

class ContactForm extends React.Component<IContactFormProps & IFormModelProps<Contact>, IContactFormState> {
    constructor(props: IContactFormProps & IFormModelProps<Contact>) {
        super(props);
        this.state = {
            addressModel: new Address({}),
            isSubmitting: false
        }
    }

    onSubmit = async () => {
        const { model, submit } = this.props;
        this.setState({ isSubmitting: true });
        const { addressModel } = this.state;

        if (model.bornAt instanceof Date) {
            // @ts-ignore
            model.bornAt = formatISO(this.props.model.bornAt, { representation: 'complete' }).slice(0, 19).replace('T', ' ');
        }

        try {
            let result = await apiService.post('address/create', addressModel);
            model.addresses = [new Address(result.data)]
            await this.props.submit();
        } catch (error) {
            console.error('Failed to submit address:', error);
        }
    }

    handleReferentChange = (selectedUserId) => {
        const { model } = this.props;
        model.referent = selectedUserId;
        this.forceUpdate();
    }

    public render() {
        const { model, submit, submitting, id, parentModel } = this.props;
        const principalAddress = model.addresses?.find(address => address.isPrincipal);
        const addressData = {};
        const { addressModel } = this.state
        return (
            <SidebarLayout
                title={id ? "Modification d'une entreprise" : "Création d'une entreprise"}
                bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting}
                    onClick={() => this.onSubmit()}>{id ? "Modifier le contact" : "Créer le contact"}</AsyncButton>}
            >
                <div className="fadeInDown">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <SmartInputText model={model} name="firstname" label="Nom de l'entreprise"
                                containerClassName="mb-3" />
                        </div>
                        <div className="col-md-6 col-12">
                            <SmartInputText model={model} name="vatNumber" label="Numéro de TVA" containerClassName="mb-3" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <SmartInputText model={model} name="civ" label="Civilité" containerClassName="mb-3" />
                        </div>
                        <div className="col-md-6 col-12">
                            <SmartInputText model={model} name="lastname" label="Président" containerClassName="mb-3" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <SmartInputText model={model} name="quartier" label="Zone géographique"
                                containerClassName="mb-3" />
                        </div>
                        <div className="col-md-6 col-12">
                            <SmartInputText model={model} name="codeNaf" label="Code NAF" containerClassName="mb-3" />
                        </div>
                    </div>
                    <SmartInputText model={model} name="siret" label="SIRET" containerClassName="mb-3" />
                    <div className="row">
                        <div className="col-md-6 col-12">
                        <SmartInputText model={model} name="phone" label="Numéro de téléphone" containerClassName="mb-3" />
                        </div>
                        <div className="col-md-6 col-12">
                        <SmartInputText model={model} name="email" label="Mail" containerClassName="mb-3" />
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <SmartInputText model={model} name="effectif" label="Effectif" containerClassName="mb-3" />
                        </div>
                        <div className="col-md-6 col-12">
                            <SmartDatePicker model={model} name="bornAt" label="Date de création"
                                containerClassName="mb-3" />
                        </div>
                        <div className="col-md-6 col-12">
                            <SmartInputText model={model} name="rcs" label="RCS" containerClassName="mb-3" />
                        </div>
                        <div className="col-md-6 col-12">
                            <UserSelect
                                name="referent_id"
                                label="Attribuer un référent"
                                containerClassName="mb-3"
                                onChange={this.handleReferentChange}
                                value={model.referent}
                            />
                        </div>
                        <div className="col-md-6 col-12">
                            <SmartInputText model={model} name="activitySector" label="Secteur" containerClassName="mb-3" />
                        </div>
                    </div>
                    {!id &&
                        <>
                            <h3 className='text-muted'><u>Adresse</u></h3>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <SmartInputText model={addressModel} name="cp" label="Code Postale"
                                        containerClassName="mb-3" />
                                </div>
                                <div className="col-md-6 col-12">
                                    <SmartInputText model={addressModel} name="address" label="Numéro et nom de voie"
                                        containerClassName="mb-3" />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <SmartInputText model={addressModel} name="addressComp" label="Complément d'adresse"
                                    containerClassName="mb-3" />
                            </div>
                        </>
                    }

                </div>
            </SidebarLayout>
        );
    }
}

export default formModel<IContactFormProps>(ContactForm, { modelClass: Contact });