export default abstract class Validator {

  public message: string

  check(value) {
    return true;
  }

  getError() {
    return {message: this.message}
  }

}