import AdminModelsList from '@components/common/AdminModelsList';
import SectorGrid from '@components/grid/SectorGrid';
import MainLayout from '@components/layout/MainLayout';
import Sector from '@models/Sector';
import * as React from 'react';

export interface ICustomersCRMPageProps {
}

export default class CustomersCRMPage extends React.Component<ICustomersCRMPageProps> {
  public render() {
    return (
      <MainLayout title="Entreprises">
        <div className="app-content">
          <AdminModelsList
            gridClass={SectorGrid}
            options={{ loadOnReady: true, paginate: true, modelClass: Sector}}
          />
        </div>
      </MainLayout>
    );
  }
}