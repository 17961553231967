import FullCalendar, { CustomContentGenerator, DayHeaderContentArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import InterventionSlot from '@models/InterventionSlot';
import Model from "@models/Model";
import EntityManager from '@services/EntityManager';
import listPlugin from '@fullcalendar/list';
import * as React from 'react';
import CountDisplayer from './CountDisplayer';
import SmartInputText from '@components/input/SmartInputText';
import SmartSwitch from '@components/input/SmartSwitch';
import dayjs from 'dayjs';
import Alert from "@models/Alert";
import ApiService from "@services/ApiService";
import DOMService from "@services/DOMService";
import {SidebarRoute} from "@components/layout/SidebarRouter";

export interface IContactSlotCalendarProps {
  parentModel: Model
  context: 'admin' | 'contact';
  contentHeight?
  params?
  onSelect?: (start: Date, end: Date) => void
  add?: InterventionSlot,
  onSlotClick?
  eventContent?
  eventsInBackground?
  canEdit?
  list?
  with?
  hideAmountByDay?
  withCount?
}

export default class UserSlotCalendar extends React.Component<IContactSlotCalendarProps, {interventionSlots: InterventionSlot[], alerts: Alert[], address: string, inFuture: boolean}> {
  constructor(props) {
    super(props);
    this.state = {
      interventionSlots: [],
      alerts: [],
      address: "",
      inFuture: true,
    }
  }

  ref = React.createRef<FullCalendar>();

  componentDidMount() {
    this.loadData();
  }


  loadData = async () => {
    if (!this.props.parentModel) return;
    const userId = this.props.parentModel.id;
    try {
      const response = await ApiService.get(`users/${userId}/interventions-alerts`);
      const { interventions, alerts } = response.data;
      this.setState({
        interventionSlots: interventions || [],
        alerts: alerts || [],
      });
    } catch (error) {
      console.error('Failed to load data:', error);
    }
  };

  getEvents = () => {
    const { interventionSlots, alerts } = this.state;
    let events = [];

    events = events.concat(interventionSlots.map(slot => ({
      title: "Rendez-vous avec: " + slot.contact.firstname || "Intervention",
      start: slot.startAt,
      end: slot.endAt,
      color: "#f56b00",
      slot: slot
    })));

    events = events.concat(alerts.map(alert => ({
      title: `Alerte: ${alert.message || "No message"}${alert.contact ? ", Concerne le contact: " + alert.contact.firstname : ""}`,
      start: alert.startAt,
      end: dayjs(alert.startAt).add(1, 'hour').toDate(),
      color: "#ff0000",
      slot: alert
    })));
    return events;
  };


  getTitleFormat = (date) => {
    let startOfWeek = dayjs(date).startOf('week').format('D MMMM');
    let endOfWeek = dayjs(date).endOf('week').format('D MMMM YYYY');
    return `Semaine du ${startOfWeek} au ${endOfWeek}`;
  }

  viewDidMount = (viewInfo) => {
    // Modifier le titre de la vue ici
    let titleElement = document.querySelector('.fc-toolbar-title');
    if(titleElement) {
      titleElement.textContent = this.getTitleFormat(viewInfo.view.currentStart);
    }
  }

  updateCalendarTitle = (arg) => {
    let titleElement = document.querySelector('.fc-toolbar-title');
    if (titleElement) {
      // Calculez le titre en fonction de la date de début actuelle de la vue
      titleElement.textContent = this.getTitleFormat(arg.start);
    }
  }

  eventUpdate = async (e) => {
    if (e.event.title==="RDV") return;
    let slot: InterventionSlot = e.event.extendedProps.slot;

    slot.startAt = e.event.start;
    slot.endAt = e.event.end;
    await EntityManager.update(slot, {only: ["startAt", "endAt"]});
  }

  select = async (e) => {
    this.props.onSelect(e.start, e.end);
  }

  countAlertsForDay = (date) => {
    return this.state.alerts.filter((alert) => {
      return dayjs(alert.startAt).isSame(date, 'day');
    }).length;
  }

  headersDay = ({date, text}) => {
    const alertCount = this.props.context === 'admin' ? this.countAlertsForDay(date) : 0;

    return (
        <div className='row-flex'>
          <div>{text}</div>
          {alertCount > 0 && (
              <div style={{fontWeight: "400", marginLeft: "4px"}}>
                {alertCount} alerte(s)
              </div>
          )}
          <div className='ms-auto'>{dayjs(date).format("D MMMM YYYY")}</div>
        </div>
    );
  }

  onSlotClick = (event) => {
    if (!("intervention" in event)) {
      DOMService.openSidebar(SidebarRoute.AlertForm, {id: event.id, onSubmit: () => {
          DOMService.closeSidebar();
        }});
    } else {
      DOMService.openSidebar(SidebarRoute.InterventionSlotForm, {id: event.id, onSubmit: () => {
          DOMService.closeSidebar();
        }});
    }
    //DOMService.openSidebar(SidebarRoute.InterventionSlotForm, {id: slot.id, noCalendar: true, onSubmit: (_) => this.reload()})
  }

  public render() {
    const {contentHeight, onSelect, list, eventContent, withCount, hideAmountByDay} = this.props;
    const {address, inFuture} = this.state;
    let events = this.getEvents();
    let filteredEvents = events;
    if (address) {
      filteredEvents = filteredEvents.filter((e) => e.slot.matchWithAddress(address));
    }
    let headersDay: CustomContentGenerator<DayHeaderContentArg>
    if (!hideAmountByDay) headersDay = (_) => {
      return _ != null ? <div className='row-flex'>
      <div>{_.text}</div>
      <div style={{fontWeight: "400"}} className='ms-4'>{filteredEvents.filter((ev) => {
        return ev.slot != null && ev.slot.startAt?.getDate() == _?.date?.getDate();
      }).length} rdv</div>
      <div className='ms-auto'>{dayjs(_.date).format("D MMMM YYYY")}</div>
    </div> : null
    }
    return (
      <>
      {(this.ref.current) && <div className="d-flex mb-4">
        {withCount && <CountDisplayer total={events.length} calendar={this.ref.current} />}
      </div>}
        <FullCalendar
            ref={this.ref}
            plugins={[listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView='listWeek'
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: ""
            }}
            viewDidMount={this.viewDidMount}
            datesSet={this.updateCalendarTitle}
        buttonText={{
          today: "Aujourd'hui",
          timeGridDay: "Par jour",
          timeGridWeek: "Par semaine",
          listWeek: "Liste"
        }}
        // schedulerLicenseKey="0344037874-fcs-1637598111"
        contentHeight={contentHeight || 'auto'}
        allDaySlot={false}
        dayHeaderContent={this.headersDay}
        eventContent={eventContent}
        locale={"fr"}
        hiddenDays={[0, 6]}
        slotDuration={'00:30:00'}
        slotMinTime="08:00:00"
        eventClassNames={"pointer"}
        // selectOverlap={false}
        slotMaxTime="18:00:00"
        displayEventTime={list}
        select={onSelect ? this.select : null}
        editable={this.props.canEdit}
        eventChange={this.eventUpdate}
        eventResize={this.eventUpdate}
        selectable={true}
        events={filteredEvents}
        eventClick={this.props.onSlotClick ? (e) => this.props.onSlotClick(e.event._def.extendedProps.slot) : (e) => this.onSlotClick(e)}

      />
      </>
    );
  }
}
