import FullCalendar from '@fullcalendar/react';
import * as React from 'react';

export interface ICountDisplayerProps {
  total: number
  calendar: FullCalendar
}

export default class CountDisplayer extends React.Component<ICountDisplayerProps> {

  componentDidMount(): void {
    const {calendar} = this.props;
    calendar.getApi().on("datesSet", (e) => {
      this.setState({});
    });
  }

  public render() {
    const {total, calendar} = this.props;
    if (!calendar) return <></>
    let displayed = calendar.getApi().getEvents().filter(event => {
      const s = calendar.getApi().view.activeStart, e = calendar.getApi().view.activeEnd
      if (event.start > e || event.end < s) return false
      return true
  }).length
    return (
      <div>
        {displayed}/{total} évènements affiché(s)
      </div>
    );
  }
}
