import Interlocutor from '@models/Interlocutor';
import KeyyoService from '@services/KeyyoService';
import * as React from 'react';

export interface IInterlocutorItemProps {
  interlocutor: Interlocutor
  onGoEdit
}

export default class InterlocutorItem extends React.Component<IInterlocutorItemProps> {
  public render() {
    const {interlocutor, onGoEdit} = this.props;
    return (
      <div className='bg-light border rounded-2 py-2 px-3'>
        <div className="row">
          <div className="col-md-8">
            <div className='fw-bold text-capitalize'>
              {interlocutor.fullname}
            </div>
            <div className='text-secondary'>
              {interlocutor.job}
            </div>
            <div className='mt-2'>
              {interlocutor.email}
            </div>
            <div>
              {interlocutor.phones?.map((p) => <div className='row-flex'>
                <div className='fas fa-phone ms-n2 p-2 text-primary pointer ' onClick={() => KeyyoService.startCall(p)}>
                </div>
                <div>
                  {p}
                </div>
              </div>)}
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex flex-column align-items-end">
              <div className='small-btn-icon mx-n2' onClick={() => onGoEdit()}>
                <i className='fas fa-pencil'></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
