import OptionService from "../services/OptionService";
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import FileAttribute from './core/FileAttribute';
import ModelAttribute from './core/ModelAttribute';
import Model from "./Model";
import OptionType from './OptionType';

export default class Option extends Model {

  public label: string
  public color: string
  public key: string
  public description: string;
  public iconUrl: string;
  public default: boolean
  public optionType: OptionType
  public optionTypeId: number

  public iconFile: File

  static modelUrl = "options";
  public modelName = "option";

  public attributes = new AttributesCollection({
    label: new Attribute("label"),
    key: new Attribute("key"),
    iconUrl: new Attribute("iconUrl"),
    description: new Attribute("description"),
    optionType: new ModelAttribute("optionType", OptionType),
    color: new Attribute("color"),
    default: new Attribute("default"),
    iconFile: new FileAttribute("iconFile")
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  isSameOf(key): boolean {
    return OptionService.getOptionId(this.optionType.name, key) === this.id
  }

}