import AdminModelsList from '@components/common/AdminModelsList';
import CRMUserGrid from '@components/grid/CRMUserGrid';
import MainLayout from '@components/layout/MainLayout';
import * as React from 'react';
import User from 'src/models/User';
import DOMService from "@services/DOMService";
import {SidebarRoute} from "@components/layout/SidebarRouter";

export interface ICRMUsersPageProps {
}

export default class CRMUsersPage extends React.Component<ICRMUsersPageProps> {
    specificActions = () => <></>
    openUserForm = () => {
        DOMService.openSidebar(
            SidebarRoute.UserForm
        )
    }

    public render() {
        return (
            <MainLayout title="Agents">
                <div className="app-content">
                    <div className="row-end">
                        {this.specificActions()}
                        {this.openUserForm &&
                            <button onClick={this.openUserForm} className="btn btn-primary">Nouveau</button>}
                    </div>

                    <AdminModelsList
                        gridClass={CRMUserGrid}
                        options={{loadOnReady: true, paginate: true, modelClass: User}}
                    />
                </div>
            </MainLayout>
        );
    }
}
