import SmartSelect from '@components/input/SmartSelect';
import * as React from 'react';
import Yard from 'src/models/Yard';
import { YardCompositionProgress } from './YardCompositionProgress';
import ApiService from "@services/ApiService";

export interface IYardProgressProps {
  yard: Yard
  className?: string
}

export default class YardProgress extends React.PureComponent<IYardProgressProps, any> {

  public doneDateOptions;

  constructor(props) {
    super(props);
    this.doneDateOptions = [{label: "Tout", id: "all"}, ...props.yard.getMonthReported()]
    this.state = {
      selected: this.doneDateOptions[0],
      workerStats: null
    }
  }

  componentDidMount() {
    this.loadStats(this.props.yard.id);
  }

  async loadStats(yard) {
    if (yard){
      let data = await ApiService.get("get_yard_stats", {
        yard_id: yard,
        month: this.state.selected.id.split("/")[0],
        year: this.state.selected.id.split("/")[1]
      });
      let stats = data.data

      for(let i=0; i < stats.length; i++){
        if(stats[i].state_id === 2){
          let index_journee = stats.findIndex((element) => {
            return element.state_id === 1
          })
          if(index_journee > -1){
            stats[index_journee].day +=  stats[i].day
          }else{
            stats.unshift({"state_id": 1, day: stats[i].day, state_name: "Journée"})
            //Pour ne pas refaire l'élément traité.
            i++
          }
        }
      }

      this.setState({workerStats: stats})
    }
  }

  onChange = (selected) => this.setState({selected}, () => this.loadStats(this.props.yard.id))

  public render() {
    const {className, yard} = this.props;
    const {selected} = this.state;
    return (
      <div className={className}>
        <div className="row-end mb-4">
          <SmartSelect noSearch onChange={this.onChange} value={selected} options={this.doneDateOptions} />
        </div>
        <div className="mb-2">
          <div className="text-s text-secondary">Temps travaillé</div>
          {this.state.workerStats && this.state.workerStats.map((stat) => {
            if(stat.state_id === 8) {
              return <>
                <div key={"demie"}>Demie journée: {stat.day}</div>
                <div key={stat.state_id}>{stat.state_name}: {stat.day}</div>
              </>
            }
            return <div key={stat.state_id}>{stat.state_name}: {stat.day}</div>
          })}
        </div>
        {yard.yardCompositions.map((compo, index) => <YardCompositionProgress selected={selected.id} key={"a" +index} compo={compo} />)}
      </div>
    );
  }
}
