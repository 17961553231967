
class GetX {

  private managers = {}

  put(ref, manager) {
    this.managers[ref] = manager;
  }

  destroy(ref) {
    delete this.managers[ref];
  }

  get(ref): any {
    return this.managers[ref];
  }
}

export default new GetX()