import { EventEmitter } from 'events';
import Model from "../Model";
import Validator from './form/validators/Validator';

export interface AttributeOptions {
  submittable?: boolean
  key?: string,
  nullable?: boolean
  validator?: Validator,
}

export default class Attribute {

  public name: string
  public className;
  public submittable: boolean;
  public key: string
  public value: any
  public originalValue: any
  public emitter: EventEmitter;
  public validator: Validator;
  public nullable;
  public errors

  constructor(name: string, options: AttributeOptions = {}) {
    this.name = name;
    this.submittable = options.submittable ?? true;
    this.key = options.key ?? name;
    if (options.validator) this.validator = options.validator;
    if (options.nullable) this.nullable = options.nullable || false;
  }

  public getFormDataKey() {
    return this.key;
  }

  public fill(parentModel: any, json) {
    this.originalValue = json[this.name];
    parentModel.attributes.attributes[this.name].setValue(this.createAttribute(parentModel, json));
    Object.defineProperty(parentModel, this.name, this.definePropertyOptions(parentModel, this.name));
  }

  public change(previousValue?, newValue?) {
    this.emitter?.emit("change", {previousValue, newValue});
  }

  public listen(callback) {
    if (!this.emitter) this.emitter = new EventEmitter();
    this.emitter.on("change", (previousValue, newValue) => callback(previousValue, newValue));
  }

  public definePropertyOptions(model, name) {
    return {
      get: () => {
        return model.getAttribute(name).getValue();
      },
      set: (val) => {
        let previousValue = model.getAttribute(name).getValue();
        model.getAttribute(name).setValue(val);
        let newValue = model.getAttribute(name).getValue();
        this.change(previousValue, newValue);
      }
    }
  }

  public isListened() {
    return this.emitter?.listenerCount("change");
  }

  public toApiData(parentModel: Model, stringify = false) {
    return this.nullable && parentModel[this.name]===null ? "null" : parentModel[this.name];
  }

  public createAttribute(parentModel: Model, json) {
    return json[this.name];
  }

  setValue(value) {
    this.value = value;
    if (this.errors && this.checkForSubmit()) {
      this.errors = null;
      this.change();
    };
  }

  getValue() {
    return this.value;
  }

  serialize() {
    return this.value;
  }

  checkForSubmit() {
    return this.checkSelfForSubmit();
  }

  checkSelfForSubmit() {
    if (!this.validator) return true;

    let valid = this.validator.check(this.getValue());
    if (!valid) {
      this.errors = this.validator.getError();
      this.change();
    }
    return valid;
  }

  getErrorMessage() {
    return this.errors?.message;
  }

  hasFileAttribute() {
    return false;
  }

  isDirty() {
    return this.originalValue !== this.value;
  }
}