import groupBy from 'lodash/groupBy';
import Option from '../models/Option';
import EntityManager from './EntityManager';
import Prestation from '../models/Prestation';
import { uniqBy } from 'lodash';
import PrestationType from '../models/PrestationType';
import Sector from '@models/Sector';

class OptionService {

  public options: {[name: string]: Option[]};
  public optionsList: Option[] = [];
  public prestations: Prestation[]
  public quartiers: {[key: number]: string[]} = {}

  get(key: string) {
    let options = this.options[key];
    return options;
  }

  setPrestation(prestations: Prestation[]) {
    this.prestations = prestations;
  }

  prestationTypes() {
    return uniqBy(this.prestations.map(p => p.prestationType).flat(), "key");
  }

  async loadQuartier(sectorId) {
    if (!this.quartiers[sectorId]) {
      this.quartiers[sectorId] = (await EntityManager.get(Sector, {path: sectorId + "/quartiers"})).data.data;
    }
  }

  findPrestationType(id): PrestationType {
    return this.prestationTypes().find(o => o.id === id);
  }

  findPrestation(id): Prestation {
    return this.prestations.find(o => o.id === id);
  }

  getDefault(key: string) {
    return this.options[key].find(o => o.default);
  }

  setData(options: any[]) {
    options = options.map(m => EntityManager.toModel(Option, m));
    this.organizeData(options);
  }

  organizeData(options: Option[]) {
    this.optionsList = options;
    this.options = groupBy<Option>(options, (o) => o.optionType.name);
  }

  async load() {
    let options = (await EntityManager.all<Option>(Option)).models;
    this.organizeData(options);
  }

  find(id: number): Option {
    return this.optionsList.find(o => o.id === id);
  }

  getOption(optionType: string, key: string) {
    return this.options[optionType].find(ot => ot.key === key);
  }

  getOptionId(optionType: string, key: string) {
    return this.getOption(optionType, key)?.id;
  }

  findOptionType(name: string) {
    return this.options[name][0].optionType;
  }

}

export default new OptionService();