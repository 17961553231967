import React from 'react'
import {useState} from 'react';

interface Props {
  submit: () => Promise<any>
  children: (loading: boolean, validity?: {[name: string]: ValidityState}) => JSX.Element
  formProps?: React.FormHTMLAttributes<HTMLFormElement>
}

export const DummyForm = (props: Props) => {

  const [loading, setLoading] = useState(false)
  const [validity, setValidity] = useState(null)

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    if (props.formProps?.noValidate && !event.currentTarget.checkValidity()) {
      let errors = {}
      Object.values(event.currentTarget.elements).forEach((element: any) => {
        if (element.tagName === "INPUT") {
          errors[element.name] = element.validity;
        }
      })
      setValidity(errors);
      return;
    }
    setLoading(true);
    await props.submit();
    setLoading(false);
  }

  return (
    <form onSubmit={submit} {...props.formProps} >
      {props.children(loading, validity)}
    </form>
  )
}
