import React from "react";
import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import formModel, {IFormModelProps, IInjectedFormModelProps} from '@components/logic/FormModel';
import FileType from '@models/FileType';
import SidebarLayout from '../layout/SidebarLayout';
import ApiService from "@services/ApiService";
import {sessionBloc} from "@bloc/SessionBloc";

interface IUser {
    id: number;
    name: string;
    // Add other necessary user fields
}

export interface IFileTypeFormProps extends IInjectedFormModelProps<FileType> {
    currentUser: IUser;
}

export interface IFileTypeFormState {
    name: string;
    description: string;
    file: File | null;
}

class FileTypeForm extends React.Component<IFileTypeFormProps & IFormModelProps<FileType>, IFileTypeFormState> {
    constructor(props: IFileTypeFormProps & IFormModelProps<FileType>) {
        super(props);

        this.state = {
            name: '',
            description: '',
            file: null,
        };
    }

    submit = async () => {
        const { name, description, file } = this.state;
        if (!file) {
            console.error("Please select a file.");
            return;
        }
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('file', file);

        try {
            const fileResponse = await ApiService.post(`files`, formData);

            if (fileResponse.status === 200 && fileResponse.data.file.id) {
                const fileId = fileResponse.data.file.id;
                const currentUser = sessionBloc.user;
                const fileTypeData = {
                    date: new Date().toISOString().slice(0, 10),
                    file_id: fileId,
                    depositor_id: currentUser.id,
                    name: this.props.model.name,
                    description: this.props.model.description,
                };
                await ApiService.post(`file-types`, fileTypeData);
                window.location.reload()
            }
        } catch (error) {
            console.error("Error submitting file type information:", error);
        }
    };

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        if (fileList) {
            this.setState({file: fileList[0]});
        }
    };


    public render() {
        const {submitting} = this.props;
        const {name, description} = this.state;
        return (
            <SidebarLayout
                title="Ajout d'un type de fichier"
                bottomArea={
                    <AsyncButton
                        className="btn btn-primary w-100 btn-lg"
                        loading={submitting}
                        onClick={this.submit}>
                        Enregistrer
                    </AsyncButton>
                }
            >
                <div className="fadeInDown">
                    <SmartInputText
                        model={this.props.model}
                        name="name"
                        label="Nom du type de fichier"
                        value={name}
                        containerClassName="mb-3"
                    />
                    <SmartInputText
                        model={this.props.model}
                        name="description"
                        label="Description"
                        value={description}
                        containerClassName="mb-3"
                    />
                    <div className="mb-3">
                        <label htmlFor="file-upload" className="form-label">Choisir un fichier</label>
                        <input
                            type="file"
                            className="form-control"
                            id="file-upload"
                            onChange={this.handleFileChange}
                        />
                    </div>
                </div>
            </SidebarLayout>
        );
    }
}

export default formModel<IFileTypeFormProps>(FileTypeForm, {modelClass: FileType});
