import User from '@models/User';
import dayjs from 'dayjs';
import Yard from 'src/models/Yard';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import DateAttribute from './core/DateAttribute';
import ModelAttribute from './core/ModelAttribute';
import Model from './Model';
import { OptionAttribute } from '@models/core/OptionAttribute';
import Option from '@models/Option';

export default class Slot extends Model {

  public startAt: Date
  public endAt: Date
  public remaining: number
  public ordersCount: number
  public user: User
  public yard: Yard
  public yardId: number
  public userId: number
  public type: Option
  public duration: number

  static modelUrl = "slots";
  public modelName = "slot";

  public attributes = new AttributesCollection({
    startAt: new DateAttribute("startAt"),
    endAt: new DateAttribute("endAt"),
    duration: new Attribute("duration"),
    remaining: new Attribute("remaining", {submittable: false}),
    ordersCount: new Attribute("ordersCount", {submittable: false}),
    user: new ModelAttribute("user", User),
    type: new OptionAttribute("type", {optionType: "slot_type"}),
    yard: new ModelAttribute("yard", Yard),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  getDurationWithWeek() {
    return dayjs(this.endAt).diff(this.startAt, "day") + 1;
  }

  dontOverlap = (start, end) => {
    return dayjs(end).isBefore(this.startAt) || dayjs(end).isSame(this.startAt) || dayjs(start).isAfter(this.endAt) || dayjs(start).isSame(this.endAt);
  }

  isWork() {
    return this.type.key === "slot_type_work";
  }

}