import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import SidebarLayout from '@components/layout/SidebarLayout';
import * as React from 'react';
import ApiService from '@services/ApiService';
import DOMService from 'src/services/DOMService';
import { DummyForm } from '@components/form/DummyForm';

export interface IPasswordUpdatePageProps {
}

export default class PasswordUpdatePage extends React.PureComponent<IPasswordUpdatePageProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      errorServer: null
    }
  }

  submit = async () => {
    this.setState({loading: true})
    const {oldPassword, newPassword} = this.state
    try {
      await ApiService.post("users/password_change", {oldPassword, newPassword});
      DOMService.closeSidebar();
      setTimeout(() => DOMService.setToaster("Votre mot depasse a bien été modifié"), 800)
      
    } catch (error) {
      this.setState({loading: false, errorServer: error.response.data.message})
    }
  }

  handleChange = (val, key) => {
    this.setState({[key]: val})
  }

  public render() {
    const {oldPassword, newPassword, errorServer} = this.state
    return (
      <DummyForm submit={this.submit} formProps={{}}>
        {(loading, validity) =>
          <>
            <SidebarLayout
              title="Changer son mot de passe"
              bottomArea={<AsyncButton loading={loading} className="btn btn-primary w-100 btn-lg">{"Changer mon mot de passe"}</AsyncButton>}
            >
              <div className="fadeIn">
                <SmartInputText value={oldPassword} onChange={(val) => this.handleChange(val, "oldPassword")} label="Ancien mot de passe" containerClassName="mb-3" required />
                <SmartInputText value={newPassword} onChange={(val) => this.handleChange(val, "newPassword")} label="Nouveau mot de passe" containerClassName="mb-3" required />
                <div className="mt-3 text-danger">
                {errorServer}
                </div>
              </div>
            </SidebarLayout>
          </>
        }
      </DummyForm>
    );
  }
}
