import User from '@models/User';
import CustomerPage from './pages/CustomerPage';
import HomePage from './pages/HomePage';
import PlanningPage from './pages/PlanningPage';
import SettingPage from './pages/SettingPage';
import UsersPage from './pages/UsersPage';
import YardPage from './pages/YardPage';
import RhPage from "./pages/RhPage";


const getRoutes = (user: User) => {
  const routes = [
    {
      icon: "fa-user-clock",
      label: "Gestion",
      url: "/management/Rh",
      page: RhPage,
      exact: true,
      roles: ["user_role_admin", "user_role_ordonnanceur", "user_role_national_responsible"]
    },{
      icon: "fa-chart-pie",
      label: "Accueil",
      url: "/management",
      page: HomePage,
      exact: true,
      roles: ["user_role_admin", "user_role_ordonnanceur"]
    },{
      icon: "fa-hard-hat",
      label: "Chantiers",
      url: "/management/yards",
      page: YardPage,
      roles: ["user_role_admin", "user_role_responsible", "user_role_manager", "user_role_national_responsible"]
    },{
      icon: "fa-handshake",
      label: "Clients",
      url: "/management/customers",
      page: CustomerPage,
      exact: true,
      roles: ["user_role_admin", "user_role_manager"]
    },{
      icon: "fa-calendar-alt",
      label: "Planning",
      url: "/management/plannings",
      page: PlanningPage,
      exact: true,
      roles: ["user_role_admin", "user_role_responsible", "user_role_manager", "user_role_national_responsible"]
    },{
      icon: "fa-users",
      label: "Utilisateurs",
      url: "/management/users",
      page: UsersPage,
      roles: ["user_role_admin", "user_role_manager", "user_role_national_responsible"]
    },{
      icon: "fa-cogs",
      label: "Paramètres",
      url: "/management/settings",
      page: SettingPage,
      exact: true,
      roles: ["user_role_admin", "user_role_manager", "user_role_secretaire", "user_role_responsible", "user_role_national_responsible"]
    },
    // {
    //   icon: "fa-circle-phone-flip",
    //   label: "CRM",
    //   url: "/crm",
    //   page: CRMPage,
    //   exact: true,
    //   roles: ["user_role_admin", "user_role_secretaire", "user_role_manager"]
    // }
    //   icon: "fa-power-off",
    //   label: "Déconnexion",
    //   url: "/logout",
    //   page: LogoutPage,
    //   exact: true
    // }
  ];
  return routes.filter(r => r.roles.includes(user.role.key))
} 

export default getRoutes;