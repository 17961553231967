import { sessionBloc } from '@bloc/SessionBloc';
import * as React from 'react';
import Page403 from 'src/pages/Page403';

export interface IWorkerLayoutProps {
}

export default class WorkerLayout extends React.Component<IWorkerLayoutProps> {
  public render() {
    if (!sessionBloc.isWorker() && !sessionBloc.isAdmin()) return <Page403/>
    return (
      <div className='p-3 bg-white'>
      {this.props.children}
      </div>
    );
  }
}
