import { AxiosResponse } from 'axios';
import Model from '../../models/Model';
import EntityManager from '../EntityManager';
import HttpResponse from './HttpResponse';

export default class ModelCollectionResponse<T extends Model> extends HttpResponse {

  public models: T[]
  public count: number;

  constructor(response: AxiosResponse, options?) {
    super(response, options);
    if (response.data.models) {
      this.models = response.data.models.map(m => EntityManager.toModel(options.modelClass, m))
      this.count = response.data.count || response.data.models.length
    } 
  }

}