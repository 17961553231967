import AsyncButton from '@components/button/AsyncButton';
import SmartDateRangePicker from '@components/input/SmartDateRangePicker';
import SidebarLayout from '@components/layout/SidebarLayout';
import Customer from '@models/Customer';
import ApiService from '@services/ApiService';
import * as React from 'react';

export interface IExportSuiviPanelProps {
  customer: Customer
}

export default class ExportSuiviPanel extends React.Component<IExportSuiviPanelProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      from: null,
      to: null,
    }
  }

  async submit() {
    const { from, to } = this.state;
    let response = await ApiService.post("dashboard/crm/export", {from, to});
    var blob=new Blob([response.data]);
    var link=document.createElement('a');
    link.href=window.URL.createObjectURL(blob);
    link.download= this.props.customer.name + "_suivi.csv";
    link.click();
  }

  onPeriodChange(_) {
    this.setState({
      from: _[0],
      to: _[1],
    })
    
  }

  public render() {
    const { from, to } = this.state;

    return (
      <SidebarLayout
        title="Export du suivi"
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={false} onClick={() => this.submit()} >Exporter</AsyncButton>}
      >
        <div className="fadeInDown">
          <SmartDateRangePicker onChange={(_) => this.onPeriodChange(_)} from={from} to={to} />
        </div>
      </SidebarLayout>
    );
  }
}
