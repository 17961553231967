import React, { useState } from 'react';
import _ from "lodash";

interface MonthPickerProps {
    initialMonth: Date;
    onMonthChange: (newMonth: Date) => void;
}

const MonthPicker: React.FC<MonthPickerProps> = ({
                                                     initialMonth,
                                                     onMonthChange
                                                 }) => {
    const [currentMonth, setCurrentMonth] = useState(initialMonth);

    const handlePreviousMonth = () => {
        const previousMonth = new Date(
            currentMonth.getFullYear(),
            currentMonth.getMonth() - 1
        );
        setCurrentMonth(previousMonth);
        onMonthChange(previousMonth);
    };

    const handleNextMonth = () => {
        const nextMonth = new Date(
            currentMonth.getFullYear(),
            currentMonth.getMonth() + 1
        );
        setCurrentMonth(nextMonth);
        onMonthChange(nextMonth);
    };

    return (
        <div className="month-picker text-center">
            <button className="btn btn-primary m-3" onClick={handlePreviousMonth}>Mois précédent</button>
            <span className="current-month">{_.capitalize(currentMonth.toLocaleDateString('fr-EU', { month: 'long', year: 'numeric' }))}</span>
            <button className="btn btn-primary m-3" onClick={handleNextMonth}>Mois suivant</button>
        </div>
    );
};

export default MonthPicker;
