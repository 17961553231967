import SidebarRouter from '@components/layout/SidebarRouter';
import * as React from 'react';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import Model from 'src/models/Model';

export interface IAddOnFlyProps {
  parentModel: Model
  attribute: string
  formRoute: SidebarRoute
}

export default class AddOnFly extends React.Component<IAddOnFlyProps> {

  add = () => {
    SidebarRouter.push(this.props.formRoute, {onSubmit: this.onSubmit});
  }

  onSubmit = (model) => {
    const {attribute, parentModel} = this.props
    parentModel[attribute] = model;
    SidebarRouter.pop();
  }

  public render() {
    return (
      <div className="btn btn-primary" onClick={this.add}>
        <i className="fas fa-plus"></i>
      </div>
    );
  }
}
