import classNames from 'classnames';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import * as React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import "react-day-picker/lib/style.css";
import OutsideAlerter from '../logic/OutsideAlerter';
import SmartInput2, { ISmartInputProps } from './SmartInput2';

dayjs.extend(localeData)

export interface ISmartDateRangePickerProps extends ISmartInputProps {
  names?: string[]
  from?
  to?
  onChange?
  periodLabel?: string
  actions?
  className?: string
}

class SmartDateRangePicker extends React.Component<ISmartDateRangePickerProps, any> {

  months = []
  containerRef = React.createRef<HTMLDivElement>()

  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
    this.state = this.getInitialState();
    this.months = dayjs.months()
  }

  getInitialState() {
    return {
      from: null,
      to: null,
      isOpen: false,
      enteredTo: this.defaultEnteredTo(),
    };
  }

  defaultEnteredTo() {
    return this.props.model ? this.props.model[this.props.names[1]] : this.props.to
  }

  componentDidUpdate(prevProps) {
    if (prevProps.to !== this.props.to) this.setState({enteredTo: this.defaultEnteredTo()})
  }

  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  close = () => this.setState({isOpen: false})

  handleDayClick(day: Date) {
    const {from, to} = this.getCurrentValue()
    
    if (from && to && day >= from && day <= to) {
      this.props.onChange && this.props.onChange([null, null]);
      this.setState({
        enteredTo: null,
      });
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      
      this.updateStates([day, null])
      this.setState({
        enteredTo: null,
      });
    } else {
      day = dayjs(day).add(1, "day").toDate();
      this.updateStates([from, day])
      this.close()
      this.setState({
        enteredTo: day,
      });
    }
  }
  handleDayMouseEnter(day) {
    const {from, to} = this.getCurrentValue()
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  }

  updateStates = (values, e?) => {
    
    const { model, names, onChange } = this.props;
    if (!model) return this.props.onChange(values);
    // if (helpFunction) value = helpFunction(value);
    // let needChange = this.beforeChange(value)
    // if (!needChange) return;
    if (model) {
      model[names[0]] = values[0];
      model[names[1]] = values[1];
    }
    onChange?.call(values, e);
    this.setState({});
    // if (!model?.getAttribute(name).isListened()) this.setState({});
  }

  open = () => this.setState({isOpen: true});

  getCurrentValue() {
    return {
      from: this.props.model ? this.props.model[this.props.names[0]] : this.props.from,
      to: this.props.model ? this.props.model[this.props.names[1]] : this.props.to
    }
  }

  buttonText() {
    if (this.props.periodLabel) return this.props.periodLabel;
    const {from, to} = this.getCurrentValue();
    if (from && to) {
      return `Période de ${dayjs(from).format("DD/MM/YYYY")} à ${dayjs(to).format("DD/MM/YYYY")}`
    } else {
      return "Choisir une période"
    }
  }

  onClear = () => {
    this.updateStates([null, null])
    this.close()
  }

  containerStyle = (): React.CSSProperties => {
    let elementRect = this.containerRef.current.getBoundingClientRect();
    let bodyRect = document.body.getBoundingClientRect();
    let left = bodyRect.right - elementRect.left > 630;
    return {[left ? "left" : "right"]: 0, top: "100%"}
  }

  render() {
    const {isOpen, enteredTo} = this.state;
    const { containerClassName, manager, className} = this.props;
    const {from, to} = this.getCurrentValue()
    const modifiers = { start: from, end: enteredTo };
    // const disabledDays = { before: from, after: new Date() };
    const selectedDays = [from, { from, to: enteredTo }];
    
    return (
      <div ref={this.containerRef} className={containerClassName + " position-relative"}>
        { manager.renderLabel()}
        {
          <div className="pointer" onClick={this.open}>
            {manager.wrapWithIcon(
              <div className={classNames({"form-control": true, [className]: !!className})} onChange={() => {}} >{this.buttonText()}</div>
            )}
          </div>
        }
        { isOpen &&
          <OutsideAlerter close={() => this.setState({isOpen: false})} style={this.containerStyle()} className="date-range-dropdown">
            {this.props.actions && this.props.actions(this)}
            <DayPicker
              className="DayPicker-range"
              numberOfMonths={2}
              // toMonth={new Date()}
              locale="FR-fr"
              months={this.months}
              weekdaysShort={dayjs.weekdaysShort()}
              selectedDays={selectedDays}
              modifiers={modifiers}
              onDayClick={this.handleDayClick}
              onDayMouseEnter={this.handleDayMouseEnter}
            />
            <div className="text-center">
            {!from && !to && <div className="text-secondary">Choisissez le premier jour de la période.</div>}
            {from && !to && <div className="text-secondary">Choisissez le dernier jour de la période.</div>}
            {from && to && (
              <button className="btn btn-light" onClick={this.onClear}>
                Reset
              </button>
            )}
            </div>
          </OutsideAlerter>
        }
      {this.props.children}
    </div>
    )
  }
}

export default SmartInput2<ISmartDateRangePickerProps, any>(SmartDateRangePicker)