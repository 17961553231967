import capitalize from "lodash/capitalize";
import Model from './Model';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import Building from "./Building";
import ModelAttribute from "./core/ModelAttribute";
import ArrayModelAttribute from "./core/ArrayModelAttribute";

export default class Address extends Model {

  public name: string;
  public phone: string;
  public address: string;
  public addressComp: string;
  public number
  public cp: string;
  public city: string;
  public latitude: number;
  public longitude: number;
  public description: string;
  public isPrincipal: boolean;
  public building: Building;

  static modelUrl = "addresses";
  public modelName = "address";

  public attributes = new AttributesCollection({
    name: new Attribute("name"),
    phone: new Attribute("phone"),
    number: new Attribute("number"),
    address: new Attribute("address"),
    addressComp: new Attribute("addressComp"),
    cp: new Attribute("cp"),
    city: new Attribute("city"),
    latitude: new Attribute("latitude"),
    longitude: new Attribute("longitude"),
    description: new Attribute("description"),
    isPrincipal: new  Attribute("isPrincipal"),
    building: new ModelAttribute("building", Building),
  });
  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }



  isEmpty(): boolean {
    return !this.address && !this.addressComp && !this.cp && !this.city;
  }

  shortAddress(): string {
    let properties = [this.number, this.address, this.city].filter((p) => p);
    return properties.map((p) => capitalize(p)).join(", ");
  }

  firstLine(): string {
    let properties = [this.number, this.address, this.addressComp].filter((p) => p);
    return properties.map((p) => capitalize(p)).join(" ");
  }

  cpAndCity() {
    let properties = [this.cp, this.city].filter((p) => p);
    return properties.map((p) => capitalize(p)).join(" ");
  }

  format() {
    return this.address + ", " + this.cpAndCity();
  }

  fullAddress(): string {
    let properties = [this.number, this.address, this.addressComp, this.cp, this.city].filter((p) => p);
    return properties.join(" ");
  }

  isOk() {
    return this.address && this.city
  }
}