import * as React from 'react';
import SmallLoading from './SmallLoading';

export interface IDetailedLoadingProps {
}

export function DetailedLoading (props: IDetailedLoadingProps) {
  return (
    <div className="row-center py-6">
      <SmallLoading className="primary" />
      <div className="ms-3">Patientez...</div>
    </div>
  );
}
