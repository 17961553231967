import SidebarLayout from '@components/layout/SidebarLayout';
import DOMService from '@services/DOMService';
import * as React from 'react';
import ContactShow from './ContactShow';

export interface IFullContactShowSidebarProps {
  id
}

export default class FullContactShowSidebar extends React.Component<IFullContactShowSidebarProps, any> {


  componentDidMount(): void {
    DOMService.sidebarRef.changeStyle({maxWidth: "100%"})
  }

  componentWillUnmount(): void {
    DOMService.sidebarRef.changeStyleToDefault();
  }

  
  public render() {
    const {id} = this.props
    
    return (
      <SidebarLayout
        title={
          "Détail de l'abonné"
        }
      >
        <ContactShow id={id} />
      </SidebarLayout>
    );
  }
}
