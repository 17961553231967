import React, { Component } from 'react';
import EntityManager from '@services/EntityManager';
import AlertModel from '@models/Alert';
import dayjs from 'dayjs';

interface Alert {
    id: string;
    startAt: string;
    message: string;
}

interface State {
    alerts: AlertModel[];
    showNotifications: boolean;
}

export default class NotificationBell extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            alerts: [],
            showNotifications: false
        };
    }

    componentDidMount() {
        this.loadAlerts();
    }

    async loadAlerts() {
        try {
            let response = await EntityManager.all<AlertModel>(AlertModel);
            if (response.models) {
                const sortedAlerts = response.models.sort((a, b) => dayjs(a.startAt).diff(dayjs(b.startAt)));
                this.setState({ alerts: sortedAlerts });
            }
        } catch (error) {
            console.error("Failed to load alerts", error);
        }
    }

    toggleNotifications = () => {
        this.setState(prevState => ({ showNotifications: !prevState.showNotifications }));
    }

    renderTimeRemaining(startAt: string) {
        const now = dayjs();
        const alertDate = dayjs(startAt);
        const isPassed = alertDate.isBefore(now);
        const isWithin24Hours = alertDate.isBefore(now.add(24, 'hours')) && !isPassed;
        const daysRemaining = alertDate.diff(now, 'days');
        let statusText = "";
        let color = "";

        if (isPassed) {
            statusText = "Dépassé";
            color = "black";
        } else if (isWithin24Hours) {
            statusText = ">24h";
            color = "red";
        } else if (daysRemaining <= 7) {
            statusText = `${daysRemaining} jour(s) restant(s)`;
            color = "orange";
        } else {
            statusText = `${daysRemaining} jour(s) restant(s)`;
            color = ""; // Default color or you can define one
        }

        return { statusText, color };
    }

    render() {
        const { alerts, showNotifications } = this.state;

        // Filtrez pour obtenir uniquement les alertes actives
        const activeAlerts = alerts.filter(alert => alert.active);

        return (
            <div className="notification-bell-container d-flex justify-content-end position-relative" style={{ marginRight: '20px' }}>
                <button onClick={this.toggleNotifications} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                    <img alt="Notification Bell" width="24" height="24" src="https://img.icons8.com/material-rounded/24/appointment-reminders.png" />
                    {activeAlerts.length > 0 && ( // Utilisez activeAlerts.length pour afficher le compte
                        <span className="badge rounded-pill bg-danger" style={{ position: 'absolute', top: '-10px', right: '-10px', fontSize: '0.75em' }}>
                        {activeAlerts.length} {/* Affichez le nombre d'alertes actives */}
                    </span>
                    )}
                </button>
                {showNotifications && (
                    <div className="notifications-dropdown position-absolute bg-white p-3 border rounded shadow" style={{ top: '100%', right: 0, zIndex: 1000, overflowY: 'auto', maxHeight: '400px' }}>
                        {activeAlerts.map((alert) => { // Assurez-vous d'afficher également uniquement les alertes actives ici
                            const { statusText, color } = this.renderTimeRemaining(alert.startAt.toString());
                            return (
                                <div key={alert.id} className="alert alert-secondary" role="alert" style={{ color: color }}>
                                    <strong>Alerte :</strong> {alert.message}
                                    <div><strong>Date :</strong> {dayjs(alert.startAt).format('DD/MM/YYYY HH:mm')}</div>
                                    <div><strong>Statut :</strong> {statusText}</div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}
