import SmartInputText from '@components/input/SmartInputText';
import * as React from 'react';

export interface IPhonesMultipleFormProps {
  phones: string[]
  onChange: (p: string[]) => void
}

export default class PhonesMultipleForm extends React.Component<IPhonesMultipleFormProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      phone: ""
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    let current = this.props.phones || []
    this.props.onChange([...current, this.state.phone]);
    this.setState({phone: ""})
  } 

  public render() {
    const {phone} = this.state
    const {phones, onChange} = this.props
    return (
      <div>
        <div className='form-label'>Téléphones de l'interlocuteur</div>
        <form action="" onSubmit={this.onSubmit}>
          <div className="row-flex mb-3">
            <SmartInputText containerClassName='flex-grow-1' onChange={(e) => this.setState({phone: e})} value={phone} placeholder="Numéro de téléphone"/>
            <button className="btn btn-primary">Ajouter</button>
          </div>
        </form>
        <div>
          {phones?.map((p) => (
            <div className="row-flex py-2">
              <i className='fas fa-phone me-2'></i>
              <div>{p}</div>
              <div onClick={() => onChange(phones.filter(phone => p !== phone))} className='ms-auto small-btn-icon'>
                <i className='fas fa-xmark'></i>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
