import PrestationType from '@models/PrestationType';
import * as React from 'react';
import ModelSelect, { IModelSelectProps } from './ModelSelect';

export default class PrestationTypeSelect extends React.Component<IModelSelectProps> {

  formatOptionLabel = (model: PrestationType) => {
    return <div className="row-flex">
      <div className="me-3 capitalize">
        <div>{model.label}</div>
      </div>
    </div>
  }

  render() {
    return (
      <ModelSelect<PrestationType> {...this.props} formatOptionLabel={this.formatOptionLabel} modelClass={PrestationType} />
    );
  }

}
