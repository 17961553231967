import { sessionBloc } from "@bloc/SessionBloc";
import { SidebarRoute } from "@components/layout/SidebarRouter";
import Sector from "@models/Sector";
import DOMService from "@services/DOMService";
import ModelGrid from "./ModelGrid";

export default class SectorGrid extends ModelGrid {

  public formRoute = SidebarRoute.SectorForm
  static gridName = "customercrm"

  title() {
    return "Secteurs"
  }

  gridConfig = {
    headers: ["Secteur", "Direction", "Nombre d'abonné"],
    body: [
      (sector: Sector) => <b>{sector.name}</b>,
      (sector: Sector) => <div>{sector.customer.name}</div>,
      (sector: Sector) => <div>{sector.contactsCount}</div>,
    //   (sector: Sector) => sector.phone,
    //   (sector: Sector) => <div>
    //   <div>{sector.address}</div>
    //   <div>{sector.cpAndCity()}</div>
    // </div>,
      
    ]
  }

  public rowActions = (model: Sector) => <>
    <div className="small-btn-icon bg-info text-white me-2" onClick={() => DOMService.openSidebar(SidebarRoute.ExportSuiviPanel, {customer: model.customer})}>
      <i className="fas fa-file-export"></i>
    </div>
  </>

  onClickNew = null

  onRowClick = (model) => {
    sessionBloc.push("/crm/sectors/" + model.id)
  };

  onDelete = null
}