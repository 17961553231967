import InputIcon from '@components/common/InputIcon';
import Model from '@models/Model';
import EntityManager from '@services/EntityManager';
import classNames from 'classnames';
import * as React from 'react';

export interface ISmartInputProps {
  children?: any
  model?: Model;
  name?: string;
  value?: any;
  innerRef?
  type?: string;
  label?: string | JSX.Element;
  autoSave?: boolean;
  autoFocus?: boolean;
  checked?: boolean
  maxLength?: number
  minLenght?: number
  required?: boolean;
  className?: string;
  containerClassName?: string
  disabled?: boolean;
  autoComplete?: string
  placeholder?: string;
  id?: string;
  style?: React.CSSProperties;
  leftIcon?: any
  rightIcon?: any
  onChange?: (arg0: any, e: React.ChangeEvent<HTMLElement>, ) => void;
  beforeChange?: (arg0: any) => boolean;
  onUpdate?: () => void;
  onModelUpdated?: (arg0: Model) => void;
  onBlur?: () => void
  onEnter?: () => void
  helpFunction?: (val: string) => string
  manager?:any
  errorMessage?: string
}

export interface ISmartInputState {
}

export default function SmartInput2<P extends ISmartInputProps, S extends ISmartInputState>(Component) {

  return class extends React.Component<P, S> {
  
    componentDidMount() {
      this.props.model?.getAttribute(this.props.name)?.listen(this.up);
    }

    // componentWillUnmount() {
    //   if (this.props.model?.getAttribute(this.props.name).isListened()) this.props.model.onChange(this.up);
    // }

    up = (t) => {
      this.setState({});
    }

    public targetProperty = "value";

    public handleChange = (e) => {
      let value = this.handleEvent(e);
      this.updateState(value, e);
    }

    beforeChange = (value) => {
      return this.props.beforeChange ? this.props.beforeChange(value) : value;
    }
  
    updateState = (value, e) => {
      const { model, name, onChange, helpFunction }: P = this.props;
      if (helpFunction) value = helpFunction(value);
      value = this.beforeChange(value)
      if (model) model[name] = value;
      if (onChange) onChange(value, e);
      if (!model?.getAttribute(name).isListened()) this.setState({});
    }

    getErrorMessage() {
      const { model, name }: P = this.props;
      if (!model) return null;
      
      return model.getAttribute(name)?.getErrorMessage();
    }
  
    handleEvent = (e) => {
      return e.target[this.targetProperty];
    }

    public update = async () => {
      const { name, model, onUpdate, onModelUpdated } = this.props;
      if (onUpdate) onUpdate();
      const updatedModel = (await EntityManager.update(model, {only: [name]})).model;
      if (onModelUpdated) onModelUpdated(updatedModel);
    }
  
    getValue = () => {
      const { model, name }: any = this.props;
      return (model ? model[name] : this.props[this.targetProperty]) ?? "";
    }
  
    getClassName = () => {
      const {containerClassName} = this.props;
      return classNames({"smart-input": true, [containerClassName]: !!containerClassName})
    }

    wrapWithIcon(component, initial: any = {}) {
      return <InputIcon leftIcon={initial.leftIcon || this.props.leftIcon} rightIcon={initial.rightIcon || this.props.rightIcon} >
        {component}
      </InputIcon>
    }

    renderLabel(className = "form-label") {
      if (!this.props.label) return <></>
      return <label className={className} htmlFor={ this.props.id || this.props.name }>{ this.props.label }</label>;
    }

    onKeyDown = (e) => { if (e.key === "Enter" && this.props.onEnter) this.props.onEnter() }

    render() {
      let errorMessage = this.getErrorMessage();
      let props = {
        ...this.props,
        containerClassName: this.getClassName(),
        value: this.getValue(),
        className: classNames({ "is-invalid": !!errorMessage, [this.props.className]: !!this.props.className}),
        errorMessage: errorMessage,
        name: this.props.name,
        autoFocus: this.props.autoFocus,
        label: this.props.label,
        checked: this.props.checked,
        required: this.props.required,
        disabled: this.props.disabled,
        onBlur: this.props.autoSave ? () => this.update() : this.props.onBlur,
        type: this.props.type,
        autoComplete: this.props.autoComplete,
        placeholder: this.props.placeholder,
        id: this.props.id,
        style: this.props.style,
        model: this.props.model
      }
      return <Component manager={this} {...props} />
    }
  }
}
