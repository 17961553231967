import * as React from 'react';
import DOMService from '../../services/DOMService';
import CustomToast from './CustomToast';

export default class BaseToaster extends React.Component<any, any> {

  constructor(props) {
    super(props)
    DOMService.toasterRef = this;
    this.state = {
      toasts: [],
    }
  }

  onClose = (index) => {
    this.setState({toasts: this.state.toasts.filter((_, i) => index !== i)})
  }

  public setToast = (toast: any) => this.setState({toasts: [...this.state.toasts, toast]})

  public render() {
    const { toasts } = this.state;
    if (toasts.length === 0) return <></>
    return (
      <div className="base-toaster">
        {toasts.map((t, i) => <CustomToast key={"toast" + i} onClose={() => this.onClose(i)} text={t} />)}
      </div>
    );
  }
}
