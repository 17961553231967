import KeyyoService from '@services/KeyyoService';
import { CallKeyyo } from './../services/KeyyoService';

export default class KeyyoCall {
  
  public call: CallKeyyo
  public isEmitted: boolean
  public state = "setup";

  constructor(call: CallKeyyo) {
    this.call = call
    this.isEmitted = this.checkEmitted(call);
  }

  get id() { return this.call.callref; }

  checkEmitted(call: CallKeyyo) {
    return [KeyyoService.ownNumber, KeyyoService.landlineNumber].includes(call.caller)
  }

  getOtherNumber() {
    if (this.isEmitted) return this.call.callee;
    else return this.call.caller;
  }

  getRef() {
    return this.call.callref;
  }
}