import * as React from 'react';

export interface IMainLayoutProps {
  title,
  children
  right?
}

export default function MainLayout(props: IMainLayoutProps) {
  return (
    <div className="home">
      <div className="px-0 px-md-5">
        <div className="head-up row-between px-3 px-md-0">
          {typeof props.title==="string" ? <h4 className="text-capitalize">{props.title}</h4> : props.title}
          {props.right}
        </div>
        {props.children}
      </div>
    </div>
  );
}
