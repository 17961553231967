import ArrayModelAttribute from './core/ArrayModelAttribute';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import { PrestationAttribute } from './core/PrestationAttribute';
import Model from "./Model";
import Prestation from "./Prestation";
import ReportComposition from './ReportComposition';

export default class YardPrice extends Model {

  public unitPrice: number
  public yardId: number
  public prestationId: number
  public done: number
  public prestation: Prestation
  public reportCompositions: ReportComposition[]

  static modelUrl = "yard_prices";
  public modelName = "yard_price";

  public attributes = new AttributesCollection({
    unitPrice: new Attribute("unitPrice"),
    yardId: new Attribute("yardId"),
    done: new Attribute("done", {submittable: false}),
    prestation: new PrestationAttribute("prestation", Prestation),
    reportCompositions: new ArrayModelAttribute("reportCompositions", ReportComposition, {submittable: false}),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

  countAsUsual() {
    return this.prestation.countInProgress
  }

}