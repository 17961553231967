import Contact from "@models/Business";
import ContactGrid from "./ContactGrid";

export default class SelectableContactGrid extends ContactGrid {

  static gridName = "selectcontactcrm"

  specificActions = () => <></>

  gridConfig = {
    headers: ["Contact", "Email", "Client"],
    body: [
      (contact: Contact) => <div>
        <b>{contact.getFullName()}</b>
        <div className="text-secondary">{contact.address?.shortAddress()}</div>
      </div>,
      (contact: Contact) => <div>{contact.email}</div>,
      (contact: Contact) => <div>{contact.customer?.name}</div>,
    ]
  }

  onRowClick = (model: Contact) => {
    this.props.onRowClick(model)
  }
}