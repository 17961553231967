import Model from './Model';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";

export default class Statistique extends Model {

    public label: string;
    public value: number;
    public month: number;
    public year: number;

    static modelUrl = "statistiques";
    public modelName = "statistique";

    public attributes = new AttributesCollection({
        label: new Attribute("label"),
        value: new Attribute("value"),
        month: new Attribute("month"),
        year: new Attribute("year")
    });

    constructor(json) {
        super(json)
        this.fillAttributes(json);
    }

    getAttribute(name) {
        return this.attributes[name];
    }

    formatLabel(): string {
        return `${this.label}: ${this.value}`;
    }

}
