import { sessionBloc } from '@bloc/SessionBloc';
import AdminModelsList from '@components/common/AdminModelsList';
import CustomerYardGrid from '@components/grid/CustomerYardGrid';
import MainLayout from '@components/layout/MainLayout';
import Yard from '@models/Yard';
import * as React from 'react';

export interface IClientAreaPageProps {
}

export default class ClientAreaPage extends React.Component<IClientAreaPageProps> {
  public render() {
    return (
      <MainLayout title="Chantiers">
        <div className="app-content">
          <AdminModelsList
            gridClass={CustomerYardGrid}
            options={{ loadOnReady: true, paginate: true, modelClass: Yard, params: {
              scopes: [
                JSON.stringify({name: "shared", param: sessionBloc.getUserId()})
              ]
            }}}
          />
        </div>
      </MainLayout>
    );
  }
}
