import { sessionBloc } from '@bloc/SessionBloc';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import TextIcon from './TextIcon';
import SmartSwitch from "@components/input/SmartSwitch";

export interface IProfileButtonProps {
}

export default class ProfileButton extends React.Component<IProfileButtonProps> {
  public render() {
    return (
      <Dropdown>
          <Dropdown.Toggle as={React.forwardRef(({ children, onClick }: any, ref: React.LegacyRef<HTMLDivElement>) => (
            <div onClick={onClick} ref={ref} className='row-flex pointer'>
              <div className='bg-primary height-6 width-6 me-3 row-center rounded-pill'>
                <i className='fas fa-user text-white'></i>
              </div>
              <div>
                <div className='capitalize fw-bold'>
                  {sessionBloc.user.firstname}
                </div>
                <div className="text-s text-secondary">
                  {sessionBloc.user.role.label}
                </div>
              </div>
            </div>
          ))}/>
          <Dropdown.Menu align="end" className="fade" style={{marginTop: "1rem"}} >
            {(sessionBloc.isAdmin() || sessionBloc.isOrdonnanceur()) &&
              <>
              {!window.location.pathname.startsWith("/crm") &&
                  <Dropdown.Item  >
                    <NavLink className="" to="/crm">
                        <TextIcon leftIcon="fas fa-arrow-up-right-from-square">Ouvrir le CRM</TextIcon>
                    </NavLink>
                  </Dropdown.Item>
              }
              </>
            }
            <Dropdown.Item  >
            <TextIcon leftIcon="fas fa-sign-out"><NavLink className="" to="/logout">Se déconnecter</NavLink></TextIcon>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
    );
  }
}
