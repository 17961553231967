import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import Model from "./Model";
import User from 'src/models/User';
import ModelAttribute from './core/ModelAttribute';
import DateAttribute from './core/DateAttribute';
import ReportComposition from "./ReportComposition";
import ArrayModelAttribute from './core/ArrayModelAttribute';
import RequiredValidator from "./core/form/validators/RequiredValidator";
import dayjs from 'dayjs';
import YardComposition from './YardComposition';

export default class Report extends Model {

  public userId: number
  public user: User
  public date: Date
  public comment: string
  public absent: boolean
  public createdBy: number
  public yardCompositionId: number
  public profitability: any
  public yardComposition: YardComposition
  public reportCompositions: ReportComposition[]

  static modelUrl = "reports";
  public modelName = "report";

  public attributes = new AttributesCollection({
    user: new ModelAttribute("user", User, {validator: new RequiredValidator()}),
    date: new DateAttribute("date", {validator: new RequiredValidator()}),
    comment: new Attribute("comment"),
    profitability: new Attribute("profitability"),
    absent: new Attribute("absent"),
    createdBy: new Attribute("createdBy"),
    yardComposition: new ModelAttribute("yardComposition", YardComposition),
    reportCompositions: new ArrayModelAttribute("reportCompositions", ReportComposition),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }
  
  getDescription() {
    return this.reportCompositions.map((compo) => `${compo.quantity} ${compo.yardPrice.prestation.label}`).join(" - ")
  }

  isBetween({start, end}) {
    return (dayjs(start).isBefore(this.date) || dayjs(start).isSame(this.date)) && (dayjs(end).isAfter(this.date) || dayjs(end).isSame(this.date));
  }
}