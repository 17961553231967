import AdminModelsList from '@components/common/AdminModelsList';
import DocumentGrid from '@components/grid/DocumentGrid';
import * as React from 'react';
import Customer from '@models/Customer';
import MainLayout from '@components/layout/MainLayout';
import FileType from "@models/FileType";
import DOMService from "@services/DOMService";
import {SidebarRoute} from "@components/layout/SidebarRouter";

export interface ICustomerPageProps {
}

export default class CustomerPage extends React.Component<ICustomerPageProps> {
    specificActions = () => <></>

    openFileTypeForm = () => {
        DOMService.openSidebar(
            SidebarRoute.FileTypeForm
        )
    }

    public render() {
        return (
            <MainLayout title="Documents types">
                <div className="app-content">
                    <div className="row-end">
                        {this.specificActions()}
                        {this.openFileTypeForm &&
                            <button onClick={this.openFileTypeForm} className="btn btn-primary">Nouveau</button>}
                    </div>
                    <AdminModelsList
                        gridClass={DocumentGrid}
                        options={{loadOnReady: true, paginate: true, modelClass: FileType}}
                    />
                </div>
            </MainLayout>
        );
    }
}
