import UserSlotCalendar from '@components/calendar/UserSlotCalendar';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import UserSelect from '@components/modelSelect/UserSelect';
import InterventionSlot from '@models/InterventionSlot';
import User from '@models/User';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import * as React from 'react';
import Alert from "@models/Alert";
import dayjs from "dayjs";

export interface ICrmInterventionSlotPageProps {
}

export default class CrmInterventionSlotPage extends React.Component<ICrmInterventionSlotPageProps, {user: User, interventionSlots: InterventionSlot[], alerts: Alert[]}> {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      alerts: [],
      interventionSlots: [],
    }
  }

  calendarRef = React.createRef<UserSlotCalendar>()


  closeAndRefresh() {
    DOMService.closeModal();
  }

  delete = async (slot) => {
    DOMService.setToaster(<div className="p-3">
      <div>Etes vous sur  de vouloir supprimer ce rendez-vous ?</div>
      <div className="row-center mt-3">
        <div className="btn btn-light mx-2">Annuler</div>
        <div onClick={() => {
          EntityManager.delete(slot).then(() => {
            this.closeAndRefresh()
          });
        }} className="btn btn-danger mx-2">Supprimer</div>
      </div>
    </div>);

  }

  reload() {
    DOMService.closeSidebar();
  }

  selectUser(user: User) {
    this.setState({user}, () => {
      if (this.calendarRef.current) {
        this.calendarRef.current.loadData();
      }
    });
  }

  onSlotClick = (event) => {
    if (!("intervention" in event)) {
      DOMService.openSidebar(SidebarRoute.AlertForm, {id: event.id, onSubmit: () => {
          DOMService.closeSidebar();
        }});
    } else {
      DOMService.openSidebar(SidebarRoute.InterventionSlotForm, {id: event.id, onSubmit: () => {
          DOMService.closeSidebar();
        }});
    }
    //DOMService.openSidebar(SidebarRoute.InterventionSlotForm, {id: slot.id, noCalendar: true, onSubmit: (_) => this.reload()})
  }

  public render() {
    const {user} = this.state;
    return (
        <div className="home">
          <div className="px-0 pt-4 px-md-5">
            <div className="app-content">
              <h4 className='fw-bold mb-3'>Calendrier</h4>
              <UserSelect value={user} onChange={(e) => this.selectUser(e)} name="user" placeholder="Choisir un agent" label="Agent" containerClassName="mb-3"/>
              {user && <UserSlotCalendar
                  ref={this.calendarRef}
                  onSlotClick={(slot) => this.onSlotClick(slot)}
                  parentModel={user}
                  context="admin"
                  with={["contact.counter.connectionAddress"]}
                  canEdit
                  withCount
              />}
            </div>
          </div>
        </div>
    );
  }
}