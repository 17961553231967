
  export let colors = {
    "A RAPPELER": "#d17d33",
    "MODULE": "#c833d1",
    "OUVERT": "#33d16c",
    "MANQ": "#d1cc33",
    "A CHARGER": "#93a9cb",
    "RDV": "#d1cc33",
    "EXCLUS": "#3fdfb2",
    "1 APPEL": "#33d0d1",
    "2 APPEL": "#337ad1",
    "CHARGEE": "#3380d1",
    "INJ": "#d17d33",
    "REFUS": "#d13333",
    "IMP": "#c833d1",
    "NE PAS RAPPELER": "#68360b"
  }