import AsyncButton from '@components/button/AsyncButton';
import SmartColorInput from '@components/input/SmartColorInput';
import SmartInputNumber from '@components/input/SmartInputNumber';
import SmartInputText from '@components/input/SmartInputText';
import formModel, { IInjectedFormModelProps } from '@components/logic/FormModel';
import Prestation from '@models/Prestation';
import * as React from 'react';
import SidebarLayout from '../layout/SidebarLayout';
import SmartSwitch from '@components/input/SmartSwitch';
import PrestationTypeSelect from '@components/modelSelect/PrestationTypeSelect';
import OptionService from '@services/OptionService';

export interface IPrestationFormProps extends IInjectedFormModelProps<Prestation> {
}

export interface IPrestationFormState {
}

class PrestationForm extends React.Component<IPrestationFormProps, IPrestationFormState> {
  constructor(props: IPrestationFormProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const {model, submit, submitting} = this.props;
    return (
      <SidebarLayout
        title={model.id ? "Modifier la prestation" : "Création d'une prestation"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={submit} >{model.id ? "Modifier la prestation" : "Créer la prestation"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <SmartInputText model={model} name="label" label="Nom de la prestation" containerClassName="mb-3" />
          <SmartInputNumber model={model} name="price" label="Prix de base" containerClassName="mb-3" />
          <SmartSwitch id="countinprogress" model={model} name="countInProgress" label="Compte dans la progression" containerClassName="mb-3" />
          <SmartInputNumber model={model} name="threshold" label="Seuil de rentabilité" containerClassName="mb-3" />
          { !model.id && <PrestationTypeSelect model={model} name="prestationType" options={OptionService.prestationTypes()} label="Type de prestation" containerClassName="mb-3" />}
          <SmartColorInput model={model} name="color" label="Couleur" containerClassName="mb-3" />
        </div>
      </SidebarLayout>
    );
  }
}

export default formModel<IPrestationFormProps>(PrestationForm, {modelClass: Prestation})