import { sessionBloc } from '@bloc/SessionBloc';
import JobSection from '@components/common/JobSection';
import CustomEmailList from '@components/crm/CustomEmailList';
import OwnPhoneForm from '@components/crm/OwnPhoneForm';
import SmartInputText from '@components/input/SmartInputText';
import MainLayout from '@components/layout/MainLayout';
import * as React from 'react';

export interface ICRMSettingPageProps {
}

export default class CRMSettingPage extends React.Component<ICRMSettingPageProps> {
  public render() {
    return (
      <MainLayout title="Paramètres CRM" >
        <div className="row">
          <div className="col-md-9 col-12">
            <div className="app-content mb-2">
              <CustomEmailList/>
            </div>
          </div>
          <div className="col-md-9 col-12">
            <div className="app-content">
              <h5 className="fw-bold mb-5">Maintenance</h5>
              <JobSection/>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}
