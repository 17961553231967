import TextIcon from '@components/common/TextIcon';
import UserSelect from '@components/modelSelect/UserSelect';
import * as React from 'react';
import Model from '../../models/Model';
import User from '../../models/User';

interface ModelWithUsers extends Model {
  users: User[]
}

export interface IMultiUserSelectProps {
  model: ModelWithUsers
  params?
  placeholder?: string
}

export interface IMultiUserSelectState {
}

export default class MultiUserSelect extends React.Component<IMultiUserSelectProps, IMultiUserSelectState> {

  constructor(props: IMultiUserSelectProps) {
    super(props);

    this.state = {
    }
  }

  delete(option) {
    const {model} = this.props;
    model.users = model.users.filter(o => o.id !== option.id);
    this.setState({})
  }

  onNewRowChange(option) {
    const {model} = this.props;
    model.users.push(option);
    this.setState({})
  }

  public render() {
    const {model, placeholder, params} = this.props;
    return <div>
      <div className="row-flex mb-3">
        <UserSelect filterModels={(models) => models.filter(m => !model.users.find(u => u.id === m.id))} onChange={(option) => this.onNewRowChange(option)} placeholder={placeholder} params={params}/>
      </div>
      <div className="">
        {model.users.map((user: User) => (
          <div key={user.getElementId()} className="yard-user-deletable mb-2 me-2">
            <div className="row-flex">
              <div className="mx-3">
                <TextIcon leftIcon="fas fa-user">{user.getFullName()}</TextIcon>
              </div>
              <div onClick={() => this.delete(user)} className="px-3 clear-btn ms-auto pointer me-n2"><i className="text-secondary far fa-times"></i></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  }
}
