import classNames from 'classnames';
import * as React from 'react';

export interface IPaginationComponentProps {
  lastPage: number
  currentPage: number
  onPageChange: (arg: number) => void
}

export interface IPaginationComponentState {
}

export default React.memo<IPaginationComponentProps>(function PaginationComponent(props: IPaginationComponentProps) {

  const {lastPage, currentPage, onPageChange} = props;
  return (
    <nav>
      <div className="pagination">
        <div className={classNames({"page-item first": true, "disabled": currentPage === 1})}>
          <div className="page-link" onClick={() => onPageChange(1)}>
            <i className="far fa-chevron-double-left"></i>
          </div>
        </div>
        <div className={classNames({"page-item previous": true, "disabled": currentPage === 1})}>
          <div className="page-link" onClick={() => onPageChange(currentPage - 1)}>
            <i className="far fa-chevron-left"></i>
          </div>
        </div>
        <div className={"page-item active"}>
          <div className="page-link">
            {currentPage || 1}
          </div>
        </div>
        <div className={classNames({"page-item next": true, "disabled": currentPage === lastPage})}>
          <div className="page-link" onClick={() => onPageChange(currentPage + 1)}>
            <i className="far fa-chevron-right"></i>
          </div>
        </div>
        <div className={classNames({"page-item last": true, "disabled": currentPage === lastPage})}>
          <div className="page-link" onClick={() => onPageChange(lastPage)}>
            <i className="far fa-chevron-double-right"></i>
          </div>
        </div>
      </div>
    </nav>
  );
})