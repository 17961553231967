import Attribute from './core/Attribute';
import AttributesCollection from "./core/AttributeCollection";
import Model from "./Model";

export default class Interlocutor extends Model {

  public fullname: string;
  public email: string;
  public job: Date
  public contactableId: string;
  public contactableType: string;
  public contactable: Model;
  public phones: string[];

  static modelUrl = "interlocutors";
  public modelName = "interlocutor";

  public attributes = new AttributesCollection({
    fullname: new Attribute("fullname"),
    email: new Attribute("email"),
    job: new Attribute("job"),
    contactableId: new Attribute("contactableId"),
    contactableType: new Attribute("contactableType"),
    phones: new Attribute("phones"),
  });

  constructor(json) {
    super(json)
    if (json.phones) json.phones = JSON.parse(json.phones);
    this.fillAttributes(json);
  }

  public getApiParam(options?: {only?: string[], dirty?: boolean}) {
    let r = this.attributes.getApiParam(this, options);
    if (r.phones) r.phones = JSON.stringify(r.phones);
    return r;
  }

}