import Model from "@models/Model";
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";

export default class DocumentType extends Model {
    public label: string;
    public key: string;

    static modelUrl = "document_types";
    public modelName = "Document_Type";

    public attributes = new AttributesCollection({
        label: new Attribute("label"),
        key: new Attribute("key"),
    });

    constructor(json?: any) {
        super(json);
        this.fillAttributes(json);
    }

    public getDocumentType(): string {
        return `${this.label}`;
    }
}