import React from "react";
import ModelGrid from './ModelGrid';
import FileType from "@models/FileType";
import Contact from "@models/Business";
import {format} from "date-fns";
import dayjs from "dayjs";
import {sessionBloc} from "@bloc/SessionBloc";
import ApiService from "@services/ApiService";
import mime from "mime-types";
import File from '@models/File'


export default class DocumentGrid extends ModelGrid<FileType> {
    static gridName = "DocumentGrid"

    state = {
        fileTypes: []
    };

    componentDidMount() {
    }

    handleDownload = async (model: FileType) => {
        try {
            let fileName: string = model.file.fileName;
            let response = ApiService.get(`downloadByMail/${fileName}`, {
                responseType: 'blob',
            })
                .then(response => {
                    alert('Le fichier a été envoyé par email.');
                })
                .catch(error => {
                    console.error('Error sending file by email:', error);
                    alert('Erreur lors de l\'envoi du fichier par email.');
                });
            ;

        } catch (e) {
        }
    };

    title() {
        return "Dossiers";
    }

    onRowClick = (model: FileType) => {
        this.handleDownload(model);
    }
    gridConfig = {
        headers: ["Nom Dossier", "Date", "Description", "Déposé par"],
        body: [

            (model: FileType) => <div className='text-primary'>{model.name}</div>,
            (model: FileType) => <div>{dayjs(model.date, "YYYY-MM-DDTHH:mm:ssZ").format("D MMMM YYYY")}</div>,
            (model: FileType) => <div>{model.description}</div>,
            (model: FileType) =>
                <div>{model.depositor ? `${model.depositor.firstname} ${model.depositor.lastname}` : 'Non spécifié'}</div>,
        ]
    }

    multipleActions = [
        {
            action: () => console.log("Delete selected file types"),
            label: "Supprimer le(s) type(s) de fichier"
        }
    ];
}
