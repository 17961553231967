import Address from 'src/models/Address';
import Attribute from './core/Attribute';
import AttributesCollection from "./core/AttributeCollection";
import DateAttribute from './core/DateAttribute';
import ModelAttribute from './core/ModelAttribute';
import Model from "./Model";

export default class Counter extends Model {

  public reference: string;
  public yearOfManufacture: string;
  public installationDate: Date
  public place: string;
  public orientation: string;
  public diameter: string;
  public accessibility: string;
  public connectionAddress: Address;

  static modelUrl = "counters";
  public modelName = "counter";

  public attributes = new AttributesCollection({
    reference: new Attribute("reference"),
    yearOfManufacture: new Attribute("yearOfManufacture"),
    installationDate: new DateAttribute("installationDate"),
    place: new Attribute("place"),
    orientation: new Attribute("orientation"),
    diameter: new Attribute("diameter"),
    accessibility: new Attribute("accessibility"),
    connectionAddress: new ModelAttribute("connectionAddress", Address, {submitObject: true}),
  });

  constructor(json) {
    super(json)
    this.fillAttributes(json);
  }

}