import { sessionBloc } from '@bloc/SessionBloc';
import SmartInputText from '@components/input/SmartInputText';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import CustomEmail from '@models/CustomEmail';
import * as React from 'react';
import PreviewTemplate from './PreviewTemplate';

export interface ITemplateWithOverviewProps {
  model: CustomEmail
}

export default class TemplateWithOverview extends React.Component<ITemplateWithOverviewProps> {

  focused = "content"
  object = React.createRef<HTMLInputElement>();
  content = React.createRef<HTMLTextAreaElement>();

  focusField(field) {
    this.focused = field;
    if (this.props.model[this.focused] === undefined) this.props.model[this.focused] = ""
  }

  appendText(text) {
    if (this.props.model[this.focused] === undefined) return;
    const caretPos = this[this.focused].current.selectionStart;
    this.props.model[this.focused] = this.props.model[this.focused].substring(0, caretPos) + text + this.props.model[this.focused].substring(caretPos);
    this[this.focused].current.focus();
    setTimeout(() => {
      this[this.focused].current.selectionStart = caretPos + text.length
      this[this.focused].current.selectionEnd = caretPos + text.length
    })
  }

  public render() {
    const {model} = this.props;
    return (
      <>
        { model.type === "email" && <SmartInputText innerRef={this.object} onFocus={() => {this.focusField("object")}} model={model} name="object" label="Objet" containerClassName="mb-3" />}
        <SmartInputTextarea innerRef={this.content} onFocus={() => {this.focusField("content")}} model={model} name="content" label="Contenu" containerClassName="mb-3" rows={10} />
        <div className='mb-3'>
          <button onClick={() => this.setState({})} className="btn btn-primary btn-sm">Rafraichir l'aperçu</button>
        </div>
        <PreviewTemplate template={model} />
      </>
    );
  }
}
