import Sector from '@models/Sector';
import * as React from 'react';
import ModelSelect, { IModelSelectProps } from './ModelSelect';
import List from "@models/List";

export default class UserListSelect extends React.Component<IModelSelectProps> {

    formatOptionLabel = (model: List) => {
        return <div className="row-flex">
            <div className="me-3 capitalize">
                <div>{model.name}</div>
            </div>
            <div>
                <div className="badge bg-primary">{model.name}</div>
            </div>
        </div>
    }

    render() {
        return (
            <ModelSelect<List> {...this.props} formatOptionLabel={this.formatOptionLabel} modelClass={List} />
        );
    }

}
