import GetX from '@services/GetX';
import * as React from 'react';
import Model from '../../models/Model';
import EntityManager from '../../services/EntityManager';
import SmallLoading from '../common/SmallLoading';

export interface IShowModelProps<M extends Model> extends IInjectedShowModelProps<M> {
  id?: any
}

export interface IInjectedShowModelProps<M extends Model> {
  model: M
  load: () => Promise<void>
}

const showModel = <P extends IShowModelProps<Model> & IInjectedShowModelProps<Model>>(Component: React.ComponentType<P>, options: any) => {

  let component = class extends React.Component<P & IInjectedShowModelProps<Model>, any> {

    constructor(props) {
      super(props);
      this.state = {
        model: null
      }
    }

    componentDidMount() {
      this.load()
      if (options.getRef) GetX.put(options.getRef, this);
    }

    componentDidUpdate(prevProps: Readonly<P & IInjectedShowModelProps<Model>>, prevState: Readonly<any>, snapshot?: any): void {
      if (prevProps.id !== this.props.id) this.load();
    }

    componentWillUnmount(): void {
      if (options.getRef) GetX.destroy(options.getRef);
    }
  
    load = () => {
      EntityManager.show<Model>(options.modelClass, this.props.id).then(r => {
        r.model.manager = this;
        this.setState({model: r.model});
      })
    }

    public render() {
      if (!this.state.model) return <SmallLoading/>
      return (
        <Component {...this.props as P} load={this.load} model={this.state.model} />
      )
    }
  }

  return component

}

export default showModel;