import NoAppLayout from '@components/layout/NoAppLayout';
import * as React from 'react';

export interface IResetPasswordConfirmPageProps {
}

export default class ResetPasswordConfirmPage extends React.PureComponent<IResetPasswordConfirmPageProps, any> {

  public render() {
    return (
      <NoAppLayout>
        <h3 className="fw-bold mb-5 text-center">
          Votre mot de passe a été réinitialisé et vous a été envoyé par email
        </h3>
      </NoAppLayout>
    );
  }
}
