import * as React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import SmartInput2, { ISmartInputProps } from './SmartInput2';

dayjs.locale('fr');  // Définit la localisation française pour dayjs

export interface ISmartDatePickerProps extends ISmartInputProps {
}

function SmartDatePicker (props: ISmartDatePickerProps) {
  const {
    containerClassName,
    manager,
    className,
    autoFocus
  } = props;

  const handleDateChange = (event) => {
    const date = event.target.value;
    if (date) {
      manager.updateState(dayjs(date).toDate());
    }
  };

  return (
    <div className={containerClassName}>
      <input
        aria-label="Date"
        type="date"
        id={props.id}
        required={props.required}
        autoFocus={autoFocus}
        className={className}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        style={props.style}
        lang="fr"  // Définit la langue du calendrier
        value={props.value ? dayjs(props.value).format("YYYY-MM-DD") : ''}
        onChange={handleDateChange}
      />
      { manager.renderLabel() }
      {props.children}
    </div>
  );
}

export default SmartInput2<ISmartDatePickerProps, any>(SmartDatePicker);
