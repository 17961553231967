import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import SmartInputTextarea from '@components/input/SmartInputTextarea';
import SidebarLayout from '@components/layout/SidebarLayout';
import RecordManagerV2 from '@components/logic/RecordManagerV2';
import Contact from '@models/Business';
import CustomEmail from '@models/CustomEmail';
import Customer from '@models/Customer';
import HttpResponse from '@services/apiResponse/HttpResponse';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import GetX from '@services/GetX';
import * as React from 'react';

export interface ISendPageProps {
  contact?: Contact;
  customer?: Customer;
  dateRdv?: Date;
  type: string;
  to?: string;
  contacts?: Contact[];
}

export default class SendPage extends React.Component<ISendPageProps, {submitting: boolean, to: string[], edit: boolean, subject: string, text: string, credit: any}> {

  public hasTyped = false;

  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      edit: false,
      subject: "",
      text: "",
      credit: null,
      to: props.to ? [props.to] : (props.type === "sms" ? [props.contact?.portable] : []),
    }
  }

  async componentDidMount() {
    const { type, contact } = this.props;
    if (type === "email" && contact) {
      this.loadCredit();
      try {
        const contactDetailsResponse = await EntityManager.get(Contact, { path: `${contact.id}/details/receiving-emails` });
        const contactEmails = contactDetailsResponse.data?.map(detail => detail.email) || [];
        this.setState({ to: contactEmails });
      } catch (error) {
        console.error("Error fetching contact emails: ", error);
      }
    }
  }

  async loadCredit() {
    let res = await EntityManager.get(CustomEmail, { path: "plan" });
    this.setState({ credit: res.data });
  }

  onPickEmail(email: CustomEmail) {
    this.setData(email);
    this.setState({
      edit: true,
      subject: email.getRealObjectWith(),
      text: email.getRealContentWith()
    });
  }

  setData(email: CustomEmail) {
    const { contact, customer, dateRdv, contacts } = this.props;
    email.withContacts(contacts || [contact]).withCustomer(customer).withDateRdv(dateRdv);
  }

  async send() {
    const { subject, text, to } = this.state;
    const { type, contact, contacts } = this.props;
    this.setState({ submitting: true });
    let response: HttpResponse;
    let successMessage: string;

    // Flatten and clean email addresses
    const cleanedTo = to.flatMap(emailString => emailString.split(',').map(email => email.trim())).filter(email => email);

    console.log("Sending to emails: ", cleanedTo);

    if (type === "sms") {
        successMessage = "Le sms a bien été envoyé !";
        let params = {
            text, to: cleanedTo.join(','), contactId: contact?.id
        };
        response = await EntityManager.postDirect(Contact, { path: "sms", params });
    } else {
        if (cleanedTo.length > 0) {
            successMessage = "L'email a bien été envoyé !";
            let params = {
                subject, text, to: cleanedTo, contactId: contact?.id || (contacts && contacts[0]?.id)
            };
            response = await EntityManager.postDirect(Contact, { path: "email", params });
        } else {
            successMessage = "Aucun contact à envoyer l'email.";
        }
    }

    if (response.ok) {
        DOMService.closeSidebar();
        if (contact && contact.manager) contact.manager.load();
        if (contacts) contacts.forEach(c => c.manager && c.manager.load());
        setTimeout(() => DOMService.setToaster(successMessage), 500);
        GetX.get("contactExchanges")?.loadModels();
    } else {
        DOMService.setToaster(response.data.result);
    }

    this.setState({ submitting: false });
}


  renderItem(template) {
    return (
      <div onClick={() => this.onPickEmail(template)} className='mb-3 border rounded-2 pointer py-3 px-4'>
        <div className="row-flex">
          <i className='fas fa-envelope me-4 fa-2x text-secondary'></i>
          <div>
            <div>{template.title}</div>
            <div className='text-secondary'>{template.object}</div>
            {(template.needRdvDate() && !this.props.dateRdv) && <div className='text-danger text-s'>Il est conseillé de sélectionner un rendez-vous afin d'utiliser ce template.</div>}
          </div>
        </div>
      </div>
    );
  }

  public render() {
    const { submitting, subject, text, to, edit, credit } = this.state;
    const { type } = this.props;
    return (
      <SidebarLayout
        title={"Envoyer un " + (type)}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={() => this.send()}>Envoyer</AsyncButton>}
      >
        <div className="fadeInDown">
          <SmartInputText value={to.join(', ')} onChange={(to) => this.setState({ to: to.split(', ').map(email => email.trim()) })} label="Destinataire" containerClassName='mb-3' />
          {edit && (
            <div className="mb-4">
              <div>
                {type === "email" && <SmartInputText value={subject} onChange={(subject) => this.setState({ subject })} label="Objet" containerClassName='mb-3' />}
                <SmartInputTextarea value={text} onChange={(text) => this.setState({ text })} label="Corps" containerClassName='mb-3' rows={12} />
              </div>
            </div>
          )}
          <div className='mb-3 fw-bold'>Vos modèles</div>
          {this.renderItem(new CustomEmail({ type, title: "Saisie libre" }))}
          <RecordManagerV2<CustomEmail> options={{ modelClass: CustomEmail, loadOnReady: true, paginate: true, params: { type } }}>
            {(manager, props) => (
              <div>
                {props.models?.map((m) => {
                  return this.renderItem(m);
                })}
              </div>
            )}
          </RecordManagerV2>
        </div>
      </SidebarLayout>
    );
  }
}
