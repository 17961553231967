import CallDialogBody from '@components/crm/CallDialogBody';
import KeyyoCall from '@core/KeyyoCall';
import DOMService from '@services/DOMService';
import * as React from 'react';

export interface ICallDialogProps {
}

export default class CallDialog extends React.Component<ICallDialogProps, {calls: KeyyoCall[]}> {
  constructor(props) {
    super(props)
    DOMService.callDialogRef = this;
    this.state = {
      calls: []
      // calls: [new KeyyoCall({callref: "sdfsdf", callee: "33683396525", caller: "33683399525"}), new KeyyoCall({callref: "sdfsdf", callee: "33683336525", caller: "33683749525"})]
    }
  }

  close = (index) => {
    this.setState({calls: []})
  }

  updateState(id, state) {
    let index = this.state.calls.findIndex((c) => c.id === id);
    let newState = this.state;
    newState.calls[index].state = state;
    this.setState(newState)
  }

  public release = (id) => {
    let index = this.state.calls.findIndex((c) => c.id === id);
    let newState = this.state;
    newState.calls.splice(index, 1);
    this.setState(newState)
  }

  public setDialog = (call: KeyyoCall) => this.setState({calls: [...this.state.calls, call]})

  public render() {
    const { calls } = this.state;
    if (calls.length === 0) return <></>
    return (
      <div className="call-dialog">
        {calls.map(call => (
          <CallDialogBody call={call} />
        ))}
      </div>
    );
  }
}
