import Model from "@models/Model";
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";

export default class File extends Model {
    public fileName: string;
    public filePath: string;

    static modelUrl = "files";
    public modelName = "file";

    public attributes = new AttributesCollection({
    fileName: new Attribute("fileName"),
    filePath: new Attribute("filePath"),

    });

    constructor(json?: any) {
        super(json);
        this.fillAttributes(json);
    };

}