import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import Customer from '@models/Customer';
import Sector from '@models/Sector';
import * as React from 'react';
import SidebarLayout from '../layout/SidebarLayout';

export interface ISectorFormProps extends IInjectedFormModelProps<Customer> {
}

export interface ISectorFormState {
}

class SectorForm extends React.Component<ISectorFormProps & IFormModelProps<Customer>, ISectorFormState> {
  constructor(props: ISectorFormProps & IFormModelProps<Customer>) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const {model, submit, submitting, id} = this.props;
    
    return (
      <SidebarLayout
        title={id ? "Modification d'un secteur" : "Création d'un secteur"}
        bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={submit} >{id ? "Modifier le secteur" : "Créer le secteur"}</AsyncButton>}
      >
        <div className="fadeInDown">
          <div className="row">
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="name" label="Nom" containerClassName="mb-3" />
            </div>
            <div className="col-md-6 col-12">
              <SmartInputText model={model} name="senderEmail" label="Expéditeur" placeholder='Adresse email' containerClassName="mb-3" />
            </div>
          </div>
        </div>
      </SidebarLayout>
    );
  }
}

export default formModel<ISectorFormProps>(SectorForm, {modelClass: Sector})