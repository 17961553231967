import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend, ChartOptions,
} from 'chart.js';
import ApiService from "@services/ApiService";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                color: '#537798',
            },
        },
    },
    scales: {
        x: {
            ticks: {
                color: '#537798',
            },
        },
        y: {
            ticks: {
                color: '#537798',
            },
        }
    },
};

export default class StatFinance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedYear: new Date().getFullYear(),
            data: {
                labels: [],
                datasets: [],
            },
        };
    }

    componentDidMount() {
        // @ts-ignore
        this.fetchData(this.state.selectedYear);
    }

    componentDidUpdate(prevProps, prevState) {
        // @ts-ignore
        if (prevState.selectedYear !== this.state.selectedYear) {
            // @ts-ignore
            this.fetchData(this.state.selectedYear);
        }
    }

    fetchData = async (year) => {
        try {
            const dataSubvention = await ApiService.get(`statistiques/Subventions/${year}`);
            const dataBudget = await ApiService.get(`statistiques/Budget/${year}`);


            this.processData(dataSubvention, dataBudget);
        } catch (error) {
            console.error("Erreur lors de la récupération des données statistiques :", error);
        }
    }


    processData(dataSubventionResponse, dataBudgetResponse) {
        const labels = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

        const dataSubvention = dataSubventionResponse.data ?? [];
        const dataBudget = dataBudgetResponse.data ?? [];

        const formattedDataSubvention = dataSubvention.map(stat => ({
            ...stat,
            value: parseFloat(stat.value),
            month: stat.month + 1
        }));

        const formattedDataBudget = dataBudget.map(stat => ({
            ...stat,
            value: parseFloat(stat.value),
            month: stat.month + 1
        }));

        const subventionData = labels.map((_, index) => {
            const stat = formattedDataSubvention.find(stat => stat.month === index + 1);
            return stat ? stat.value : 0;
        });

        const budgetData = labels.map((_, index) => {
            const stat = formattedDataBudget.find(stat => stat.month === index + 1);
            return stat ? stat.value : 0;
        });

        this.setState({
            data: {
                labels,
                datasets: [
                    {
                        label: 'Subventions',
                        data: subventionData,
                        backgroundColor: '#71860d',
                    },
                    {
                        label: 'Budget',
                        data: budgetData,
                        backgroundColor: '#a2c013',
                    },
                ],
            },
        });
    }

    handleYearChange = (offset) => {
        // @ts-ignore
        this.setState((prevState) => ({
            // @ts-ignore
            selectedYear: prevState.selectedYear + offset,
        }));
    };

    render() {
        // @ts-ignore
        const { selectedYear, data } = this.state;
        return (
            <div>
                <h3 className={'text-center fs-4'}>Statistiques financières</h3>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '20px'
                }}>
                    <button className="bg-transparent border-0" onClick={() => this.handleYearChange(-1)}>
                        <span className='fc-icon fa-2x fc-icon-chevron-left text-primary'></span>
                    </button>
                    <span>{selectedYear}</span>
                    <button className="bg-transparent border-0" onClick={() => this.handleYearChange(1)}>
                        <span className='fc-icon fa-2x fc-icon-chevron-right text-primary'></span>
                    </button>
                </div>
                <Bar options={options} data={data}/>
            </div>
        );
    }
}
