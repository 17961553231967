import AsyncButton from '@components/button/AsyncButton';
import UserSlotCalendar from '@components/calendar/UserSlotCalendar';
import SmartDatePicker from '@components/input/SmartDatePicker';
import SmartSelect from '@components/input/SmartSelect';
import SmartSwitch from '@components/input/SmartSwitch';
import SidebarLayout from '@components/layout/SidebarLayout';
import formModel, {IInjectedFormModelProps} from '@components/logic/FormModel';
import UserSelect from '@components/modelSelect/UserSelect';
import Contact from '@models/Business';
import InterventionSlot from '@models/InterventionSlot';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import dayjs from 'dayjs';
import * as React from 'react';
import {sessionBloc} from "@bloc/SessionBloc";
import SmartInputText from "@components/input/SmartInputText";

export interface IInterventionSlotFormProps extends IInjectedFormModelProps<InterventionSlot> {
    id?
    noCalendar
    contactId: number,
    parentModel
}

class InterventionSlotForm extends React.Component<IInterventionSlotFormProps, { alertUser: boolean }> {

    constructor(props) {
        super(props);
        this.state = {
            alertUser: null
        }
        props.model.createdById = sessionBloc.getUserId()
    }

    componentDidMount() {
        if (this.props.contactId) {
            this.props.model.contactId = this.props.contactId;
        }
    }

    onSelect(start: Date, end: Date) {
        const {model} = this.props;
        model.startAt = start
        model.endAt = end
        this.setState({})

    }

    async submit() {
        const {model} = this.props;
        await this.props.submit();
        if (this.state.alertUser) {
            let text = "Nouveau rendez-vous le " + dayjs(model.startAt).format("DD/MM/YYYY HH:mm") + " à " + model.contact.getFullName() + " chez " + model.contact.getFullName();
            let params = {
                text, to: model.user.phone, contactId: model.contact.id
            }
            await EntityManager.postDirect(Contact)
        }
    }

    eventDetail = ({event}) => {
        return <div className="p-1">
            <div className="fw-bold">{event.title}</div>
            {/* <div className="mb-1">{slot?.contact?.getFullName()}</div> */}
        </div>;
    };

    delete = async () => {
        DOMService.setToaster(<div className="p-3">
            <div>Etes vous sur de vouloir supprimer ce rendez-vous ?</div>
            <div className="row-center mt-3">
                <div className="btn btn-light mx-2">Annuler</div>
                <div onClick={() => {
                    EntityManager.delete(this.props.model).then(() => {
                        DOMService.closeModal();
                        this.props.onSubmit(this.props.model);
                    });
                }} className="btn btn-danger mx-2">Supprimer
                </div>
            </div>
        </div>);

    }

    onDateChange(e: Date) {
        const {model} = this.props;

        if (!model.startAt) model.startAt = new Date(e);
        if (!model.endAt) model.endAt = new Date(e);

        let startAt = new Date(e);
        let endAt = new Date(e);

        startAt.setHours(model.startAt.getHours(), model.startAt.getMinutes());
        endAt.setHours(model.endAt.getHours(), model.endAt.getMinutes());

        model.startAt = startAt;
        model.endAt = endAt;

        this.setState({});
    }

    changeTime(attr, type, e) {
        const {model} = this.props;
        if (type === "hour") model[attr].setHours(e.id)
        else model[attr].setMinutes(e.id)
        model[attr] = model[attr];
    }

    public render() {
        const {model, submitting, id, noCalendar} = this.props;
        const {alertUser} = this.state;
        const isAgentSelected = model.user !== undefined;
        return (
            <SidebarLayout
                title={id ? "Modification d'un rendez-vous" : "Création d'un rendez-vous"}
                bottomArea={
                    <AsyncButton
                        className="btn btn-primary w-100 btn-lg"
                        loading={submitting}
                        onClick={() => this.submit()}
                        disabled={!isAgentSelected}
                    >
                        {id ? "Modifier le rendez-vous" : "Créer le rendez-vous"}
                    </AsyncButton>
                }
                action={<div>
                    {id && <div onClick={this.delete} className="my-n2 small-btn-icon">
                        <i className="fas fa-trash"></i>
                    </div>}
                </div>}
            >
                <div className="fadeInDown">
                    <UserSelect model={model} name="user" placeholder="Choisir un agent" label="Agent"
                                containerClassName="mb-3"/>
                    <SmartDatePicker label="Date" value={model?.startAt} onChange={(e) => this.onDateChange(e)}
                                     containerClassName="w-100 mb-3"/>
                  <SmartInputText model={model} name="comment" label="Commentaire" containerClassName="mb-3" />
                    <div className="row-flex mb-3">
                        <SmartSelect label="Heure début" onChange={(e) => this.changeTime("startAt", "hour", e)}
                                     containerClassName='me-2'
                                     options={[8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22].map(e => ({
                                         label: e,
                                         id: e
                                     }))}
                                     value={{label: model?.startAt?.getHours(), value: model?.startAt?.getHours()}}/>
                        <SmartSelect label="Minute début" onChange={(e) => this.changeTime("startAt", "minute", e)}
                                     containerClassName='me-6' options={[
                            {label: "00", id: 0},
                            {label: "15", id: 15},
                            {label: "30", id: 30},
                            {label: "45", id: 45}
                        ]} value={{label: model.startAt?.getMinutes(), value: model.startAt?.getMinutes()}}/>
                        <SmartSelect label="Heure fin" onChange={(e) => this.changeTime("endAt", "hour", e)}
                                     containerClassName='me-2'
                                     options={[8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22].map(e => ({
                                         label: e,
                                         id: e
                                     }))} value={{label: model.endAt?.getHours(), value: model.endAt?.getHours()}}/>
                        <SmartSelect label="Minute fin" onChange={(e) => this.changeTime("endAt", "minute", e)}
                                     options={[
                                         {label: "00", id: 0},
                                         {label: "15", id: 15},
                                         {label: "30", id: 30},
                                         {label: "45", id: 45}
                                     ]} value={{label: model.endAt?.getMinutes(), value: model.endAt?.getMinutes()}}/>
                    </div>
                    <hr/>
                    {(model.user && !noCalendar) &&                   // @ts-ignore
                        <UserSlotCalendar
                            add={model.startAt ? model : null}
                            parentModel={model.user}
                            eventContent={this.eventDetail}
                            canEdit
                            eventsInBackground
                            params={{_with: ["contact"]}}
                            onSelect={(_, __) => this.onSelect(_, __)}
                        />}
                </div>
            </SidebarLayout>
        );
    }
}

export default formModel<IInterventionSlotFormProps>(InterventionSlotForm, {
    modelClass: InterventionSlot,
    refreshOnFieldsChange: ["user", "startAt", "endAt"]
})