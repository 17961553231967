import { sessionBloc } from '@bloc/SessionBloc';
import AdminModelsList from '@components/common/AdminModelsList';
import FailedInterventionGrid from '@components/grid/FailedInterventionGrid';
import Contact from '@models/Business';
import * as React from 'react';

export interface IResumeSectorProps {
  id
}

export default class ResumeSector extends React.Component<IResumeSectorProps> {
  public render() {
    return (
      <div className="home">
        <div className="px-0 pt-3 px-md-5">
          <div className="app-content">
            <div className="row-flex mb-3">
              <i onClick={() => sessionBloc.pop()} className="fas fa-chevron-left pe-4 pointer"></i>
              <h2 className='mb-0 text-capitalize'>
                <span className='me-3 fw-bold'>Infructueux</span>
              </h2>
            </div>
            <AdminModelsList
              gridClass={FailedInterventionGrid}
              key="InterventionGrid"
              options={{ modelClass: Contact, loadOnReady: true, paginate: true, params: {scope: "failed", sector_id: this.props.id} }}
            />
          </div>
        </div>
      </div>
    );
  }
}
