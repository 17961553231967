import Contact from '@models/Business';
import User from '@models/User';
import Model from "./Model";
import FileAttribute from './core/FileAttribute';
import Attribute from "./core/Attribute";
import File from '@models/File'
import AttributesCollection from "./core/AttributeCollection";
import DateAttribute from './core/DateAttribute';
import ModelAttribute from './core/ModelAttribute';
import DocumentType from "@models/DocumentType";

export default class Document extends Model {
    public file: File;
    public title: string;
    public uploadedAt: string;
    public uploadedBy: User;
    public contact: Contact;
    public documentType: DocumentType;
    public comment: string;
    static modelUrl = "documents";
    public modelName = "document";

    public attributes = new AttributesCollection({
        title: new Attribute("title"),
        file: new ModelAttribute("file", File),
        uploadedAt: new DateAttribute("uploadedAt"),
        uploadedBy: new ModelAttribute("uploadedBy", User),
        contact: new ModelAttribute("contact", Contact),
        documentType: new ModelAttribute("documentType", DocumentType),
        comment: new Attribute("comment")

    });

    constructor(json?: any) {
        super(json);
        this.fillAttributes(json);
    }

}
