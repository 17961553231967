import React from 'react';
import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import formModel, {IFormModelProps, IInjectedFormModelProps} from '@components/logic/FormModel';
import Statistique from '@models/Statistique';
import SidebarLayout from '../layout/SidebarLayout';
import ApiService from "@services/ApiService";
import SmartSelect from "@components/input/SmartSelect";
import SmartInputNumber from "@components/input/SmartInputNumber";

const monthOptions = [
    {value: 0, label: 'Janvier'},
    {value: 1, label: 'Février'},
    {value: 2, label: 'Mars'},
    {value: 3, label: 'Avril'},
    {value: 4, label: 'Mai'},
    {value: 5, label: 'Juin'},
    {value: 6, label: 'Juillet'},
    {value: 7, label: 'Août'},
    {value: 8, label: 'Septembre'},
    {value: 9, label: 'Octobre'},
    {value: 10, label: 'Novembre'},
    {value: 11, label: 'Décembre'},
];



export interface IStatistiqueFormProps extends IInjectedFormModelProps<Statistique> {
    parentModel: Statistique;
    fetchData: any;
}

export interface IStatistiqueFormState {
    entryExists: boolean;
    label: string;
    value: number;
    month: number;
    year: number;
    id?: string;
}

class StatistiqueForm extends React.Component<IStatistiqueFormProps & IFormModelProps<Statistique>, IStatistiqueFormState> {
    constructor(props: IStatistiqueFormProps & IFormModelProps<Statistique>) {
        super(props);
        this.state = {
            entryExists: false,
            label: '',
            value: 0,
            month: 0,
            year: new Date().getFullYear(),
            id: undefined,
        };
    }

    async componentDidMount() {
    }

    labelOptions = [
        { value: 'Industrie', label: 'Industrie' },
        { value: 'Innovation', label: 'Innovation' },
        { value: 'Agricole', label: 'Agricole' },
        { value: 'AideCommerce', label: 'Aide au commerce' },
        { value: 'AideCommerceCentreVille', label: 'Aide au commerce centre-ville' },
        { value: 'Subventions', label: 'Subvention' },
        { value: 'Budget', label: 'Budget' },
    ];

    componentDidUpdate(prevProps, prevState) {
        if (this.state.label !== prevState.label || this.state.month !== prevState.month || this.state.year !== prevState.year) {
            this.checkEntryExists();
        }
    }

    handleLabelChange = (option) => {
        this.setState({label: option.value, entryExists: false, id: undefined});
    };

    handleMonthChange = (option) => {
        this.setState({month: option.value, entryExists: false, id: undefined});
    };

    handleYearChange = (newValue) => {
        this.setState({year: parseInt(newValue, 10), entryExists: false, id: undefined});
    };

    checkEntryExists = async () => {
        const {label, month, year} = this.state;
        if (!label || month < 0 || !year) {

            return;
        }

        try {
            const response = await ApiService.get(`statistiques/check/${encodeURIComponent(label)}/${month}/${year}`);
            if (response.data.exists) {
                this.setState({
                    entryExists: true,
                    value: response.data.value,
                    id: response.data.id, // Assuming the response includes the ID
                });
            } else {
                this.setState({entryExists: false, id: undefined, value: 0});
            }
        } catch (error) {
            console.error("Erreur lors de la vérification de l'existence:", error);
        }
    };

    submit = async () => {
        const {label, value, month, year, id, entryExists} = this.state;

        const data = {label, month, year, value};
        try {

            if (entryExists) {
                if (!window.confirm("Cette statistique existe déjà. Voulez-vous vraiment la modifier ?")){
                    return false;
                }
            }
            await ApiService.post('statistiques/upsert', data);
            alert("Statistique créée avec succès.");

            this.setState({
                entryExists: false,
                id: undefined,
                label: '',
                month: 0,
                year: new Date().getFullYear(),
                value: 0
            });
            this.props.fetchData(year);
            window.location.reload()
        } catch (error) {
            console.error("Erreur lors de l'envoi des données :", error);
        }
    };

    public render() {
        const {submitting} = this.props;
        const {entryExists, label, value, month, year} = this.state;

        return (
            <SidebarLayout
                title={entryExists ? "La statistique existe déjà" : "Ajouter une nouvelle statistique"}
                bottomArea={
                    <>
                        {(
                            <button onClick={this.submit} className="btn btn-primary w-100 mt-2"
                                    disabled={submitting || !label || month === undefined || !year}>
                                {entryExists ? "Modifer" : "Ajouter"}
                            </button>
                        )}
                    </>
                }
            >
                <div className="fadeInDown">
                    <SmartSelect
                        options={this.labelOptions}
                        value={this.labelOptions.find(option => option.value === label)}
                        onChange={this.handleLabelChange}
                        label="Label"
                        containerClassName="mb-3"
                    />

                    <SmartSelect
                        options={monthOptions}
                        value={monthOptions.find(option => option.value === month)}
                        onChange={this.handleMonthChange}
                        label="Mois"
                        containerClassName="mb-3"
                    />
                    <SmartInputNumber
                        value={year}
                        onChange={(newValue) => this.handleYearChange(newValue)}
                        label="Année"
                        containerClassName="mb-3"
                    />
                    {(label && month !== undefined && year) && (
                        <>
                            <p className="text-warning">
                                {entryExists && "Cette statistique existe déjà. Attention toute modifications supprimera l'ancienne données."}
                            </p>
                            <SmartInputNumber
                                value={value}
                                onChange={(newValue) => this.setState({value: parseFloat(newValue) || 0})}
                                label="Valeur"
                                containerClassName="mb-3"
                            />
                        </>
                    )}
                </div>
            </SidebarLayout>
        )
            ;
    }
}

export default formModel<IStatistiqueFormProps>(StatistiqueForm, {modelClass: Statistique});