import classNames from 'classnames';
import * as React from 'react';

export interface ICustomToastProps {
  text: string
  onClose: () => void
}

export interface ICustomToastState {
  closing: boolean
}

export default class CustomToast extends React.Component<ICustomToastProps, ICustomToastState> {

  interval;

  constructor(props: ICustomToastProps) {
    super(props);

    this.state = {
      closing: false
    }
  }

  componentDidMount() {
    
    setTimeout(() => this.setState({}), 100);
    setTimeout(() => this.closing(), 3000);
  }

  closing() {
    this.setState({closing: true}, () => {
      setTimeout(() => this.props.onClose(), 1000);
    })
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  public render() {
    const {text} = this.props;
    return (
      <div className={classNames({"toast overflow-hidden": true, "fadeOutUp": this.state.closing})}>
        <div className="row-between toast-body">
          <div>
            {text}
          </div>
          <div style={{marginRight: "-1rem"}} className="size-5 ms-3 row-center pointer" onClick={() => this.closing()}>
            <i className={"far fa-times"}></i>
          </div>
        </div>
      </div>
    );
  }
}
