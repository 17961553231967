import SmallLoading from '@components/common/SmallLoading';
import SmartSelect from '@components/input/SmartSelect';
import OptionService from '@services/OptionService';
import * as React from 'react';

export interface ISectorStatHeaderProps {
  filterQuartier
  quartier
  sectorId
  title
  loading
}

export default class SectorStatHeader extends React.Component<ISectorStatHeaderProps> {

  public quartiersOption = []
  async componentDidMount() {
    const {sectorId} = this.props;
    await OptionService.loadQuartier(sectorId);
    this.quartiersOption = [
      {label: "Tous", id: null},
      ...OptionService.quartiers[this.props.sectorId].map((q) => ({label: q, id: q}))
    ]
    this.setState({})
  }
  
  public render() {
    const {filterQuartier, title, quartier, loading} = this.props;

    return (
      <div className="row-between">
        <div className="row-flex">
          <h4 className="fw-bold mb-0 me-3">{title}</h4>
          {loading && <SmallLoading className='primary' />}
        </div>
        <div  style={{width: "300px"}}>
          <SmartSelect options={this.quartiersOption} value={ quartier ? {label: quartier, id: quartier} : null} onChange={ (e) => {
            filterQuartier(e.id)
          } }  placeholder={"Par quartier"} />
        </div>
      </div>
    );
  }
}
