import * as React from 'react';

export interface ISmallLoadingProps {
  className?: string
}

export default function SmallLoading (props: ISmallLoadingProps) {
  return (
    <div className="loading-2">
      <div className="spinner">
        <div className="circle circle-1">
          <div className={"circle-inner" + (" " + props.className || "")}></div>
        </div>
        <div className="circle circle-2">
          <div className={"circle-inner" + (" " + props.className || "")}></div>
        </div>
      </div>
    </div>
  );
}
