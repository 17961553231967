import SmartInputNumber from '@components/input/SmartInputNumber';
import Yard from '@models/Yard';
import * as React from 'react';
import YardComposition from '@models/YardComposition';
import { sessionBloc } from '@bloc/SessionBloc';

export interface IYardSubFormProps {
  yard: Yard
}

export default class YardSubForm extends React.PureComponent<IYardSubFormProps> {

  onChangeComposition = (compo: YardComposition) => {
    const {yard} = this.props
    if (!compo.quantity) return;
    let yardPrices = yard.yardPrices.filter(yp => yp.prestation.prestationTypeId === compo.prestationTypeId);
    yardPrices.forEach(yardPrice => {
      if (!yardPrice.unitPrice) {
        yardPrice.unitPrice = yardPrice.prestation.price;
      }
    })
  }

  public render() {
    const {yard} = this.props
    return (
      <div className="">
        <div className="fw-bold mb-3 mt-5">{"Prestations à réaliser"}</div>
        {yard.yardCompositions.map((composition) => (
          <div key={composition.getElementId()} className="row-flex mb-2">
            <div>{composition.prestationType.label}</div>
            <SmartInputNumber onBlur={() => this.onChangeComposition(composition)} model={composition} name="quantity" placeholder="Quantité" containerClassName="ms-auto" min={0} />
          </div>
        ))}
        {sessionBloc.isManager() && <>
          <div className="fw-bold mb-3 mt-5">{"Prix des prestations"}</div>
          {yard.yardPrices.map((price) => (
            <div key={price.getElementId()} className="row-flex mb-2">
              <div>{price.prestation.label}</div>
              <SmartInputNumber model={price} name="unitPrice" placeholder="Prix" containerClassName="ms-auto" min={0} />
            </div>
          ))}
        </>
        }
      </div>
    );
  }
}
