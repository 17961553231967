import * as React from 'react';
import classNames from 'classnames';

export interface IInputIconProps {
  leftIcon?: any
  rightIcon?: any
  children: any
}

export default function InputIcon ({leftIcon = null, rightIcon = null, children}: IInputIconProps) {
  return (
    <div className={classNames({
      "icon-input": !!leftIcon || !!rightIcon,
      "icon-input-left": leftIcon,
      "icon-input-right": rightIcon
    })}
    >
      {leftIcon && <div className="icon left-icon">{leftIcon}</div>}
        {children}
      {rightIcon && <div className="icon right-icon">{rightIcon}</div>}
    </div>
  );
}
