import React, { Component } from 'react';
import AsyncButton from '@components/button/AsyncButton';
import formModel, { IFormModelProps, IInjectedFormModelProps } from '@components/logic/FormModel';
import Document from '@models/Document';
import SidebarLayout from '../layout/SidebarLayout';
import SmartInputTextarea from "@components/input/SmartInputTextarea";
import SmartOptionSelect from '@components/input/SmartOptionSelect';
import DocumentTypeService from '@services/DocumentTypesService';
import axios from 'axios';
import ApiService from "@services/ApiService";
import DOMService from "@services/DOMService";
import SmartDatePicker from '@components/input/SmartDatePicker';

export interface IFileFormProps extends IInjectedFormModelProps<Document> {
  contactId: number,
}

export interface IFileFormState {
  file: File | null;
  selectedDocumentTypeName: string;
  selectedDocumentTypeId: number;
  documentTypeLoaded: boolean;
  dateStart: Date,
  dateEnd: Date,
}

class FileForm extends Component<IFileFormProps & IFormModelProps<Document>, IFileFormState> {
  constructor(props: IFileFormProps & IFormModelProps<Document>) {
    super(props);
    this.state = {
      file: null,
      selectedDocumentTypeName: '',
      selectedDocumentTypeId: null,
      documentTypeLoaded: false,
      dateStart: new Date(),
      dateEnd: new Date(),
    };
  }

  async componentDidMount() {
    await DocumentTypeService.load(); // Charge les types de documents
    this.setState({documentTypeLoaded: true})
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ file });
    }
  }

  handleDocumentTypeChange = (selectedOption) => {
    this.setState({
      selectedDocumentTypeName: selectedOption.label,
      selectedDocumentTypeId: selectedOption.value,
    });
  };

  async submit(event) {
    event.preventDefault();
    const { file, selectedDocumentTypeId, dateEnd, dateStart } = this.state;
    const { model } = this.props;
    const contactId = this.props.contactId;
    let fileId = null;

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('contact_id', contactId.toString());

      try {
        const fileUploadResponse = await ApiService.post('uploads/files', formData);
        fileId = fileUploadResponse.data.file.id;
      } catch (error) {
        console.error('Error during file upload:', error.response ? error.response.data : error);
        return;
      }
    }

    const documentData = {
      title: model.title,
      comment: model.comment,
      document_type_id: selectedDocumentTypeId,
      file_id: fileId,
      contact_id: contactId,
      date_start: dateStart,
      date_end: dateEnd
    };

    try {
      const documentResponse = await ApiService.post('documents/create', documentData);
      DOMService.closeSidebar();
      setTimeout(() => window.location.reload(), 500);
    } catch (error) {
      console.error('Error during document submission:', error.response ? error.response.data : error);
    }
  }


  render() {
    const { submitting } = this.props;
    const { file, selectedDocumentTypeName } = this.state;


    return (
        <SidebarLayout
            title={"Ajout d'un fichier"}
            bottomArea={<AsyncButton className="btn btn-primary w-100 btn-lg" loading={submitting} onClick={(e) => this.submit(e)}>Ajouter un fichier</AsyncButton>
            }
        >
          <div className="fadeInDown">
            <div className="mb-3">
              <label htmlFor="file-upload" className="form-label">Choisir un fichier</label>
              <input type="file" className="form-control" id="file-upload" onChange={this.handleFileChange}/>
              {file && <div className="mt-2">Fichier joint : {file.name}</div>}
            </div>
            <SmartInputTextarea model={this.props.model} name="title" label="titre" containerClassName="mb-3 " />
            <SmartInputTextarea model={this.props.model} name="comment" label="Commentaire" containerClassName="mb-3 " />
            {this.state.documentTypeLoaded &&
              // @ts-ignore
              <SmartOptionSelect model={this.props.model} name="documentType" label="Type de document" options={DocumentTypeService.getAll().map(dt => ({
                value: dt.value,
                label: dt.label,
              }))} onChange={this.handleDocumentTypeChange} value={selectedDocumentTypeName} containerClassName="mb-3"/>
            }
            {this.state.selectedDocumentTypeId === 5 && 
            <div>
              <SmartDatePicker value={this.state.dateStart}  label="Date de réception"
                                containerClassName="mb-3" onChange={(dateStart) => {this.setState({dateStart})}} />
              <SmartDatePicker value={this.state.dateEnd} label="Date de fin" onChange={(dateEnd) => {this.setState({dateEnd})}}
              containerClassName="mb-3" />
            </div>}
          </div>
        </SidebarLayout>
    );
  }
}

export default formModel<IFileFormProps>(FileForm, {modelClass: Document});
