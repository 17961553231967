import capitalize from "lodash/capitalize";
import Model from './Model';
import Attribute from "./core/Attribute";
import AttributesCollection from "./core/AttributeCollection";
import User from "@models/User";
import ModelAttribute from "./core/ModelAttribute";
import ArrayModelAttribute from "./core/ArrayModelAttribute";
import File from "@models/File";

export default class FileType extends Model {

    public name: string;
    public description: string;
    public file: File;
    public depositor: User;
    public date: Date;

    static modelUrl = "file-types";
    public modelName = "fileType";

    public attributes = new AttributesCollection({
        name: new Attribute("name"),
        description: new Attribute("description"),
        file: new ModelAttribute("file", File),
        depositor: new ModelAttribute("depositor", User),
        date: new Attribute("date"),
    });

    constructor(json) {
        super(json);
        this.fillAttributes(json);
    }

    public formatDescription(): string {
        return `${capitalize(this.name)} - ${this.description}`;
    }

    public detailedInfo(): string {
        let depositorName = this.depositor ? this.depositor.firstname : "Unknown";
        return `File ID: ${this.file}, Deposited by: ${depositorName}`;
    }

}
