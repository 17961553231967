
import React from 'react';
import Model from '../../models/Model';
import ModelResponse from '../../services/apiResponse/ModelResponse';
import EntityManager from '../../services/EntityManager';
import SmallLoading from '../common/SmallLoading';
import { IInjectedRecordManagerProps } from './RecordManager';
import DOMService from '@services/DOMService';

export interface IFormModelProps<M extends Model> extends IInjectedRecordManagerProps<M> {
  id?: any
  newModel?: M
  parentModel?: Model
}

export interface IInjectedFormModelProps<M extends Model> {
  model: M
  submitting: boolean
  submit: () => Promise<void>
  onSubmit: (model: M) => void
}

export interface IFormModelOptions {
  modelClass: any
  noDefaultOption?: boolean
  refreshOnFieldsChange?: string[]
}

const formModel = <P extends IFormModelProps<Model> & IInjectedFormModelProps<Model>>(Component: React.ComponentType<P>, options: IFormModelOptions) => {

  let component = class extends React.Component<P & IInjectedFormModelProps<Model>, any> {

    public model: Model
    public validators;


    constructor(props: any) {
      super(props)

      this.state = {
        submitting: false
      }
    }

    async componentDidMount() {
      let model;
      if (this.props.id) {
        model = (await EntityManager.show(options.modelClass, this.props.id)).model;
      } else if (this.props.newModel) {
        model = this.props.newModel;
      } else {
        model = new options.modelClass({});
        if (!options.noDefaultOption) model.setOptionAttributesToDefault();
      }
      options.refreshOnFieldsChange?.forEach(f => {
        model?.getAttribute(f).listen(() => {
          this.setState({})
        })
      })
      this.model = model;
      this.setState({});
    }

    // public async reload() {
    //   this.setState({model: (await EntityManager.show(options.modelClass, this.props.id)).model});
    // }

    public create = async (model: Model): Promise<ModelResponse<Model>> => {
      this.setState({submitting: true});
      let response = await EntityManager.create(model, {parentModel: this.props.parentModel});
      this.setState({submitting: false});
      return response;
    }

    public update = async (model: Model): Promise<ModelResponse<Model>> => {
      return await EntityManager.update(model, {toaster: true});
    }

    public updateOrCreate = async (model: Model): Promise<ModelResponse<Model>> => {
      if (model.id) return this.update(model);
      else          return this.create(model);
    }

    submit = async () => {
      
      if (!this.model.checkForSubmit()) {
        return false;
      }
      let response: ModelResponse<Model> = await this.updateOrCreate(this.model);
      if (!response.ok) {
        if (response.errors) {
          this.model.attributes.setServerErrors(response.errors)
        } else {
          DOMService.setToaster(response.errorMessage)
        }
        return false;
      }
      if (this.props.onSubmit) this.props.onSubmit(response.model);
      return true;
    }

    public render() {
      if (!this.model) return <SmallLoading className="text-primary" />
      return (
        <Component {...this.props as P} model={this.model} submit={this.submit} />
      )
    }
  }

  return component;
}


export default formModel;

