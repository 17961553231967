import AdminModelsList from '@components/common/AdminModelsList';
import Yard from '@models/Yard';
import * as React from 'react';
import YardGrid from '../components/grid/YardGrid';
import MainLayout from '@components/layout/MainLayout';

export interface IYardPageProps {
}

export default class YardPage extends React.Component<IYardPageProps> {
  public render() {
    return (
      <MainLayout title="Chantiers">
        <div className="app-content">
          <AdminModelsList
            gridClass={YardGrid}
            options={{ loadOnReady: true, paginate: true, modelClass: Yard, defaultState: {filter: JSON.parse(localStorage.getItem("yardGridFilters") || "{}")}}}
          />
        </div>
      </MainLayout>
    );
  }
}
