
import ProfileButton from '@components/common/ProfileButton';
import TextIcon from '@components/common/TextIcon';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { consumeSessionBloc, ISessionBlocConsumer } from '../../bloc/SessionBloc';
import classNames from "classnames";

export interface INavbarProps {
  transparent?: boolean
  shadow?: boolean
  onMinimize?
  minimize?: boolean
  router
}

export interface INavbarState {
}

function Navbar(props: ISessionBlocConsumer & INavbarProps) {
  return (
      <>
        <div className={classNames("navbar", {"navbar-minimized": props.minimize})}>
          <nav className="navbar d-none d-md-flex">
            <div className="row-flex head-up mb-4">
              <ProfileButton/>
      </div>
      <div className="w-100 overflow-hidden">
        {props.router.map(route => (
          <NavLink className="mb-3" key={route.url} to={route.url} exact={route.exact}>
            <div className={"px-3 py-2 rounded row-flex sidebar-link"}>
              <TextIcon spacing={3} leftIcon={"fas " + route.icon} >{!props.minimize ? route.label : null}</TextIcon>
            </div>
          </NavLink>
        ))}
      </div>
      <div className="mt-auto head-up">
          <button className='btn btn-close position-absolute top-0 end-0 mt-2 me-2' onClick={props.onMinimize}></button>

          <div className="fw-bold godin-logo mb-0" style={{fontSize: "1.5rem"}}>Grand Montauban</div>
        <div className="godin-logo text-l">
          {
            window.location.pathname.startsWith("/crm") ? "CRM" : "Gestion de chantier"
          }
        </div>
      </div>
    </nav>
    <nav className="mobile-navbar row-end d-md-none">
      {props.router.map(route => (
        <NavLink className="" key={route.url} to={route.url} exact={route.exact}>
          <div className="mobile-navbar-btn">
            <i className={"fas " + route.icon}></i>
          </div>
        </NavLink>
      ))}
    </nav>
        </div>
        {/* <div className={classNames({"d-flex mt-10 flex-column h-100 justify-content-end": true, "align-items-center": props.minimize, "align-items-end": !props.minimize})}>
          <i onClick={props.onMinimize} className={classNames({"fas fa-chevron-left pointer p-2 text-secondary": true, "fa-rotate-180": props.minimize})}></i>
        </div> */}
    </>
  );
}

export default consumeSessionBloc(React.memo(Navbar, (prevProps, nextProps) => prevProps.logged === nextProps.logged && prevProps.minimize === nextProps.minimize));
