import { EventEmitter } from 'events'
import React, { Suspense } from 'react';

interface Stack<T> {
  props: any
  route: T
}

export default abstract class CustomRouter<T> {

  public stacks: Stack<T>[] = [];
  public position = 0;

  public emitter = new EventEmitter();

  routes = {}

  constructor(routes) {
    this.routes = routes;
  }

  renderRoute(route: T, props = {}) {
    let routes: any = this.routes;
    let Page = routes[route];
    return <Suspense fallback={<div></div>}><Page {...props}/></Suspense>
  }

  listen(callback) {
    this.emitter.on("routeChange", callback);
  }

  dispose(callback) {
    this.emitter.off("routeChange", callback);
    this.resetStacks();
  }

  push(route: T, props = {}) {
    this.position++;
    this.addStack({route, props});
    this.emitter.emit("routeChange")
  }

  to(route: T, props = {}) {
    this.emitter.emit("routeChange");
    this.resetStacks();
  }

  pop() {
    this.position--;
    this.stacks.pop();
    this.emitter.emit("routeChange", this.previousStack());
  }

  addStack(stack) {
    this.stacks.push(stack)
  }

  previousStack() {
    return this.stacks[this.position - 1];
  }

  popable() {
    return this.stacks.length > 1;
  }

  resetStacks() {
    this.position = 0;
    this.stacks = [];
  }

}