import Model from '../../models/Model';
import RecordManagerV2, { IRecordManagerV2State } from '../logic/RecordManagerV2';

export interface IAdminModelsListProps {
  gridClass: any
  options: any
  onRowClick?
  getKey?
}

export default function AdminModelsList(props: IAdminModelsListProps) {

  const Grid = props.gridClass
  let defaultState: IRecordManagerV2State<Model> = {}
  if (Grid.select) defaultState = {filter: {select: Grid.select}}
  const perPage = localStorage.getItem(`gridPerPage${Grid.gridName}`);
  if (perPage) defaultState.perPage = parseInt(perPage)
  return <RecordManagerV2 options={props.options} getRef={props.getKey} defaultState={{...defaultState, ...props.options.defaultState ?? {}}}>
    {(manager, recordProps) => <div className="">
      <Grid
        manager={manager}
        onRowClick={props.onRowClick}
        {...recordProps}
      />
    </div>}
  </RecordManagerV2>
}