import authService from '@services/AuthService';
import * as React from 'react';
import { Redirect } from 'react-router';

export interface ILogoutPageProps {
}

export default function LogoutPage (props: ILogoutPageProps) {

  authService.logout();
  return (
    <Redirect to="/login" />
  );
}
