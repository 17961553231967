import Intervention from '@models/Intervention';
import dayjs from 'dayjs';
import * as React from 'react';

export interface IInterventionItemProps {
  intervention: Intervention
}

export default class InterventionItem extends React.Component<IInterventionItemProps> {
  public render() {
    const { intervention } = this.props;
    return (
      <div className='mb-5'>
        <div className="text-primary fw-bold mb-2">
          Intervention {intervention.ref}
        </div>
        { intervention.status && <>
        <div className=' fw-bold'>
          Status
        </div>
        <div className='mb-4'>
          {intervention.status}
          {intervention.realisedAt && dayjs(intervention.realisedAt).format(" le D MMMM YYYY HH:mm")}
        </div>
        </>
        }
        { intervention.instruction && <>
        <div className=' fw-bold'>
          Instruction
        </div>
        <div className='mb-4' style={{whiteSpace: "pre-line"}}>
          {intervention.instruction}
        </div>
        </>
        }
        { intervention.comment && <>
        <div className=' fw-bold'>
          Commentaire
        </div>
        <div className='mb-4' style={{whiteSpace: "pre-line"}}>
          {intervention.comment}
        </div>
        </>
        }
        { intervention.returnComment && <>
        <div className=' fw-bold'>
          Commentaire retour
        </div>
        <div className='mb-4' style={{whiteSpace: "pre-line"}}>
          {intervention.returnComment}
        </div>
        </>
        }
        { intervention.resultReturn && <>
          <div className=' fw-bold'>
            Résultat
          </div>
          <div>
            {intervention.resultReturn}
          </div>
        </>
        }
      </div>
    );
  }
}
