import TextIcon from '@components/common/TextIcon';
import YardComposition from '@models/YardComposition';
import * as React from 'react';
import ModelSelect, { IModelSelectProps } from './ModelSelect';

export default class YardCompositionSelect extends React.Component<IModelSelectProps> {

  formatOptionLabel = (model: YardComposition) => {
    return <div className="row-flex">
      <TextIcon className="me-3 capitalize" leftIcon={model.prestationType.regularIcon()}>
        <div>{model.prestationType.label}</div>
      </TextIcon>
    </div>
  }

  render() {
    return (
      <ModelSelect<YardComposition> noSearch {...this.props} formatOptionLabel={this.formatOptionLabel} modelClass={YardComposition} />
    );
  }

}
