import SmartSelect from '@components/input/SmartSelect';
import { OptionAttribute } from '@models/core/OptionAttribute';
import Option from '@models/Option';
import OptionService from "@services/OptionService";
import includes from "lodash/includes";
import React from "react";
import { ISmartInputProps } from './SmartInput2';


export interface ISmartOptionSelectProps extends ISmartInputProps {
  subOptionName?: string
  optionName?: string
  options?: Option[]
  clearable?: boolean
  renderOption?: any
  onChange?: any
  containerClassName?: any
}

export default class SmartOptionSelect extends React.Component<ISmartOptionSelectProps, any> {

  public options: Option[];

  constructor(props: any) {
    super(props);

    if (this.props.options) this.options = this.props.options;
    else {
      let optionType;
      if (this.props.model) {
        let optionAttribute = this.props.model.getAttribute(this.props.name) as OptionAttribute;
        optionType = optionAttribute.optionType;
      } else {
        optionType = this.props.optionName;
      }
      this.options = OptionService.get(optionType);
    }

  }

  filterRules = (o, input: string) => {
    return includes(o.label.toLowerCase(), input.toLowerCase());
  }

  renderOption = (o: Option) => {
    return <span>{o.label}</span>
  }

  render() {
    const {model, name, containerClassName, label, value, clearable, onChange, placeholder} = this.props;

    return <SmartSelect
      containerClassName={containerClassName}
      value={value}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      filterRules={this.filterRules}
      label={label}
      model={model}
      clearable={clearable}
      options={this.options}
    />
  }
}

