import { sessionBloc } from '@bloc/SessionBloc';
import AdminModelsList from '@components/common/AdminModelsList';
import TextIcon from '@components/common/TextIcon';
import ContactGrid from '@components/grid/ContactGrid';
import { SidebarRoute } from '@components/layout/SidebarRouter';
import showModel, { IInjectedShowModelProps } from '@components/logic/ShowModel';
import Address from '@models/Address';
import Contact from '@models/Business';
import Contract from '@models/Contract';
import Counter from '@models/Counter';
import Sector from '@models/Sector';
import ApiService from '@services/ApiService';
import DOMService from '@services/DOMService';
import EntityManager from '@services/EntityManager';
import GetX from '@services/GetX';
import OptionService from '@services/OptionService';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';

export interface SectorPageProps extends IInjectedShowModelProps<Sector> {
  id
}

export interface SectorPageState {
  selectedContacts
}

class SectorPage extends React.Component<SectorPageProps, SectorPageState> {

  public options = [
    {
      label: "Map",
      icon: "fa-map",
      action: () => sessionBloc.push("/crm/sectors/"+this.props.model.id+"/map")
    },
    {
      label: "Exporter VIP",
      icon: "fa-file-export",
      action: () => this.exportVip()
  },
  ]

  constructor(props: SectorPageProps) {
    super(props);

    this.state = {
      selectedContacts: [],
    }
  }

  componentDidMount(): void {
    this.loadQuartier()
  }

  async loadQuartier() {
    await OptionService.loadQuartier(this.props.id);
    this.setState({})
  }

  async exportVip() {
    let response = await ApiService.post("dashboard/crm/export/vip", {sectorId: this.props.model.id});
    let blob=new Blob([response.data]);
    let link=document.createElement('a');
    link.href=window.URL.createObjectURL(blob);
    link.download= "VIP.csv";
    link.click();
  }

  toggleContactSelection = (contact) => {
    this.setState(prevState => {
      const isSelected = prevState.selectedContacts.find(c => c.id === contact.id);
      if (isSelected) {
        return { selectedContacts: prevState.selectedContacts.filter(c => c.id !== contact.id) };
      } else {
        return { selectedContacts: [...prevState.selectedContacts, contact] };
      }
    });
  };


  public render() {
    const { model } = this.props;
    return (
        <div className="home">
          <div className="px-0 pt-3 px-md-5">
            <div className="app-content">
              <div className="row-flex mb-3">
                <i onClick={() => sessionBloc.push('/crm/sectors')} className="fas fa-chevron-left pe-4 pointer"></i>
                <h2 className='mb-0 text-capitalize'>
                  <span>{model.name}</span>
                  <span className='ms-3 fs-4 fw-light'>{model.customer.name}</span>
                </h2>
                <div className="ms-auto d-flex">
                  <button
                      onClick={() => DOMService.openSidebar(SidebarRoute.ContactForm, {
                        onSubmit: () => {
                          GetX.get("ContactGrid")?.loadModels();
                          DOMService.closeSidebar();
                        }, newModel: new Contact({
                          sectorId: this.props.model?.id,
                          contract: new Contract({}),
                          counter: new Counter({connectionAddress: new Address({})})
                        })
                      })
                      }
                      className="btn btn-primary me-2"
                  >
                    <i className="fas fa-plus"></i> Créer une nouvelle entreprise
                  </button>

                  <Dropdown>
                    <Dropdown.Toggle
                        as={React.forwardRef(({children, onClick}: any, ref: React.LegacyRef<HTMLButtonElement>) => (
                            <button ref={ref} onClick={onClick} className="btn text-white" style={{backgroundColor:'#0a3d6d'}}>
                              ...
                            </button>
                        ))}/>
                    <Dropdown.Menu align="end" className="fade" style={{marginTop: "1rem"}}>
                      {this.options.map(opt => (
                          <Dropdown.Item key={opt.label} onClick={opt.action}>
                            <TextIcon width={4} leftIcon={"fas " + opt.icon}>
                              {opt.label}
                            </TextIcon>
                          </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <AdminModelsList
                  gridClass={ContactGrid}
                  key="ContactGrid"
                  getKey="ContactGrid"
                  options={{
                    modelClass: Contact,
                    loadOnReady: true,
                    paginate: true,
                    parentModel: model,
                    defaultState: {filter: JSON.parse(localStorage.getItem("contactGridFilters") || "{}")}
                  }}
              />
            </div>
          </div>
        </div>
    );
  }
}

export default showModel(SectorPage, {modelClass: Sector}) as any
