import SidebarLayout from '@components/layout/SidebarLayout';
import Exchange from '@models/Exchange';
import * as React from 'react';

export interface ISentDetailProps {
  exchange: Exchange
}

export default class SentDetail extends React.Component<ISentDetailProps> {
  public render() {
    const { exchange } = this.props;
    return (
      <SidebarLayout
        title={"Detail"}
      >
        <div className="fadeInDown">
          <div className="mb-3">
            {exchange.getTitle()}
          </div>
          <div>
            { exchange.data.subject &&
              <>
                <div className='text-secondary text-s'>
                Objet
                </div>
                <div className='py-3 px-3 mb-3 bg-light'>
                  {exchange.data.subject}
                </div>
              </>
            }
            <div className='text-secondary text-s' >
            Corps
            </div>
            <div className='py-3 px-3 bg-light' style={{whiteSpace: "pre-line"}}>
              {exchange.data.text}
            </div>
          </div>
        </div>
      </SidebarLayout>
    );
  }
}
