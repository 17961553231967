import { sessionBloc } from '@bloc/SessionBloc';
import AsyncButton from '@components/button/AsyncButton';
import { DummyForm } from '@components/form/DummyForm';
import SmartInputText from '@components/input/SmartInputText';
import NoAppLayout from '@components/layout/NoAppLayout';
import User from '@models/User';
import ModelResponse from '@services/apiResponse/ModelResponse';
import authService from '@services/AuthService';
import EntityManager from '@services/EntityManager';
import classNames from 'classnames';
import { parseUrl } from 'query-string';
import * as React from 'react';
import { Redirect } from 'react-router';
import { ILoginPageProps } from './LoginPage';

export interface IFirstLoginPageProps {
}

export interface IFirstLoginPageState {
  [arg: string]: any
  email: string
  password: string
  isChecking: boolean
  redirect: string
  errors: {[name: string]: string}
}

export default class FirstLoginPage extends React.Component<IFirstLoginPageProps, IFirstLoginPageState> {
  constructor(props: ILoginPageProps) {
    super(props);
    let data = parseUrl(window.location.href);
    
    this.state = {
      email: data.query?.email as string,
      userId: data.query?.id,
      password: "",
      isChecking: false,
      redirect: null,
      errors: null
    }
  }

  onKeyPressed = (e) => {
    if (e.key === "Enter") this.submit()
  }

  handleChange = (value, e) => {
    this.setState({[e.target.name]: value, errors: null})
  }

  onKeyPress = (e) => {
    if (e.key === "Enter") this.submit()
  }

  submit = async () => {
    const {userId, email, password} = this.state;
    let params = {email, password}
    let response: ModelResponse<User> = await EntityManager.post<User>(User, {path: userId + "/finalise", params});
    if (response.ok) {
      let url = "/";
      if (response.model.isAdmin) url += "yards"
      await sessionBloc.setSessionData(response.model);
      authService.storeToken(response.response.data.token);
      this.setState({redirect: url})
    }
  }

  public render() {
    const {email, password, errors, redirect} = this.state;
    if (redirect) return <Redirect to="/" />
    return (
      <NoAppLayout>
        <h3 className="fw-bold mb-5 text-center">
        Finalisation de votre compte
        </h3>
        <DummyForm submit={this.submit} formProps={{noValidate: true}}>
          {(loading, validity) =>
            <>
              <SmartInputText type="email" containerClassName="mb-3" className={classNames({"is-invalid": !!errors})} label="Adresse Email" value={email} name="email" required onChange={this.handleChange} autoFocus/>
              <SmartInputText id="password" type="password" className={classNames({"is-invalid": !!errors})} label="Créer mon mot de passe" name="password" value={password} required onChange={this.handleChange}/>
              {errors && <div className="text-danger mt-1">{errors.credential}</div>}
              <div className="text-end link mt-3 mb-5">
                <div>Mot de passe oublié ?</div>
              </div>
              <div className="text-center">
                <AsyncButton  loading={loading}  type="submit" className="btn btn-primary m-auto w-100 text-dark-primary">
                  CREER MON COMPTE
                </AsyncButton>
              </div>
            </>
          }
        </DummyForm>
      </NoAppLayout>
    );
  }
}
