import Contact from "@models/Business";
import React from "react";
import ModelSelect, { IModelSelectProps } from "./ModelSelect";

export default class ContactSelect extends React.Component<IModelSelectProps> {

  formatOptionLabel = (model: Contact) => {
    return <div className="row-flex">
      <div className="me-3 capitalize">
        <div>{model.getFullName()}</div>
      </div>
    </div>
  }

  render() {
    return (
      <ModelSelect<Contact> {...this.props} formatOptionLabel={this.formatOptionLabel} attributeRef="firstname" modelClass={Contact} />
    );
  }

}
