import EntityManager from "./EntityManager";

class HistoryService {

  private items = [];

  setIn(data, type: string) {
    this.items.push({data, type});
  }

  undo = async () => {
    if (this.items.length === 0) return null;
    let last = this.items.pop();
    this.processUndo(last);
  }

  processUndo(last) {
    if (last.type === "update") return EntityManager.update(last.data);
    if (last.type === "delete") return EntityManager.create(last.data);
    if (last.type === "create") return EntityManager.delete(last.data);
    if (last.type === "multiple") {
      last.data.forEach((child) => {
        this.processUndo(child)
      });
    }
  }
}

export default new HistoryService();