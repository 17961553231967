import * as React from 'react';
import SmallLoading from '../common/SmallLoading';

export interface IAsyncButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
  loadingColor?: string
  loading: boolean
  children: any
}

export default class AsyncButton extends React.Component<IAsyncButtonProps, {loading: boolean}> {

  render() {
    const {loadingColor, loading, children, ...other} = this.props;
    return (
      <button {...other} >{loading ? <SmallLoading className={loadingColor} /> : children}</button>
    );
  }
}
