import CustomEmail from '@models/CustomEmail';
import * as React from 'react';

export interface IPreviewTemplateProps {
  template: CustomEmail
}

export default class PreviewTemplate extends React.Component<IPreviewTemplateProps> {
  public render() {
    const {template} = this.props;
    return (
      <div>
        { template.type === "email" &&
          <>
            <div className='text-secondary text-s'>
            Objet
            </div>
            <div className='py-3 px-3 mb-3' style={{border: "1px dashed #e3e3e3"}}>
              {template.getRealObjectWith()}
            </div>
          </>
        }
        <div className='text-secondary text-s' >
        Corps
        </div>
        <div className='py-3 px-3' style={{border: "1px dashed #e3e3e3", whiteSpace: "pre-line"}}>
          {template.getRealContentWith()}
        </div>
      </div>
    );
  }
}
