import AsyncButton from '@components/button/AsyncButton';
import SmartInputText from '@components/input/SmartInputText';
import formModel, {IFormModelProps, IInjectedFormModelProps} from '@components/logic/FormModel';
import Address from '@models/Address';
import DOMService from '@services/DOMService';
import * as React from 'react';
import SidebarLayout from '../layout/SidebarLayout';
import ApiService from "@services/ApiService";

export interface IAddressFormProps extends IInjectedFormModelProps<Address> {
    parentModel: Address
    isAddingNew: boolean
    contactId: number | null
}

export interface IAddressFormState {
    isEditing: boolean;
}

class AddressForm extends React.Component<IAddressFormProps & IFormModelProps<Address>, IAddressFormState> {
    constructor(props: IAddressFormProps & IFormModelProps<Address>) {
        super(props);

        this.state = {
            isEditing: props.isAddingNew
        };
    }

    submit = async () => {
        const {parentModel, isAddingNew, model} = this.props;
        const updatedData = {
            address: model.address,
            number: model.number,
            city: model.city,
            cp: model.cp,
            addressComp: model.addressComp,
        };
        try {
            if (isAddingNew) {
                await ApiService.post(`contacts/${this.props.contactId}/add-address`, updatedData);
            } else {
                await ApiService.post(`address/${model.id}`, updatedData);
            }
        } catch (error) {
            console.error("Error submitting address information:", error);
        }
        this.setState({isEditing: false});
        window.location.reload();
    };
    toggleEdit = () => {
        this.setState(prevState => ({
            isEditing: !prevState.isEditing
        }));
    }

    public render() {
        const { parentModel, submitting, isAddingNew, model } = this.props;
        const { isEditing } = this.state;
        const title = isAddingNew ? "Ajout d'une nouvelle adresse" : (isEditing ? "Modification d'une adresse" : "Détails de l'adresse");
        const buttonLabel = isEditing ? "Enregistrer" : "Modifier l'adresse";

        return (
            <SidebarLayout
                title={title}
                bottomArea={
                    <>
                        {isEditing && (
                            <>
                                <AsyncButton
                                    className="btn btn-primary w-100 btn-lg"
                                    loading={submitting}
                                    onClick={this.submit}>
                                    {buttonLabel}
                                </AsyncButton>
                                <button
                                    onClick={this.toggleEdit}
                                    className="btn btn-secondary w-100 mt-2">
                                    Annuler
                                </button>
                            </>
                        )}
                        {!isEditing && (
                            <button onClick={this.toggleEdit} className="btn btn-primary w-100 btn-lg">
                                Modifier l'adresse
                            </button>
                        )}
                    </>
                }
            >
                {isEditing ? (
                    <div className="fadeInDown">
                        <div className="row">
                            <div className='col-md-6 col-12'>
                                <SmartInputText model={model} name="address" label="Numéro et Nom de l'adresse" containerClassName="mb-3"/>
                            </div>
                            <div className='col-md-6 col-12'>
                                <SmartInputText model={model} name="city" label="Ville" containerClassName="mb-3"/>
                            </div>
                            <div className='col-12'>
                                <SmartInputText model={model} name="addressComp" label="Suppléments d'adresse" containerClassName="mb-3"/>
                            </div>
                            <div className='col-md-6 col-12'>
                                <SmartInputText model={model} name="cp" label="Code postal" containerClassName="mb-3"/>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="mb-3"><strong>Numéro et Nom de l'adresse:</strong> {model.address}</div>
                        <div className="mb-3"><strong>Ville:</strong> {model.city}</div>
                        <div className="mb-3"><strong>Complément d'adresse:</strong> {model.addressComp || '-'}</div>
                        <div className="mb-3"><strong>Code Postale:</strong> {model.cp}</div>
                    </div>
                )}
            </SidebarLayout>
        );
    }
}

export default formModel<IAddressFormProps>(AddressForm, {modelClass: Address});
